import React , { createRef }from 'react'
import { SketchField } from '../../Common/CustomSketchField'
import { Page } from 'react-pdf'
//import { Page } from 'react-pdf/dist/esm/entry.webpack'

const fabric = require('fabric').fabric

const Tools = {
    Circle: 'circle',
    Line: 'line',
    Pencil: 'pencil',
    Rectangle: 'rectangle',
    Select: 'select',
    Pan: 'pan'
}

export class CanvasPageCopy extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            pageNumber: 1,
            fillColor: 'transparent',
            width: null,
            height: null,
            canUndo: false,
            canRedo: false,
            divPrint: 'div-to-print-portrait',
            canvasData: [],
            canvasDataHighlightingMixed: [],
            tool: Tools.Select,
            history: [],
            modal: false,
            isRendered: 0,
            removeSelectedBtnDisabled: false,
            totalPages: null,
            alreadyRedactedElements: [],
            alreadyRedactedElementsMixed: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log('WillReceiveProps BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
        if (nextProps.forPageNumber === nextProps.pageNumber) {
            this.props.setSketch(this._sketch)
        }
        if (nextProps.tool !== this.props.tool) {
            this.setState({
                tool: nextProps.tool
            })
        }
        if(nextProps.clearSketch !== this.props.clearSketch && nextProps.clearSketch && this._sketch) {
            const existingObjects = this._sketch._fc._objects
            const newObjects = []
            for(let i=0; i<existingObjects.length; i++) {
                newObjects.push(existingObjects[i])
            }
            this._sketch.clear()
            for(let i=0; i<newObjects.length; i++) {
                if(newObjects[i].annotation === this.props.drawByHandText
                    || (typeof newObjects[i].type !== 'undefined' && newObjects[i].type === 3) ) {
                    const relatedObj = newObjects[i]
                    const rect = new fabric.Rect({
                        left: relatedObj.left,
                        top: relatedObj.top,
                        width: relatedObj.width,
                        height: relatedObj.height,
                        fill: 'rgba(0, 0, 0, 0.4)' ,
                        angle: 0,
                        padding: 0,
                        selectable: true,
                        text: relatedObj.text ? relatedObj.text : '',
                        annotation: relatedObj.annotation
                    })
                    this._sketch._fc.add(rect)
                    this._sketch._fc.renderAll()
                }
            }
        }

        this.setState({
            pageNumber: nextProps.pageNumber,
            canvasData: nextProps.canvasData,
                canvasDataHighlightingMixed: nextProps.canvasDataHighlightingMixed,
            totalPages: nextProps.totalPages,
            divPrint: nextProps.divPrint,
            width: nextProps.width,
            height: nextProps.height
        }, () =>
            //this.loadPoints
            this.loadPointsUpdate
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.statusHideAllHighlightingMixed !== this.props.statusHideAllHighlightingMixed && this.props.statusHideAllHighlightingMixed === false) {
            //console.log('Update 1111111111111111111111111111111111111111111111111')
            this.setState({
                    pageNumber: this.props.pageNumber,
                    canvasData: this.props.canvasData,
                    canvasDataHighlightingMixed: this.props.canvasDataHighlightingMixed,
                    totalPages: this.props.totalPages,
                    divPrint: this.props.divPrint,
                    width: this.props.width,
                    height: this.props.height
                }, () => {
                //this.loadPointsHideAllHighlightingMixed(this.props.statusHideAllHighlightingMixed)
                this.loadPointsHideAllHighlightingMixedUpdate(this.props.statusHideAllHighlightingMixed)
                }
            )

        }
        if (prevProps.statusSaveSmartviewMixed2 !== this.props.statusSaveSmartviewMixed2 && this.props.statusSaveSmartviewMixed2 === 200) {
            //console.log('Update 2222222222222222222222222222222222222222222222')
            this.setState({
                    pageNumber: this.props.pageNumber,
                    canvasData: this.props.canvasData,
                    canvasDataHighlightingMixed: this.props.canvasDataHighlightingMixed,
                    totalPages: this.props.totalPages,
                    divPrint: this.props.divPrint,
                    width: this.props.width,
                    height: this.props.height
                }, () => {
                    //this.loadPointsHideAllHighlightingMixed(this.props.statusHideAllHighlightingMixed)
                    this.loadPointsHideAllHighlightingMixedUpdate(this.props.statusHideAllHighlightingMixed)
                    this.props.onResetStatusSaveSmartviewMixed2()
                }
            )

        }
        if (prevProps.statusHideAllHighlightingMixed !== this.props.statusHideAllHighlightingMixed && this.props.statusHideAllHighlightingMixed === true) {
            //console.log('Update 3333333333333333333333333333333333333333333333333')
            const canvas = (this._fc = new fabric.Canvas(
                this._canvas /*, {
         }*/
            ))
            this.setState({
                canvas,
                    pageNumber: this.props.pageNumber,
                    canvasData: this.props.canvasData,
                    canvasDataHighlightingMixed: this.props.canvasDataHighlightingMixed,
                    totalPages: this.props.totalPages,
                    divPrint: this.props.divPrint,
                    width: this.props.width,
                    height: this.props.height
                }, () => {
                    //console.log('UDPATE UUUUUUUUU 2222222222222222222222222222222222222222 33333333333333333')
                    //this.loadPointsHideAllHighlightingMixed(this.props.statusHideAllHighlightingMixed)
                    this.loadPointsHideAllHighlightingMixedUpdate(this.props.statusHideAllHighlightingMixed)
                }
            )

        }
    }

    loadPointsHideAllHighlightingMixedUpdate = (statusHideAllHighlightingMixed) => {
        const { alreadyRedactedElements } = this.state
        const { isOcr } = this.props
        if (this.state.canvasData) {
            if (this._sketch) {
                this._sketch.clearTemporary()
            }
            const elements = this.state.canvasData
            const elemstsJson = []
            const elemstsJson2 = []
            elements.forEach((item) => {
                if (typeof item === 'string' && JSON.parse(item)) {
                    elemstsJson.push(JSON.parse(item))
                } else if (typeof item === 'object' && item) {
                    elemstsJson2.push(item)
                    elemstsJson.push(item)
                }
            })
                this._sketch.fillPointsHighlightingMixedUpdate(elemstsJson, statusHideAllHighlightingMixed)
        }
        this.setState({ alreadyRedactedElements }, () => {
            if (this.props.setAlreadyRedactedList) {
                this.props.setAlreadyRedactedList()
            }
        })
    }
    loadPointsHideAllHighlightingMixed = (statusHideAllHighlightingMixed) => {
        const { alreadyRedactedElements } = this.state
        const { isOcr } = this.props
        const alreadyRedactedElementsMixedDefault = []
        const alreadyRedactedElementsMixed = []
         //console.log('this.state.canvasData aaaaaaaaaaaaaaaaaaaaaa', this.state.canvasData)
        // console.log('this.state.canvasDataHighlightingMixed aaaaaaaaaaaaaaaaaaaaaa', this.state.canvasDataHighlightingMixed)
        //console.log('statusHideAllHighlightingMixed aaaaaaaaaaaaaaaaaaaaaa', statusHideAllHighlightingMixed)
            if (this.state.canvasData) {
                if (this._sketch) {
                    this._sketch.clearTemporary()
                }
                if (this.state.canvasData) {
                    const elements = this.state.canvasData
                    const elemstsJson = []
                    const elemstsJson2 = []
                    elements.forEach((item) => {
                        //console.log('typeof  aaaaaaaaaaaaaaaaaaaaaa', typeof item)
                        //console.log('JSON.parse(item) aaaaaaaaaaaaaaaaaaaaaa', JSON.parse(item))
                            if (typeof item === 'string' && JSON.parse(item)) {
                                elemstsJson.push(JSON.parse(item))
                            } else if (typeof item === 'object' && item) {
                                elemstsJson2.push(item)
                                elemstsJson.push(item)
                            }
                    })
                    // console.log('elemstsJson TTTTTTTTTTTTTTTTTTTTT', elemstsJson)
                    // console.log('elemstsJson2 TTTTTTTTTTTTTTTTTTTTT', elemstsJson2)
                    // fill rectangle and annotation
                    elemstsJson.forEach((point) => {

                        if (this._sketch && point) {
                           if (statusHideAllHighlightingMixed) {
                               //console.log('point ccccccccccccccccccccccccccccc 5555555555555555555555555555555', point)
                               if (point.defaultSmartviewOCR) {
                                   if (!alreadyRedactedElementsMixedDefault.includes(point.text + point.x1 + point.y1)) {
                                       if (point.color) {
                                           ///console.log('point ccccccccccccccccccccccccccccc 5555555555555555555555555555555', point)
                                           this._sketch.fillPointsHighlightingMixed(point, point.color)
                                       } else {
                                           this._sketch.fillPointsHighlightingMixed(point, 'rgba(0,0,0,0.3)')
                                       }
                                       alreadyRedactedElementsMixedDefault.push(point.text + point.x1 + point.y1)
                                   }
                               } else {
                                   if (!alreadyRedactedElementsMixed.includes(point.text + point.x1 + point.y1)) {
                                       if (point.color) {
                                           ///console.log('point ccccccccccccccccccccccccccccc 5555555555555555555555555555555', point)
                                           this._sketch.fillPointsHighlightingMixed(point, point.color)
                                       } else {
                                           this._sketch.fillPointsHighlightingMixed(point, 'rgba(0,0,0,0.3)')
                                       }
                                       alreadyRedactedElementsMixed.push(point.text + point.x1 + point.y1)
                                   }
                               }
                            } else {
                               ///console.log('point ccccccccccccccccccccccccccccc 5555555555555555555555555555555 2222222222222222222222222222222', point)
                               if (point.text === 'picture') {
                                   this._sketch.fillPointsHighlightingMixed(point, 'transparent')
                               } else {
                                   this._sketch.fillPointsHighlightingMixed(point, 'rgba(0,0,0,0.4)')
                               }
                           }
                        }
                    })
                }
            }
        this.setState({ alreadyRedactedElements }, () => {
            if (this.props.setAlreadyRedactedList) {
                this.props.setAlreadyRedactedList()
            }
        })
    }
    componentDidMount() {
        const canvas = (this._fc = new fabric.Canvas(
            this._canvas /*, {
         }*/
        ))
        if (this.props.forPageNumber === this.props.pageNumber) {
            this.props.setSketch(this._sketch)
        }
        this.setState({
            tool: this.props.tool
        })
        //const that = this
        // if (this.props.pageNumber === this.props.totalPages) {
        //     if (this.props.totalPages <= 10) {
        //         setTimeout(function() {
        //             that.props.loadingFinished()
        //         }, Math.round((this.props.totalPages * 0.25) * 1000))
        //     } else if (this.props.totalPages > 10 && this.props.totalPages <= 30) {
        //         setTimeout(function() {
        //             that.props.loadingFinished()
        //         }, Math.round((this.props.totalPages * 0.35) * 1000))
        //     } else if (this.props.totalPages > 30 && this.props.totalPages <= 60) {
        //         setTimeout(function() {
        //             that.props.loadingFinished()
        //         }, Math.round((this.props.totalPages * 0.45) * 1000))
        //     } else if (this.props.totalPages > 60 && this.props.totalPages <= 100) {
        //         setTimeout(function() {
        //             that.props.loadingFinished()
        //         }, Math.round((this.props.totalPages * 0.60) * 1000))
        //     } else if (this.props.totalPages > 100) {
        //         setTimeout(function() {
        //             that.props.loadingFinished()
        //         }, Math.round((this.props.totalPages * 0.75) * 1000))
        //     }
        // }

        //console.log('Mount AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
        //canvas.renderOnAddRemove = false
        this.setState({
            canvas,
            canvasData: this.props.canvasData,
            canvasDataHighlightingMixed: this.props.canvasDataHighlightingMixed,
            pageNumber: this.props.pageNumber,
            totalPages: this.props.totalPages,
            loading: this.props.loading,
            width: this.props.width,
            height: this.props.height
        }, () => {
            //this.loadPoints()
            this.loadPointsUpdate()
        })

    }
    loadPointsUpdate = () => {
        const { alreadyRedactedElements } = this.state
        const canvas = (this._fc = new fabric.Canvas(
            this._canvas /*, {
         }*/
        ))
        //console.log('this.state.canvasData aaaaaaaaaaaaaaaaaaaaaa', this.state.canvasData)
        if (this.state.canvasData) {
            if (this._sketch) {
                this._sketch.clearTemporary()
            }
            if (this.state.canvasData) {
                const elements = this.state.canvasData
                const elemstsJson = []
                elements.forEach((item) => {
                    if (JSON.parse(item)) {
                        elemstsJson.push(JSON.parse(item))
                    }
                })
                canvas.renderOnAddRemove = false
                if (this._sketch) {
                    this._sketch.fillPointsUpdate(elemstsJson)
                }
            }
        }

        this.setState({ alreadyRedactedElements }, () => {
            if (this.props.setAlreadyRedactedList) {
                this.props.setAlreadyRedactedList()
            }
        })
    }
    loadPoints = () => {
        const { alreadyRedactedElements } = this.state
        const { isOcr } = this.props
        //console.log('this.state.canvasData aaaaaaaaaaaaaaaaaaaaaa', this.state.canvasData)
        const alreadyRedactedElementsMixedDefault = []
        const alreadyRedactedElementsMixed = []
            if (this.state.canvasData) {
                if (this._sketch) {
                    this._sketch.clearTemporary()
                }
                if (this.state.canvasData) {
                    const elements = this.state.canvasData
                    const elemstsJson = []
                    elements.forEach((item) => {
                        if (JSON.parse(item)) {
                            elemstsJson.push(JSON.parse(item))
                        }
                    })
                    // fill rectangle and annotation
                    elemstsJson.forEach((point) => {
                        // console.log('point POINTS 1111111111111111111111111111111', point)
                        // console.log('point alreadyRedactedElements 1111111111111111111111111111111', alreadyRedactedElements)
                        if (this._sketch && point) {
                            if (point.defaultSmartviewOCR) {
                                if (!alreadyRedactedElementsMixedDefault.includes(point.text + point.x1 + point.y1)) {
                                    if (point.color) {
                                        this._sketch.fillPoints(point, point.color)
                                    } else {
                                        this._sketch.fillPoints(point, 'rgba(0,0,0,0.3)')
                                    }
                                    if (point.text) {
                                        alreadyRedactedElementsMixedDefault.push(point.text + point.x1 + point.y1)
                                    }
                                }
                            } else {
                                if (!alreadyRedactedElementsMixed.includes(point.text + point.x1 + point.y1)) {
                                    if (point.color) {
                                        this._sketch.fillPoints(point, point.color)
                                    } else {
                                        this._sketch.fillPoints(point, 'rgba(0,0,0,0.3)')
                                    }
                                    if (point.text) {
                                        alreadyRedactedElementsMixed.push(point.text + point.x1 + point.y1)
                                    }
                                }
                            }
                        }
                    })
                }
            }

        this.setState({ alreadyRedactedElements }, () => {
            if (this.props.setAlreadyRedactedList) {
                this.props.setAlreadyRedactedList()
            }
        })
    }

    fill = (color, documentId, page, alpha, canvasData) => {
        if (this.props.isOcr) {
            this._sketch.fillRectanglesOcr(color,documentId, page, alpha, canvasData)
        } else {
            this._sketch.fillRectangles(color,documentId, page, alpha, canvasData)
        }
    }
    handleRenderSuccess = () => {
        const { handleRenderPageCopy, pageNumber } = this.props;
        if (handleRenderPageCopy) {
            handleRenderPageCopy(pageNumber)
        }
    }
    render() {
        const { pageNumber } = this.state
        const { scale, pageNumber2, isDocumentReady, isDocumentReadySketchField } = this.props
        // console.log('isDocumentReady ---- RENDER SketchField SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', isDocumentReady)
        //  console.log('isDocumentReadySketchField ---- RENDER SketchField SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', isDocumentReadySketchField)
        //  console.log('isDocumentReady ---- RENDER SketchField SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', isDocumentReady)
        // console.log('this.props ---- RENDER SketchField SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', this.props)
        return (
            <div id={this.state.divPrint} data-pageid={pageNumber} className={pageNumber === 1 ? 'show-pdf-page' : ''}>
                <React.Fragment>
                    <Page pageNumber={pageNumber}
                          key={`page_${pageNumber}`}
                          scale={scale}
                          renderAnnotationLayer={true}
                          renderTextLayer={true}
                          fontName={'ubuntu'}
                          //onRenderSuccess={ () => this.props.handleRenderPage(pageNumber) }
                          onRenderSuccess={this.handleRenderSuccess}
                          options={{
                              disableFontFace: true,
                              fontTypes: true,
                              styles: { fontFamily: 'roboto' }
                          }}
                    />
                        <SketchField
                            ref={(c) => (this._sketch = c)}
                            //ref={(el) => (this.sketchRef.current = el)}
                            className={`canvas-area canvas-page-${pageNumber}`}
                            width={this.state.width}
                            height={this.state.height}
                            tool={this.state.tool}
                            lineColor='black'
                            fillColor={this.state.fillColor}
                            background='transparent'
                            lineWidth={2}
                            pageNumber={pageNumber}
                            selectTool={this.props.selectTool}
                            selectRectangle={this.props.selectRectangle}
                            showAnnotationsList={this.props.showAnnotationsList}
                            hideAnnotationsList={this.props.hideAnnotationsList}
                            isModalShow={this.props.isModalShow}
                            selectObject={this.props.selectObject}
                            renderSelectDraw={this.props.renderSelectDraw}
                            renderSelectText={this.props.renderSelectText}
                            drawByHandText={this.props.drawByHandText}
                            onObjectAdded={(obj) => {
                                this.props.addObjectToCanvas(obj)
                            }}
                            onEndPoints={this.props.onEndPoints}
                            isOcr={this.props.isOcr}
                            renderCommentsText={this.props.renderCommentsText}
                        />
                </React.Fragment>
            </div>
        )
    }
}

export default CanvasPageCopy