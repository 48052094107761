import React from 'react'
import { getImageSrc } from '../../../utils/SmartBox/SmartBoxMethods'
import { Tooltip, Icon, Button } from 'antd'
import imgAnalysed from '../../../assets/analysed.png'
import {apiGetFolderMetadata, apiGetSmartBoxMetadata, setFileStatus} from '../../../api/SmartBox'
import moment from 'moment-timezone'
import './MetadataPage.scss'
import Select, { components } from "react-select";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {connect} from "react-redux";
import {setSmartBoxStatusChange} from "../../../actions/smartBoxActions";

class MetadataPage extends React.Component {
  state = {
    selectedFileStatus: '',
    selectedColorFileStatus: '',
    defaultSelectedValue: -1,
    customerStatus: [
      { icon: '', label: 'None', value: 'None', valueDisplay: '-'  },
      { icon: 'edit', label: 'For Review', value: 'redacted', valueDisplay: '-'  },
      { icon: 'like', label: 'Approved', value: 'approved', valueDisplay: '-'  },
      { icon: 'mail', label: 'Sent', value: 'sent', valueDisplay: '-' },
    ],
    listMetadata: {},
    isUpdateFolderStatusLoading:false,
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { customerStatus } = this.state
    let { defaultSelectedValue } = this.state
    if (this.props.updateMetadataPage && this.props.documentId && (prevProps.updateMetadataPage !== this.props.updateMetadataPage ||
        prevProps.documentId !== this.props.documentId)) {
      if (this.props && this.props.documentType === 'DIRECTORY') {
        this.setState({
          listMetadata: {}
        }, () => {
          apiGetFolderMetadata(this.props.documentId).then((response) => {
            if (response.status === 200) {
              const listMetadata = response.data
              listMetadata.name = response.data.title
              listMetadata.owner = response.data.authors ? response.data.authors[0] : '-'
              listMetadata.created= response.data.dateCreated ? response.data.dateCreated : '-'
              listMetadata.modified= response.data.dateModified ? response.data.dateModified : '-'
              listMetadata.authors= response.data.authors.length ? response.data.authors.join(', ') : '-'
              listMetadata.availability= response.data.availableOffline ? 'Available online' : 'Available offline'
              listMetadata.sharedWith= response.data.shareWith && response.data.shareWith.length ? response.data.shareWith.length : '-'
              listMetadata.location= response.data.location ? response.data.location : 'Server'
              listMetadata.documents= response.data.documents
              listMetadata.folders= response.data.folder
              listMetadata.aiFinished= false
              if ( customerStatus && customerStatus.length ) {
                const defaultSelectedValueString = response.data.systemPropertyFileStatus
                customerStatus.forEach( (status, index) => {
                  if (defaultSelectedValueString === 'sent') {
                    defaultSelectedValue = 3
                    customerStatus[1].valueDisplay = response.data.redactedUsername && response.data.redactedUsername !== 'NULL NULL' ? response.data.redactedUsername : '-'
                    customerStatus[2].valueDisplay = response.data.approvedUsername && response.data.approvedUsername !== 'NULL NULL' ? response.data.approvedUsername : '-'
                    customerStatus[3].valueDisplay = response.data.sentUsername && response.data.sentUsername !== 'NULL NULL' ? response.data.sentUsername : '-'
                  } else if (defaultSelectedValueString === 'approved') {
                    defaultSelectedValue = 2
                    customerStatus[1].valueDisplay = response.data.redactedUsername && response.data.redactedUsername !== 'NULL NULL' ? response.data.redactedUsername : '-'
                    customerStatus[2].valueDisplay = response.data.approvedUsername && response.data.approvedUsername !== 'NULL NULL' ? response.data.approvedUsername : '-'
                    customerStatus[3].valueDisplay = response.data.sentUsername && response.data.sentUsername !== 'NULL NULL' ? response.data.sentUsername : '-'
                  } else if (defaultSelectedValueString === 'redacted') {
                    defaultSelectedValue = 1
                    customerStatus[1].valueDisplay = response.data.redactedUsername && response.data.redactedUsername !== 'NULL NULL' ? response.data.redactedUsername : '-'
                    customerStatus[2].valueDisplay = response.data.approvedUsername && response.data.approvedUsername !== 'NULL NULL' ? response.data.approvedUsername : '-'
                    customerStatus[3].valueDisplay = response.data.sentUsername && response.data.sentUsername !== 'NULL NULL' ? response.data.sentUsername : '-'
                  } else {
                    defaultSelectedValue = -1
                  }
                })
              }
              this.setState({ listMetadata, defaultSelectedValue })
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        })
      } else if (this.props.documentId) {
        const getMetadataDTO = {
          docId: this.props.documentId
        }
        this.setState({
          listMetadata: {}
        }, () => {
          apiGetSmartBoxMetadata(getMetadataDTO).then((response) => {
            if (response.status === 200) {
              const listMetadata = {
                name: response.data.title,
                numberPagesFiles: response.data.docPageCount ? response.data.docPageCount : 0,
                size: response.data.size ? response.data.size : 0,
                owner: response.data.authors ? response.data.authors[0] : '-',
                created: response.data.dateCreated ? response.data.dateCreated : '-',
                modified: response.data.dateModified ? response.data.dateModified : '-',
                authors: response.data.authors.length ? response.data.authors.join(', ') : '-',
                availability: response.data.availableOffline ? 'Available online' : 'Available offline',
                sharedWith: response.data.shareWith.length ? response.data.shareWith.join(', ') : '-',
                location: response.data.location ? response.data.location : 'Server',
                aiFinished: response.data.aiFinished,
                ananlyticResultValue: response.data.totalAnnotations ? response.data.totalAnnotations : 0,
                regularExpressionResultValue: response.data.totalRedaction ? response.data.totalRedaction : 0,
                manualTermResultValue: response.data.manualTermResultValue ? response.data.manualTermResultValue : 0,
                businessTermResultValue: response.data.businessTermResultValue ? response.data.businessTermResultValue : 0,
                dateUpload: response.data.dateUpload ? response.data.dateUpload : '-'
              }
              if ( customerStatus && customerStatus.length ) {
                const defaultSelectedValueString = response.data.systemPropertyFileStatus
                customerStatus.forEach( (status, index) => {
                  if (defaultSelectedValueString === 'sent') {
                    defaultSelectedValue = 3
                    customerStatus[1].valueDisplay = response.data.redactedUsername ? response.data.redactedUsername : '-'
                    customerStatus[2].valueDisplay = response.data.approvedUsername ? response.data.approvedUsername : '-'
                    customerStatus[3].valueDisplay = response.data.sentUsername ? response.data.sentUsername : '-'
                  } else if (defaultSelectedValueString === 'approved') {
                    defaultSelectedValue = 2
                    customerStatus[1].valueDisplay = response.data.redactedUsername ? response.data.redactedUsername : '-'
                    customerStatus[2].valueDisplay = response.data.approvedUsername ? response.data.approvedUsername : '-'
                    customerStatus[3].valueDisplay = response.data.sentUsername ? response.data.sentUsername : '-'
                  } else if (defaultSelectedValueString === 'redacted') {
                    defaultSelectedValue = 1
                    customerStatus[1].valueDisplay = response.data.redactedUsername ? response.data.redactedUsername : '-'
                    customerStatus[2].valueDisplay = response.data.approvedUsername ? response.data.approvedUsername : '-'
                    customerStatus[3].valueDisplay = response.data.sentUsername ? response.data.sentUsername : '-'
                  } else {
                    defaultSelectedValue = -1
                  }
                })
              }
              this.setState({ listMetadata, defaultSelectedValue })
            }
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        })
      }
    }

    if (prevProps !== this.props && prevProps.sidebarOpen !== this.props.sidebarOpen) {
      if (!this.props.sidebarOpen) {
        this.setState({
          listMetadata: {}
        })
      }
    }
  }

  setFileStatus = (e) => {
    this.setState({ selectedFileStatus: e.value, selectedColorFileStatus: e.icon, defaultSelectedValue: e.value })
  }

  updateFolderStatus = () => {
    const { selectedFileStatus, customerStatus } = this.state
    //const userWhoChanged = this.props.user.name
    const userWhoChanged = `${this.props.user.middle_name} ${this.props.user.nickname}`
    const parameters = {
      fileId: this.props.documentId,
      //filePath: this.props.document.type.toUpperCase() === 'DIRECTORY' ? this.props.document.folderPath + this.props.document.name : this.props.document.folderPath + this.props.document.name + '.' + this.props.document.type,
      fileStatus: selectedFileStatus
    }
    this.setState({
      isUpdateFolderStatusLoading:true,
    })
    setFileStatus(parameters).then((response) => {
      if (response.status === 200) {
        let addNoteComplete = []
        const notAddNote = [{
          nameActionEvent: `${ this.props.document.type === 'DIRECTORY' && this.props.document.depthFromRoot === 0 ? 'box' :
              this.props.document.type === 'DIRECTORY' && this.props.document.depthFromRoot > 0 ? 'folder' :
              this.props.document.type !== 'DIRECTORY' ? 'file' : '' } status got changed to ${selectedFileStatus === 'redacted' ? 'For review' : selectedFileStatus === 'approved' ? 'Approved' : selectedFileStatus === 'sent' ? 'Sent': selectedFileStatus  }`,
          descriptionActionEvent: `${ this.props.document.name }`,
          timesEvent: moment().format('DD/MM/YYYY HH:mm:ss'),
          folderPath: this.props.document.type === 'DIRECTORY' ? this.props.document.path : `${this.props.document.folderPath}${this.props.document.path}`,
          status: this.props.document.type === 'DIRECTORY' && this.props.document.depthFromRoot === 0 ? 'box' :
              this.props.document.type === 'DIRECTORY' && this.props.document.depthFromRoot > 0 ? 'folder' :
              this.props.document.type !== 'DIRECTORY' ? 'file' : ''
        }]
        addNoteComplete = notAddNote.concat(this.props.smartBoxStatusChange)
        const uniqueNoteComplete = addNoteComplete && addNoteComplete.length > 0 && addNoteComplete.filter((a, i) => addNoteComplete.findIndex((s) => a.folderPath === s.folderPath) === i)
        this.props.setSmartBoxStatusChange(uniqueNoteComplete)
        CustomNotificationManager.success(` Status for the ${this.props.documentNameWithoutExtension ? this.props.documentNameWithoutExtension : this.props.documentName} was changed to ${selectedFileStatus === 'redacted' ? 'For review' : selectedFileStatus === 'approved' ? 'Approved' : selectedFileStatus === 'sent' ? 'Sent': selectedFileStatus }.`,'Status changed')
        customerStatus.forEach( (status, index) => {
          if (selectedFileStatus === 'sent') {
            customerStatus[3].valueDisplay = userWhoChanged
          } else if (selectedFileStatus === 'approved') {
            customerStatus[2].valueDisplay = userWhoChanged
          } else if (selectedFileStatus === 'redacted') {
            customerStatus[1].valueDisplay = userWhoChanged
          }
        })
        this.props.setSmartBoxStatusChange(uniqueNoteComplete)
        this.setState({ customerStatus, isUpdateFolderStatusLoading:false }, () => {
          this.props.fetchFiles()
        })
      }
    }).catch((error) => {
      this.setState({
        isUpdateFolderStatusLoading:false,
      })
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  geThumbnailImageMetadata = (type, parent, fileId) => {
    let response = getImageSrc(type, parent, 'false')
    if (type !== 'DIRECTORY') {
      if (this.props.listOfThumbnails && this.props.listOfThumbnails.length) {
        this.props.listOfThumbnails.forEach((item) => {
          if (item.id === fileId) {
            response = item.thumbnailSrc
          }
        })
      }
    }
    return response
  }
  onTimezoneConvert = (time) => {
    const localTimeZone = moment.tz.guess()
      const localDate = moment.utc(time).tz(localTimeZone)
      return `${ moment(localDate).format('DD.MM.YYYY HH:mm:ss') }`
  }
  render() {
    const { listMetadata, customerStatus, defaultSelectedValue, selectedColorFileStatus, isUpdateFolderStatusLoading } = this.state
    const { documentType, documentDepth, documentId, sidebarOpen } = this.props
    const { Option } = components
    let configImage = ''
    if (this.props) {
      const parent = !documentDepth
      configImage = this.geThumbnailImageMetadata(documentType, parent, documentId)
    }
    const IconOption = props => (
      <Option {...props}>
        <Icon type={props.data.icon} className="mr-1" />
        { props.data.label }
      </Option>
    );
    return (
      <div className="metadata-page">
        { listMetadata && documentType &&
        <React.Fragment>
          <div className="metadata-page__img-container">
            { (listMetadata.aiFinished === 'true' || listMetadata.aiFinished === true) && documentType.toUpperCase() !== 'DIRECTORY' &&
            <Tooltip title="Ai data is ready"><img className="analysed-file" src={ imgAnalysed } alt={ listMetadata.name }/></Tooltip>
            }
            { documentType.toUpperCase() === 'DIRECTORY' &&
            <img src={ configImage } alt={ listMetadata.name }/>
            }
            { documentType.toUpperCase() !== 'DIRECTORY' &&
              <React.Fragment>
                { typeof configImage === 'string' ?
                  <div className="display-thumbnail-metadata"> <img src={ configImage } alt={ listMetadata.name }/> </div> :
                  <i className={ configImage.font } style={ { color: configImage.color, fontSize: 87 } }/> }
              </React.Fragment>
            }
          </div>
          { (defaultSelectedValue >= 0 || selectedColorFileStatus) &&
            <div className="metadata-page__icon">
              <Icon className="icon-status" type={defaultSelectedValue >= 0 ? customerStatus[defaultSelectedValue].icon : selectedColorFileStatus ? selectedColorFileStatus : ''}/>
            </div>
          }
          <div className="title-metadata title-file-metadata"><p>{ listMetadata.name }</p></div>
          { documentType.toUpperCase() !== 'DIRECTORY' &&
          // <div className="subtitle-metadata mt-2">{ listMetadata.numberPagesFiles }</div>
          <div className="subtitle-metadata subtitle-metadata-number mt-2">
            { listMetadata.numberPagesFiles && listMetadata.numberPagesFiles > 1 ? `${ listMetadata.numberPagesFiles } pages` : listMetadata.numberPagesFiles && listMetadata.numberPagesFiles === 1 ? `${ listMetadata.numberPagesFiles } page` : '0 pages'}</div>
          }
          { documentType.toUpperCase() === 'DIRECTORY' &&
          <div className="subtitle-metadata mt-2"> </div>
          }
          <div className="item-metadata"><span className='item-metadata__title'>Size: </span><span className='item-metadata_item'>{ listMetadata.size ? `${ Number(listMetadata.size).toFixed(2) } MB`: '0 MB'}</span></div>
          {/*{ documentType.toUpperCase() !== 'DIRECTORY' &&*/}
          {/*<div className="item-metadata"><span className='item-metadata__title'>Owner: </span><span className='item-metadata_item'>{ listMetadata.owner ? listMetadata.owner : '-' }</span></div>*/}
          {/*}*/}
          {documentType.toUpperCase() !== 'DIRECTORY' &&
          <div className="item-metadata"><span className='item-metadata__title'>Created: </span><span className='item-metadata_item'>{listMetadata.created ? this.onTimezoneConvert(listMetadata.created) : '-'}</span></div>
          }
          { documentType.toUpperCase() !== 'DIRECTORY' ?
            <div className="item-metadata"><span className='item-metadata__title'>Uploaded: </span><span className='item-metadata_item'>{listMetadata.dateUpload ? this.onTimezoneConvert(listMetadata.dateUpload) : '-'}</span></div> :
            <div className="item-metadata"><span className='item-metadata__title'>Created: </span><span className='item-metadata_item'>{listMetadata.created ? this.onTimezoneConvert(listMetadata.created) : '-'}</span></div>
          }
          <div className="item-metadata"><span className='item-metadata__title'>Modified: </span><span className='item-metadata_item'>{listMetadata.modified ? this.onTimezoneConvert(listMetadata.modified) : '-'}</span></div>
          {/*<div className="item-metadata"><span className='item-metadata__title'>Authors: </span><span className='item-metadata_item'>{listMetadata.authors ? listMetadata.authors : '-'}</span></div>*/}
          {/*<div className="item-metadata" id="availability"><span className='item-metadata__title'>Availability: </span><span className='item-metadata_item'>{listMetadata.availability ? listMetadata.availability : '-'}</span></div>*/}
          {/*<div className="item-metadata"><span className='item-metadata__title'>Shared with: </span><span className='item-metadata_item'>{listMetadata.sharedWith ? listMetadata.sharedWith : '-'}</span></div>*/}
          {/*<div className="item-metadata"><span className='item-metadata__title'>Location: </span><span className='item-metadata_item'>{listMetadata.location ? listMetadata.location : '-'}</span></div>*/}
          { documentType.toUpperCase() === 'DIRECTORY' &&
          <React.Fragment>
            <div className="item-metadata mt-4"><span className='item-metadata__title'>Folders: </span><span className='item-metadata_item'>{listMetadata.folders ? listMetadata.folders : 0}</span></div>
            <div className= 'item-metadata'><span className='item-metadata__title'>Documents: </span><span className='item-metadata_item'>{listMetadata.documents ? listMetadata.documents : 0}</span></div>
          </React.Fragment>
          }

          {/*{ customerStatus && customerStatus.length > 0 &&*/}
          {/*  <React.Fragment>*/}
          {/*    <div className= 'item-metadata mt-4'>*/}
          {/*      <span className='item-metadata__title'>Status</span>*/}
          {/*        <Icon className="selected-icon" type={ defaultSelectedValue >= 0 ? customerStatus[defaultSelectedValue].icon : selectedColorFileStatus ? selectedColorFileStatus : ''} />*/}
          {/*        <Select*/}
          {/*          placeholder="Select"*/}
          {/*          value={ customerStatus[defaultSelectedValue] }*/}
          {/*          className="custom-status-select"*/}
          {/*          options={customerStatus}*/}
          {/*          components={{Option: IconOption}}*/}
          {/*          onChange={(e) => this.setFileStatus(e)}*/}
          {/*        />*/}
          {/*      <Button className={isUpdateFolderStatusLoading ? "button-green-loading" :'button-green'} onClick={ ()=>this.updateFolderStatus() }*/}
          {/*              loading={isUpdateFolderStatusLoading}*/}
          {/*      >Update</Button>*/}
          {/*      /!*{ depth === 0 && documentType.toUpperCase() === 'DIRECTORY' &&*!/*/}
          {/*      /!*  <React.Fragment>*!/*/}
          {/*      /!*    <p className="status-item">Bulk redacted: { listMetadata.bulkRedactedNumber }</p>*!/*/}
          {/*      /!*    <p className="status-item">Attached to SAR: { listMetadata.attachedToSarNumber } </p>*!/*/}
          {/*      /!*  </React.Fragment>*!/*/}
          {/*      /!*}*!/*/}
          {/*      /!*{ (depth > 0 && documentType.toUpperCase() === 'DIRECTORY') &&*!/*/}
          {/*      /!*  <React.Fragment>*!/*/}
          {/*      /!*    <p className="status-item">Analysed: { listMetadata.approvedNumber}</p>*!/*/}
          {/*      /!*    <p className="status-item">Finalised: { listMetadata.finalisedNumber }</p>*!/*/}
          {/*      /!*  </React.Fragment>*!/*/}
          {/*      /!*}*!/*/}
          {/*      { customerStatus.map( status => {*/}
          {/*        return <p key={ status.value } className="status-item">{ status.label }: { status.valueDisplay }</p>*/}
          {/*      })*/}
          {/*      }*/}
          {/*    </div>*/}
          {/*  </React.Fragment>*/}
          {/*}*/}

          {/*{ (listMetadata.ananlyticResultValue > 0 || listMetadata.regularExpressionResultValue > 0 || listMetadata.manualTermResultValue > 0 || listMetadata.businessTermResultValue > 0) &&*/}
          {/*  <MetadataChart*/}
          {/*    ananlyticResultValue={listMetadata.ananlyticResultValue}*/}
          {/*    regularExpressionResultValue={listMetadata.regularExpressionResultValue}*/}
          {/*    manualTermResultValue={listMetadata.manualTermResultValue}*/}
          {/*    businessTermResultValue={listMetadata.businessTermResultValue}*/}
          {/*  />*/}
          {/*}*/}

        </React.Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  client: state.subscriptionReducer.subscription.subscription.client,
  user: state.userReducer.user,
  smartBoxStatusChange: state.smartBox.smartBoxStatusChange
})

export default connect(mapStateToProps, { setSmartBoxStatusChange })(MetadataPage)