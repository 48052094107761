import React from 'react'
import './SmartBoxPage.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Input, Modal, Table, Tooltip } from 'antd'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import moment from 'moment-timezone'
import Icon from 'antd/es/icon'
import DownloadBoxModalOptionFiles from './DownloadBoxModalOptionFiles'
import {
  apiSmartBoxPreviewSarDocByThumbnailId,
  apiSmartBoxPreviewSarDocFinalizedByThumbnailId,
  deleteSmartDocument,
  getFolderChildren,
  getSmartBoxFilesFilter,
  getOcrFile,
  apiSmartBoxZip,
  apiSmartBoxExportDocument,
  apiSmartBoxFinalisedDocument,
  sendToReanalyseWithAI,
  getThumbnailForFiles,
  getFolderCountFiles, getRenameFile, apiSmartBoxDownloadFile
} from '../../../api/SmartBox'
import { deleteFolderOrFile, getImageSrc, getImageWidth } from '../../../utils/SmartBox/SmartBoxMethods'
import {
  setSmartBoxScrollPositionObject,
  setSmartBoxSearchedWord,
  setSmartBoxUploadingObject,
  setSmartBoxCurrentFolder,
  setSmartBoxFoldersCheckErrors,
  setSmartBoxSecurityConfiguration,
  setSmartBoxRenamedFolder, setBoxListViewSelectedKeys,setSmartBoxCurrentPage
} from '../../../actions/smartBoxActions'
import { setFooterVisibility, setHeaderVisibility, setSidebarVisibility } from '../../../actions/shellActions'
import { ContextMenu, ContextMenuTrigger, MenuItem, SubMenu } from 'react-contextmenu'
import PDFViewer from '../../../utils/PDFViewer'
import PDFJSBackend from '../../../utils/backends/PDFJs'
import { generateBlobFromBase64 } from '../../../utils/GenerateBlob'
import { customConfirm } from '../../../utils/AssetRegister/CustomConfirm'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import FileDownload from 'js-file-download'
import DirectoryNote from './DirectoryNote'
import withRouter from '../../../private-routers/withRouter'

const nameRgex = /^[A-Za-z\d_\s]+$/
// moment(item.creationDate).isDST() ? moment(item.creationDate).subtract(1, 'hours').format('DD.MM.YYYY HH:mm:ss') : moment(item.creationDate).format('DD.MM.YYYY HH:mm:ss')
class TreeStructureFolder extends React.Component {
  state = {
    files: [],
    newFolderClicked: false,
    uploadExecuted: 0,
    showDeleteButtons: {},
    redactionMode: false,
    loading: false,
    documentId: '',
    smartBoxDocumentId: '',
    expandedRowKeys: [],
    searchText: '',
    clickTimeout: null,
    rootLevelDiff: 0,
    currentItem: '',
    previewVisibleOcr: false,
    fileContentOcr: '',
    childrenIsNotEmpty: 0,
    childrenLength: 0,
    documentNoteVisible: false,
    listOfThumbnails: [],
    divHeight: 720,
    selectRowOnClick: '',
    foldersCountFiles: [],
    foldersCheckErrors: [],
    folderClicked: false,
    renameFile: null,
    validEditFolderName: { success: true, reason: 'Invalid name' },
    depthFromRoot: 0,
    visiblePageModalDownload: false,
    fileSelectDownload: {},
    visibleCollectData: false,
    selectedKeyFileTree: []
  }
  windowDocument = null
  
  onSelectImages = (type) => {
    switch (type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 's'
      default:
        return 'sync'
    }
  }

  onFolderNewDepth = () => {
    const { folderNewDepth } = this.props
    let folderNewDepthProps = null
    if (folderNewDepth) {
      folderNewDepthProps = folderNewDepth
    }
    return folderNewDepthProps
  }
  
  constructor(props) {
    super(props)
    this.child = React.createRef()
    this.columns = [
      {
        title: ({ sortOrder, sortColumn }) => {
          return (
            <React.Fragment>
              <span>Name</span>
              { sortColumn !== null && sortColumn.dataIndex === 'name' && sortOrder === 'ascend' && <span className={"icon-v12-arrow-medium up"}></span> }
              { sortColumn !== null && sortColumn.dataIndex === 'name' && sortOrder === 'descend' && <span className={"icon-v12-arrow-medium"}></span> }
            </React.Fragment>
          )
        },
        key: 'Name',
        dataIndex: 'name',
        width: '70%',
        className: 'parent-column',
        sortDirections: ['ascend', 'descend'],
        onHeaderCell: (column, i) => {
          return {
            onClick: () => {
              this.props.resetPageNumber()
            },
          }
        },
        sorter: (a, b) => a.name && b.name ? a.name.localeCompare(b.name) : '',
        render: (text, record) => {
          let imgThumbnail = ''
          if (record.type.toUpperCase() !== 'DIRECTORY') {
            const temp = this.getTumbnails(record.type, false, record.id)
            if (typeof temp !== 'object') {
              imgThumbnail = temp
            }
          }
          const { renameFile, validEditFolderName } = this.state

          if (record.id === 0) {
              return (
                <div>
                  <Input
                    placeholder="New folder name"
                    onKeyPress={ this.props.handleNewFolderKeyPressed }
                    disabled={ this.props.disabledOnClicked }
                    className="new-folder-input"
                    style={{ width:'300px' }}
                    onBlur={ (e) => {
                      this.props.handleNewFolderKeyPressed(e)
                      this.props.removeNewFolderFormView()
                      this.newFolderInput = null
                    } } ref={ (input) => {
                      this.newFolderInput = input
                    } }/>
                  <span className="validation-name">{ this.props.validNewFolderName.success ? '' : this.props.validNewFolderName.reason}</span>
                </div>
              )
          }
          const { expandedRowKeys } = this.props.smartBoxScrollPositionObject
          const isExpanded = expandedRowKeys.indexOf(record.id) > -1
          let isParent = this.props.root && record.depth === 0

          /*if (record.depth === undefined || record.depth === 0) {
            isParent = 1
          }*/

          const hasErrors = this.getFilesErrors(record)
          const configImage = this.geThumbnailOrImageSrc(record.type, isParent, isExpanded, record.empty, hasErrors, record.numberOfFiles)

          let marginLeft = 0
          if(this.props.root) {
            marginLeft = (record.depth - this.state.rootLevelDiff) * 30
          } else {
            marginLeft = ((record.depth > 1 ? record.depth - this.props.smartBoxTreeCurrentDepth - 1 : record.depth) - this.state.rootLevelDiff) * 30
          }

          if(isNaN(marginLeft)) {
            marginLeft = 0
          }
          const imgWidth = getImageWidth(isParent, isExpanded, this.props.root)
          return (
            <div data-margin={marginLeft} data-depth={record.depth} data-level={this.state.rootLevelDiff} className={ this.state.selectRowOnClick && this.state.selectRowOnClick.id === record.id ? 'icons-container icons-selected-click' : 'icons-container' } style={ { marginLeft } }>
              <ContextMenuTrigger id={ record.type === 'DIRECTORY' ? 'context-menu-folder': 'context-menu'} collect={ () => {
                this.setState({ currentItem: record })
                return record
              } }
              disable={ record.type.toUpperCase() !== 'DIRECTORY' && record.isPdf === 'false' }>
                { record.type !== 'DIRECTORY' &&
                <div className="icons-container__icons">
                  {
                    record.bigFile !== 'true' ?
                      <React.Fragment>
                        {
                          record.pdfValidation === 'true' &&
                          <React.Fragment>
                            { (record.isPdf === 'false' || record.pdfValidation === 'false') &&
/*                              <Tooltip title="Ready for redaction"><Icon type="file-pdf"/></Tooltip> :*/
                              <Tooltip title="Converting document"><Icon type="setting" spin/></Tooltip>
                            }
                            { record.aiFinished === 'false' &&
                              /*<Tooltip title="Ai data is ready"><img className="analysed-file" src={ imgAnalysed }
                                alt={ record.name }/></Tooltip> :*/
                              <Tooltip title="AI Processing content"><Icon type={ this.onSelectImages(record.type) }
                                spin/></Tooltip>
                            }
                          </React.Fragment>
                        }
                      </React.Fragment>
                      :
                      <Tooltip title="Processing big File"><Icon type="setting" spin/></Tooltip>
                  }
                  { record.pdfValidation !== 'true' &&
                  <Tooltip title={ record.errorMessage }><Icon type="close-square"
                    style={ { color: 'red' } }/></Tooltip>
                  }
                </div>
                }
                <div data-depth={record.depth} className={ record.type === 'DIRECTORY' && record.depth === 0 ? 'icons-container__content display-status-bulk' : 'icons-container__content display-status-bulk new-display-status-bulk' }>
                  { record.type === 'DIRECTORY' && (this.props.showArrowsFirstLevel || record.depth > 0) && !record.isEmpty && <span className={ `icon-v12-arrow-medium`+(isExpanded ? ' expanded' : '')}></span> }
                  { record.type === 'DIRECTORY' && (this.props.showArrowsFirstLevel || record.depth > 0) && record.isEmpty && <span className={ `empty-25` }></span> }
                  { record.type === 'DIRECTORY' && (this.props.showArrowsFirstLevel && record.depth === 0)  && record.isEmpty && <span className={ `icon-v12-arrow-medium`+(isExpanded ? ' expanded' : '')}></span> }
                  { record.type === 'DIRECTORY' && record.depth === 0 &&
                    <React.Fragment>
                      { record.bulkProcessingStarted && record.bulkProcessingStarted === 'started' && this.props.root &&
                      <Tooltip className="bulk-processing" title="Bulk redaction in progress"><Icon type={ this.onSelectImages(record.type) } spin/></Tooltip>
                      }
                      { record.type === 'DIRECTORY' && record.finaliseFolder && record.finaliseFolder === 'started' &&
                      <Tooltip className="bulk-processing" title="Bulk finalise in progress"><Icon type="sync" spin /></Tooltip>
                      }
                      { record.bulkProcessingStarted && record.finaliseFolder !== 'true' && record.finaliseFolder !== 'started' && record.bulkProcessingStarted !== 'started' &&
                      <Tooltip className="bulk-processing" title="Bulk redaction is finished"><Icon type="check" /></Tooltip>
                      }
                      { record.type === 'DIRECTORY' && record.finaliseFolder && record.finaliseFolder === 'true' && record.bulkProcessingStarted !== 'started' &&
                      <Tooltip className="bulk-processing bulk-processing-data-finalised" title="Data finalised"><span className= 'icon-v8-smartbox-folder-finalised icon-finalise' /></Tooltip>
                      }
                    </React.Fragment>
                  }

                  { record.type === 'DIRECTORY' && record.depth !== 0 &&
                    <React.Fragment>
                      { record.bulkProcessingStarted && record.bulkProcessingStarted === 'started' && this.props.root &&
                      <Tooltip className="bulk-processing" title="Bulk redaction in progress"><Icon type={ this.onSelectImages(record.type) } spin/></Tooltip>
                      }
                      { record.finaliseFolder && record.finaliseFolder === 'started' &&
                      <Tooltip className="bulk-processing" title="Bulk finalise in progress"><Icon type="sync" spin /></Tooltip>
                      }
                      { record.bulkProcessingStarted && record.finaliseFolder !== 'true' && record.finaliseFolder !== 'started' && record.bulkProcessingStarted !== 'started' &&
                      <Tooltip className="bulk-processing" title="Bulk redaction is finished"><Icon type="check" /></Tooltip>
                      }
                      { record.finaliseFolder && record.finaliseFolder === 'true' && record.bulkProcessingStarted !== 'started' &&
                      <Tooltip className="bulk-processing bulk-processing-data-finalised" title="Data finalised"><span className= 'icon-v8-smartbox-folder-finalised icon-finalise' /></Tooltip>
                      }
                    </React.Fragment>
                  }
                  { record.type === 'DIRECTORY'
                    ? <img src={ configImage } alt={ record.name }
                           style={ renameFile !== null && renameFile.id === record.id ? {width: imgWidth, height: imgWidth, marginTop: '6px'} : { width: imgWidth, height: imgWidth } }/>
                    : imgThumbnail.length > 0 ?
                      <div className="image-thumbnail-wrapper"><img className='image-thumbnail' alt={`img_${ record.name }`} src={imgThumbnail}/></div> :
                      <i className={configImage.font} style={{ color: configImage.color, fontSize: 30, height: 30, width: 23 }}/>
                  }
                  { renameFile !== null && renameFile.id === record.id ?
                    <div className='folder-info'>
                      <Input
                        placeholder={ renameFile.name }
                        onKeyPress={ this.handleNewRenameFolderPressed }
                        disabled={ this.props.disabledOnClicked }
                        className="new-folder-input"
                        style={ { width: '300px' } }
                        onBlur={ (e) => {
                          this.setState({renameFile: null})
                          this.renameFolderInput = null
                        } } ref={ (input) => {
                        this.renameFolderInput = input
                      } }/>
                      <span
                        className="validation-name">{ validEditFolderName.success ? '' : validEditFolderName.reason }</span>
                    </div>
                    :
                    <div className="folder-info">
                      <p className={ record.type === 'DIRECTORY' ? `${ expandedRowKeys && expandedRowKeys.includes(record.id) && 'folder-info__name' }` : 'folder-info__row-name' }>{ record.nameWithoutExtension }</p>
                    </div>
                  }
                </div>
              </ContextMenuTrigger>
            </div>
          )
        }
      },
      {
        title: ({ sortOrder, sortColumn }) => {
          return (
            <React.Fragment>
              <span>Size</span>
              { sortColumn !== null && sortColumn.dataIndex === 'size' && sortOrder === 'ascend' && <span className={"icon-v12-arrow-medium up"}></span> }
              { sortColumn !== null && sortColumn.dataIndex === 'size' && sortOrder === 'descend' && <span className={"icon-v12-arrow-medium"}></span> }
            </React.Fragment>
          )
        },
        key: 'size',
        dataIndex: 'size',
        className: 'parent-size',
        sortDirections: ['ascend', 'descend'],
        width: '120px',
        sorter: (a, b) => {
          const val1 = a.type === 'DIRECTORY' ? a.numberOfFiles : (a.totalPages ? a.totalPages : 0)
          const val2 = b.type === 'DIRECTORY' ? b.numberOfFiles : (b.totalPages ? b.totalPages : 0)
          return val1 - val2
        } ,
        render: (text, record) => {
          return (
            <ContextMenuTrigger id={ record.type === 'DIRECTORY' ? 'context-menu-folder': 'context-menu'} collect={ () => record }
              disable={ record.type.toUpperCase() !== 'DIRECTORY' && record.isPdf === 'false' }>
              <p>{ record.type === 'DIRECTORY' ? (typeof record.numberOfFiles !== 'undefined' ?
                  record.numberOfFiles + (record.numberOfFiles === '1' ? ' file' : ' files') : '')
                : (record.totalPages ? record.totalPages + (record.totalPages === '1' ? ' page' : ' pages') : '') }</p>
            </ContextMenuTrigger>
          )
        }
      },
      {
        title: ({ sortOrder, sortColumn }) => {
          return (
            <React.Fragment>
              <span>Created</span>
              { sortColumn !== null && sortColumn.dataIndex === 'created' && sortOrder === 'ascend' && <span className={"icon-v12-arrow-medium up"}></span> }
              { sortColumn !== null && sortColumn.dataIndex === 'created' && sortOrder === 'descend' && <span className={"icon-v12-arrow-medium"}></span> }
            </React.Fragment>
          )
        },
        key: 'creationDate',
        dataIndex: 'creationDate',
        className: 'parent-modified',
        sortDirections: ['ascend', 'descend'],
        width: '150px',
        onHeaderCell: (column, i) => {
          return {
            onClick: () => {
              this.props.resetPageNumber()
            },
          }
        },
        sorter: (a, b) => moment(a.creationDate, 'DD.MM.YYYY').unix() - moment(b.creationDate, 'DD.MM.YYYY').unix(),
        render: (text, record) => {
          return (
            // <div className={ this.state.selectRowOnClick && this.state.selectRowOnClick.id === record.id ? 'icons-selected-click-modified' : '' }>
              <ContextMenuTrigger id={ record.type === 'DIRECTORY' ? 'context-menu-folder': 'context-menu'} collect={ () => record }
                disable={ record.type.toUpperCase() !== 'DIRECTORY' && record.isPdf === 'false' }>
                {/*<p alt={ record.creationDate }>{ moment(record.creationDate, 'DD.MM.YYYY hh:mm:ss').format('DD-MM-YYYY HH:mm:ss') }</p>*/}
                <p alt={ record.creationDate }>{record.creationDate ? this.onTimezoneConvert(record.creationDate) : '' }</p>
              </ContextMenuTrigger>
            // </div>
          )
        },
      },
      {
        title: ({ sortOrder, sortColumn }) => {
          return (
              <React.Fragment>
                <span>Modified</span>
                { sortColumn !== null && sortColumn.dataIndex === 'modified' && sortOrder === 'ascend' && <span className={"icon-v12-arrow-medium up"}></span> }
                { sortColumn !== null && sortColumn.dataIndex === 'modified' && sortOrder === 'descend' && <span className={"icon-v12-arrow-medium"}></span> }
              </React.Fragment>
          )
        },
        key: 'modificationDate',
        dataIndex: 'modificationDate',
        className: 'parent-modified',
        sortDirections: ['ascend', 'descend'],
        width: '150px',
        onHeaderCell: (column, i) => {
          return {
            onClick: () => {
              this.props.resetPageNumber()
            },
          }
        },
        sorter: (a, b) => moment(a.modificationDate, 'DD.MM.YYYY').unix() - moment(b.modificationDate, 'DD.MM.YYYY').unix(),
        render: (text, record) => {
          return (
              // <div className={ this.state.selectRowOnClick && this.state.selectRowOnClick.id === record.id ? 'icons-selected-click-modified' : '' }>
              <ContextMenuTrigger id={ record.type === 'DIRECTORY' ? 'context-menu-folder': 'context-menu'} collect={ () => record }
                                  disable={ record.type.toUpperCase() !== 'DIRECTORY' && record.isPdf === 'false' }>
                <p alt={ record.modificationDate }>{ record.modificationDate ? this.onTimezoneConvert(record.modificationDate) : '' }</p>
              </ContextMenuTrigger>
              // </div>
          )
        },
        // moment('2012-10-14', 'YYYY-MM-DD', 'fr', true)
      },
      {
        title: ({ sortOrder, sortColumn }) => {
          return (
            <React.Fragment>
              <span>Status</span>
              { sortColumn !== null && sortColumn.dataIndex === 'bulkProcessingStarted' && sortOrder === 'ascend' && <span className={"icon-v12-arrow-medium up"}></span> }
              { sortColumn !== null && sortColumn.dataIndex === 'bulkProcessingStarted' && sortOrder === 'descend' && <span className={"icon-v12-arrow-medium"}></span> }
            </React.Fragment>
          )
        },
        dataIndex: 'bulkProcessingStarted',
        width: '120px',
        sortDirections: ['ascend', 'descend'],
        className: 'parent-status',
        key: 4,
        render: (text, record) => {
          return (
            // <div className={ this.state.selectRowOnClick && this.state.selectRowOnClick.id === record.id ? 'icons-selected-click-modified' : '' }>
              <ContextMenuTrigger id={ record.type === 'DIRECTORY' ? 'context-menu-folder': 'context-menu'} collect={ () => record }>
                {/*<p className="status-redacted-list-view">{ record.type !== 'DIRECTORY' && record.finaliseFolder !== 'true' && record.finaliseFolder !== 'started' && record.bulkProcessingStarted && record.bulkProcessingStarted === 'finished' ? 'Redacted' : '' }</p>*/}
                <p className="status-redacted-list-view">
                  {/*{ this.getFilesErrors(record) && <span className="icon-v12-process-error"></span> }*/}
                  { record.systemPropertyFileStatusIcon && record.systemPropertyFileStatusIcon.includes('v211') && !record.systemPropertyFileStatusColor &&
                  <Tooltip title={record.systemPropertyFileStatus}><span className={ record.systemPropertyFileStatusIcon }></span></Tooltip>
                  }
                  { record.systemPropertyFileStatusIcon && !record.systemPropertyFileStatusColor &&
                  <Tooltip title={record.systemPropertyFileStatus}><Icon type={ record.systemPropertyFileStatusIcon } /></Tooltip>
                  }
                  { record.systemPropertyFileStatusColor && !record.systemPropertyFileStatusIcon &&
                  <Tooltip title={record.systemPropertyFileStatus}><span style={{backgroundColor: record.systemPropertyFileStatusColor }} className="color-status-file"></span></Tooltip>
                  }
                  { record.systemPropertyFileStatusIcon && record.systemPropertyFileStatusColor &&
                  <Tooltip title={record.systemPropertyFileStatus}><span className={ record.systemPropertyFileStatusIcon }></span></Tooltip>
                  }

                  {/*{ record.systemPropertyFileStatus && record.systemPropertyFileStatus === 'approved' && <Tooltip title='Approved'><span className="icon-v12-file-approved"></span></Tooltip> }*/}
                  {/*{ record.systemPropertyFileStatus && record.systemPropertyFileStatus === 'redacted' && <Tooltip title='For Review'><span className="icon-v12-file-redacted"></span></Tooltip> }*/}
                  {/*{ record.systemPropertyFileStatus && record.systemPropertyFileStatus === 'sent' && <Tooltip title='Sent'><span className="icon-v12-file-sent"></span></Tooltip> }*/}
                  { this.getFilesDuplicates(record) === 'standard' && <span className="icon-v15-duplicate"></span> }
                </p>
              </ContextMenuTrigger>
            // </div>
          )
        },
        onHeaderCell: (column, i) => {
          return {
            onClick: () => {
              this.props.resetPageNumber()
            },
          }
        },
        sorter: (a, b) => a.systemPropertyFileStatus && b.systemPropertyFileStatus ? a.systemPropertyFileStatus.localeCompare(b.systemPropertyFileStatus) : ''
        //sorter: (a, b) => a.systemPropertyFileStatus - b.systemPropertyFileStatus
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: '40px',
        align: 'right',
        render: (text, file) => {
          return (
             <div className={ `actionsDotsCell action-drop-cell type-`+file.type.toLowerCase() }>
                <DropdownButton
                  className= "dots-menu"
                  title={ <span className="icon-v12-options"></span> }
                  nocaret="true"
                  data-toggle="dropdown"
                  id={ 'dropdown-1' }
                  autoClose={true}
                  onClick={ (e) => {
                    e.stopPropagation()
                  } }
                  bsstyle="default">
                  {/*<DropdownItem className="menu-left-item-directory" onClick={ (e, props) => this.executeAction(record, 'redacting', e) }>
                  Open in Smartview
                  </DropdownItem>
                  <DropdownItem className="menu-left-item-directory" onClick={ (e, props) => this.executeAction(record, 'viewer', e) }>
                  Open original
                  </DropdownItem>
                  { record && record.finalizedDocPath && <DropdownItem className="menu-left-item-directory"
                    onClick={ (e, props) => this.executeAction(record, 'viewer-finalized', e) }>
                  Open redacted version
                  </DropdownItem>
                  }
                  { record.isOcr !== 'false' && <DropdownItem className="menu-left-item-directory"
                    onClick={ (e, props) => this.executeAction(record, 'ocr', e) }>
                  View OCR
                  </DropdownItem>
                  }
                  { record.isPdf === 'true' && record.finalizedDocPath &&
                <DropdownItem className="menu-left-item-directory"
                  onClick={ (e, props) => this.executeAction(record, 'finalise-document', e) }>
                  Update redacted version
                </DropdownItem>
                  }
                  { record.isPdf === 'true' && !record.finalizedDocPath &&
                <DropdownItem className="menu-left-item-directory"
                  onClick={ (e, props) => this.executeAction(record, 'finalise-document', e) }>
                  Create redacted version
                </DropdownItem>
                  }
                  { record.finalizedDocPath && <DropdownItem className="menu-left-item-directory"
                    onClick={ (e, props) => this.executeAction(record, 'export-document', e) }>
                  Export redacted version
                  </DropdownItem>
                  }
                  { record.isPdf === 'true' && <DropdownItem className="menu-left-item-directory"
                    onClick={ (e, props) => this.executeAction(record, 'info', e) }>
                  View file info
                  </DropdownItem>
                  }
                  <DropdownItem className="menu-left-item-directory" onClick={ (e, props) => this.executeAction(record, 'delete', e) }>
                  Delete
                  </DropdownItem>*/}

                  { file.type !== 'DIRECTORY' && [
                    file.isPdf === 'true' && <Dropdown.Item className="menu-item-directory" key={ 1 } onClick={ (e) => this.executeAction(file, 'redacting', e) }>
                      Smartview
                    </Dropdown.Item>,
                    <div className="context-sub-menu" key={ `context-sub-menu-1-${ file.id }` }>
                      <SubMenu className="context-menu-directory2" title="View">
                        { file.finalizedDocPath && <MenuItem className="menu-item-directory" key={ 3 } onClick={ (e) => this.executeAction(file, 'viewer-finalized', e) }>
                          Redacted file
                        </MenuItem> }
                        { file.conversionStatus === 'Converted' && <MenuItem className="menu-item-directory" key={ 2 } onClick={ (e) => this.executeAction(file, 'viewer', e) }>
                          Raw file
                        </MenuItem> }
                        { file.isOcr !== 'false' && <MenuItem className="menu-item-directory" key={ 4 } onClick={ (e) => this.executeAction(file, 'ocr', e) } >
                          OCR
                        </MenuItem> }
                      </SubMenu>
                      { file.type !== 'DIRECTORY' && <MenuItem key={ 52 } className="menu-item-directory8">

                      </MenuItem> }
                    </div>,
                    <div className="context-sub-menu" key={ `context-sub-menu-2-${ file.id }` }>
                      <SubMenu className="context-menu-directory2" title="Download">
                        { file.type !== 'DIRECTORY' && file.finalizedDocPath &&
                        <MenuItem className="menu-item-directory" disabled={ !file.finalizedDocPath } key={ 7 } onClick={ (e) => this.executeAction(file, 'export-document', e) }>
                          Redacted file
                        </MenuItem>
                        }
                        { file.type !== 'DIRECTORY' &&
                        <MenuItem className={ file.visibleToDownload === true ? 'menu-item-directory' : 'menu-item-directory2' } key={ 25 } disabled={ file.visibleToDownload !== true }
                                  onClick={ (e) => this.executeAction(file, 'download-file', e) }>
                          Original file
                        </MenuItem>
                        }
                      </SubMenu>
                    </div>
                  ]
                    }
                    {/*{ file.isPdf === 'true' && file.finalizedDocPath &&*/}
                    {/*<DropdownItem className="menu-item-directory" key={ 5 } onClick={ (e) => this.executeAction(file, 'finalise-document', e) }>*/}
                    {/*  Update redacted*/}
                    {/*</DropdownItem>*/}
                    {/*}*/}
                    {/*{ file.isPdf === 'true' && !file.finalizedDocPath &&*/}
                    {/*<DropdownItem className="menu-item-directory" key={ 6 } onClick={ (e) => this.executeAction(file, 'finalise-document', e) }>*/}
                    {/*  Create redacted*/}
                    {/*</DropdownItem>*/}
                    {/*}*/}
                    {/*{this.props.listKeysFiles.length > 0 */ false &&
                  <Dropdown.Item className="menu-item-directory" key={25}
                            onClick={(e) => this.executeAction(file, 'reanalyse-with-ai', e)}>
                    Reprocess
                  </Dropdown.Item>
                  }
                  { file.type !== 'DIRECTORY' && <Dropdown.Item key={ 53 } className="menu-item-category menu-item-directory8">
                  </Dropdown.Item>
                  }
                  { file.isPdf === 'true' &&
                  <Dropdown.Item className="menu-item-directory" key={ 8 } onClick={ (e) => this.executeAction(file, 'info', e) }>
                    File info
                  </Dropdown.Item>
                  }
                  { /*file.type !== 'DIRECTORY' &&
                  <DropdownItem className={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) ? 'menu-item-directory2' : 'menu-item-directory' } key={ 10 }
                            disabled={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) }
                            onClick={ (e, props) => this.executeAction(props, 'cut', e) }>
                    Cut
                  </DropdownItem>*/
                  }
                  { file.type !== 'DIRECTORY' &&
                  <Dropdown.Item className="menu-item-directory" key={ 9 } onClick={ (e) => this.executeAction(file, 'delete', e) }>
                    Delete
                  </Dropdown.Item>
                  }
                  { file.type === 'DIRECTORY' && !this.props.root && [
                    <Dropdown.Item className="menu-item-directory" key={25} onClick={(e) => this.executeAction(file, 'reanalyse-with-ai', e)}>
                      Reprocess
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 15 } onClick={ (e) => this.executeAction(file, 'rename', e) }
                                   disabled={(this.props.getFilesCountListView && this.props.getFilesCountListView(file) > 0 || file.numberOfFiles !== 0) && (file.statisticsReady === 'false' || file.statisticsReady === null)}>
                        Rename
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 16 } onClick={ (e) => this.executeAction(file, 'delete', e) }>
                      Delete
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 17 } onClick={ (e) => this.executeAction(file, 'add-note', e) }>
                      Add a Note
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 20 } onClick={ (e) => this.executeAction(file, 'info', e) }>
                      Folder Info
                  </Dropdown.Item>]}
                  { file.type === 'DIRECTORY' && this.props.root && [
                    /*!isNewFolder && */file.depth === 0 && <Dropdown.Item className={ file.statisticsReady === 'false' && this.props.getFilesCountListView && this.props.getFilesCountListView(file) > 0 ? 'menu-item-directory menu-item-disabled' : "menu-item-directory" } key={ 2 }
                                                                          onClick={ (e) => this.routeChangeFolder(e, file, 'analysis-settings') }
                    disabled={ file.statisticsReady === 'false' && this.props.getFilesCountListView && this.props.getFilesCountListView(file) > 0 }>
                      {file.statisticsReady === 'false' && this.props.getFilesCountListView && this.props.getFilesCountListView(file) ?
                            <Tooltip title="Generating stats. Please wait.">
                              <span>Box settings</span>
                            </Tooltip> 
                            :
                            <span>Box settings</span>
                      }
                    </Dropdown.Item>,
                    <Dropdown.Item className={ file.statisticsReady === 'false' ? 'menu-item-directory menu-item-disabled' : "menu-item-directory" }
                                  key={ 11 } onClick={ (e) => this.routeChangeFolder(e, file, 'data-insights/dashboard') }
                                  disabled={ file.statisticsReady === 'false' }>
                      {file.statisticsReady === 'false' ?
                      <Tooltip title={ this.props.getFilesCountListView(file) === 0 ? '' : "Generating stats. Please wait." }>
                        <span>Report</span>
                      </Tooltip> 
                      :
                      <span>Report</span>
                      }
                    </Dropdown.Item>,
                    <Dropdown.Item className={ file.statisticsReady === 'false' ? 'menu-item-directory menu-item-disabled' : "menu-item-directory" }
                                  key={ 12 } onClick={ (e) => this.routeChangeFolder(e, file, 'folder-redaction') }
                                  disabled={ file.statisticsReady === 'false' }>
                      {
                        (file.statisticsReady === 'false') ?
                            <Tooltip title={ this.props.getFilesCountListView(file) === 0 ? '' : "Generating stats. Please wait." }>
                              <span>Redaction</span>
                            </Tooltip> :
                            <span>Redaction</span>
                      }
                    </Dropdown.Item>,
                    <Dropdown.Item className={ file.statisticsReady === 'false' ? 'menu-item-directory menu-item-disabled' : "menu-item-directory" }
                                   key={ 33 } onClick={ (e) => this.routeChangeFolder(e, file, 'cull/duplicates') }
                                   disabled={ file.statisticsReady === 'false' }>
                      {
                        (file.statisticsReady === 'false') ?
                            <Tooltip title={ this.props.getFilesCountListView(file) === 0 ? '' : "Generating stats. Please wait." }>
                              <span>Cull</span>
                            </Tooltip> :
                            <span>Cull</span>
                      }
                    </Dropdown.Item>,
                    /*<DropdownItem className={ file.bulkProcessingStarted === 'finished' && file.visibleToDownload === true ? 'menu-item-directory' : 'menu-item-directory menu-item-directory4' }
                              disabled={ (file.bulkProcessingStarted !== 'finished' || file.visibleToDownload !== true) } key={ 13 } onClick={ (e) => this.executeAction(file, 'export', e) }>
                      Download Finalised
                    </DropdownItem>,*/
                    <Dropdown.Item
                        disabled={ file.visibleToDownload === false }
                        className= { file.visibleToDownload === false ? "menu-item-directory menu-item-directory6" : 'menu-item-directory' } key={ 13 } onClick={ (e) => this.executeAction(file, 'export', e) }>
                      Export
                    </Dropdown.Item>,
                    // <Dropdown.Item key={ 14 } className="menu-item-category menu-item-category3">
                    // </Dropdown.Item>,
                    /*folderNewDepthProps && folderNewDepthProps > 0 && <DropdownItem
                    className={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) ? 'menu-item-directory2' : 'menu-item-directory' }
                    key={ 23 } disabled={ (listSelectedNameIdCutPaste && listSelectedNameIdCutPaste.length === 0) }
                    onClick={ (e, props) => this.executeAction(props, 'cut-folder', e) }>
                    Cut
                    </DropdownItem>,
                    folderNewDepthProps && folderNewDepthProps > 0 && <DropdownItem className={ checkStatusPaste === false ? 'menu-item-directory2' : 'menu-item-directory' }
                    key={ 24 } disabled={ checkStatusPaste === false }
                    onClick={ (e, props) => this.executeAction(props, 'paste', e) }>
                    Paste
                    </DropdownItem>,*/
                    <Dropdown.Item className={this.props.getFilesCountListView && this.props.getFilesCountListView(file) > 0 && (file.statisticsReady === 'false' || file.statisticsReady === null) ? 'menu-item-directory menu-item-disabled menu-item-disabled-rename' : "menu-item-directory" } key={ 15 } onClick={ (e) => this.executeAction(file, 'rename', e) }>
                      Rename
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 16 } onClick={ (e) => this.executeAction(file, 'delete', e) }>
                      Delete
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 17 } onClick={ (e) => this.executeAction(file, 'add-note', e) }>
                      Add a Note
                    </Dropdown.Item>,
                    <Dropdown.Item className="menu-item-directory" key={ 20 } onClick={ (e) => this.executeAction(file, 'info', e) }>
                      Box Info
                    </Dropdown.Item>,
                    // <DropdownItem className={file.bulkProcessingStarted === 'started' ? 'menu-item-directory menu-disabled' : 'menu-item-directory' } key={ 18 } disabled={ file.bulkProcessingStarted === 'started' }
                    //               onClick={ (e) => this.executeAction(file, 'finalise-folder', e) }>
                    //   { file.finaliseFolder ? 'Update Redaction Version' : 'Create Redacted Version' }
                    // </DropdownItem>,
                    // <Dropdown.Item key={ 19 } className="menu-item-category menu-item-category3">
                    // </Dropdown.Item>,
                    /*folderNewDepthProps === null && !folderNewDepthProps > 0 && !isNewFolder &&*/ 
                    <Dropdown.Item className="menu-item-directory" key={ 21 } onClick={ (e) => this.executeAction(file, 'security-settings', e) }>
                      Security Settings
                    </Dropdown.Item>
                  ]
                }
                </DropdownButton>
              </div>
          )
        }
      },
    ]
  }

  onTimezoneConvert = (time) => {
    const localTimeZone = moment.tz.guess()
    const localDate = moment.utc(time).tz(localTimeZone)
    return `${ moment(localDate).format('DD.MM.YYYY HH:mm:ss') }`
  }
  componentDidMount() {
    if (this.props.smartBoxScrollPositionObject) {
      const { expandedRowKeys, url, scrollPosition } = this.props.smartBoxScrollPositionObject
      
      if (url === window.location.pathname && (expandedRowKeys && expandedRowKeys.length > 0)) {
        window.scrollTo(0, scrollPosition)
      } else {
        this.props.setSmartBoxScrollPositionObject({ expandedRowKeys: [], url: '', scrollPosition: 0 })
      }
    }
    let rootLevelDiff = 0
    if (this.props.files && this.props.files.length) {
      rootLevelDiff = this.props.files[0].depth

      let files = this.props.files
      const foldersCountFiles = this.props.foldersCountFiles

      files = files ? this.adjustFilesTable(files) : []
      files = this.addFilesCount(files, foldersCountFiles)
      
      let depthFromRoot = 0
      files.forEach(item => {
        if(item.type === 'DIRECTORY') {
          depthFromRoot = item.depthFromRoot
          return
        }
      })
      this.setState({
        files,
        foldersCountFiles,
        depthFromRoot
      }, () => {
        this.getTumbnailsList(this.props.files)
      })

    }
    this.setState({
      rootLevelDiff,
      // divHeight: document.querySelector('.leftNavigation').clientHeight - (this.props.hasDragBelow ? 270 : 230)
    })
    this.clickTimeout = null
    window.addEventListener('resize', this.handleResize.bind(this))
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.selectKeysListViewBox && this.props.selectKeysListViewBox.length > 0) {
      this.setState({
        selectedKeyFileTree: this.props.selectKeysListViewBox
      }, () => {
        this.displaySelectedNavigationBoxRowsTable()
      })
    }
    if (prevProps.files !== this.props.files && this.props.files) {
      this.displaySelectedRowsTable()
      const rootLevelDiff = this.props.files && this.props.files[0] ? this.props.files[0].depth : 0

      let files = this.props.files
      files = files ? this.adjustFilesTable(files) : []
      // if(prevProps.files !== null && prevProps.files.length === 0) {
      //   const foldersCountFiles = this.state.foldersCountFiles
      //   console.log(foldersCountFiles)
      //   files = this.addFilesCount(files, foldersCountFiles)
      // }

      // get thumbnails of new files
      const newFiles = []
      this.props.files.forEach((file) => {
        let foundIndex = -1
        if(prevProps?.files?.length) {
          foundIndex = prevProps.files.findIndex(item => item.id === file.id)
        }
        if (foundIndex === -1 && file.id !== 0) {
          newFiles.push(file)
        }
      })

      this.setState({
        rootLevelDiff,
        files: files,
        // foldersCountFiles: foldersCountFiles
      }, () => {
        if(newFiles.length) {
          this.getTumbnailsList(newFiles)
        }

      })
    }

    if (this.state.foldersCountFiles.length === 0 && JSON.stringify(prevProps.foldersCountFiles) === JSON.stringify(this.props.foldersCountFiles)
      && this.props.foldersCountFiles.length > 0) {
      this.setState({ foldersCountFiles: this.props.foldersCountFiles }, () => {
        let { files, foldersCountFiles } = this.state
        files = this.addFilesCount(files, foldersCountFiles)
        this.setState({ files })
      })
    }

    if (JSON.stringify(prevProps.foldersCountFiles) !== JSON.stringify(this.props.foldersCountFiles)) {
      let { files } = this.state
      files = this.addFilesCount(files, this.props.foldersCountFiles)
      this.setState({ files })
    }
    // console.log(prevProps.root, this.props.root, this.props.foldersCountFiles)
    // if(prevProps.root !== this.props.root && this.props.root) {
    //   let { files } = this.state
    //   const { foldersCountFiles } = this.props
    //
    //   files = this.addFilesCount(files, foldersCountFiles)
    //   this.setState({ files })
    // }
    if (prevProps.smartBoxRenamedFolder.id !== this.props.smartBoxRenamedFolder.id || prevProps.smartBoxRenamedFolder.newName !== this.props.smartBoxRenamedFolder.newName) {
      this.changeFileName(this.props.smartBoxRenamedFolder.id, this.props.smartBoxRenamedFolder.newName)
    }
    if (prevProps.lastFileClicked !== this.props.lastFileClicked || prevProps.lastFileClickedAction !== this.props.lastFileClickedAction) {
      this.markSelectedRowsOnTree()
    }
    if (prevProps.smartBoxSelectedFiles !== this.props.smartBoxSelectedFiles) {
      if((prevProps.smartBoxSelectedFiles.length === this.state.files.length && this.props.smartBoxSelectedFiles.length === 0)
        || (this.props.smartBoxSelectedFiles.length === this.state.files.length && prevProps.smartBoxSelectedFiles.length === 0)) {
        this.toggleSelectAllRows()
      } else {
        this.markSelectedRowsOnTree()
      }
    }
  }
  displaySelectedNavigationBoxRowsTable = () => {
    const { smartBoxSelectedFiles } = this.props
    const { selectedKeyFileTree } = this.state
    const rows = document.getElementsByClassName('list-files-row')

    for(let i=0; i< rows.length; i++) {
      rows[i].className = rows[i].className.replace(' selected', '')
      let id = rows[i].getAttribute('data-row-key')
      if(selectedKeyFileTree.includes(id) !== false
          || typeof smartBoxSelectedFiles.find(item => item.id === id) !== 'undefined') {
        rows[i].className = rows[i].className + ' selected'
      }
    }
  }
  displaySelectedRowsTable = () => {
    const { smartBoxSelectedFiles } = this.props
    const { selectedKeyFileTree } = this.state
    const rows = document.getElementsByClassName('list-files-row')

    for(let i=0; i< rows.length; i++) {
      rows[i].className = rows[i].className.replace(' selected', '')
      let id = rows[i].getAttribute('data-row-key')
      if(selectedKeyFileTree.includes(id) !== false
          || typeof smartBoxSelectedFiles.find(item => item.id === id) !== 'undefined') {
        rows[i].className = rows[i].className + ' selected'
      }
    }
  }
  getFilesDuplicates = (item) => {
    const { filesCheckDuplicates, files } = this.props
    const filesDuplicatesPath = []
    let typeIcon = ''
    if (files && files.length > 0) {
      filesCheckDuplicates && filesCheckDuplicates.length > 0 && filesCheckDuplicates.forEach((file) => {
        filesDuplicatesPath.push(file.path)
      })
      //const matchDuplicate = filesDuplicatesPath && filesDuplicatesPath.length > 0 && filesDuplicatesPath.filter((el) => (el === item.folderPath + item.path) || (el === `${ item.folderPath + item.path }.${ item.type }`))
      filesCheckDuplicates && filesCheckDuplicates.length > 0 && filesCheckDuplicates.forEach((file) => {
        if ((file.path === item.folderPath + item.path) || (file.path === `${ item.folderPath + item.path }.${ item.type }`)) {
          typeIcon = file.iconType
        }
      })
      // if (matchDuplicate && matchDuplicate.length > 0) {
      //   return true
      // }
      return typeIcon
    }
  }

  handleNewRenameFolderPressed = (e) => {
    const { validEditFolderName, renameFile } = this.state
    validEditFolderName.success= true
    if (e.key === 'Enter') {
      this.setState({
        newNameFolder: e.target.value,
        validEditFolderName
      })
      if (!nameRgex.test(e.target.value) || !this.props.isUnique(e.target.value)) {
        validEditFolderName.success = false
        if (! nameRgex.test(e.target.value)) {
          validEditFolderName.reason = 'Invalid name'
        }
        if (! this.props.isUnique(e.target.value)) {
          validEditFolderName.reason = 'Already exist'
          if (e.target.value === renameFile.name) {
            validEditFolderName.reason = 'Is old name'
          }

        }
        this.setState({
          validEditFolderName
        })
      }
      if (renameFile.name !== e.target.value && nameRgex.test(e.target.value) && this.props.isUnique(e.target.value)) {
        validEditFolderName.success = true
        this.setState({
          validEditFolderName
        })
        const newFileName = e.target.value.trim()

        const newRenameFolder = {
          id: renameFile.id,
          name: e.target.value
        }
        getRenameFile(newRenameFolder).then((response) => {
          if (response.status === 200) {

            this.setState({
              renameFile: null,
            }, () => {
              setTimeout(() => {
                this.changeFileName(renameFile.id, newFileName)
                this.props.setSmartBoxRenamedFolder({ id: renameFile.id, newName: newFileName })
              }, 2500)
            })
            if(response.data.title.toLowerCase() === 'success') {
              CustomNotificationManager.success(response.data.message, 'Success')
            } else {
              CustomNotificationManager.info(response.data.message, 'Info')
            }
          }
        }).catch((error) => {
          const errorMessage = 'Folder id does not exist'
          if (error?.response?.status === 500) {
            if (typeof error.response.data.message !== 'undefined' && error.response.data.message.indexOf(errorMessage) > 0) {
              CustomNotificationManager.error('The current folder no longer exists or you do not have access to its settings', 'Error')
              this.props.navigate('/smart-box')
            }
          }else if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      }
    }
  }

  changeFileName = (id, newFileName) => {
    const { files } = this.state

    const index = files.findIndex((file) => file.id === id)
    if (index !== -1) {

      const path = files[index].path.split('/')
      path[path.length - 1] = newFileName

      files[index].path = path.join('/')
      files[index].name = newFileName
      files[index].nameWithoutExtension = newFileName

      this.setState({ files })
    }
  }

  handleResize() {
    const divElement = document.querySelector('.leftNavigation')
    const divHeight = divElement ? divElement.clientHeight - (this.props.hasDragBelow ? 270 : 230) : 720
    this.setState({ divHeight })
  }

  getTumbnailsList = (files) => {
    const listOfFilesId = []
    const listOfThumbnailsNew = []
    if (files.length) {
      files.forEach((file) => {
        if (file.type !== 'DIRECTORY' && file.isPdf !== 'false') {
          if (!listOfFilesId.includes(file.id)) {
            listOfFilesId.push(file.id)
          }
        }
      })
      if (listOfFilesId.length) {
        const parameters = {
          list: listOfFilesId,
          clientId: this.props.clientId
        }
        getThumbnailForFiles(parameters).then((response) => {
          if (response.status === 200) {
            if (Object.keys(response.data).length) {
              const formatedResponse = this.createFromatedResponseForThumbnails(response.data)
              if (Object.keys(formatedResponse).length) {
                for (const [key, value] of Object.entries(formatedResponse)) {
                  listOfThumbnailsNew.push({ id: key, thumbnailSrc: value[0] })

                }
              }
              // if new file add it's thumbnail, else change it
              const { listOfThumbnails } = this.state

              listOfThumbnailsNew.forEach((item) => {
                const foundIndex = listOfThumbnails.findIndex(t => t.id === item.id)
                if(foundIndex === -1) {
                  listOfThumbnails.push(item)
                } else {
                  listOfThumbnails[foundIndex] = item
                }
              })
              this.setState({ listOfThumbnails })
              this.props.getThumbnailsTreeStructureFolder(listOfThumbnails)
            }
          }
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
      }
    }
  }

  createFromatedResponseForThumbnails = (data) => {
    const result = {}
    if (Object.keys(data).length) {
      for (const item of Object.entries(data)) {
        if (item.length > 1) {
          const temp = item[0].split(',')
          const documentId = temp[0]
          if (temp[1]) {
            const temporary = temp[1].split(':')
            const page = temporary[1].trim()
            const base64 = item[1]
            if (documentId in result) {
              result[documentId][page] = base64
            } else {
              result[documentId] = []
              result[documentId][page] = base64

            }
          }
        }
      }
    }
    return result
  }

  getTumbnails = (type, parent, fileId) => {
    const { listOfThumbnails } = this.state
    let response = getImageSrc(type, parent)
    if (type !== 'DIRECTORY') {
      if (listOfThumbnails && listOfThumbnails.length) {
        listOfThumbnails.forEach((item) => {
          if (item.id === fileId) {
            response = item.thumbnailSrc
          }
        })
      }
    }
    return response
  }
  getListFoldersCheckErrors = (files) => {
    // if (files.length > 0) {
    //   getCheckFolderErrors(files).then((response) => {
    //     if (response.data.length) {
    //       const foldersCheckErrors = this.props.smartBoxFoldersCheckErrors
    //
    //       response.data.forEach((item) => {
    //         const index = foldersCheckErrors.findIndex((el) => el.path === item.path)
    //         if (index !== -1) {
    //           foldersCheckErrors[index].folderHasErrors = item.folderHasErrors
    //         } else {
    //           foldersCheckErrors.push(item)
    //         }
    //       })
    //       this.setState({foldersCheckErrors})
    //       this.props.setSmartBoxFoldersCheckErrors(foldersCheckErrors)
    //     }
    //   })
    // }
  }
  markSelectedRowsOnTree = () => {
    const { smartBoxSelectedFiles } = this.props
    const rows = document.getElementsByClassName('list-files-row')

    for(let i=0; i< rows.length; i++) {
      rows[i].className = rows[i].className.replace(' selected', '')
      let id = rows[i].getAttribute('data-row-key')
      if(smartBoxSelectedFiles.includes(id) !== false
        || typeof smartBoxSelectedFiles.find(item => item.id === id) !== 'undefined') {
          rows[i].className = rows[i].className + ' selected'
      }
    }
  }

  toggleSelectAllRows = () => {
    const { smartBoxSelectedFiles } = this.props
    const rows = document.getElementsByClassName('list-files-row')

    for(let i=0; i< rows.length; i++) {
      rows[i].className = rows[i].className.replace(' selected','')
      if(smartBoxSelectedFiles.length > 0) {
        rows[i].className = rows[i].className + ' selected'
      }
    }
      }

  clickHandler = (event, open, folder) => {
    const selectedFile = []
    selectedFile.push(folder.id)
    this.setState({
      selectRowOnClick: folder,
      selectedKeyFileTree:selectedFile
    })
    if (this.props.root && this.props.currentPage) {
      this.props.setSmartBoxCurrentPage(this.props.currentPage)
    }
    if (this.clickTimeout !== null) {
      this.props.setBoxListViewSelectedKeys(selectedFile)
      if (folder.type === 'DIRECTORY') {
        //this.props.setBoxListViewSelectedKeys(selectedFile)
      }
      if(this.props.root) {
        clearTimeout(this.clickTimeout)
        this.clickTimeout = null

        this.props.navigate(`/smart-box/folder/${ folder.id }`)
      } else {
        if (folder.type !== 'DIRECTORY') {
          this.executeAction(folder, 'redacting', event)
        }
        this.toggleLine(folder, event)

        clearTimeout(this.clickTimeout)
        this.clickTimeout = null
      }
      // this.props.toggleFilePreview(false, {})

    } else {
      this.clickTimeout = setTimeout(() => {
        /*if (folder.type === 'DIRECTORY' || folder.isPdf === 'true') {
          this.props.toggleFilePreview(open, folder)
        } else {
          this.props.toggleFilePreview(false, {})
        }*/
        this.props.toggleFileSelect(folder)
        clearTimeout(this.clickTimeout)
        this.clickTimeout = null
      }, 400)
    }
  }
  
  fetchFiles = (docKey, docFolderName, docFolderPath) => {
    const initFilter = {
      'docFilename': docFolderName ? docFolderName : this.state.searchText,
      'docFolderPath': docFolderPath ? docFolderPath : null
    }
    getSmartBoxFilesFilter(initFilter).then((response) => {
      if (docKey) {
        const responseFiles = response.data
        const propsFiles = this.props.files

        // console.log(response.data)
        this.getListFoldersCheckErrors(responseFiles)

        let folders = responseFiles.filter(item => item.type === 'DIRECTORY')
        if(folders.length > 0) {
          const pathFiles = []
          folders && folders.length > 0 && folders.forEach((item) => {
            if (item.path) {
              pathFiles.push(item.path)
            }
          })
          const folderCountFilesDTO = {
            pathList: pathFiles
          }
          getFolderCountFiles(folderCountFilesDTO).then((res) => {
            const temp = this.addNode(docKey, propsFiles, responseFiles, res.data)

            this.props.setNewFiles(temp)
            this.getTumbnailsList(responseFiles)
          }).catch((error) => {
            if (error?.response?.status === 504) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else if (error?.response?.status === 403) {
              CustomNotificationManager.cleanBuffer('warning', '')
            }else{
              console.log(error)
            }
          })
        } else {
          const temp = this.addNode(docKey, propsFiles, responseFiles, [])

          this.props.setNewFiles(temp)
          this.getTumbnailsList(responseFiles)
        }
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  };
  
  handleDeleteFile = (file) => {
    const { files } = this.props
    const temp = this.removeNode(file.id, files)
    this.props.setNewFiles(temp)
  }
  
  removeNode(id, currentNodes) {
    let currentNodesCopy = [ ...currentNodes ]
    for (let i = 0; i < currentNodes.length; i++) {
      if (currentNodesCopy[i]?.id === id) {
        currentNodesCopy = currentNodesCopy.filter((curNode) => curNode.id !== id)
      } else if (currentNodesCopy[i]?.children) {
        currentNodesCopy[i].children = this.removeNode(id, currentNodesCopy[i].children)
      }
    }
    return currentNodesCopy
  }
  
  addNode(id, currentNodes, newNodes, foldersCountFiles) {
    const currentNodesCopy = [ ...currentNodes ]
    for (let i = 0; i < currentNodes.length; i++) {
      if (currentNodesCopy[i].id === id) {
        newNodes = this.adjustFilesTable(newNodes)
        newNodes = this.addFilesCount(newNodes, foldersCountFiles)

        currentNodesCopy[i].children = newNodes
      } else if (currentNodesCopy[i].children && currentNodesCopy[i].children.length) {
        currentNodesCopy[i].children = this.addNode(id, currentNodesCopy[i].children, newNodes, foldersCountFiles)
      }
    }
    return currentNodesCopy
  }
  
  adjustFilesTable(files = []) {
    for (let i = 0; i < files.length; i++) {
      files[i].key = files[i].id
      if (files[i].children && files[i].children.length > 0) {
        files[i].children = this.adjustFilesTable(files[i].children)
      }
    }
    return files
  }
  
  customExpandIcon = (props) => {
    // if (props.record.type === 'DIRECTORY') {
    //   if (props.expanded) {
    //     return <svg width="16px" height="16px" className="expand-collapse"
    //       style={ { paddingLeft: (props.record.depth - this.state.rootLevelDiff) * 60 } }>
    //       <polygon points="0,3,16,3,8,13" fill="#81b7e6"/>
    //     </svg>
    //   }
    //   return <svg width="16px" height="16px" className="expand-collapse"
    //     style={ { paddingLeft: (props.record.depth - this.state.rootLevelDiff) * 60 } }>
    //     <polygon points="3,0,13,8 3,16" fill="none" stroke="rgba(110,120,130,0.4)" strokeWidth="2"/>
    //   </svg>
    // }
    // return <svg width="16px" height="16px" className="expand-collapse" style={ {
    //   visibility: 'hidden',
    //   paddingLeft: (props.record.depth - this.state.rootLevelDiff) * 60
    // } }>
    //   <polygon points="0,3,16,3,8,13" fill="#81b7e6"/>
    // </svg>
  }
  
  toggleLine = (folder, event) => {
    const { expandedRowKeys } = this.props.smartBoxScrollPositionObject
    const temp = expandedRowKeys.indexOf(folder.id)

    if (temp > -1) {
      expandedRowKeys.splice(temp, 1)
    } else {
      expandedRowKeys.push(folder.id)
      this.fetchFiles(folder.id, folder.name, folder.folderPath)
    }
    
    const scrollPositionObject = {
      expandedRowKeys,
      scrollPosition: event.clientY,
      url: window.location.pathname
    }
    
    this.props.setSmartBoxScrollPositionObject(scrollPositionObject)
  }

  executeAction = (record, action, e) => {
    e.stopPropagation()
    e.preventDefault()
    switch (action) {
      case 'viewer':
        apiSmartBoxPreviewSarDocByThumbnailId(record.id).then((response) => {
          this.windowDocument = generateBlobFromBase64(response.data.doc)
          const { systemPropertyFileStatus } = response.data
          this.setState({
            renderingBlock: <PDFViewer backend={ PDFJSBackend } src={ this.windowDocument } toolbar={ 0 }/>,
            previewVisible: true,
            systemPropertyFileStatus
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
        break
      case 'viewer-finalized':
        apiSmartBoxPreviewSarDocFinalizedByThumbnailId(record.id).then((response) => {
          this.windowDocument = generateBlobFromBase64(response.data)
          this.setState({
            renderingBlock: <PDFViewer backend={ PDFJSBackend } src={ this.windowDocument } toolbar={ 0 }/>,
            previewVisible: true
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        })
        break
      // case 'insights':
      //   this.routeChangeDataInsight(e, record)
      //   break
      // case 'analysis':
      //   this.routeChangeAnalysisSettings(e, record)
      //   break
      // case 'redaction':
      //   this.routeChangeFolderRedaction(e, record)
      //   break
      case 'security-settings':
        this.routeChangeFolderSecurity(e, record, action)
        break
      case 'redacting':
        this.props.onFilePressed(record)
        break
      case 'rename':
        if (this.props.getFilesCountListView && this.props.getFilesCountListView(record) > 0 && (record.statisticsReady === 'false' || record.statisticsReady === null)) {
          return false
        } else {
          this.onChangeRenameFile(record)
        }
        break
      case 'export':
        //this.onDownloadZip(record.id, record.name)
          this.onViewOptionsFileModalDownload(record)
        break
      case 'download-file':
        this.onDownloadFileOriginal(record)
        break
      case 'delete':
        this.props.toggleFilePreview(false, {})
        this.showConfirm(record, e)
        break
      case 'info':
        this.props.toggleFilePreview(true, record)
        break
      case 'finalise-document':
        this.onFinaliseDocument(record)
        break
      case 'ocr':
        this.previewFilfeOcr(record, action,e)
        break
      case 'settings':
        this.routeChangeFolderSettings(e)
        break
      case 'add-note':
        this.setState({
          documentNoteVisible: true,
          currentItem: record,
        })
        break
      case 'finalise-folder':
        this.onFinaliseFolder(record.id)
        break
      case 'export-document':
        this.onExportDocument(record.id, record.name)
        break
      case 'reanalyse-with-ai':
        this.reanalyseWithAI(record)
        break
      default:
        break
    }
  }

  onDownloadFileOriginal = (record) => {
    const { clientId } = this.props
    const indexRelativePath = record.folderPath ? record.folderPath.indexOf(`smartBox_${ clientId }`) : ''
    const newRelativPath = record.folderPath ? record.folderPath.substr(indexRelativePath + `smartBox_${ clientId }`.length) : ''
    const finallyPath = newRelativPath ? newRelativPath.substr(1, newRelativPath.length) : ''
    const nameFile = record.name
    const typeFile = record.type
    const hasRight = true
    const finallyPathDownload = `${ finallyPath }${ nameFile }.${ typeFile }`
    const fileId = record.id ? record.id : ''
    const FileDownloadDTO = {
      fileId: fileId,
      hasRight: hasRight
    }
    apiSmartBoxDownloadFile(FileDownloadDTO).then((response) => {
      if (response.status === 200) {
        FileDownload(response.data, `${ nameFile }.${ typeFile }`)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  onChangeRenameFile = (renameFile) => this.setState({ renameFile })

  reanalyseWithAI = (record) => {
    const data = {
      clientId: this.props.clientId,
      list: [record.id]
    }
    sendToReanalyseWithAI(data).then((response) => {
      if (response.status === 200) {
        CustomNotificationManager.success(`${ record.name } was sent to be re-analysed.`)
      }
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  onExportDocument = (id, name) => {
    apiSmartBoxExportDocument(id).then((response) => {
      FileDownload(response.data, `${ name }.pdf`)
    }).catch((error) => {
      if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }

  onFinaliseDocument = (file) => {
    const fileId = file.id
    apiSmartBoxFinalisedDocument(fileId).then(() => {
      this.props.fetchFiles()
    })
  }

  showConfirm = async(file, e) => {
    e.stopPropagation()
    e.preventDefault()
    const parentId = {
      id: file.id,
      filePath: file.path,
      folder: file.type === 'DIRECTORY'
    }
    const fileList = await getFolderChildren(parentId)
    this.setState({
      childrenIsNotEmpty: fileList.data.length > 0,
      childrenLength: fileList.data.length
    }, () => {
      customConfirm({
        confirmAction: () => deleteSmartDocument(parentId).then((resp) => {
          if (resp.data === 'Please wait until upload finishes!') {
            CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
          }
          this.handleDeleteFile(file)
        }).catch((error) => {
          if (error.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }
        }),
        title: 'Do you want to delete item ?',
        content: `${ file.name } ${ this.state.childrenIsNotEmpty ? `contains ${ this.state.childrenLength } items` : '' } ?`,
        confirmButtonText: 'DELETE',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button'
      })
    })
  }

  previewFilfeOcr = (record, e) => {
    const fileId = record.id
    const getOCRFileTextDTO = {
      idDocument: fileId
    }
    getOcrFile(getOCRFileTextDTO).then((response) => {
      this.setState({
        // fileContentOcr: response.data,
        fileContentOcr: typeof response.data === "object" ? '' : response.data,
        previewVisibleOcr: true
      })
    }).catch((error) => {
      if (error?.response?.status === 500) {
        const errorMessage = 'OCR transformation was not successful for this file.'
        CustomNotificationManager.error(errorMessage, 'Error')
      }else if (error?.response?.status === 504) {
        CustomNotificationManager.cleanBuffer('warning', '')
      }else{
        console.log(error)
      }
    })
  }
  handleCancelModal = () => this.setState({ previewVisibleOcr: false }, () =>
    window.URL.revokeObjectURL(this.windowDocument))

  handleCancel = () => this.setState({ previewVisible: false, renderingBlock: null }, () =>
    window.URL.revokeObjectURL(this.windowDocument))
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this))
    window.URL.revokeObjectURL(this.windowDocument)
  }

  onDownloadZip = (e, selectedFile) => {
    apiSmartBoxZip(selectedFile.id, selectedFile.name).then((response) => {
      FileDownload(response.data, `${ selectedFile.name }.zip`)
    })
  }

  deleteFolder = async(e, selectedFile) => {
    const { childrenIsNotEmpty, childrenLength }= this.state
    e.stopPropagation()
    e.preventDefault()
    const parentId = {
      id: selectedFile.id,
      filePath: selectedFile.path,
      folder: selectedFile.type === 'DIRECTORY'
    }
    const fileList = await getFolderChildren(parentId)
    this.setState({
      childrenIsNotEmpty: fileList.data.length > 0,
      childrenLength: fileList.data.length
    }, () => {
      customConfirm({
        confirmAction: () => deleteSmartDocument(parentId).then((resp) => {
          if (resp.data === 'Please wait until upload finishes!') {
            CustomNotificationManager.info('', 'Please wait until upload finishes!', { duration: 5 })
          }
          this.setState({
            files: deleteFolderOrFile(selectedFile, this.state.files)
          })
        }).catch((error) => {
          if (error?.response?.status === 504) {
            CustomNotificationManager.cleanBuffer('warning', '')
          }else{
            console.log(error)
          }
        }),
        title: 'Do you want to delete item ?',
        content: `${ selectedFile.name } ${ childrenIsNotEmpty ? `contains ${ childrenLength } items` : '' } ?`,
        confirmButtonText: 'DELETE',
        cancelButtonText: 'CANCEL',
        mask: true,
        maskClosable: false,
        closeHaveLogic: false,
        className: 'asset-register-modal',
        centered: true,
        cancelClassName: 'cancel-button',
        confirmClassName: 'confirm-button'
      })
    })
  }

  toggleNoteVisible = (e, open) => {
    this.setState({ documentNoteVisible: open })
  }

  routeChangeFolder = (e, selectedFile, type) => {
    e.stopPropagation()
    this.onChangeRouteCurrentFolder(e, selectedFile, type)
    if (selectedFile) {
      if (type === 'analysis-settings' || type === 'data-insights' || type === 'security-security-settings' || type === 'folder-redaction' || 'security-settings' || type === 'cull') {
        if (selectedFile.type === 'DIRECTORY') {
          const path = `/smart-box/folder/${ selectedFile.id }/${ type }`
          this.props.navigate(path)
        }
      }
    } else {
      const path = '/smart-box'
      this.props.navigate(path)
    }
  }
  routeChangeFolderSecurity = (e, selectedFile, type) => {
    e.stopPropagation()
    this.onChangeRouteCurrentFolder(e, selectedFile, type)
    if (selectedFile) {
      if (selectedFile.type === 'DIRECTORY' && type === 'security-settings') {
        this.props.setSmartBoxSecurityConfiguration('security-settings')
        const path = `/smart-box/folder/${ selectedFile.id }/analysis-settings`
        this.props.navigate(path)
      }
    } else {
      const path = '/smart-box'
      this.props.navigate(path)
    }
  }

  onChangeRouteCurrentFolder(e, selectedFile, type) {
    e.stopPropagation()
    if (selectedFile) {
      if (type === 'analysis-settings' || type === 'data-insights' || type === 'security-security-settings' || type === 'folder-redaction' || 'security-settings') {
        if (selectedFile.type === 'DIRECTORY') {
          this.props.setSmartBoxCurrentFolder(selectedFile.id)
        }
      }
    }
  }

  geThumbnailOrImageSrc = (type, parent, opened, isEmpty, hasError, numberOfFiles) => {
    let response = getImageSrc(type, parent, opened, '', isEmpty, hasError, true, numberOfFiles)
    if (type !== 'DIRECTORY') {
      if (this.props.listOfThumbnails && this.props.listOfThumbnails.length) {
        this.props.listOfThumbnails.forEach((item) => {
          if (item.id === opened) {
            response = item.thumbnailSrc
          }
        })
      }
    }
    return response
  }
  addFilesCount = (filesTable, foldersCountFiles) => {

    let filesTableMod = [...filesTable]

    if(filesTable.length > 0) {
      filesTable.forEach((item, index) => {
        if(item.type === 'DIRECTORY') {
          const match = foldersCountFiles.filter(el => el.path === item.path)
          // const nr = match && match.length > 0 ? match[0].numberOfFiles : 0
          // filesTableMod[index].numberOfFiles = nr
          if(match && match.length > 0) {
            filesTableMod[index].numberOfFiles = match[0].numberOfFiles
          }

          if(typeof item.children !== 'undefined' && item.children !== null && item.children.length > 0) {
            filesTableMod[index].children = this.addFilesCount(filesTableMod[index].children, foldersCountFiles)
          }
        }
      })
    }

    return filesTableMod
  }

  getFilesErrors = (item) => {
    // const { foldersCheckErrors } = this.state
    const foldersCheckErrors = this.props.smartBoxFoldersCheckErrors
    const match = foldersCheckErrors.filter((el) => el.path === item.path)

    if (match && match.length > 0) {
      return match[0].folderHasErrors
    }
    return false
  }
  onViewOptionsFileModalDownload = (file) => {
    this.setState({
      visiblePageModalDownload: true,
      visibleCollectData: true,
      fileSelectDownload: file
    })
  }
  onCancelModalDownload = (status) => {
    this.setState({
      visiblePageModalDownload: status,
    })
  }
  onCloseCollectData = (status) => {
    this.setState({
      visibleCollectData: status,
    })

  }
  render() {
    const { previewVisible, renderingBlock, currentItem, previewVisibleOcr, fileContentOcr, files, documentNoteVisible, divHeight,
      visiblePageModalDownload, visibleCollectData, fileSelectDownload, selectedKeyFileTree } = this.state
    const { smartBoxScrollPositionObject, folderNewDepth } = this.props
    let filesTable = files /*? this.adjustFilesTable(files) : []

    filesTable = this.addFilesCount(filesTable, foldersCountFiles)
    */
    let folderNewDepthProps = null
    if (folderNewDepth) {
      folderNewDepthProps = folderNewDepth
    }
    filesTable && filesTable.length > 0 && filesTable.forEach((file) => {
      file.systemPropertyFileStatus = file.systemPropertyFileStatus === null ? 'empty' : file.systemPropertyFileStatus
    })
    return (
      <React.Fragment>
        {
          (visiblePageModalDownload && visibleCollectData) &&
          <DownloadBoxModalOptionFiles
              file={ fileSelectDownload }
              onCancelModalDownload = { this.onCancelModalDownload }
              onCloseCollectData = { this.onCloseCollectData }/>
        }
        { filesTable.length > 0 &&
          <Table
            showLine={ true }
            dataSource={ filesTable }
            expandedRowKeys={ smartBoxScrollPositionObject.expandedRowKeys }
            columns={ this.columns }
            rowClassName="editable-row list-files-row"
            pagination={ false }
            className="files-table parent-files"
            loading={ this.props.loadingBoxPage }
            switcherIcon={ <Icon type="left"/> }
            expandIcon={ (props) => this.customExpandIcon(props) }
            onRow={ (record) => {
              return {
                onClick: (e) => this.clickHandler(e, true, record),
                onContextMenu: (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }
            } }
          />
        }
        <ContextMenu className="context-menu-directory" id="context-menu">
          <MenuItem className="menu-item-directory" key={ 3 }
            onClick={ (e, props) => this.executeAction(props, 'redacting', e) }>
            Open in Smartview
          </MenuItem>
          <MenuItem key={ 1 } className="menu-item-directory"
            onClick={ (e, props) => this.executeAction(props, 'viewer', e) }>
            Open original
          </MenuItem>
          { currentItem && currentItem.finalizedDocPath && <MenuItem key={ 2 } className="menu-item-directory" onClick={ (e, props) => this.executeAction(props, 'viewer-finalized', e) }>
            Open redacted version
          </MenuItem> }
          {
            currentItem.isOcr !== 'false' && <MenuItem className="menu-item-directory" key={ 4 }
              onClick={ (e, props) => this.executeAction(props, 'ocr', e) }>
              View OCR
            </MenuItem>
          }
          { currentItem.isPdf === 'true' && currentItem.finalizedDocPath &&
            <MenuItem className="menu-item-directory" key={ 6 }
              onClick={ (e, props) => this.executeAction(props, 'finalise-document', e) }>
              Update redacted version
            </MenuItem>
          }
          {/*{ currentItem.isPdf === 'true' && !currentItem.finalizedDocPath &&*/}
          {/*<MenuItem className="menu-item-directory" key={ 6 }*/}
          {/*  onClick={ (e, props) => this.executeAction(props, 'finalise-document', e) }>*/}
          {/*  Create redacted version*/}
          {/*</MenuItem>*/}
          {/*}*/}
          <MenuItem className="menu-item-directory" key={ 23 } onClick={ (e, props) => this.executeAction(props, 'reanalyse-with-ai', e) }>
            Reprocess
          </MenuItem>
          {currentItem.finalizedDocPath &&
          <MenuItem className="menu-item-directory" key={8}
            onClick={(e, props) => this.executeAction(props, 'export-document', e)}>
            Export redacted version
          </MenuItem>
          }
          { currentItem.isPdf === 'true' &&
          <MenuItem className="menu-item-directory" key={ 5 }
            onClick={ (e, props) => this.executeAction(props, 'info', e) }>
            View file info
          </MenuItem>
          }
          <MenuItem className="menu-item-directory" key={ 7 }
            onClick={ (e, props) => this.executeAction(props, 'delete', e) }>
            Delete
          </MenuItem>
        </ContextMenu>
        <ContextMenu className="context-menu-directory" id="context-menu-folder">
          {this.props.root && <MenuItem
              className={currentItem.statisticsReady === 'false' && this.props.getFilesCountListView && this.props.getFilesCountListView(currentItem) > 0 ? "menu-item-directory menu-item-disabled" : 'menu-item-directory'}
              key={12}
              disabled={currentItem.statisticsReady === 'false' && this.props.getFilesCountListView && this.props.getFilesCountListView(currentItem) > 0}
              onClick={(e, props) => this.routeChangeFolder(e, props, 'analysis-settings')}>
            { currentItem.statisticsReady === 'false' && 
              this.props.getFilesCountListView && 
              this.props.getFilesCountListView(currentItem) ?
                  <Tooltip title="Generating stats. Please wait."><span>Box settings</span></Tooltip> 
                  :
                  <span>Box settings</span>
            }
          </MenuItem>
          }
          {this.props.root && <MenuItem
              className={currentItem.statisticsReady === 'false' ? "menu-item-directory menu-item-disabled" : 'menu-item-directory'}
              key={13}
              disabled={currentItem.statisticsReady === 'false'}
              onClick={(e, props) => this.routeChangeFolder(e, props, 'data-insights/dashboard')}>
            {currentItem.statisticsReady === 'false' ?
                  <Tooltip title={ this.props.getFilesCountListView(currentItem) === 0 ? '' : "Generating stats. Please wait." }>
                    <span>Report</span>
                  </Tooltip> :
                  <span>Report</span>
            }
          </MenuItem>
          }
          {this.props.root && <MenuItem
              className={currentItem.statisticsReady === 'false' ? "menu-item-directory menu-item-disabled" : 'menu-item-directory'}
              key={20}
              disabled={currentItem.statisticsReady === 'false'}
              onClick={(e, props) => this.routeChangeFolder(e, props, 'folder-redaction')}>
            {currentItem.statisticsReady === 'false' ?
                  <Tooltip title={ this.props.getFilesCountListView(currentItem) === 0 ? '' : "Generating stats. Please wait." }>
                    <span>Redaction</span>
                  </Tooltip> :
                  <span>Redaction</span>
            }
          </MenuItem>
          }
          {this.props.root && <MenuItem
              className={currentItem.statisticsReady === 'false' ? "menu-item-directory menu-item-disabled" : 'menu-item-directory'}
              key={43}
              disabled={currentItem.statisticsReady === 'false'}
              onClick={(e, props) => this.routeChangeFolder(e, props, 'cull')}>
            {currentItem.statisticsReady === 'false' ?
                  <Tooltip title={ this.props.getFilesCountListView(currentItem) === 0 ? '' : "Generating stats. Please wait." }>
                    <span>Cull</span>
                  </Tooltip> :
                  <span>Cull</span>
            }
          </MenuItem>
          }
          {currentItem && currentItem.bulkProcessingStarted === 'finished' &&
          <MenuItem className="menu-item-directory" key={15} onClick={(e, props) => this.onDownloadZip(e, props)}>
            Export finalised
          </MenuItem>
          }
          {/* <MenuItem key={ 14 } className="menu-item-category menu-item-category3">
          </MenuItem> */}
          <MenuItem className="menu-item-directory" key={ 18 } onClick={ (e) => this.toggleNoteVisible(e, true) }>
            Add a note
          </MenuItem>
          <MenuItem className="menu-item-directory" key={ 23 } onClick={ (e, props) => this.executeAction(props, 'reanalyse-with-ai', e) }>
            Reprocess
          </MenuItem>
          {/*<MenuItem className="menu-item-directory menu-disabled" key={ 19 } disabled={true}>*/}
          {/*  { currentItem.finaliseFolder ? 'Update redaction versions' : 'Create redacted versions' }*/}
          {/*</MenuItem>*/}
          {
            folderNewDepthProps === null && !folderNewDepthProps > 0 &&
            <React.Fragment>
              { currentItem && currentItem.depth === 0 && <MenuItem className="menu-item-directory" key={ 22 }
                onClick={ (e, props) => this.routeChangeFolderSecurity(e, props, 'security-settings') }>
                Security Settings
              </MenuItem>
              }
            </React.Fragment>
          }
          {/* <MenuItem key={ 45 } className="menu-item-category">
          </MenuItem> */}
          <MenuItem className="menu-item-directory" key={ 21 } onClick={ (e, props) => this.props.toggleFilePreview(true, props) }>
            Folder info
          </MenuItem>
          <MenuItem className="menu-item-directory" key={ 17 } onClick={ (e, props) => this.deleteFolder(e, props) }>
            Delete
          </MenuItem>
        </ContextMenu>
        <Modal visible={ previewVisible } footer={ null } onCancel={ this.handleCancel } className="pdf-modal">
          <div className="custom-modal-body">
            { renderingBlock }
          </div>
        </Modal>
        <Modal key={ 4 } visible={ previewVisibleOcr } onCancel={ this.handleCancelModal } footer={ null } className="pdf-modal">
          <div className="custom-modal-body align-text">
            <p>{ fileContentOcr }</p>
          </div>
        </Modal>
        <React.Fragment key={`notes-${ currentItem.id }`}>
          { documentNoteVisible ?
              <DirectoryNote closeDocumentNote={ (e) => this.toggleNoteVisible(e, false) }
                             documentName={ currentItem.name }
                             documentId={ currentItem.id }
                             type={currentItem.type}
                             depthFromRoot={currentItem.depthFromRoot}
                             depth={currentItem.depth}
                             user={ this.props.user } /> : '' }
        </React.Fragment>
      </React.Fragment>
    )
  }
}

TreeStructureFolder.propTypes = {
  toggleFilePreview: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func
}

TreeStructureFolder = withRouter(TreeStructureFolder)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  redactingMode: !state.shellReducer.sidebarVisible && !state.shellReducer.headerVisible && !state.shellReducer.footerVisible,
  fetchFolderContent: state.smartBox.fetchFolderContent,
  smartBoxSearchedText: state.smartBox.smartBoxSearchedText,
  smartBoxScrollPositionObject: state.smartBox.smartBoxScrollPositionObject,
  user: state.userReducer.user,
  smartBoxRenamedFolder: state.smartBox.smartBoxRenamedFolder,
  smartBoxFoldersCheckErrors: state.smartBox.smartBoxFoldersCheckErrors,
  smartBoxSelectedFiles: state.smartBox.smartBoxSelectedFiles,
  smartBoxTreeCurrentDepth: state.smartBox.smartBoxTreeCurrentDepth,
  selectKeysListViewBox: state.smartBox.selectKeysListViewBox,
})
export default connect(mapStateToProps, {
  setHeaderVisibility,
  setSidebarVisibility,
  setFooterVisibility,
  setSmartBoxUploadingObject,
  setSmartBoxSearchedWord,
  setSmartBoxScrollPositionObject,
  setSmartBoxCurrentFolder,
  setSmartBoxFoldersCheckErrors,
  setSmartBoxSecurityConfiguration,
  setSmartBoxRenamedFolder,
  setBoxListViewSelectedKeys,
  setSmartBoxCurrentPage
})(TreeStructureFolder)