import React from 'react'
import {Button, Modal, Select, Tooltip} from 'antd'
import imgFolderParentNegative from '../../assets/box/box_negative_20x20.png'
import './CullingModal.scss'
import Switcher from "./Switcher";
import {getCullingFiles} from "../../api/SmartBox";
import CustomNotificationManager from './CustomNotificationManager'
const { Option } = Select
class CullingModal extends React.Component {

    constructor(props) {
        super(props)
        this.state= {
            valueShareDuplicates: false,
            valueShareNearDuplicates: false,
            valueShareEmail: false,
            valueShareRemoveEmpty: false,
            valueSelectKeep: 'keep newest file',
            isLoading:false,
            loadingQuickCullCancel: false
        }
    }

    handleCancelModal = () => {
        this.props.statusCloseModalCulling(false)
    }
    onCloseModal = (e) => {
        e.preventDefault()
        this.setState({
            loadingQuickCullCancel: true
        }, () => {
            this.props.statusCloseModalCulling(false)
            this.setState({
                loadingQuickCullCancel: false
            })
        })
    }
    handleChangeShareDuplicates(status) {
        this.setState({
            valueShareDuplicates: status
        })
    }
    handleChangeShareNearDuplicates(status) {
        this.setState({
            valueShareNearDuplicates: status
        })
    }
    handleChangeShareEmail(status) {
        this.setState({
            valueShareEmail: status
        })
    }
    handleChangeShareRemoveFolders(status) {
        this.setState({
            valueShareRemoveEmpty: status
        })
    }
    onChangeSelectKeep = (e) => {
        this.setState({
            valueSelectKeep: e
        })
    }
    onSweepCulling = () => {
        const { valueShareDuplicates, valueShareNearDuplicates, valueShareEmail, valueShareRemoveEmpty, valueSelectKeep } = this.state
        const { folderParent,
            // baseDataForTree,
            boxNameBaseId, idParentBox } = this.props
        // let basePathBox = ''
        // let nameBox = ''
        // if (folderParent && typeof folderParent.relativePath !== 'undefined') {
        //     nameBox = folderParent.relativePath.split('/')[1]
        // }
        // baseDataForTree && baseDataForTree.length > 0 && baseDataForTree.forEach((baseBox) => {
        //     if (baseBox.name === nameBox) {
        //         basePathBox = baseBox.id
        //     }
        // })
        const dtoCulling = {
            boxId: idParentBox ? idParentBox : '',
            removeDuplicates: valueShareDuplicates,
            removeNearDuplicates: valueShareNearDuplicates,
            removeNearDuplicatesOptions: valueSelectKeep,
            removeEmailThreading: valueShareEmail,
            removeEmptyFolders: valueShareRemoveEmpty,
        }
        this.setState({
            isLoading:true,
        })
        getCullingFiles(dtoCulling).then((response) => {
            this.setState({
                isLoading:false,
            })
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
           if (response.status === 200) {
               this.props.statusCloseModalCulling(false)
           }
        })
    }
    render() {
        const { boxNameBase, folderParent, smartBoxParentFolderName } = this.props
        const { valueShareDuplicates, valueShareNearDuplicates, valueShareEmail, valueShareRemoveEmpty, valueSelectKeep, isLoading,
            loadingQuickCullCancel } = this.state

        let disabledSweep = false
        if (!valueShareDuplicates && !valueShareNearDuplicates && !valueShareEmail && !valueShareRemoveEmpty) {
            disabledSweep = true
        }
        return (
            <Modal
                title={ <div className="header-modal-culling"><span className="content-header-modal-culling"><img src={imgFolderParentNegative} alt={imgFolderParentNegative}/>
                    <span className="title-culling">{ boxNameBase ? boxNameBase : folderParent ? folderParent.name : smartBoxParentFolderName }</span></span>
                    <span onClick={(e) => this.onCloseModal(e)} className="icon-v18-close-black"></span></div> }
                visible={true}
                footer={ null }
                closable={false}
                className="culling-modal"
                onCancel={this.handleCancelModal}
                style={{width: '790px'}}
            >
                <div className='culling-modal-content'>
                    <div className="culling-contain-function">
                       <div className="culling-modal-header">
                            <span className="title-header">Quick cull</span>
                           {/*<span className="icon-v14-question"></span>*/}
                           <Tooltip overlayClassName="help-message-locations" title={<div className="content-help-message-locations">
                               <span className="text-information">Culling is the function that enables the quick removal of duplicated data, email threads and empty folders from a Box.</span>
                               <span className="more-info-link" onClick={ () => window.open('https://support.smartbox.ai/support/solutions/articles/47001235835-culling', '_blank') }>More info.</span>
                           </div>}>
                               <span className="icon-v14-question"></span>
                           </Tooltip>
                       </div>
                        <div className="culling-contain">
                            <div className="content-functions-culling">
                                <div className="title-functions-culling">Duplicates</div>
                                <div className="functions-culling-contain">
                                        <div className="row">
                                            <div className="right-container">
                                                    <div className="teams switcher-select-configuration">
                                                        <Switcher
                                                            active={ valueShareDuplicates }
                                                            updateActive={() => {
                                                                this.handleChangeShareDuplicates(!valueShareDuplicates)
                                                            }}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                </div>
                            </div>

                            <div className="content-functions-culling">
                                <div className="title-functions-culling">Near duplicates</div>
                                <div className="functions-culling-contain">
                                    <div className="row">
                                        <div className="right-container">
                                            <div className="teams switcher-select-configuration">
                                                <Switcher
                                                    active={ valueShareNearDuplicates }
                                                    updateActive={() => {
                                                        this.handleChangeShareNearDuplicates(!valueShareNearDuplicates)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="options-keep-file">
                                            <Select placeholder="Select tag"
                                                    value={ <span className="content-select-keep"><span>{ valueSelectKeep }</span><span className="icon-v16-arrow-medium"></span></span> }
                                                    onChange={(e) => this.onChangeSelectKeep(e)}
                                                    className="select-keep-file"
                                                    dropdownClassName="dropdown-keep-file"
                                                    disabled={ valueShareNearDuplicates === false }
                                                    showArrow={ false }
                                            >
                                                <Option className="options-status-keep" key={ 1 } value="keep newest file">
                                                    Keep newest file
                                                </Option>
                                                <Option className="options-status-keep" key={ 2 } value="keep biggest file">
                                                    Keep biggest file
                                                </Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="content-functions-culling">
                                <div className="title-functions-culling">Email threads</div>
                                <div className="functions-culling-contain">
                                    <div className="row">
                                        <div className="right-container">
                                            <div className="teams switcher-select-configuration">
                                                <Switcher
                                                    active={ valueShareEmail }
                                                    updateActive={() => {
                                                        this.handleChangeShareEmail(!valueShareEmail)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="content-functions-culling">
                                <div className="title-functions-culling">Empty folders</div>
                                <div className="functions-culling-contain">
                                    <div className="row">
                                        <div className="right-container">
                                            <div className="teams switcher-select-configuration">
                                                <Switcher
                                                    active={ valueShareRemoveEmpty }
                                                    updateActive={() => {
                                                        this.handleChangeShareRemoveFolders(!valueShareRemoveEmpty)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="footer-culling">
                            <Button loading={loadingQuickCullCancel} onClick={(e) => this.onCloseModal(e)} className="action-cancel-culling">Cancel</Button>
                            <Button disabled={ disabledSweep === true } loading={isLoading} onClick={(e) => this.onSweepCulling(e)} className={ isLoading ? "action-sweep-culling-loading" : "action-sweep-culling"}>Cull</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

CullingModal.propTypes = {
}

export default CullingModal