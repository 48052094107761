import React from 'react'
import PropTypes from 'prop-types'
import {notification, Select, Spin} from 'antd'
import StandardTab from './StandardTab'
import StandardTabCopy from './StandardTabCopy'
import ManualTab from './ManualTab'
import ManualTabCopy from './ManualTabCopy'
import swal from "sweetalert2";
import {
    apiSmartBoxCheckAllCanvasPoints,
    apiSmartBoxUpdateRedactionNumber,
    getBusinessCategoryColor,
    getRegexCategoryColor,
    getSmartBoxScanAnnotations,
    newGetBusinessTermWords,
    newGetRegexWords,
    apiRedactionColourProfileGetCategories,
    getSmartBoxScanAnnotationsCopy,
    apiSmartBoxSaveQueryStatusMixed
} from "../../../api/SmartBox";
import CustomNotificationManager from "../../Common/CustomNotificationManager";
import {getAnnotationColorsByClientId, getScanAnnotations} from "../../../api/Sar";
import {connect} from "react-redux";
const { Option } = Select
import {
    generateHtmlForSecurityScanResult,
    renderTreeNodes,
    markWordsOnDocumentForCategory,
    timerCheckCanvas,
    markWordsOnDocumentForCategoryCopy, markWordsOnDocumentForCategoryALL, generateHtmlForSecurityScanResultALL,
    generateHtmlForSecurityScanResultALLCopy,
    markWordsOnDocumentForCategoryALLCopy, generateHtmlForSecurityScanResultALLCopyOcr
} from '../../../utils/SmartBox/SmartBoxMethods'
import {categories} from "../../../utils/AITagsNew";
let timerCheckMarkReady
let timerCheckMarkWordsDocument
class AiAnnotationsSidebarCopy extends React.Component {
    state = {
        activeKey: 'standardTab',
        checkedKeysObjectRefs: [],
        checkedKeysObjectRefsList: [],
        checkedKeysObjectRefsBoxTerms: [],
        checkedKeysObjectRefsRegEx: [],
        treeStandard: [],
        updateRedactAllStatus: false,
        checkedKeysAllStandard: [],
        finalChildrenStatus: [],
        checkAiStandard: true,
        statusFilterReview: 'review-all',
        treeDataStandardOption: [],
        checkedKeysRedacted: [],
        treeBusiness: [],
        checkedKeysAllBusiness: [],
        treeRegEx: [],
        checkedKeysAllRegEx: [],
        docAnnotationsRegEx: {},
        docAnnotationsList: {},
        docAnnotationsOcr: {},
        finalChildrenStandard: [],
        visibleOptionsReviewAll: false,
        docAnnotationsStandard: [],
        docAnnotationsStandardOcr: [],
        docAnnotationsStandardDetailedOcr: [],
        docAnnotationsColorsStandard: [],
        docAnnotationCategoriesStandard: [],
        categoriesColorsStandard: {
            names: '',
            locations: '',
            numbers: '',
            time: '',
            addresses: '',
            identifiers: '',
            organisations: '',
        },
        treeDataAnnotationsStandard: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsStandardOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsStandardMixed: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsBusinessMixed: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsRegExMixed: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        docAnnotationsBusiness: {},
        docAnnotationsBusinessOcr: {},
        docAnnotationsBusinessDetailedOcr: {},
        categoryColoursBusiness: {},
        categoryColoursBusinessOcr: {},
        treeDataAnnotationsBusiness: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsBusinessOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        docAnnotationsRegularExp: {},
        docAnnotationsRegularExpOcr: {},
        docAnnotationsRegularExpDetailedOcr: {},
        categoryColoursRegularExp: {},
        categoryColoursRegularExpOcr: {},
        treeDataAnnotationsRegEx: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsRegExOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAll: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAllOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        expandedKeysAll: [],
        getMixedChildrenRegEx: [],
        getAllChildrenRegExParent: [],
        regExListVersionUpdate: {},
        getAllChildrenStandardUpdate: [],
        fetchResponseLoadingStandard: false,
        setIntervalCheckPdfCanvas: 0,
        responseCheckPdfCanvas: '',
        listAllDocAnnotationOcr: ['Dictionary', 'RegEx', 'doc_annotate_address_email', 'doc_annotate_address_url', 'doc_annotate_address_phone', 'doc_annotate_address_postcode',
            'doc_annotate_address_complete', 'doc_annotate_address_ip', 'doc_annotate_person_female', 'doc_annotate_person_male', 'doc_annotate_person', 'doc_annotate_identifier',
            'doc_annotate_password', 'doc_annotate_location_country', 'doc_annotate_location_city', 'doc_annotate_location_region', 'doc_annotate_location_airport',
            'doc_annotate_location_country_abbrev', 'doc_annotate_location_county', 'doc_annotate_location_province', 'doc_annotate_location_other', 'doc_annotate_location_pre',
            'doc_annotate_money', 'doc_annotate_percent', 'doc_annotate_ssn', 'doc_annotate_creditcard', 'doc_annotate_organization_company', 'doc_annotate_organization_department',
            'doc_annotate_organization_government', 'doc_annotate_organization_newspaper', 'doc_annotate_organization_university', 'doc_annotate_organization_team', 'doc_annotate_date'],
        statusHideAllHighlighting: true,
        statusIconHideAllHighlighting: true,
        listCategories: [],
        getMixedChildrenBusiness: [],
        getAllChildrenBusinessParent: [],
        pagesCountChildrenMixed: [],
        checkedKeysAllMixedStandard: [],
        checkedKeysAllMixedBusiness: [],
        checkedKeysAllMixedRegEx: []
    }
    componentWillUnmount() {
        global.finalItems = {}
        global.items = {}
        global.markedTimes = 0
        global.itemsToBeMarked = 0
        global.arEl = []
        global.arOptions = []

        clearInterval(timerCheckMarkReady)
        clearInterval(timerCheckMarkWordsDocument)
        clearInterval(timerCheckCanvas)
    }
    componentDidMount() {
        this.setState({
            checkedKeysObjectRefs: [],
            checkedKeysObjectRefsList: [],
            checkedKeysObjectRefsBoxTerms: [],
            checkedKeysObjectRefsRegEx: [],
        })
        this.onRedactionColourProfileGetCategories()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.onSelectResetProfilesStatus !== this.props.onSelectResetProfilesStatus && this.props.onSelectResetProfilesStatus === 'start') {
            this.onRedactionColourProfileGetCategories()
        }
        if (prevProps.isDocumentReady !== this.props.isDocumentReady && Object.keys(this.props.isNextDocumentId).length === 0) {
                 this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered, this.props.responseAnnotationsCopy)
        }
        if (prevProps.isDocumentReady !== this.props.isDocumentReady && Object.keys(prevProps.isNextDocumentId).length > 0) {
                this.fetchDataAllCategories(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered, this.props.responseAnnotationsCopy)

        }
    }

    onRedactionColourProfileGetCategories = () => {
        const { boxRedactionColorProfileId } = this.props
        const getRedactionColorProfileCategoriesRequestDTO = {
            defaultColorProfile: null,
            redactionColorProfileId: boxRedactionColorProfileId,
        }
        apiRedactionColourProfileGetCategories(getRedactionColorProfileCategoriesRequestDTO).then((response) => {
            this.props.onSelectResetProfilesStatusUpdate()
            this.setState({
                listCategories: response.data.categoryResponseDTOList,
            })
        })

    }
    fetchDataAllCategories = (docId, clientId, userId, docId2, allPagesRendered, responseAnnotationsCopy1) => {
        let responseAnnotationsCopy
        let responseStandard1
        let responseStandard1Ocr
        let responseStandard1DetailedOcr
        let responseStandard2
        let responseBusiness1
        let responseBusiness1Ocr
        let responseBusiness1DetailedOcr
        let responseBusiness2
        let responseBusiness2Ocr
        let responseRegEx1
        let responseRegEx1Ocr
        let responseRegEx1DetailedOcr
        let responseRegEx2
        let responseRegEx2Ocr
        if (this.props.isSmartBoxApplication) {
            //responseAnnotationsCopy = await getSmartBoxScanAnnotationsCopy(docId)
            responseAnnotationsCopy = responseAnnotationsCopy1
            //console.log('responseAnnotationsCopy AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', responseAnnotationsCopy.data)
            //responseStandard1 = await getSmartBoxScanAnnotations(docId)
            responseStandard1 = responseAnnotationsCopy.data.documentAnnotationsNonOCR
            responseStandard1Ocr = responseAnnotationsCopy.data.documentAnnotationsOCR
            responseStandard1DetailedOcr = responseAnnotationsCopy.data.documentAnnotationsDetailedOCR ? responseAnnotationsCopy.data.documentAnnotationsDetailedOCR : {}
            // responseStandard1DetailedOcr = {
            //     doc_annotate_location_city: [{ word: "CANBERRA", children: [{word: "CANBERRA", page: 8, pagePosition: 1, key: "CANBERRA-8-1"}]}],
            //     doc_annotate_person: [{ word: "Mark Callahan", children: [{ word: "Mark Callahan", page: 2, pagePosition: 1, key: "Mark Callahan-2-1"},
            //             { word: "Mark Callahan", page: 2, pagePosition: 2, key: "Mark Callahan-2-2"}]
            //         }]
            // }
            //responseBusiness1 = await newGetBusinessTermWords(docId2)

            responseBusiness1 = responseAnnotationsCopy.data.businessTerms
            responseBusiness1Ocr = responseAnnotationsCopy.data.businessTermsOCR
            responseBusiness1DetailedOcr = responseAnnotationsCopy.data.businessTermsDetailedOCR
            // responseBusiness1DetailedOcr = {
            //     Dict3: [{ word: "the", children: [{word: "the", page: 8, pagePosition: 1, key: "the-8-1"},
            //             {word: "the", page: 8, pagePosition: 2, key: "the-8-2"}]}],
            // }

            //responseRegEx1 = await newGetRegexWords(docId2)
            responseRegEx1 = responseAnnotationsCopy.data.regexTerms
            responseRegEx1Ocr = responseAnnotationsCopy.data.regexTermsOCR
            responseRegEx1DetailedOcr = responseAnnotationsCopy.data.regexTermsDetailedOCR
            // responseRegEx1DetailedOcr = {
            //     doc_annotate_passport: [{ word: "passport", children: [{word: "passport", page: 8, pagePosition: 1, key: "passport-8-1"}]}],
            // }

            //responseStandard2 = await getAnnotationColorsByClientId(clientId, userId)
            responseStandard2 = responseAnnotationsCopy.data.annotateColorDTOS

            //responseBusiness2 = await getBusinessCategoryColor(docId2)
            responseBusiness2 = responseAnnotationsCopy.data.businessTermsColor
            //responseBusiness2 = { dict3: "rgba(228, 200, 1, 0.5)" }
            responseBusiness2Ocr = responseAnnotationsCopy.data.businessTermsColorOCR
            //responseBusiness2Ocr = { dict3: "rgba(228, 200, 1, 0.5)" }

            //responseRegEx2 = await getRegexCategoryColor(docId2)
            responseRegEx2 = responseAnnotationsCopy.data.regexTermsColor
            responseRegEx2Ocr = responseAnnotationsCopy.data.regexTermsColorOCR
            //responseRegEx2Ocr = { passport: "rgba(228, 200, 1, 0.5)" }
            this.props.onDisabledNextDocument(false)
            this.props.onDisabledBackDocument(false)
            // this.onDocAnnotationsOcr(responseStandard1Ocr)
            if (responseStandard1 && Object.keys(responseStandard1).length === 0 && this.props.docAnnotationsRegEx && this.props.docAnnotationsList && Object.keys(this.props.docAnnotationsRegEx).length === 0 && Object.keys(this.props.docAnnotationsList).length === 0) {
                //CustomNotificationManager.error('No AI annotations available for this document, yet.', 'No annotations available')
                notification.info({
                    message: `No Ai results found`,
                    className: 'no-ai-results-found',
                    duration: 3
                })
            }
            let statusResponseStandard = null
            if (responseAnnotationsCopy.status === 200) {
                statusResponseStandard = true
            } else {
                statusResponseStandard = false
            }

            this.setState({
                docAnnotationsStandard: responseStandard1,
                //docAnnotationsOcr: responseStandard1Ocr,
                docAnnotationsStandardOcr: responseStandard1Ocr,
                docAnnotationsStandardDetailedOcr: responseStandard1DetailedOcr,

                docAnnotationsColorsStandard: responseStandard2,
                categoriesColorsStandard: {
                    names: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'names')[0].color : [],
                    locations: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'locations')[0].color : [],
                    numbers: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'numbers')[0].color : [],
                    time: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'time')[0].color : [],
                    addresses: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'addresses')[0].color : [],
                    identifiers: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'identifiers')[0].color : [],
                    organisations: responseStandard2 && responseStandard2.length > 0 ? responseStandard2.filter((annColor) => annColor.category === 'organisations')[0].color : []
                },
                docAnnotationsBusiness: responseBusiness1,
                docAnnotationsBusinessOcr: responseBusiness1Ocr,
                docAnnotationsBusinessDetailedOcr: responseBusiness1DetailedOcr,

                categoryColoursBusiness: responseBusiness2,
                categoryColoursBusinessOcr: responseBusiness2Ocr,

                docAnnotationsRegularExp: responseRegEx1,
                docAnnotationsRegularExpOcr: responseRegEx1Ocr,
                docAnnotationsRegularExpDetailedOcr: responseRegEx1DetailedOcr,

                categoryColoursRegularExp: responseRegEx2,
                categoryColoursRegularExpOcr: responseRegEx2Ocr,

                fetchResponseLoadingStandard: statusResponseStandard
            }, () => {
                if (responseAnnotationsCopy.status === 200) {
                    this.renderAllCategoriesSections()
                }
            })
        }
    }
    renderAllCategoriesSections = () => {
        const { docAnnotationsStandard, docAnnotationsOcr, treeDataAnnotationsStandard, treeDataAll, treeDataAnnotationsBusiness, treeDataAnnotationsRegEx,
            docAnnotationsStandardOcr, treeDataAnnotationsStandardMixed, docAnnotationsBusinessOcr, categoryColoursBusinessOcr,
            treeDataAnnotationsBusinessOcr, treeDataAnnotationsBusinessMixed, treeDataAnnotationsRegExMixed,
            treeDataAnnotationsRegExOcr, categoryColoursRegularExpOcr, docAnnotationsStandardDetailedOcr,
            docAnnotationsBusinessDetailedOcr, docAnnotationsRegularExpDetailedOcr } = this.state
        let { categoriesColorsStandard, docAnnotationsColorsStandard, docAnnotationCategoriesStandard,
            docAnnotationsBusiness, categoryColoursBusiness, newBusinessUpdate,
            docAnnotationsRegularExp, categoryColoursRegularExp, expandedKeysAll, docAnnotationsRegularExpOcr,
            treeDataAnnotationsStandardOcr, treeDataAllOcr } = this.state
        const { landscape } = this.props
        //Standard
        const categoriesColorsStandardArray = []
        const categoriesColorsStandardArrayOcr = []
        const docAnnotationsStandardArray = []
        const docAnnotationsStandardArrayOcr = []
        const docAnnotationsStandardArrayDetailedOcr = []
        const categoriesArray = []
        const categoriesArrayOcr = []
        const childrenStandardOcr = []

        //Business
        const categoryColoursBusinessArray = []
        const categoryColoursBusinessArrayOcr = []
        const docAnnotationsBusinessArray = []
        const docAnnotationsBusinessArrayOcr = []
        const docAnnotationsBusinessArrayDetailedOcr = []
        const categoriesBusinessArray = []
        const categoriesBusinessArrayOcr = []

        const childrenBusiness = []
        const childrenBusinessOcr = []
        const childrenBusinessParent = []

        //RegularExp
        const categoryColoursRegularExpArray = []
        const categoryColoursRegularExpArrayOcr = []
        const docAnnotationsRegularExpArray = []
        const docAnnotationsRegularExpArrayOcr = []
        const docAnnotationsRegularExpArrayDetailedOcr = []
        const categoriesRegularExpArray = []
        const categoriesRegularExpArrayOcr = []

        const childrenRegEx = []
        const childrenRegExOcr = []
        const childrenRegExParent = []

        //All categories
        let categoryColoursArrayAll = []
        let categoryColoursArrayAllOcr = []
        let docAnnotationsArrayAll = []
        let docAnnotationsArrayAllOcr = []
        let categoriesArrayAll = []
        let categoriesArrayAllOcr = []

        //actions array Standard
        for (const categoryColor of Object.keys(categoriesColorsStandard)) {
            categoriesColorsStandardArray.push({
                name: categoryColor,
                colour: categoriesColorsStandard[categoryColor],
                categoryName: 'standard'
            })
        }
        for (const categoryColor of Object.keys(categoriesColorsStandard)) {
            categoriesColorsStandardArrayOcr.push({
                name: categoryColor,
                colour: categoriesColorsStandard[categoryColor],
                categoryName: 'standard'
            })
        }
        for (const category of Object.keys(docAnnotationsStandard)) {
            docAnnotationsStandardArray.push({
                name: category,
                list: docAnnotationsStandard[category]
            })
        }
        //console.log('docAnnotationsStandardOcr AAAAAAAAAAAAAAAAAAAAAAAAA', docAnnotationsStandardOcr)
        // for (const category of Object.keys(docAnnotationsStandardOcr)) {
        //     console.log('category AAAAAAAAAAAAAAAAAAAAAAAAA', category)
        //     console.log('docAnnotationsStandardOcr[category] AAAAAAAAAAAAAAAAAAAAAAAAA', docAnnotationsStandardOcr[category])
        //     docAnnotationsStandardArrayOcr.push({
        //         name: category,
        //         list: docAnnotationsStandardOcr[category]
        //     })
        // }
        //console.log('docAnnotationsStandardArrayOcr AAAAAAAAAAAAAAAAAAAAAAAAA', docAnnotationsStandardArrayOcr)

        //console.log('docAnnotationsStandardDetailedOcr BBBBBBBBBBBBBBBBBBBBBBBBBB', docAnnotationsStandardDetailedOcr)
        for (const category of Object.keys(docAnnotationsStandardDetailedOcr)) {
            //console.log('category BBBBBBBBBBBBBBBBBBBBBBBBBB', category)
            //console.log('docAnnotationsStandardOcr[category] BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB', docAnnotationsStandardDetailedOcr[category])
            const listDetails = []
            docAnnotationsStandardDetailedOcr[category] && docAnnotationsStandardDetailedOcr[category].length > 0 && docAnnotationsStandardDetailedOcr[category].forEach((cat) => {
                //console.log('cat BBBBBBBBBBBBBBBBBBBBBBBBBB 222222222222222222222222222', cat)
                listDetails.push(cat.word)
                docAnnotationsStandardArrayDetailedOcr.push({
                    nameCategory: category,
                    nameCategoryParent: cat.word,
                    children: cat.children,
                })

            })
            docAnnotationsStandardArrayOcr.push({
                name: category,
                list: listDetails
            })
        }
        //console.log('docAnnotationsStandardArrayOcr CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC', docAnnotationsStandardArrayOcr)
        //console.log('docAnnotationsStandardArrayDetailedOcr CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC', docAnnotationsStandardArrayDetailedOcr)
        for (const category of Object.keys(categories)) {
            categoriesArray.push({
                name: category,
                list: categories[category],
                categoryName: 'standard'
            })
            categoriesArrayOcr.push({
                name: category,
                list: categories[category],
                categoryName: 'standard'
            })
        }

        //actions array Business
        for (const categoryColor of Object.keys(categoryColoursBusiness)) {
            categoryColoursBusinessArray.push({
                name: categoryColor,
                colour: categoryColoursBusiness[categoryColor],
                categoryName: 'business'
            })
        }
        for (const categoryColorOcr of Object.keys(categoryColoursBusinessOcr)) {
            categoryColoursBusinessArrayOcr.push({
                name: categoryColorOcr,
                colour: categoryColoursBusiness[categoryColorOcr],
                categoryName: 'business'
            })
        }

        for (const category of Object.keys(docAnnotationsBusiness)) {
            docAnnotationsBusinessArray.push({
                name: `doc_annotate_${category}`,
                list: docAnnotationsBusiness[category]
            })
        }
        // console.log('docAnnotationsBusinessOcr DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD', docAnnotationsBusinessOcr)
        // console.log('docAnnotationsBusinessDetailedOcr DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD', docAnnotationsBusinessDetailedOcr)
        // for (const categoryOcr of Object.keys(docAnnotationsBusinessOcr)) {
        //     docAnnotationsBusinessArrayOcr.push({
        //         name: `doc_annotate_${categoryOcr}`,
        //         list: docAnnotationsBusinessOcr[categoryOcr]
        //     })
        // }

        for (const categoryOcr of Object.keys(docAnnotationsBusinessDetailedOcr)) {
            const listDetailsBusiness = []
            //console.log('categoryOcr DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD AAAAAAAAAAAAAAA', categoryOcr)
            docAnnotationsBusinessDetailedOcr[categoryOcr] && docAnnotationsBusinessDetailedOcr[categoryOcr].length > 0 && docAnnotationsBusinessDetailedOcr[categoryOcr].forEach((catDict) => {
                listDetailsBusiness.push(catDict.word)
                docAnnotationsBusinessArrayDetailedOcr.push({
                    nameCategory: `doc_annotate_${categoryOcr}`,
                    nameCategoryParent: catDict.word,
                    children: catDict.children,
                })
            })
            docAnnotationsBusinessArrayOcr.push({
                name: `doc_annotate_${categoryOcr}`,
                list: listDetailsBusiness
            })
        }
        // console.log('docAnnotationsBusinessArrayOcr DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD 22222222222222222222', docAnnotationsBusinessArrayOcr)
        // console.log('docAnnotationsBusinessArrayDetailedOcr DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD 22222222222222222222', docAnnotationsBusinessArrayDetailedOcr)


        for (const categoryColor of Object.keys(categoryColoursBusiness)) {
            categoriesBusinessArray.push({
                name: categoryColor,
                list: [{
                    tagName: categoryColor.charAt(0).toUpperCase() + categoryColor.slice(1),
                    tag: `doc_annotate_${categoryColor}`,
                }],
                categoryName: 'business'
            })
        }
        for (const categoryColorOcr of Object.keys(categoryColoursBusinessOcr)) {
            categoriesBusinessArrayOcr.push({
                name: categoryColorOcr,
                list: [{
                    tagName: categoryColorOcr.charAt(0).toUpperCase() + categoryColorOcr.slice(1),
                    tag: `doc_annotate_${categoryColorOcr}`,
                }],
                categoryName: 'business'
            })
        }

        //actions array Regular Expressions
        for (const categoryColor of Object.keys(categoryColoursRegularExp)) {
            categoryColoursRegularExpArray.push({
                name: categoryColor,
                colour: categoryColoursRegularExp[categoryColor],
                categoryName: 'regular'
            })
        }
        for (const categoryColorOcr of Object.keys(categoryColoursRegularExpOcr)) {
            categoryColoursRegularExpArrayOcr.push({
                name: categoryColorOcr,
                colour: categoryColoursRegularExpOcr[categoryColorOcr],
                categoryName: 'regular'
            })
        }

        for (const category of Object.keys(docAnnotationsRegularExp)) {
            docAnnotationsRegularExpArray.push({
                //name: `doc_annotate_${category}`,
                name: category.includes('doc_annotate_') ? category : `doc_annotate_${category}`,
                list: docAnnotationsRegularExp[category]
            })
        }
        // for (const categoryOcr of Object.keys(docAnnotationsRegularExpOcr)) {
        //     docAnnotationsRegularExpArrayOcr.push({
        //         //name: `doc_annotate_${category}`,
        //         name: categoryOcr.includes('doc_annotate_') ? categoryOcr : `doc_annotate_${categoryOcr}`,
        //         list: docAnnotationsRegularExpOcr[categoryOcr]
        //     })
        // }

        for (const categoryOcr of Object.keys(docAnnotationsRegularExpDetailedOcr)) {
            const listDetailsRegEx = []
            //console.log('categoryOcr DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD AAAAAAAAAAAAAAA', categoryOcr)
            docAnnotationsRegularExpDetailedOcr[categoryOcr] && docAnnotationsRegularExpDetailedOcr[categoryOcr].length > 0 && docAnnotationsRegularExpDetailedOcr[categoryOcr].forEach((catDict) => {
                listDetailsRegEx.push(catDict.word)
                docAnnotationsRegularExpArrayDetailedOcr.push({
                    nameCategory: `doc_annotate_${categoryOcr}`,
                    nameCategoryParent: catDict.word,
                    children: catDict.children,
                })
            })
            docAnnotationsRegularExpArrayOcr.push({
                //name: `doc_annotate_${category}`,
                name: categoryOcr.includes('doc_annotate_') ? categoryOcr : `doc_annotate_${categoryOcr}`,
                list: listDetailsRegEx
            })
        }

        for (const categoryColor of Object.keys(categoryColoursRegularExp)) {
            categoriesRegularExpArray.push({
                name: categoryColor,
                list: [{
                    tagName: categoryColor.charAt(0).toUpperCase() + categoryColor.slice(1),
                    tag: categoryColor.includes('doc_annotate_') ? categoryColor : `doc_annotate_${categoryColor}`,
                    //tag: categoryColor,
                }],
                categoryName: 'regular'
            })
        }
        for (const categoryColorOcr of Object.keys(categoryColoursRegularExpOcr)) {
            categoriesRegularExpArrayOcr.push({
                name: categoryColorOcr,
                list: [{
                    tagName: categoryColorOcr.charAt(0).toUpperCase() + categoryColorOcr.slice(1),
                    tag: categoryColorOcr.includes('doc_annotate_') ? categoryColorOcr : `doc_annotate_${categoryColorOcr}`,
                }],
                categoryName: 'regular'
            })
        }


        categoryColoursArrayAll = categoriesColorsStandardArray.concat(categoryColoursBusinessArray, categoryColoursRegularExpArray)
        categoryColoursArrayAllOcr = categoriesColorsStandardArrayOcr.concat(categoryColoursBusinessArrayOcr, categoryColoursRegularExpArrayOcr)
        docAnnotationsArrayAll = docAnnotationsStandardArray.concat(docAnnotationsBusinessArray, docAnnotationsRegularExpArray)
        docAnnotationsArrayAllOcr = docAnnotationsStandardArrayOcr.concat(docAnnotationsBusinessArrayOcr, docAnnotationsRegularExpArrayOcr)
        categoriesArrayAll = categoriesArray.concat(categoriesBusinessArray, categoriesRegularExpArray)
        categoriesArrayAllOcr = categoriesArrayOcr.concat(categoriesBusinessArrayOcr, categoriesRegularExpArrayOcr)
        timerCheckMarkWordsDocument = setInterval(() => {
            markWordsOnDocumentForCategoryALLCopy(categoryColoursArrayAll, docAnnotationsArrayAll, landscape, categoriesArrayAll)
            clearInterval(timerCheckMarkWordsDocument)
        }, 50)
        docAnnotationCategoriesStandard = []
        timerCheckMarkReady = setInterval(() => {
            if (global.itemsToBeMarked === global.markedTimes) {
                clearInterval(timerCheckMarkReady)
                this.props.hideLoadingBeforeMark()
                for (const categoryColor of categoryColoursArrayAll) {
                    const { result, expandedSubCategoryParent, result2 } = generateHtmlForSecurityScanResultALLCopy(categoryColor.name, categoryColor.colour, docAnnotationsArrayAll, landscape, categoriesArrayAll, docAnnotationCategoriesStandard, categoryColor.categoryName,
                        this.props.isOcr)

                    this.props.setQueryStatus(docAnnotationCategoriesStandard)
                    expandedKeysAll = [...expandedKeysAll, ...expandedSubCategoryParent]
                    //console.log('result STANDADRD BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB', result)
                    treeDataAll[0].children.push(result)
                    //console.log('result AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', result)
                    switch (result.categoryName) {
                        case 'standard':
                           // console.log('result STANDADRD AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', result)
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                                    item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item3, index3) => {
                                        const keyName = item3.name.replace(/\W/g, 'a')
                                        item3.indexChild = index3
                                        item3.key3 = `${keyName}-${index3}`
                                    })
                                })

                            })
                            treeDataAnnotationsStandard[0].children.push(result)
                            this.setState({
                                docAnnotationCategoriesStandard: []
                            })

                            this.onGetStandardUpdate(treeDataAnnotationsStandard)
                            break
                        case 'business':
                            result && result.children && result.children.length > 0 && result.children.forEach((item) => {
                                if (item) {
                                    result.children = item.children
                                }
                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                if (item2) {
                                    item2.identifier = 'children-parent'
                                }

                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                if (item2) {
                                    item2.backgroundColor = result.backgroundColor
                                    childrenBusinessParent.push(item2)
                                    item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3, index3) => {
                                        const keyName = item3.name.replace(/\W/g, 'a')
                                        item3.indexChild = index3
                                        item3.key3 = `${keyName}-${index3}`
                                        item3.backgroundColor = result.backgroundColor
                                        childrenBusiness.push(item3)

                                    })
                                }

                            })
                            if (result) {
                                treeDataAnnotationsBusiness[0].children.push(result)
                            }
                            break
                        case 'regular':
                            result && result.children && result.children.length > 0 && result.children.forEach((item) => {
                                result.children = item.children
                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2.identifier = 'children-parent'

                            })
                            result && result.children && result.children.length > 0 && result.children.forEach((item2) => {
                                item2.backgroundColor = result.backgroundColor
                                childrenRegExParent.push(item2)
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3, index3) => {
                                    const keyName = item3.name.replace(/\W/g, 'a')
                                    item3.indexChild = index3
                                    item3.key3 = `${keyName}-${index3}`
                                    item3.backgroundColor = result.backgroundColor
                                    childrenRegEx.push(item3)

                                })

                            })
                            treeDataAnnotationsRegEx[0].children.push(result)
                            this.allChildrenTreeRegEx(treeDataAnnotationsRegEx)
                            break
                        default: break
                    }
                    //expandedKeysAll = categoryColoursArrayAll
                    //expandedKeysAll = [ ...expandedKeysAll, ...categoryColoursArrayAll ]
                }
                for (const categoryColor of categoryColoursArrayAllOcr) {

                    const { resultOCr, expandedSubCategoryParent } = generateHtmlForSecurityScanResultALLCopyOcr(categoryColor.name, categoryColor.colour, docAnnotationsArrayAllOcr, landscape, categoriesArrayAllOcr, docAnnotationCategoriesStandard, categoryColor.categoryName,
                        this.props.isOcr)
                    treeDataAllOcr[0].children.push(resultOCr)

                    //this.props.setQueryStatus(docAnnotationCategoriesStandard)
                    expandedKeysAll = [...expandedKeysAll, ...expandedSubCategoryParent]
                    //treeDataAll[0].children.push(resultOCr)
                    switch (resultOCr.categoryName) {
                        case 'standard':
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item2) => {
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                                    item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item3, index3) => {
                                        const keyName = item3.name.replace(/\W/g, 'a')
                                        item3.indexChild = index3
                                        item3.key3 = `${keyName}-${index3}`
                                    })
                                })

                            })
                            this.setState({
                                docAnnotationCategoriesStandard: []
                            })
                            treeDataAnnotationsStandardOcr[0].children.push(resultOCr)
                            //this.onGetStandardUpdate(treeDataAnnotationsStandardOcr)
                            break
                        case 'business':
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item) => {
                                resultOCr.children = item.children
                            })
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item2) => {
                                item2.identifier = 'children-parent'

                            })
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item2) => {
                                item2.backgroundColor = resultOCr.backgroundColor
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3, index3) => {
                                    const keyName = item3.name.replace(/\W/g, 'a')
                                    item3.indexChild = index3
                                    item3.key3 = `${keyName}-${index3}`
                                    item3.backgroundColor = resultOCr.backgroundColor

                                })

                            })
                            treeDataAnnotationsBusinessOcr[0].children.push(resultOCr)
                            break
                        case 'regular':
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item) => {
                                resultOCr.children = item.children
                            })
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item2) => {
                                item2.identifier = 'children-parent'

                            })
                            resultOCr && resultOCr.children && resultOCr.children.length > 0 && resultOCr.children.forEach((item2) => {
                                item2.backgroundColor = resultOCr.backgroundColor
                                item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3, index3) => {
                                    const keyName = item3.name.replace(/\W/g, 'a')
                                    item3.indexChild = index3
                                    item3.key3 = `${keyName}-${index3}`
                                    item3.backgroundColor = resultOCr.backgroundColor

                                })

                            })
                            treeDataAnnotationsRegExOcr[0].children.push(resultOCr)
                            //this.allChildrenTreeRegEx(treeDataAnnotationsRegExOcr)
                            break
                        default: break
                    }

                }
                // console.log('treeDataAnnotationsStandardOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', treeDataAnnotationsStandardOcr)
                // console.log('docAnnotationsStandardOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', docAnnotationsStandardOcr)
                // console.log('docAnnotationsStandardArrayDetailedOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', docAnnotationsStandardArrayDetailedOcr)
                treeDataAnnotationsStandardOcr && treeDataAnnotationsStandardOcr[0].children && treeDataAnnotationsStandardOcr[0].children.length > 0 && treeDataAnnotationsStandardOcr[0].children.forEach((children1) => {
                    children1.children.length > 0 && children1.children.forEach((children2) => {
                        if (docAnnotationsStandardArrayDetailedOcr && docAnnotationsStandardArrayDetailedOcr.length) {
                            docAnnotationsStandardArrayDetailedOcr.forEach((category, index) => {
                                let title = category.nameCategory && category.nameCategory.replace('doc_annotate_', '')
                                title = title && title.replace('_', ' ')
                                if (children2.key === title) {
                                        const parentAllCategory = {
                                            backgroundColor: children2.backgroundColor,
                                            children: category.children,
                                            key: `${ category.nameCategoryParent }`,
                                            name: category.nameCategoryParent,
                                            index: index,
                                            title: category.nameCategoryParent,
                                            pageNumber: '0',
                                            isOcr: true,
                                            categoryOcr: 'parent'
                                            //isSearchedWord: true,
                                        }
                                    parentAllCategory.children && parentAllCategory.children.length > 0 && parentAllCategory.children.forEach((childParent, index2) => {
                                        childParent.title = childParent.word
                                        childParent.name = childParent.word
                                        childParent.pageNumber = childParent.page
                                        childParent.children = []
                                        childParent.isOcr = true
                                        childParent.isSearchedWord = true
                                        childParent.index = index2
                                        childParent.backgroundColor = children2.backgroundColor
                                        childParent.categoryOcr = 'children'
                                        childrenStandardOcr.push(childParent)
                                    })
                                        children2.children.push(parentAllCategory)
                                }
                            })
                        }
                    })
                })

                // treeDataAnnotationsStandardOcr && treeDataAnnotationsStandardOcr[0].children && treeDataAnnotationsStandardOcr[0].children.length > 0 && treeDataAnnotationsStandardOcr[0].children.forEach((children1) => {
                //     children1.children.length > 0 && children1.children.forEach((children2) => {
                //         if (Object.keys(docAnnotationsStandardOcr) && Object.keys(docAnnotationsStandardOcr).length) {
                //             Object.keys(docAnnotationsStandardOcr).forEach((category) => {
                //                 let title = category && category.replace('doc_annotate_', '')
                //                 title = title && title.replace('_', ' ')
                //                 if (children2.key === title) {
                //                     docAnnotationsStandardOcr[category] && docAnnotationsStandardOcr[category].length > 0 && docAnnotationsStandardOcr[category].forEach((nameParent, index) => {
                //                         const parentAllCategory = {
                //                             backgroundColor: children2.backgroundColor,
                //                             children: [],
                //                             key: `${ nameParent }`,
                //                             name: nameParent,
                //                             index: index,
                //                             title: nameParent,
                //                             pageNumber: '0',
                //                             isOcr: true,
                //                             isSearchedWord: true,
                //                         }
                //                         children2.children.push(parentAllCategory)
                //                         childrenStandardOcr.push(parentAllCategory)
                //                     })
                //                 }
                //             })
                //         }
                //     })
                // })
                // console.log('treeDataAnnotationsBusinessOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', treeDataAnnotationsBusinessOcr)
                // console.log('docAnnotationsBusinessArrayOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', docAnnotationsBusinessArrayOcr)
                // console.log('docAnnotationsBusinessArrayDetailedOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', docAnnotationsBusinessArrayDetailedOcr)
                treeDataAnnotationsBusinessOcr && treeDataAnnotationsBusinessOcr[0].children && treeDataAnnotationsBusinessOcr[0].children.length > 0 && treeDataAnnotationsBusinessOcr[0].children.forEach((children1) => {
                    if (docAnnotationsBusinessArrayDetailedOcr && docAnnotationsBusinessArrayDetailedOcr.length) {
                        docAnnotationsBusinessArrayDetailedOcr.forEach((category, index) => {
                            let title = category.nameCategory && category.nameCategory.replace('doc_annotate_', '')
                            title = title && title.replace('_', ' ')
                            if (children1.key === title) {
                                    const parentAllCategory = {
                                        backgroundColor: children1.backgroundColor,
                                        children: category.children,
                                        key: `${ category.nameCategoryParent }`,
                                        name: category.nameCategoryParent,
                                        index: index,
                                        title: category.nameCategoryParent,
                                        categoryOcr: 'parent',
                                        isOcr: true,
                                    }
                                parentAllCategory.children && parentAllCategory.children.length > 0 && parentAllCategory.children.forEach((childParent, index2) => {
                                    childParent.title = childParent.word
                                    childParent.name = childParent.word
                                    childParent.pageNumber = childParent.page
                                    childParent.children = []
                                    childParent.isOcr = true
                                    childParent.isSearchedWord = true
                                    childParent.index = index2
                                    childParent.backgroundColor = children1.backgroundColor
                                    childParent.categoryOcr = 'children'
                                    childrenBusinessOcr.push(childParent)
                                })
                                    children1.children.push(parentAllCategory)

                            }
                        })
                    }

                })
               // console.log('treeDataAnnotationsBusinessOcr GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', treeDataAnnotationsBusinessOcr)
                // treeDataAnnotationsBusinessOcr && treeDataAnnotationsBusinessOcr[0].children && treeDataAnnotationsBusinessOcr[0].children.length > 0 && treeDataAnnotationsBusinessOcr[0].children.forEach((children1) => {
                //     if (docAnnotationsBusinessOcr && Object.keys(docAnnotationsBusinessOcr).length) {
                //         Object.keys(docAnnotationsBusinessOcr).forEach((category) => {
                //             let title = category && category.replace('doc_annotate_', '')
                //             title = title && title.replace('_', ' ')
                //             if (children1.key === title) {
                //                 docAnnotationsBusinessOcr[category] && docAnnotationsBusinessOcr[category].length > 0 && docAnnotationsBusinessOcr[category].forEach((nameParent, index) => {
                //                     const parentAllCategory = {
                //                         backgroundColor: children1.backgroundColor,
                //                         children: [],
                //                         key: `${ nameParent }`,
                //                         name: nameParent,
                //                         index: index,
                //                         title: nameParent,
                //                         pageNumber: '1',
                //                         isOcr: true,
                //                         isSearchedWord: true,
                //                     }
                //                     children1.children.push(parentAllCategory)
                //                     childrenBusinessOcr.push(parentAllCategory)
                //                 })
                //             }
                //         })
                //     }
                //
                // })
                treeDataAnnotationsRegExOcr && treeDataAnnotationsRegExOcr[0].children && treeDataAnnotationsRegExOcr[0].children.length > 0 && treeDataAnnotationsRegExOcr[0].children.forEach((children1) => {
                    if (docAnnotationsRegularExpArrayDetailedOcr && docAnnotationsRegularExpArrayDetailedOcr.length) {
                        docAnnotationsRegularExpArrayDetailedOcr.forEach((category, index) => {
                            let title = category.nameCategory && category.nameCategory.replace('doc_annotate_', '')
                            title = title && title.replace('_', ' ')
                            if (children1.key === title) {
                                    const parentAllCategory = {
                                        backgroundColor: children1.backgroundColor,
                                        children: category.children,
                                        key: `${ category.nameCategoryParent }`,
                                        //identifier: 'children',
                                        name: category.nameCategoryParent,
                                        index: index,
                                        title: category.nameCategoryParent,
                                        pageNumber: '1',
                                        categoryOcr: 'parent',
                                        isOcr: true,
                                    }
                                parentAllCategory.children && parentAllCategory.children.length > 0 && parentAllCategory.children.forEach((childParent, index2) => {
                                    childParent.title = childParent.word
                                    childParent.name = childParent.word
                                    childParent.pageNumber = childParent.page
                                    childParent.children = []
                                    childParent.isOcr = true
                                    childParent.isSearchedWord = true
                                    childParent.index = index2
                                    childParent.backgroundColor = children1.backgroundColor
                                    childParent.categoryOcr = 'children'
                                    childParent.identifier = 'children'
                                    childrenRegExOcr.push(childParent)
                                })
                                    children1.children.push(parentAllCategory)
                            }
                        })
                    }

                })
                // treeDataAnnotationsRegExOcr && treeDataAnnotationsRegExOcr[0].children && treeDataAnnotationsRegExOcr[0].children.length > 0 && treeDataAnnotationsRegExOcr[0].children.forEach((children1) => {
                //     if (docAnnotationsRegularExpOcr && Object.keys(docAnnotationsRegularExpOcr).length) {
                //         Object.keys(docAnnotationsRegularExpOcr).forEach((category) => {
                //             let title = category && category.replace('doc_annotate_', '')
                //             title = title && title.replace('_', ' ')
                //             if (children1.key === title) {
                //                 docAnnotationsRegularExpOcr[category] && docAnnotationsRegularExpOcr[category].length > 0 && docAnnotationsRegularExpOcr[category].forEach((nameParent, index) => {
                //                     const parentAllCategory = {
                //                         backgroundColor: children1.backgroundColor,
                //                         children: [],
                //                         key: `${ nameParent }`,
                //                         identifier: 'children',
                //                         name: nameParent,
                //                         index: index,
                //                         title: nameParent,
                //                         pageNumber: '1',
                //                         isOcr: true,
                //                         isSearchedWord: true,
                //                     }
                //                     children1.children.push(parentAllCategory)
                //                     childrenRegExOcr.push(parentAllCategory)
                //                 })
                //             }
                //         })
                //     }
                //
                // })
                const wordCounterStandard = {}
                const wordCounterBusiness = {}
                const wordCounterRegEx = {}
                const pagesStandard = []
                const pagesStandardOcr = []
                const pagesBusiness = []
                const pagesBusinessOcr = []
                const pagesRegEx = []
                const pagesRegExOcr = []
                treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length > 0 && treeDataAnnotationsStandard[0].children.forEach((elem) => {
                    elem.children && elem.children.length > 0 && elem.children.forEach((elem2) => {
                        elem2.children && elem2.children.length > 0 && elem2.children.forEach((elem3) => {
                            elem3.children && elem3.children.length > 0 && elem3.children.forEach((elem4) => {
                                elem4.subcategoryName = elem2.key
                                const { name, pageNumber, subcategoryName } = elem4
                                if (!wordCounterStandard[`${pageNumber}${subcategoryName}`]) {
                                    wordCounterStandard[`${pageNumber}${subcategoryName}`] = {}
                                }
                                if (!wordCounterStandard[`${pageNumber}${subcategoryName}`][name]) {
                                    wordCounterStandard[`${pageNumber}${subcategoryName}`][name] = 1
                                } else {
                                    wordCounterStandard[`${pageNumber}${subcategoryName}`][name] += 1
                                }
                                elem4.pageCount = wordCounterStandard[`${pageNumber}${subcategoryName}`][name]
                                elem4.keyMixtRedaction = `${elem4.name}-${elem4.pageNumber}-${elem4.pageCount}`
                                pagesStandard.push(elem4)
                            })
                        })
                    })
                })
                treeDataAnnotationsBusiness[0].children && treeDataAnnotationsBusiness[0].children.length > 0 && treeDataAnnotationsBusiness[0].children.forEach((elem) => {
                    elem.children && elem.children.length > 0 && elem.children.forEach((elem2) => {
                        elem2.children && elem2.children.length > 0 && elem2.children.forEach((elem3) => {
                            elem3.subcategoryName = elem.key
                                const { name, pageNumber, subcategoryName } = elem3
                                if (!wordCounterBusiness[`${pageNumber}${subcategoryName}`]) {
                                    wordCounterBusiness[`${pageNumber}${subcategoryName}`] = {}
                                }
                                if (!wordCounterBusiness[`${pageNumber}${subcategoryName}`][name]) {
                                    wordCounterBusiness[`${pageNumber}${subcategoryName}`][name] = 1
                                } else {
                                    wordCounterBusiness[`${pageNumber}${subcategoryName}`][name] += 1
                                }
                                elem3.pageCount = wordCounterBusiness[`${pageNumber}${subcategoryName}`][name]
                            elem3.keyMixtRedaction = `${elem3.name}-${elem3.pageNumber}-${elem3.pageCount}`
                            pagesBusiness.push(elem3)
                        })
                    })
                })
                treeDataAnnotationsRegEx[0].children && treeDataAnnotationsRegEx[0].children.length > 0 && treeDataAnnotationsRegEx[0].children.forEach((elem) => {
                    elem.children && elem.children.length > 0 && elem.children.forEach((elem2) => {
                        elem2.children && elem2.children.length > 0 && elem2.children.forEach((elem3) => {
                            elem3.subcategoryName = elem.key
                            const { name, pageNumber, subcategoryName } = elem3
                            if (!wordCounterRegEx[`${pageNumber}${subcategoryName}`]) {
                                wordCounterRegEx[`${pageNumber}${subcategoryName}`] = {}
                            }
                            if (!wordCounterRegEx[`${pageNumber}${subcategoryName}`][name]) {
                                wordCounterRegEx[`${pageNumber}${subcategoryName}`][name] = 1
                            } else {
                                wordCounterRegEx[`${pageNumber}${subcategoryName}`][name] += 1
                            }
                            elem3.pageCount = wordCounterRegEx[`${pageNumber}${subcategoryName}`][name]
                            elem3.keyMixtRedaction = `${elem3.name}-${elem3.pageNumber}-${elem3.pageCount}`
                            pagesRegEx.push(elem3)
                        })
                    })
                })
                // console.log('pagesStandard SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', pagesStandard)
                // console.log('pagesBusiness SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', pagesBusiness)
                // console.log('pagesRegEx SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', pagesRegEx)
                let pagesCountChildrenMixed = []
                pagesCountChildrenMixed = pagesStandard.concat(pagesBusiness, pagesRegEx)
                treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length && treeDataAnnotationsStandard[0].children.forEach((elemNonOcr) => {
                    treeDataAnnotationsStandardMixed[0].children.push(elemNonOcr)
                })
                treeDataAnnotationsBusiness[0].children && treeDataAnnotationsBusiness[0].children.length && treeDataAnnotationsBusiness[0].children.forEach((elemNonOcr) => {
                    treeDataAnnotationsBusinessMixed[0].children.push(elemNonOcr)
                })
                treeDataAnnotationsRegEx[0].children && treeDataAnnotationsRegEx[0].children.length && treeDataAnnotationsRegEx[0].children.forEach((elemNonOcr) => {
                    treeDataAnnotationsRegExMixed[0].children.push(elemNonOcr)
                })


                treeDataAnnotationsStandardOcr[0].children && treeDataAnnotationsStandardOcr[0].children.length && treeDataAnnotationsStandardOcr[0].children.forEach((elemOcr) => {
                    elemOcr.children && elemOcr.children.length > 0 && elemOcr.children.forEach((elemOcr2) => {
                        let existElementInStandard = false
                        let elemAll22 = elemOcr2

                        treeDataAnnotationsStandardMixed[0].children && treeDataAnnotationsStandardMixed[0].children.length && treeDataAnnotationsStandardMixed[0].children.forEach((elemAll) => {
                           if (elemAll.children && elemAll.children.length > 0) {
                               elemAll.children.forEach((elemAll2) => {
                                   if (elemOcr2.key === elemAll2.key) {
                                       existElementInStandard = true
                                       elemAll22 = elemAll2
                                   }
                               })
                           }

                        })


                        if (!existElementInStandard) {
                            treeDataAnnotationsStandardMixed[0].children && treeDataAnnotationsStandardMixed[0].children.length && treeDataAnnotationsStandardMixed[0].children.forEach((e) => {
                                if (e.key === elemOcr.key) {
                                    elemOcr2.children && elemOcr2.children.length > 0 && elemOcr2.children.forEach((elemOcrA) => {
                                        elemOcrA.children && elemOcrA.children.length > 0 && elemOcrA.children.forEach((wordOcr) => {
                                            wordOcr.isOcr = true
                                        })
                                    })
                                    e.children.push(elemOcr2)
                                }
                            })



                        } else {
                            elemOcr2.children && elemOcr2.children.length > 0 && elemOcr2.children.forEach((wordOcr) => {
                                let existWordOcrInListMixed = false

                                elemAll22.children && elemAll22.children.length > 0 && elemAll22.children.forEach((wordMixed) => {
                                    if (wordMixed.key === wordOcr.key) {
                                        existWordOcrInListMixed = true
                                    }
                                })

                                if (existWordOcrInListMixed === false) {
                                    wordOcr.isOcr = true
                                    elemAll22.children.push(wordOcr)
                                }
                            })
                        }

                    })
                })

                treeDataAnnotationsBusinessOcr[0].children && treeDataAnnotationsBusinessOcr[0].children.length && treeDataAnnotationsBusinessOcr[0].children.forEach((subcategoryOcr) => {

                    let existElementInStandard = false
                    let subcategoryOcrFinal = subcategoryOcr
                    treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length && treeDataAnnotationsBusinessMixed[0].children.forEach((subcategoryMixed) => {
                        if (subcategoryOcr.key === subcategoryMixed.key) {
                            existElementInStandard = true
                            subcategoryOcrFinal = subcategoryMixed
                        }
                    })
                    if (!existElementInStandard) {
                        treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length && treeDataAnnotationsBusinessMixed[0].children.forEach((subcategoryMixed2) => {
                            let existWordOcrInListMixed = false
                            if (subcategoryMixed2.key === subcategoryOcr.key) {
                                subcategoryOcrFinal.isOcr = true
                            }
                        })
                        treeDataAnnotationsBusinessMixed[0].children.push(subcategoryOcrFinal)
                    } else {
                        subcategoryOcr.children && subcategoryOcr.children.length && subcategoryOcr.children.forEach((wordOcr) => {
                            let existWordOcrInListMixed = false
                            subcategoryOcrFinal.children && subcategoryOcrFinal.children.length && subcategoryOcrFinal.children.forEach((wordOcrMixt) => {
                                if (wordOcr.title === wordOcrMixt.title) {
                                    existWordOcrInListMixed = true
                                }
                            })
                            if (existWordOcrInListMixed === false) {
                                wordOcr.isOcr = true
                                subcategoryOcrFinal.children.push(wordOcr)
                            }
                        })
                    }

                })

                treeDataAnnotationsRegExOcr[0].children && treeDataAnnotationsRegExOcr[0].children.length && treeDataAnnotationsRegExOcr[0].children.forEach((subcategoryOcr) => {
                    let existElementInStandard = false
                    let subcategoryOcrFinal = subcategoryOcr
                    treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length && treeDataAnnotationsRegExMixed[0].children.forEach((subcategoryMixed) => {
                        if (subcategoryOcr.key === subcategoryMixed.key) {
                            existElementInStandard = true
                            subcategoryOcrFinal = subcategoryMixed
                        }
                    })
                    if (!existElementInStandard) {
                        treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length && treeDataAnnotationsRegExMixed[0].children.forEach((subcategoryMixed2) => {
                            let existWordOcrInListMixed = false
                            if (subcategoryMixed2.key === subcategoryOcr.key) {
                                subcategoryOcrFinal.isOcr = true
                            }
                        })
                        treeDataAnnotationsRegExMixed[0].children.push(subcategoryOcrFinal)
                    } else {
                        subcategoryOcr.children && subcategoryOcr.children.length && subcategoryOcr.children.forEach((wordOcr) => {
                            let existWordOcrInListMixed = false
                            subcategoryOcrFinal.children && subcategoryOcrFinal.children.length && subcategoryOcrFinal.children.forEach((wordOcrMixt) => {
                                if (wordOcr.title === wordOcrMixt.title) {
                                    existWordOcrInListMixed = true
                                }
                            })
                            if (existWordOcrInListMixed === false) {
                                wordOcr.isOcr = true
                                subcategoryOcrFinal.children.push(wordOcr)
                            }
                        })
                    }

                })
                let pagesCountChildrenMixedOcr = []
                pagesCountChildrenMixedOcr = childrenStandardOcr.concat(childrenBusinessOcr, childrenRegExOcr)
                let pagesCountChildrenMixedAll = pagesCountChildrenMixed.concat(pagesCountChildrenMixedOcr)
                const getChildrenAllCategories = {
                    childrenStandard: pagesStandard,
                    childrenStandardOcr: childrenStandardOcr,
                    childrenBusiness: pagesBusiness,
                    childrenBusinessOcr: childrenBusinessOcr,
                    childrenRegEx: pagesRegEx,
                    childrenRegExOcr: childrenRegExOcr,
                }
                this.onGetRegExVersionUpdate(docAnnotationsRegularExp)
                this.props.onGetPagesCountChildrenMixed(pagesCountChildrenMixed, pagesCountChildrenMixedOcr, pagesCountChildrenMixedAll, getChildrenAllCategories )
                this.setState({
                    treeDataAll,
                    treeDataAnnotationsStandard,
                    treeDataAnnotationsStandardOcr,
                    treeDataAnnotationsBusiness,
                    treeDataAnnotationsRegEx,
                    expandedKeysAll,
                    treeDataAnnotationsStandardMixed: treeDataAnnotationsStandardMixed,
                    getMixedChildrenBusiness: childrenBusiness.concat(childrenBusinessOcr),
                    getAllChildrenBusinessParent: childrenBusinessParent,
                    getMixedChildrenRegEx: childrenRegEx.concat(childrenRegExOcr),
                    getAllChildrenRegExParent: childrenRegExParent,
                    pagesCountChildrenMixed
                })
                global.itemsToBeMarked = 0
                global.markedTimes = 0
            }
        }, 100)
    }

    onGetRegExVersionUpdate = (regExListVersionUpdate) => {
        this.setState({
            regExListVersionUpdate: regExListVersionUpdate
        })
    }

    onGetStandardUpdate = (standardList) => {
        const childrenStandard = []
        standardList && standardList[0].children && standardList[0].children.length > 0 && standardList[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                        childrenStandard.push(child4)
                    })
                })
            })
        })
        this.setState({
            getAllChildrenStandardUpdate: childrenStandard
        })
    }

    onSelectTab = (activeKey) => {
        this.setState({
            activeKey: activeKey
        })
    }

    onCheckedKeysObjectRefs = (checkedKeysObject) => {
        const { checkedKeysObjectRefsList, checkedKeysObjectRefsBoxTerms, checkedKeysObjectRefsRegEx } = this.state
        this.setState({
            //checkedKeysObjectRefs: checkedKeysObject
            checkedKeysObjectRefs: checkedKeysObject ? checkedKeysObject.concat(checkedKeysObjectRefsList, checkedKeysObjectRefsBoxTerms, checkedKeysObjectRefsRegEx) : ''
        })

    }
    onCheckedKeysObjectRefsList = (checkedKeysObject) => {
        let { checkedKeysObjectRefs, checkedKeysObjectRefsList } = this.state
        checkedKeysObjectRefs = checkedKeysObjectRefs && checkedKeysObjectRefs.filter((redChild) => !checkedKeysObjectRefsList.map((red) => red.key).includes(redChild.key))
        this.setState({
            checkedKeysObjectRefsList: checkedKeysObject,
            checkedKeysObjectRefs: checkedKeysObjectRefs.concat(checkedKeysObject)
        })
    }
    onCheckedKeysObjectRefsBoxTerms = (checkedKeysObject) => {
        let { checkedKeysObjectRefs, checkedKeysObjectRefsBoxTerms } = this.state
        checkedKeysObjectRefs = checkedKeysObjectRefs && checkedKeysObjectRefs.filter((redChild) => !checkedKeysObjectRefsBoxTerms.map((red) => red.key).includes(redChild.key))
        this.setState({
            checkedKeysObjectRefsBoxTerms: checkedKeysObject,
            checkedKeysObjectRefs: checkedKeysObjectRefs.concat(checkedKeysObject)
        })
    }
    onCheckedKeysObjectRefsRegEx = (checkedKeysObject) => {
        let { checkedKeysObjectRefs, checkedKeysObjectRefsRegEx } = this.state
        checkedKeysObjectRefs = checkedKeysObjectRefs && checkedKeysObjectRefs.filter((redChild) => !checkedKeysObjectRefsRegEx.map((red) => red.key).includes(redChild.key))
        this.setState({
            checkedKeysObjectRefsRegEx: checkedKeysObject,
            checkedKeysObjectRefs: checkedKeysObjectRefs.concat(checkedKeysObject)
        })
    }

    allChildrenTree = (tree) => {
        this.setState({
            treeStandard: tree
        })
    }
    allChildrenTreeBusiness = (tree) => {
        this.setState({
            treeBusiness: tree
        })
    }
    allChildrenTreeRegEx = (tree) => {
        this.setState({
            treeRegEx: tree
        })
    }
    onDocAnnotationsRegEx = (doc) => {
        this.setState({
            docAnnotationsRegEx: doc
        }, () => {
            this.setState({
                docAnnotationsRegEx: doc
            })
        })
    }
    onDocAnnotationsList = (doc) => {
        this.setState({
            docAnnotationsList: doc
        }, () => {
            this.setState({
                docAnnotationsList: doc
            })
        })
    }
    onDocAnnotationsOcr = (doc) => {
        this.setState({
            docAnnotationsOcr: doc
        })
    }
    onCheckAiAnnotations = (status) => {
        this.setState({
            checkAiStandard: status
        })
    }
    onRedactAllCategoriesCopy = (e) => {
        const { treeStandard, treeBusiness, treeRegEx, listCategories, treeDataAnnotationsStandardMixed,
            treeDataAnnotationsBusinessMixed, treeDataAnnotationsRegExMixed} = this.state
        const selectAllItemsStandard = []
        const selectAllItemsStandardOcr = []
        const selectRedactionStandardOcr = []
        const selectAllItemsBusiness = []
        const selectAllItemsBusinessOcr = []
        const selectRedactionBusinessOcr = []
        const selectAllItemsRegEx = []
        const selectAllItemsRegExOcr = []
        const selectRedactionRegExOcr = []
        let allWordsRedacted = []
        let allWordsRedactedOcr = []
        let allWordsRedactedMixed = []
        //console.log('JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ')
        treeDataAnnotationsStandardMixed && treeDataAnnotationsStandardMixed.length > 0 && treeDataAnnotationsStandardMixed[0].children && treeDataAnnotationsStandardMixed[0].children.length > 0 && treeDataAnnotationsStandardMixed[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    // if (child3.children.length === 0 && child3.isOcr) {
                    //     child3.categoryName = child.key
                    //     child3.subcategoryName = child2.key
                    //     selectAllItemsStandardOcr.push(child3)
                    //     selectRedactionStandardOcr.push({
                    //         word: child3.key,
                    //         category: child.key,
                    //         subcategory: child2.key,
                    //     })
                    // }
                    child3 && child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                        if (!child4.isOcr) {
                            child4.categoryName = child.key
                            child4.subcategoryName = child2.key
                            selectAllItemsStandard.push(child4)
                        }
                        if (child4.isOcr) {
                            child4.categoryName = child.key
                            child4.subcategoryName = child2.key
                            selectAllItemsStandardOcr.push(child4)
                            selectRedactionStandardOcr.push({
                                word: child4.key,
                                category: child.key,
                                subcategory: child2.key,
                            })
                        }
                    })
                })
            })
        })
        treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed.length > 0 && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && treeDataAnnotationsBusinessMixed[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                // if (child2.children.length === 0 && child2.isOcr) {
                //     child2.categoryName = 'Dictionaries'
                //     child2.subcategoryName = child.key
                //     selectAllItemsBusinessOcr.push(child2)
                //     selectRedactionBusinessOcr.push({
                //         word: child2.key,
                //         category: 'Dictionaries',
                //         subcategory: child.key,
                //     })
                // }
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    if (!child3.isOcr) {
                        child3.categoryName = 'Dictionaries'
                        child3.subcategoryName = child.key
                        selectAllItemsBusiness.push(child3)
                    }
                    if (child3.isOcr) {
                        child3.categoryName = 'Dictionaries'
                        child3.subcategoryName = child.key
                        selectAllItemsBusinessOcr.push(child3)
                        selectRedactionBusinessOcr.push({
                            word: child3.key,
                            category: 'Dictionaries',
                            subcategory: child.key,
                        })
                    }
                })
            })
        })
        treeDataAnnotationsRegExMixed && treeDataAnnotationsRegExMixed.length > 0 && treeDataAnnotationsRegExMixed[0].children && treeDataAnnotationsRegExMixed[0].children.length > 0 && treeDataAnnotationsRegExMixed[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                // if (child2.children.length === 0 && child2.isOcr) {
                //     child2.categoryName = 'Regular Expressions'
                //     child2.subcategoryName = child.key
                //     selectAllItemsRegExOcr.push(child2)
                //     selectRedactionRegExOcr.push({
                //         word: child2.key,
                //         category: 'Regular Expressions',
                //         subcategory: child.key,
                //     })
                // }
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    if (!child3.isOcr) {
                        child3.categoryName = 'Regular Expressions'
                        child3.subcategoryName = child.key
                        selectAllItemsRegEx.push(child3)
                    }
                    if (child3.isOcr) {
                        child3.categoryName = 'Regular Expressions'
                        child3.subcategoryName = child.key
                        selectAllItemsRegExOcr.push(child3)
                        selectRedactionRegExOcr.push({
                            word: child3.key,
                            category: 'Regular Expressions',
                            subcategory: child.key,
                        })
                    }
                })
            })
        })
        allWordsRedacted = selectAllItemsStandard.concat(selectAllItemsBusiness, selectAllItemsRegEx)
        allWordsRedactedOcr = selectAllItemsStandardOcr.concat(selectAllItemsBusinessOcr, selectAllItemsRegExOcr)
        allWordsRedactedMixed = allWordsRedacted.concat(allWordsRedactedOcr)

        if (allWordsRedactedMixed && allWordsRedactedMixed.length > 0) {
            this.setState({
                finalChildrenStatus: selectAllItemsStandard,
                finalChildrenStandard: selectAllItemsStandard,

            })
            listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
                elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                    allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                        if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                            item.categoryColor = elem2.subcategoryColorWithAlpha
                        }
                    })
                })
            })
            const objectListRedact = {
                selectAllItemsStandard: selectAllItemsStandard,
                selectAllItemsStandardOcr: selectAllItemsStandardOcr,
                selectAllItemsBusiness: selectAllItemsBusiness,
                selectAllItemsBusinessOcr: selectAllItemsBusinessOcr,
                selectAllItemsRegEx: selectAllItemsRegEx,
                selectAllItemsRegExOcr: selectAllItemsRegExOcr,
                allWordsRedacted: allWordsRedacted,
                allWordsRedactedOcr: allWordsRedactedOcr,
                allWordsRedactedMixed: allWordsRedactedMixed,
                selectRedactionStandardOcr: selectRedactionStandardOcr,
                selectRedactionBusinessOcr: selectRedactionBusinessOcr,
                selectRedactionRegExOcr: selectRedactionRegExOcr,
            }
            this.onRedactAiAllTagCopy(allWordsRedacted, selectAllItemsStandard, selectAllItemsBusiness, selectAllItemsRegEx, allWordsRedactedMixed, objectListRedact)
        }
    }
    onRedactAiAllTagCopy = (allWordsRedacted, selectAllItemsStandard, selectAllItemsBusiness, selectAllItemsRegEx, allWordsRedactedMixed, objectListRedact) => {
        if (allWordsRedactedMixed && allWordsRedactedMixed.length > 0) {
            const sortedByPageItems = allWordsRedacted.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsMixed = allWordsRedactedMixed.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsStandard = selectAllItemsStandard.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsBusiness = selectAllItemsBusiness.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            const sortedByPageItemsRegEx = selectAllItemsRegEx.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
            this.drawMatchedAllWordsCopy(sortedByPageItems.map((item) => item.key), sortedByPageItemsMixed.map((item) => item.key),
                sortedByPageItemsStandard.map((item) => item.key),
                sortedByPageItemsBusiness.map((item) => item.key), sortedByPageItemsRegEx.map((item) => item.key), objectListRedact)
        }
    }
    onCheckedKeysAllMixedStandard = (checkedKeysAllMixedStandard) => {
       this.setState({
           checkedKeysAllMixedStandard: checkedKeysAllMixedStandard
       })
    }
    onCheckedKeysAllMixedBusiness = (checkedKeysAllMixedBusiness) => {
        this.setState({
            checkedKeysAllMixedBusiness: checkedKeysAllMixedBusiness
        })
    }
    onCheckedKeysAllMixedRegEx = (checkedKeysAllMixedRegEx) => {
        this.setState({
            checkedKeysAllMixedRegEx: checkedKeysAllMixedRegEx
        })
    }
    drawMatchedAllWordsCopy = (classList, classNameListMixed, classListStandard, classListBusiness, classListRegEx, objectListRedact) => {
        const { treeBusiness, treeStandard, treeRegEx, checkedKeysAllMixedStandard, checkedKeysAllMixedBusiness, checkedKeysAllMixedRegEx } = this.state
        const { statusRedactAll, alreadyRedactedKeys } = this.props
        const unCheckedKeys = []
        let classStandard = []
        let classStandardOcr = []
        let classListStandardMixed = []
        let classBusiness = []
        let classBusinessOcr = []
        let classListBusinessMixed = []
        let classRegEx = []
        let classRegExOcr = []
        let classListRegExMixed = []
        objectListRedact.selectAllItemsStandard &&  objectListRedact.selectAllItemsStandard.length > 0 &&  objectListRedact.selectAllItemsStandard.forEach((elem) => {
            classStandard.push(elem.key)
        })
        objectListRedact.selectAllItemsStandardOcr &&  objectListRedact.selectAllItemsStandardOcr.length > 0 &&  objectListRedact.selectAllItemsStandardOcr.forEach((elem) => {
            classStandardOcr.push(elem.key)
        })
        classListStandardMixed = classStandard.concat(classStandardOcr)

        objectListRedact.selectAllItemsBusiness &&  objectListRedact.selectAllItemsBusiness.length > 0 &&  objectListRedact.selectAllItemsBusiness.forEach((elem) => {
            classBusiness.push(elem.key)
        })
        objectListRedact.selectAllItemsBusinessOcr &&  objectListRedact.selectAllItemsBusinessOcr.length > 0 &&  objectListRedact.selectAllItemsBusinessOcr.forEach((elem) => {
            classBusinessOcr.push(elem.key)
        })
        classListBusinessMixed = classBusiness.concat(classBusinessOcr)
        objectListRedact.selectAllItemsRegEx &&  objectListRedact.selectAllItemsRegEx.length > 0 &&  objectListRedact.selectAllItemsRegEx.forEach((elem) => {
            classRegEx.push(elem.key)
        })
        objectListRedact.selectAllItemsRegExOcr &&  objectListRedact.selectAllItemsRegExOcr.length > 0 &&  objectListRedact.selectAllItemsRegExOcr.forEach((elem) => {
            classRegExOcr.push(elem.key)
        })
        classListRegExMixed = classRegEx.concat(classRegExOcr)
         let classListStandardMixedAll = []
         let alreadyRedactedKeysAll = []
         let alreadyRedactedKeysAllMixed = []
        classListStandardMixedAll = classListStandardMixed.concat(classListBusinessMixed, classListRegExMixed)
        alreadyRedactedKeysAll = alreadyRedactedKeys.standard.concat(alreadyRedactedKeys.standardOCR, alreadyRedactedKeys.business, alreadyRedactedKeys.businessOCR,
            alreadyRedactedKeys.regex, alreadyRedactedKeys.regexOCR)
        //console.log('objectListRedact AAAAAAAAAAAAAAAAAAAAAAAAA', objectListRedact)
        //console.log('alreadyRedactedKeys AAAAAAAAAAAAAAAAAAAAAAAAA', alreadyRedactedKeys)
        //console.log('classListStandardMixedAll AAAAAAAAAAAAAAAAAAAAAAAAA', classListStandardMixedAll)
        //console.log('alreadyRedactedKeysAll AAAAAAAAAAAAAAAAAAAAAAAAA', alreadyRedactedKeysAll)
        //console.log('checkedKeysAllMixedStandard AAAAAAAAAAAAAAAAAAAAAAAAA', checkedKeysAllMixedStandard)
        alreadyRedactedKeysAllMixed = checkedKeysAllMixedStandard.concat(checkedKeysAllMixedBusiness, checkedKeysAllMixedRegEx)
        objectListRedact.classListStandardMixedAll = classListStandardMixedAll
        objectListRedact.alreadyRedactedKeysAllMixed = alreadyRedactedKeysAllMixed
        const textMessageRedact =
            //statusRedactAll === "true" ? `Remove all ${ objectListRedact.allWordsRedactedMixed.length } selected?` :
            (classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length) ?
                `Remove all ${ objectListRedact.allWordsRedactedMixed.length } selected?` :
            objectListRedact.allWordsRedactedMixed && objectListRedact.allWordsRedactedMixed.length === 1 ? `Redact ${ objectListRedact.allWordsRedactedMixed.length } selected?` : `Redact all ${ objectListRedact.allWordsRedactedMixed.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: (classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
                && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length) ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                //de decomentat 3333333333333333333
                // if (statusRedactAll !== 'true')
                // {
                //     this.onUpdateRedactionNumber(classList)
                //     //de decomentat
                //     this.onSaveQueryStatusMixed(objectListRedact, classListStandardMixedAll, alreadyRedactedKeysAll)
                // } else {
                //     this.onUpdateRedactionNumber(classList)
                //     //de decomentat
                //     this.onSaveQueryStatusMixed(objectListRedact, classListStandardMixedAll, alreadyRedactedKeysAll)
                // }
                this.onUpdateRedactionNumber(classList)
                //de decomentat
                this.onSaveQueryStatusMixed(objectListRedact, classListStandardMixedAll, alreadyRedactedKeysAllMixed)
                this.setState({
                    redactedKeys: []
                }, () => {
                    let classAllNameList = []
                    classAllNameList = classListStandard.concat(classListBusiness, classListRegEx)
                    this.props.drawRedactAllMatchedWordsMixed(classAllNameList, classNameListMixed, [], 'mixed', objectListRedact)
                    if (classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
                        && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length) {
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllStandard: [],
                            checkedKeysAllBusiness: [],
                            checkedKeysAllRegEx: [],
                        })
                    } else {
                        this.setState({
                            unCheckedKeys: [],
                            updateRedactAllStatus: true,
                            checkedKeysAllStandard: classListStandardMixed,
                            checkedKeysAllBusiness: classListBusinessMixed,
                            checkedKeysAllRegEx: classListRegExMixed,
                        })
                    }
                })
            }
        })
    }

    onSaveQueryStatusMixed = (objectListRedact, classListStandardMixedAll, alreadyRedactedKeysAllMixed) => {
        //console.log('objectListRedact SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', objectListRedact)
        const keyListWordNonOcr = []
        const keyListWordNonOcrRegex = []
        const keyListWordNonOcrDictionary = []
        const keyListWordOcr = []
        const keyListWordOcrRegex = []
        const keyListWordOcrDictionary = []
        if (objectListRedact.selectAllItemsStandard && objectListRedact.selectAllItemsStandard.length > 0) {
            objectListRedact.selectAllItemsStandard.forEach((standard) => {
                //keyListWordNonOcr.push(`${ standard.key } => ${ standard.name } => ${ standard.pageNumber }`)
                keyListWordNonOcr.push(standard.keyMixtRedaction)
            })
        }
        if (objectListRedact.selectAllItemsStandardOcr && objectListRedact.selectAllItemsStandardOcr.length > 0) {
            objectListRedact.selectAllItemsStandardOcr.forEach((standard) => {
                keyListWordOcr.push(standard.key)
            })
        }
        if (objectListRedact.selectAllItemsRegEx && objectListRedact.selectAllItemsRegEx.length > 0) {
            objectListRedact.selectAllItemsRegEx.forEach((regEx) => {
                //keyListWordNonOcrRegex.push(`${ regEx.key } => ${ regEx.name } => ${ regEx.pageNumber }`)
                keyListWordNonOcrRegex.push(regEx.keyMixtRedaction)
            })
        }
        if (objectListRedact.selectAllItemsRegExOcr && objectListRedact.selectAllItemsRegExOcr.length > 0) {
            objectListRedact.selectAllItemsRegExOcr.forEach((regEx) => {
                keyListWordOcrRegex.push(regEx.key)
            })
        }
        if (objectListRedact.selectAllItemsBusiness && objectListRedact.selectAllItemsBusiness.length > 0) {
            objectListRedact.selectAllItemsBusiness.forEach((dict) => {
                //keyListWordNonOcrDictionary.push(`${ dict.key } => ${ dict.name } => ${ dict.pageNumber }`)
                keyListWordNonOcrDictionary.push(dict.keyMixtRedaction)
            })
        }
        if (objectListRedact.selectAllItemsBusinessOcr && objectListRedact.selectAllItemsBusinessOcr.length > 0) {
            objectListRedact.selectAllItemsBusinessOcr.forEach((dict) => {
                keyListWordOcrDictionary.push(dict.key)
            })
        }
        let updateStatus = false
        if ((keyListWordNonOcr && keyListWordNonOcr.length > 0) || (keyListWordNonOcrRegex && keyListWordNonOcrRegex.length > 0) || (keyListWordNonOcrDictionary && keyListWordNonOcrDictionary.length > 0)) {
            updateStatus = true
        } else {
            updateStatus = false
        }
        const saveAnnotationWithStateDTO = {
            keyWordOcr: classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length ? [] : keyListWordOcr,
            keyWordOcrRegex: classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length ? [] : keyListWordOcrRegex,
            keyWordOcrDictionary: classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length ? [] : keyListWordOcrDictionary,
            keyWordNonOcr: classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length ? [] : keyListWordNonOcr,
            keyWordNonOcrRegex: classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length ? [] : keyListWordNonOcrRegex,
            keyWordNonOcrDictionary: classListStandardMixedAll && classListStandardMixedAll.length > 0 && alreadyRedactedKeysAllMixed && alreadyRedactedKeysAllMixed.length > 0
            && classListStandardMixedAll.length === alreadyRedactedKeysAllMixed.length ? [] : keyListWordNonOcrDictionary,
            updateRedactions: updateStatus,
            idDocument: this.props.idDocument
        }
        //console.log('saveAnnotationWithStateDTO SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS', saveAnnotationWithStateDTO)
        apiSmartBoxSaveQueryStatusMixed(saveAnnotationWithStateDTO).then((response) => {
            this.props.onGetRedactedKeysMixed()
        })
    }

    onCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
            this.setState({
                responseCheckPdfCanvas: response.data
            })
            if(response.data === 'Processing') {
                this.setIntervalCheckAllCanvasPoints()
            } else {
                this.props.fetchSmartBoxDataCanvasOcr()
            }
        })
    }

    setIntervalCheckAllCanvasPoints = () => {
        const { responseCheckPdfCanvas} = this.state
        let { setIntervalCheckPdfCanvas} = this.state
        if (setIntervalCheckPdfCanvas === 0) {
            if (responseCheckPdfCanvas === 'Processing') {
                setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
                this.setState({
                    setIntervalCheckPdfCanvas
                })
            } else {
                clearInterval(setIntervalCheckPdfCanvas)
            }
        }
    }

    callBackCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        if(responseCheckPdfCanvas === 'Processing') {
            apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
                this.setState({
                    responseCheckPdfCanvas: response.data
                })
                if (response.data === 'Finished') {
                    this.props.fetchSmartBoxDataCanvasOcr()
                }
            })
        }
    }
    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }

    onDropdownVisibleReview = (e) => {
        this.setState({
            visibleOptionsReviewAll: e
        })
    }
    filterOptionsReview = (e) => {
        const { treeDataStandardOption, checkedKeysRedacted } = this.state
        this.setState({
            statusFilterReview: e
        })
        //const treeDataFilter = treeDataStandardOption[0]
        if (e === 'review-redacted' && checkedKeysRedacted && checkedKeysRedacted.length > 0) {
            treeDataStandardOption && treeDataStandardOption[0].children && treeDataStandardOption[0].children.length > 0 && treeDataStandardOption[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        child3.children && child3.children.length > 0 && child3.children.forEach((child4) => {
                            if (child4.status === 1) {
                                //child3.status = 1
                                child4.status = 1
                            } else {
                                //child3.status = 1
                                child4.status = 0
                            }
                            if (child3.children.includes(child4.status === 1)) {
                                //child3.status = 1
                            }
                        })
                    })
                })
            })

            treeDataStandardOption && treeDataStandardOption[0].children && treeDataStandardOption[0].children.length > 0 && treeDataStandardOption[0].children.forEach((child) => {
                child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                    child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                        if (child3.children.filter(e => e.status === 1).length > 0) {
                            child3.status = 1
                            child.status = 1
                            child2.status = 1
                            treeDataStandardOption[0].status = 1
                        } else {
                            child3.status = 0
                            child.status = 0
                            child2.status = 0
                            treeDataStandardOption[0].status = 0
                        }
                    })
                })
            })
        }
    }
    treeDataFilter = (tree) => {
        this.setState({
            treeDataStandardOption: tree
        })
    }
    statusCheckedChildrenRedact = (checked) => {
        this.setState({
            checkedKeysRedacted: checked
        })
    }

    onBlockRedactAllCategories = () => {
        return true
    }
    onActionHideAllHighlighting = () => {
        const { treeDataAnnotationsStandard, statusHideAllHighlighting, statusIconHideAllHighlighting } = this.state
        this.props.onActionHideAllHighlightingCopy(!this.state.statusHideAllHighlighting)
        if (!statusHideAllHighlighting) {
            this.setState({
                statusHideAllHighlighting: !this.state.statusHideAllHighlighting,
                statusIconHideAllHighlighting: true,
            })
        } else {
            this.setState({
                statusHideAllHighlighting: !this.state.statusHideAllHighlighting,
                statusIconHideAllHighlighting: !this.state.statusIconHideAllHighlighting,
            })
        }
    }
    onChangeStatusIconHideAllHighlighting = () => {
        this.setState({
            statusIconHideAllHighlighting: true
        })
    }

    render() {
        const { activeKey, updateRedactAllStatus, checkedKeysAllStandard, statusFilterReview, checkedKeysAllBusiness, checkedKeysAllRegEx,
            treeRegEx, treeBusiness, docAnnotationsRegEx, docAnnotationsList, visibleOptionsReviewAll,
            categoriesColorsStandard, docAnnotationsStandard, docAnnotationsColorsStandard, docAnnotationCategoriesStandard, treeDataAnnotationsStandard,
            expandedKeysAll, docAnnotationsBusiness, categoryColoursBusiness, treeDataAnnotationsBusiness,
            docAnnotationsRegularExp, categoryColoursRegularExp, treeDataAnnotationsRegEx, getMixedChildrenRegEx, regExListVersionUpdate, docAnnotationsOcr, docAnnotationsBusinessOcr, docAnnotationsRegularExpOcr,
            getAllChildrenStandardUpdate, getAllChildrenBusinessParent, getAllChildrenRegExParent, fetchResponseLoadingStandard, statusHideAllHighlighting, statusIconHideAllHighlighting,
            listCategories, treeDataAnnotationsStandardOcr, docAnnotationsStandardOcr,
            treeDataAnnotationsStandardMixed, treeDataAnnotationsBusinessMixed, categoryColoursBusinessOcr, treeDataAnnotationsRegExMixed,
            categoryColoursRegularExpOcr, getMixedChildrenBusiness} = this.state
        const { lockSmartViewChanges } = this.props
        ////console.log('docAnnotationsStandardOcr _--------------- RENDER AI',docAnnotationsStandardOcr)
        ////console.log('lockSmartViewChanges _--------------- RENDER AI',lockSmartViewChanges)
        return (
            <React.Fragment>
                { fetchResponseLoadingStandard === false &&
                <div className="content-spinning-standard-ai"> <Spin tip="Loading"></Spin></div> }
                { this.props.isDocumentReady && <div className="find-asset-container">
                    {/*<div className="title">SEARCH TERMS</div>*/}
                    <div className="header-action-ai-results">
                        <div className="content-option-ai">
                       <span className="option-ai-contain">
                         <span onClick={lockSmartViewChanges ? (e) => this.onBlockRedactAllCategories(e) :
                                 (e) => this.onRedactAllCategoriesCopy(e)}
                               className={ lockSmartViewChanges ? 'icon-v17-redaction-hover icon-v17-redaction-hover-block' : "icon-v17-redaction-hover" }
                         ></span>
                           <span
                               className={statusIconHideAllHighlighting ? "icon-v22-visible-active" : 'icon-v22-unvisible-active'}
                               onClick={(e) => this.onActionHideAllHighlighting(e)}></span>
                           </span>
                            <Select
                                placeholder="show-all"
                                className='custom-option-review'
                                value={<span
                                    className="content-options-review"><span>{statusFilterReview === 'review-all' ? 'show all' : statusFilterReview === 'review-redacted' ? 'redacted' :
                                    statusFilterReview === 'review-annotated' ? 'annotated' : 'show all'}</span>
                         <span
                             className={visibleOptionsReviewAll ? "icon-v19-arrow-medium icon-v19-arrow-medium-rotate" : 'icon-v19-arrow-medium'}></span></span>}
                                dropdownClassName="custom-option-review-ai"
                                showArrow={false}
                                onChange={(e) => this.filterOptionsReview(e)}
                                onDropdownVisibleChange={(e) => this.onDropdownVisibleReview(e)}
                            >
                                <Option className="item-options-review" key={1} value="review-all">
                                    show all
                                </Option>
                                <Option className="item-options-review" key={2} value="review-redacted">
                                    redacted
                                </Option>
                                <Option className="item-options-review" key={3} value="review-annotated">
                                    annotated
                                </Option>
                            </Select>
                        </div>

                    </div>
                    <div>
                        <StandardTabCopy
                            isDocumentReady={this.props.isDocumentReady}
                            isSmartBoxApplication={this.props.isSmartBoxApplication}
                            documentId={this.props.documentId}
                            idDocument={this.props.idDocument}
                            landscape={this.props.landscape}
                            changePage={this.props.changePage}
                            pageNumber={this.props.pageNumber}
                            drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                            collapseAnnotateSelectModal={this.props.collapseAnnotateSelectModal}
                            setNumberOfAnnotateSelected={this.props.setNumberOfAnnotateSelected}
                            hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}
                            allPagesRendered={this.props.allPagesRendered}
                            setQueryStatus={this.props.setQueryStatus}
                            setTreeDataAnnotations={this.props.setTreeDataAnnotations}
                            newListKeys={this.props.newListKeys}
                            smartBoxDocumentPath={this.props.smartBoxDocumentPath}
                            toggleLoadingProcess={this.props.toggleLoadingProcess}
                            newListKeysStandard={this.props.newListKeysStandard}
                            verificationOnClickClearAll={this.props.verificationOnClickClearAll}
                            checkOnClearAll={this.props.checkOnClearAll}
                            activeKey={activeKey}
                            onCheckStatusSelectAgainRedacted={this.props.onCheckStatusSelectAgainRedacted}
                            onSelectTab={this.onSelectTab}
                            statusKeysEmptyOnClearAll={this.props.statusKeysEmptyOnClearAll}
                            onSendStatusOnClearAll={this.props.onSendStatusOnClearAll}
                            isOcr={this.props.isOcr}
                            fetchSmartBoxDataCanvasOcr={this.props.fetchSmartBoxDataCanvasOcr}
                            onCheckedKeysObjectRefs={this.onCheckedKeysObjectRefs}
                            isNextDocument={this.props.isNextDocument}
                            isNextDocumentId={this.props.isNextDocumentId}
                            disabledNextDocument={this.props.disabledNextDocument}
                            nextDocumentLength={this.props.nextDocumentLength}
                            filesTable={this.props.filesTable}
                            allChildrenTree={this.allChildrenTree}
                            updateRedactAllStatus={updateRedactAllStatus}
                            checkedKeysAllStandard={checkedKeysAllStandard}
                            onCheckAiAnnotations={this.onCheckAiAnnotations}
                            treeDataFilter={this.treeDataFilter}
                            statusCheckedChildrenRedact={this.statusCheckedChildrenRedact}
                            statusFilterReview={statusFilterReview}
                            checkedKeysObjectRefsStandard={this.props.checkedKeysObjectRefsStandard}
                            statusSaveQuery={this.props.statusSaveQuery}
                            alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                            treeRegEx={treeRegEx}
                            treeBusiness={treeBusiness}
                            docAnnotationsRegEx={docAnnotationsRegEx}
                            docAnnotationsList={docAnnotationsList}
                            onDocAnnotationsOcr={this.onDocAnnotationsOcr}
                            getStatusElementRedaction={this.props.getStatusElementRedaction}
                            categoriesColorsStandard={categoriesColorsStandard}
                            docAnnotationsStandard={docAnnotationsStandard}
                            docAnnotationsColorsStandard={docAnnotationsColorsStandard}
                            docAnnotationCategoriesStandard={docAnnotationCategoriesStandard}
                            treeDataAnnotationsStandard={treeDataAnnotationsStandard}
                            treeDataAnnotationsStandardOcr={treeDataAnnotationsStandardOcr}
                            expandedKeysAll={expandedKeysAll}
                            statusNextDocumentStandard={this.props.statusNextDocumentStandard}
                            onGetStatusNextDocumentStandard={this.props.onGetStatusNextDocumentStandard}
                            getAllChildrenStandardUpdate={getAllChildrenStandardUpdate}
                            fetchResponseLoadingStandard={fetchResponseLoadingStandard}
                            showHideCategoryOcr={ this.props.showHideCategoryOcr }
                            lockSmartViewChanges={ this.props.lockSmartViewChanges }
                            statusHideAllHighlighting={ statusHideAllHighlighting }
                            onChangeStatusIconHideAllHighlighting={ this.onChangeStatusIconHideAllHighlighting }
                            listCategories={ listCategories }
                            docAnnotationsOcr={ docAnnotationsOcr }
                            docAnnotationsStandardOcr={ docAnnotationsStandardOcr }
                            treeDataAnnotationsStandardMixed={ treeDataAnnotationsStandardMixed }
                            drawRedactAllMatchedWordsMixed={ this.props.drawRedactAllMatchedWordsMixed }
                            startDrawRedactMatchedWordsMixed={ this.props.startDrawRedactMatchedWordsMixed }
                            onGetRedactedKeysMixed={ this.props.onGetRedactedKeysMixed }
                            onCheckedKeysAllMixedStandard={ this.onCheckedKeysAllMixedStandard }
                            changePageMixed={ this.props.changePageMixed }
                        />
                        <ManualTabCopy isDocumentReady={this.props.isDocumentReady}
                                   isSmartBoxApplication={this.props.isSmartBoxApplication}
                                   documentId={this.props.documentId}
                                   landscape={this.props.landscape}
                                   changePage={this.props.changePage}
                                   pageNumber={this.props.pageNumber}
                                   drawAllMatchedWordsByClassList={this.props.drawAllMatchedWordsByClassList}
                                   hideLoadingBeforeMark={this.props.hideLoadingBeforeMark}
                                   idDocument={this.props.idDocument}
                                   highlightRectangle={this.props.highlightRectangle}
                                   toggleLoadingProcess={this.props.toggleLoadingProcess}
                                   activeKey={activeKey}
                                   newListKeysManual={this.props.newListKeysManual}
                                   verificationOnClickClearAllManual={this.props.verificationOnClickClearAllManual}
                                   checkOnClearAllManual={this.props.checkOnClearAllManual}
                                   newListKeysRegEx={this.props.newListKeysRegEx}
                                   checkOnClearAllRegEx={this.props.checkOnClearAllRegEx}
                                   verificationOnClickClearAllRegEx={this.props.verificationOnClickClearAllRegEx}
                                   newListKeysBusiness={this.props.newListKeysBusiness}
                                   checkOnClearAllBusiness={this.props.checkOnClearAllBusiness}
                                   verificationOnClickClearAllBusiness={this.props.verificationOnClickClearAllBusiness}
                                   onCheckedKeysObjectRefsList={this.onCheckedKeysObjectRefsList}
                                   onCheckedKeysObjectRefsBoxTerms={this.onCheckedKeysObjectRefsBoxTerms}
                                   onCheckedKeysObjectRefsRegEx={this.onCheckedKeysObjectRefsRegEx}
                                   isNextDocumentId={this.props.isNextDocumentId}
                                   allChildrenTreeBusiness={this.allChildrenTreeBusiness}
                                   checkedKeysAllBusiness={checkedKeysAllBusiness}
                                   updateRedactAllStatus={updateRedactAllStatus}
                                   allChildrenTreeRegEx={this.allChildrenTreeRegEx}
                                   checkedKeysAllRegEx={checkedKeysAllRegEx}
                                   onDocAnnotationsRegEx={this.onDocAnnotationsRegEx}
                                   onDocAnnotationsList={this.onDocAnnotationsList}
                                   isOcr={this.props.isOcr}
                                   fetchSmartBoxDataCanvasOcr={this.props.fetchSmartBoxDataCanvasOcr}
                                   alreadyRedactedKeys={this.props.alreadyRedactedKeys}
                                   treeRegEx={treeRegEx}
                                   treeBusiness={treeBusiness}
                                   setTreeDataRegEx={this.props.setTreeDataRegEx}
                                   setTreeDataDict={this.props.setTreeDataDict}
                                   getStatusElementRedaction={this.props.getStatusElementRedaction}
                                   docAnnotationsBusiness={docAnnotationsBusiness}
                                   docAnnotationsBusinessOcr={docAnnotationsBusinessOcr}
                                   categoryColoursBusiness={categoryColoursBusiness}
                                       categoryColoursBusinessOcr={categoryColoursBusinessOcr}
                                   treeDataAnnotationsBusiness={treeDataAnnotationsBusiness}
                                   docAnnotationsRegularExp={docAnnotationsRegularExp}
                                   docAnnotationsRegularExpOcr={docAnnotationsRegularExpOcr}
                                   categoryColoursRegularExp={categoryColoursRegularExp}
                                   treeDataAnnotationsRegEx={treeDataAnnotationsRegEx}
                                       getMixedChildrenRegEx={getMixedChildrenRegEx}
                                       getAllChildrenRegExParent={getAllChildrenRegExParent}
                                   regExListVersion={regExListVersionUpdate}
                                       getMixedChildrenBusiness={getMixedChildrenBusiness}
                                   getAllChildrenBusinessParent={getAllChildrenBusinessParent}
                                   statusNextDocumentBusiness={this.props.statusNextDocumentBusiness}
                                   onGetStatusNextDocumentBusiness={this.props.onGetStatusNextDocumentBusiness}
                                   statusNextDocumentRegEx={this.props.statusNextDocumentRegEx}
                                   onGetStatusNextDocumentRegEx={this.props.onGetStatusNextDocumentRegEx}
                                   showHideCategoryOcr={ this.props.showHideCategoryOcr }
                                   lockSmartViewChanges={ this.props.lockSmartViewChanges }
                                   statusHideAllHighlighting={ statusHideAllHighlighting }
                                   onChangeStatusIconHideAllHighlighting={ this.onChangeStatusIconHideAllHighlighting }
                                   listCategories={ listCategories }
                                       treeDataAnnotationsBusinessMixed={ treeDataAnnotationsBusinessMixed }
                                       treeDataAnnotationsRegExMixed={ treeDataAnnotationsRegExMixed }
                                       categoryColoursRegularExpOcr={ categoryColoursRegularExpOcr }
                                       drawRedactAllMatchedWordsMixed={ this.props.drawRedactAllMatchedWordsMixed }
                                       startDrawRedactMatchedWordsMixed={ this.props.startDrawRedactMatchedWordsMixed }
                                       onGetRedactedKeysMixed={ this.props.onGetRedactedKeysMixed }
                                       onCheckedKeysAllMixedBusiness={ this.onCheckedKeysAllMixedBusiness }
                                       onCheckedKeysAllMixedRegEx={ this.onCheckedKeysAllMixedRegEx }
                                       changePageMixed={ this.props.changePageMixed }
                        />
                    </div>

                </div>
                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    userId: state.userReducer.user.id
})
AiAnnotationsSidebarCopy.propTypes = {
    isDocumentReady: PropTypes.bool.isRequired,
    collapseAnnotateSelectModal: PropTypes.func,
    setNumberOfAnnotateSelected: PropTypes.func,
    hideLoadingBeforeMark: PropTypes.func,
    setQueryStatus: PropTypes.func,
    setTreeDataAnnotations: PropTypes.func,
    toggleLoadingProcess: PropTypes.func,
    clientId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
}

//export default AiAnnotationsSidebar
export default connect(mapStateToProps)(AiAnnotationsSidebarCopy)