/*eslint no-unused-vars: 0*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import History from './history'
import { uuid4 } from './utils'
import Select from './select'
import Rectangle from './rectangle'
import Tool from './tools'
import {apiSmartBoxGetAllCanvasPoints} from "../../../api/SmartBox";
import {isJsons} from "react-csv/src/core";

const fabric = require('fabric').fabric

/**
 * Sketch Tool based on FabricJS for React Applications
 */
export class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
    startX: PropTypes.number,
    startY: PropTypes.number,
    endX: PropTypes.number,
    endY: PropTypes.number,
    pageNumber: PropTypes.number,
    pagePointSum: PropTypes.string
  };

  static defaultProps = {
    lineColor: 'black',
    lineWidth: 10,
    fillColor: 'transparent',
    backgroundColor: 'transparent',
    opacity: 1.0,
    undoSteps: 25,
    tool: Tool.Pencil,
    widthCorrection: 2,
    heightCorrection: 0,
    forceValue: false
  };

  state = {
    parentWidth: 550,
    action: true,
    selectedObject: {},
    haveChanges: false,
    overObject: false,
    redactingMode: true,
  };
  _initTools = (fabricCanvas) => {
    this._tools = {}
    this._tools[Tool.Select] = new Select(fabricCanvas)
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas)
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    const canvas = this._fc
    if (canvas.allowTouchScrolling) return
    canvas.allowTouchScrolling = true
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    const canvas = this._fc
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false
    }
  };

  changeRedactingMode = (checked) => {
    this.setState({ redactingMode: checked })
  }

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */
  addImg = (dataUrl, options = {}) => {
    //console.log('DDDDDDDDDD 11111111111111111111111111111')
    const canvas = this._fc
    fabric.Image.fromURL(dataUrl, (oImg) => {
      const opts = {
        left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
        top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
        scale: 0.5
      }
      Object.assign(opts, options)
      oImg.scale(opts.scale)
      oImg.set({
        left: opts.left,
        top: opts.top
      })
      canvas.add(oImg)
    })
  };

  /**
   * Action when an object is added to the canvas
   */
  _onObjectAdded = (e) => {
    //console.log('Added AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')
    if (!this.props.renderCommentsText) {

      if (!this.state.action) {
        this.setState({
          action: true
        })
        return
      }
      this.setState({
        haveChanges: true
      })

      const obj = e.target
      if (obj.type === 'rect' || obj.type === 3 || obj.type === 1) {
        const x1 = Math.min(e.target.aCoords.bl.x, e.target.aCoords.br.x).toFixed(2)
        const x2 = Math.max(e.target.aCoords.bl.x, e.target.aCoords.br.x).toFixed(2)
        const y1 = Math.min(e.target.aCoords.bl.y, e.target.aCoords.tl.y).toFixed(2)
        const y2 = Math.max(e.target.aCoords.bl.y, e.target.aCoords.tl.y).toFixed(2)
        if (e.target.pagePointSum) {
          obj.pagePointSum = e.target.pagePointSum
        } else {
          obj.pagePointSum = (String(Math.floor(x1)) + String(Math.floor(x2)) + String(Math.floor(y1)) + String(Math.floor(y2)) + String(this.props.pageNumber)).substring(0, 32)
        }
        if (obj.type !== 1) {
          obj.scaleX = 1
          obj.scaleY = 1
          obj.lockScalingX = false
          obj.lockScalingY = false
          obj.hasBorders = true
          obj.annotationType = 1
        }
      }
      obj.__version = 1
      // record current object state as json and save as originalState
      const objState = obj
      obj.__originalState = objState
      const state = JSON.stringify(objState)
      // object, previous state, current state
      this._history.keep([obj, state, state])

      if (this.props.onObjectAdded && (obj.type === 1 || obj.type === 3)) {
        const onObjectAdded = this.props.onObjectAdded
        setTimeout(() => {
          onObjectAdded(obj)
        }, 10)
      }
    } else if (this.props.renderCommentsText) {
      const obj = e.target
      if (obj.type === 'rect' || obj.type === 3 || obj.type === 1 || obj.type === 4) {
        const x1 = Math.min(e.target.aCoords.bl.x, e.target.aCoords.br.x).toFixed(2)
        const x2 = Math.max(e.target.aCoords.bl.x, e.target.aCoords.br.x).toFixed(2)
        const y1 = Math.min(e.target.aCoords.bl.y, e.target.aCoords.tl.y).toFixed(2)
        const y2 = Math.max(e.target.aCoords.bl.y, e.target.aCoords.tl.y).toFixed(2)
        if (e.target.pagePointSum) {
          obj.pagePointSum = e.target.pagePointSum
        } else {
          obj.pagePointSum = (String(Math.floor(x1)) + String(Math.floor(x2)) + String(Math.floor(y1)) + String(Math.floor(y2)) + String(this.props.pageNumber)).substring(0, 32)
        }
        if (obj.type !== 1) {
          obj.scaleX = 1
          obj.scaleY = 1
          obj.lockScalingX = false
          obj.lockScalingY = false
          obj.hasBorders = true
          obj.annotationType = 1
        }
      }
      obj.__version = 1
      // record current object state as json and save as originalState
      const objState = obj
      obj.__originalState = objState
      const state = JSON.stringify(objState)
      this._history.keep([obj, state, state])

      if (this.props.onObjectAdded && (obj.type === 1 || obj.type === 3 || obj.type === 4)) {
        const onObjectAdded = this.props.onObjectAdded
        setTimeout(() => {
          onObjectAdded(obj)
        }, 10)
      }
    }
  };

  /**
   * Action when an object is moving around inside the canvas
   */
  _onObjectMoving = (e) => {
    //console.log('DDDDDDDDDD 222222222222222222222222')
    this.unselectObject()
    this.setState({
      haveChanges: true })
  }

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = (e) => {
   // console.log('DDDDDDDDDD 333333333333333333333333333')
    this.unselectObject()
    this.setState({
      haveChanges: true })
  };

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = (e) => {};

  _onObjectModified = (e) => {
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 0000000000000000000000000000')
    const canvas = this._fc
    const obj = e.target
    obj.__version += 1
    const prevState = JSON.stringify(obj.__originalState)
    const objState = obj
    // record current object state as json and update to originalState
    obj.__originalState = objState
    const currState = JSON.stringify(objState)
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 0000000000000000000000000000 obj', obj)
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 0000000000000000000000000000 currState', currState)
    obj.typeMixed = 12
    this._history.keep([obj, prevState, currState])
    if (e.transform.action === 'drag') {
      const relatedObj = this.findByPagePointSum(obj.pagePointSum, obj.annotationType)
      if (relatedObj) {
        if (obj.annotationType === 1 || obj.annotationType === 'RECTANGLE') {
          const prevStateDecoded = JSON.parse(prevState)
          let topPosition = relatedObj.top + (obj.top - prevStateDecoded.top)
          let leftPosition = relatedObj.left + (obj.left - prevStateDecoded.left)
          // top limit
          if (topPosition < 0) {
            topPosition = obj.top + (obj.height* obj.scaleY) + 2
          }
          // bottom limit
          if (topPosition + relatedObj.height > canvas.height) {
            topPosition = obj.top - 3
          }
          // left limit
          if (leftPosition < 0) {
            leftPosition = obj.left + (obj.width * obj.scaleX) + 3
          }
          // right limit
          if (leftPosition + relatedObj.width > canvas.width) {
            leftPosition = obj.left - (relatedObj.width * relatedObj.scaleX) - 3
          }
          relatedObj.top = topPosition
          relatedObj.left = leftPosition
          relatedObj.setCoords()
          canvas.renderAll()
        }
      }
      this._fc.setActiveObject(e.target)
    }
    if (['scale', 'scaleX', 'scaleY'].indexOf(e.transform.action) > -1) {
      this.repositioningAnnotation()
    }
  }

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    //console.log('DDDDDDDDDD 4444444444444444444444444')
    const obj = e.target
    if (obj.__removed) {
      obj.__version += 1
      return
    }
    obj.__version = 0
  }

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = (e) => {
    const { redactingMode } = this.state
    //console.log('this.state QQQQQQQQQQQQQQQQQQQQQQQQQQ', this.state)
   // console.log('this.props QQQQQQQQQQQQQQQQQQQQQQQQQQQ', this.props)
    this.props.hideAnnotationsList()
    const canvas = this._fc
    const activeObj = canvas.getActiveObject()
    //console.log('activeObj QQQQQQQQQQQQQQQQQQQQQQQQQQQ', activeObj)
      //console.log('activeObj QQQQQQQQQQQQQQQQQQQQQQQQQQQ 22222222222222222', activeObj)
      if (activeObj) {
        //console.log('INTRA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        const relatedObj = this.findByPagePointSumAnnotate(activeObj.pagePointSum, activeObj.annotationType, activeObj.pagePointSumAnnotate)
        this.props.selectObject(activeObj, relatedObj)
      }
      canvas.renderAll()
      if (e.target) {
        this.setState({
          selectedObject: e.target
        }, () => {
          if (this.state.selectedObject && e.target) {
            if (this.props.isModalShow) {
              this.props.showAnnotationsList()
            } else {
              this.props.hideAnnotationsList()
            }
            if (e.target.pagePointSum !== this.state.selectedObject.pagePointSum) {
              this._fc.setActiveObject(e.target)
              this.props.hideAnnotationsList()
              this.props.showAnnotationsList()
            }
          }

        })
      }
      this.setState({
        startX: e.absolutePointer.x,
        startY: e.absolutePointer.y
      })
      const colorForRectangles = redactingMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 1)'
      const pageNumber = this.props.pageNumber ? this.props.pageNumber : null
      this._selectedTool.doMouseDown(e, colorForRectangles, pageNumber)
  };

  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  _onMouseMove = (e) => {
   // console.log('DDDDDDDDDD 555555555555555555555555555555')
    this._selectedTool.doMouseMove(e)
  };

  /**
   * Action when the mouse cursor is moving out from the canvas or objects
   */
  _onMouseOut = (e) => {
    //console.log('DDDDDDDDDD 66666666666666666666666666')
    if (e.target) {
      this.setState({
        overObject: false
      })
    }
    this._selectedTool.doMouseOut(e)
    if (this.props.onChange) {
      const onChange = this.props.onChange
      setTimeout(() => {
        onChange(e.e)
      }, 10)
    }
  };

  _onMouseUp = (e) => {
    this.setState({
      endX: e.absolutePointer.x,
      endY: e.absolutePointer.y
    }
    )
    this.props.onEndPoints(e.absolutePointer.x, e.absolutePointer.y)
    this._selectedTool.doMouseUp(e)
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      //console.log('MouseUp MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM 111111111111111111111111111111111111')
      const canvas = this._fc
      const objects = canvas.getObjects()
      //console.log('MouseUp MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM 111111111111111111111111111111111111 objects', objects)
      const newObj = objects[objects.length - 1]
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON()
        //newObj.text = newObj.annotation = typeof this.props.drawByHandText !== 'undefined' ? this.props.drawByHandText : '!@#draw-by-hand#@!'
        if (newObj.text && newObj.annotation && typeof this.props.drawByHandText !== 'undefined') {
          newObj.text = newObj.annotation = typeof this.props.drawByHandText !== 'undefined' ? newObj.annotation : '!@#draw-by-hand#@!'
        } else {
          newObj.text = newObj.annotation = typeof this.props.drawByHandText !== 'undefined' ? this.props.drawByHandText : '!@#draw-by-hand#@!'
        }
      }
    }
    if (this.props.onChange) {
      const onChange = this.props.onChange
      setTimeout(() => {
        onChange(e.e)
      }, 10)
    }
  };

  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */
  _resize = (e) => {
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 11111111111111111111111111111111111111111')
    if (e) e.preventDefault()
    const { widthCorrection, heightCorrection } = this.props
    const canvas = this._fc
    const { offsetWidth, clientHeight } = this._container
    const prevWidth = canvas.getWidth()
    const prevHeight = canvas.getHeight()
    const wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2)
    const hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2)
    canvas.setWidth(offsetWidth - widthCorrection)
    canvas.setHeight(clientHeight - heightCorrection)
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      const bi = canvas.backgroundImage
      bi.width = bi.width * wfactor
      bi.height = bi.height * hfactor
    }
    const objects = canvas.getObjects()
    for (const i in objects) {
      const obj = objects[i]
      const scaleX = obj.scaleX
      const scaleY = obj.scaleY
      const left = obj.left
      const top = obj.top
      const tempScaleX = scaleX * wfactor
      const tempScaleY = scaleY * hfactor
      const tempLeft = left * wfactor
      const tempTop = top * hfactor
      obj.scaleX = tempScaleX
      obj.scaleY = tempScaleY
      obj.left = tempLeft
      obj.top = tempTop
      obj.setCoords()
    }
    this.setState({
      parentWidth: offsetWidth
    })
    canvas.renderAll()
    canvas.calcOffset()
  };

  /**
   * Sets the background color for this sketch
   * @param color in rgba or hex format
   */
  _backgroundColor = (color) => {
    if (!color) return
    const canvas = this._fc
    canvas.setBackgroundColor(color, () => canvas.renderAll())
  };

  findByPagePointSum = (pagePointSum, annotationType) => {
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 22222222222222222222222222222222222222222222')
    let response = null
    const canvas = this._fc
    const objects = canvas.getObjects()
    for (const i in objects) {
      //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 22222222222222222222222222222222222222222222 objects[i]', objects[i])
      if (objects[i].pagePointSum === pagePointSum && objects[i].annotationType !== annotationType) {
        response = objects[i]
      }
    }
    return response
  }

  findByPagePointSumAnnotate = (pagePointSum, annotationType, pagePointSumAnnotate) => {
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 33333333333333333333333333333333333333333')
    let response = null
    const canvas = this._fc
    const objects = canvas.getObjects()
    for (const i in objects) {
      //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 3333333333333333333333333333333333333 objects[i]', objects[i])
      if ((objects[i].pagePointSum === pagePointSum && objects[i].annotationType !== annotationType) || (objects[i].pagePointSumAnnotate && pagePointSumAnnotate && objects[i].pagePointSumAnnotate === pagePointSumAnnotate && objects[i].annotationType !== annotationType)) {
        response = objects[i]
      }
    }
    return response
  }

  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */
  zoom = (factor) => {
    const canvas = this._fc
    const objects = canvas.getObjects()
    for (const i in objects) {
      objects[i].scaleX = objects[i].scaleX * factor
      objects[i].scaleY = objects[i].scaleY * factor
      objects[i].left = objects[i].left * factor
      objects[i].top = objects[i].top * factor
      objects[i].setCoords()
    }
    canvas.renderAll()
    canvas.calcOffset()
  };

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    const history = this._history
    const [obj, prevState, currState] = history.getCurrent()
    history.undo()
    if (obj.__removed) {
      this.setState({ action: false }, () => {
        this._fc.add(obj)
        obj.__version -= 1
        obj.__removed = false
      })
    } else if (obj.__version <= 1) {
      this._fc.remove(obj)
    } else {
      obj.__version -= 1
      obj.setOptions(JSON.parse(prevState))
      obj.setCoords()
      this._fc.renderAll()
    }
    if (this.props.onChange) {
      this.props.onChange()
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    const history = this._history
    if (history.canRedo()) {
      const canvas = this._fc
      const [obj, prevState, currState] = history.redo()
      if (obj.__version === 0) {
        this.setState({ action: false }, () => {
          canvas.add(obj)
          obj.__version = 1
        })
      } else {
        obj.__version += 1
        obj.setOptions(JSON.parse(currState))
      }
      obj.setCoords()
      canvas.renderAll()
      if (this.props.onChange) {
        this.props.onChange()
      }
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo()
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo()
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = (json) => {
    if (!json) return
    const canvas = this._fc
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        canvas.renderAll()
        if (this.props.onChange) {
          this.props.onChange()
        }
      })
    }, 100)
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */

  clear = (propertiesToInclude) => {
    const discarded = this.toJSON(propertiesToInclude)
    this._fc.clear()
    this._history.clear()
    return discarded
  };

  clearAllImages = (propertiesToInclude) => {
    const discarded = this.toJSON(propertiesToInclude)
    this._fc.clear()
    this._history.clear()
    return discarded
  };

  clearTemporary = () => {
    const canvas = this._fc
    canvas.clear()
    canvas.renderAll()
    this._history.clear()
  };

  /**
   * Remove selected object from the canvas
   */
  // removeSelectedImage = (select) => {
  //   const canvas = this._fc
  //   let activeObj = []
  //   canvas && canvas._objects && canvas._objects.map((Klass, i) => {
  //     activeObj = Klass
  //     if (select && Klass.pagePointSum === select[0]) {
  //       const relatedObj = this.findByPagePointSum(select, activeObj.annotationType)
  //       if (relatedObj) {
  //         relatedObj.__removed = true
  //         const objState = relatedObj.toJSON()
  //         relatedObj.__originalState = objState
  //         const state = JSON.stringify(objState)
  //         this._history.keep([relatedObj, state, state])
  //         canvas.remove(relatedObj)
  //       }
  //       const selected = []
  //       if (activeObj.type === 'activeSelection') {
  //         activeObj.forEachObject((obj) => selected.push(obj))
  //       } else {
  //         selected.push(activeObj)
  //       }
  //       selected.forEach((obj) => {
  //         obj.__removed = true
  //         const objState = obj.toJSON()
  //         obj.__originalState = objState
  //         const state = JSON.stringify(objState)
  //         this._history.keep([obj, state, state])
  //         canvas.remove(obj)
  //       })
  //       canvas.discardActiveObject()
  //       canvas.requestRenderAll()
  //     }
  //
  //   })
  //   return activeObj
  // };

  removeSelectedImage = (select) => {
   // console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 99999999999999999999999999999999999')
    const canvas = this._fc
    let activeObj = []
    canvas && canvas._objects && canvas._objects.map((Klass, i) => {
      activeObj = Klass
      if (select && Klass.pagePointSum === select[0]) {
        const relatedObj = this.findByPagePointSum(select, activeObj.annotationType)
        if (relatedObj) {
          relatedObj.__removed = true
          const objState = relatedObj.toJSON()
          relatedObj.__originalState = objState
          const state = JSON.stringify(objState)
          this._history.keep([relatedObj, state, state])

          canvas.remove(relatedObj)
        }
        const selected = []
        if (activeObj.type === 'activeSelection') {
          activeObj.forEachObject((obj) => selected.push(obj))
        } else {
          selected.push(activeObj)
        }

        selected.forEach((obj) => {
          obj.__removed = true
          const objState = obj.toJSON()
          obj.__originalState = objState
          const state = JSON.stringify(objState)
          this._history.keep([obj, state, state])
          canvas.remove(obj)
        })
        canvas.discardActiveObject()
        canvas.requestRenderAll()
      }
      return true
    })
    return activeObj
  };

  removeSelected = () => {
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 44444444444444444444444444444444444')
    const canvas = this._fc
    let activeObj = canvas.getActiveObject()
    if (!activeObj || !Object.keys(activeObj).length) {
      activeObj = this.state.selectedObject
    }
    if (activeObj && Object.keys(activeObj).length) {
      if (activeObj.annotationType === 1) {
        const relatedObj = this.findByPagePointSum(activeObj.pagePointSum, activeObj.annotationType)
        if (relatedObj) {
          relatedObj.__removed = true
          const objState = relatedObj.toJSON()
          relatedObj.__originalState = objState
          const state = JSON.stringify(objState)
          this._history.keep([relatedObj, state, state])
          canvas.remove(relatedObj)
        }
      }
      const selected = []
      if (activeObj.type === 'activeSelection') {
        activeObj.forEachObject((obj) => selected.push(obj))
      } else {
        selected.push(activeObj)
      }
      selected.forEach((obj) => {
        obj.__removed = true
        const objState = obj.toJSON()
        obj.__originalState = objState
        const state = JSON.stringify(objState)
        this._history.keep([obj, state, state])
        canvas.remove(obj)
      })
      canvas.discardActiveObject()
      canvas.requestRenderAll()
    }
    return activeObj
  };

  removeSelectedAnnotation = () => {
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 55555555555555555555555555555555')
    const canvas = this._fc
    let activeObj = canvas.getActiveObject()
    if (!activeObj) {
      activeObj = this.state.selectedObject
    }
    if (activeObj) {
      if (activeObj.annotationType === 1) {
        const relatedObj = this.findByPagePointSum(activeObj.pagePointSum, activeObj.annotationType)
        if (relatedObj) {
          relatedObj.__removed = true
          const objState = relatedObj.toJSON()
          relatedObj.__originalState = objState
          const state = JSON.stringify(objState)
          this._history.keep([relatedObj, state, state])
          canvas.remove(relatedObj)
        }
      }
      canvas.discardActiveObject()
      canvas.requestRenderAll()
    }
    return activeObj
  };

  /**
   * Fill rectangles with rgba color
   */
  fillRectanglesOcr = (color,documentId, page, alpha, canvasData) => {
    //console.log('DDDDDDDDDD 66666666666666666666666666666')
    const canvas = this._fc
    canvas.getObjects().forEach((rectangle) => {
      if (rectangle.fill === 'rgba(0,0,0,0.3)' || rectangle.fill === 'rgba(0, 0, 0, 0.3)') {
        rectangle.set('fill', 'rgba(0,0,0,1)')
        rectangle.set('stroke', 'rgba(0,0,0,0.41)')
        rectangle.set('strokeWidth', 1)
      } else if (rectangle.fill === 'rgba(0,0,0,1)' || rectangle.fill === 'rgba(0, 0, 0, 1)') {
        rectangle.set('fill', color)
        rectangle.set('stroke', 'rgba(0,0,0,0.41)')
        rectangle.set('strokeWidth', 1)
      } else if (rectangle.fill === 'rgba(0,0,0,0.4)' || rectangle.fill === 'rgba(0, 0, 0, 0.4)') {
        rectangle.set('fill', 'rgba(0,0,0,1)')
        rectangle.set('stroke', 'rgba(0,0,0,0.41)')
        rectangle.set('strokeWidth', 1)

      }

      // rectangle.set('fill', color)
      // rectangle.set('stroke', 'rgba(0,0,0,0.41)')
      // rectangle.set('strokeWidth', 1)
    })

    // Eduard 5291
    let colors = [];

    let i = page;
    if (canvasData[i] !== undefined) {

      let j = 0;
      while (canvasData[i][j] !== undefined) {
        let jsonObject = JSON.parse(canvasData[i][j]);
        let obj = {};
        obj['color'] = this.modifyAlpha(jsonObject.color, alpha);
        obj['text'] = jsonObject.text;
        colors.push(obj);
        j++;
      }
    }
    i = 0;
    canvas.getObjects().forEach((rectangle) => {
      let l = canvas.getObjects().length;

      rectangle.set('fill', colors[i]['color']);
      rectangle.set('stroke', 'rgba(0,0,0,0.41)')
      rectangle.set('strokeWidth', 1)
      i++;
    })
    // until here
    canvas.renderAll()
  }


  /**
   Function to modify alpha parameter of a colour
   */

   modifyAlpha = (color, alpha) =>{
    // Handle hex color codes
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
      let r, g, b;
      if (color.length === 4) {
        r = "0x" + color[1] + color[1];
        g = "0x" + color[2] + color[2];
        b = "0x" + color[3] + color[3];
      } else {
        r = parseInt(color.substr(1, 2), 16);
        g = parseInt(color.substr(3, 2), 16);
        b = parseInt(color.substr(5, 2), 16);
      }
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    if(color == null){
      // assign black by default
        color = 'rgba(0, 0, 0, 0)';
    }

    // Handle rgb and rgba color codes
    const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/);
    if (match) {
      return `rgba(${match[1]}, ${match[2]}, ${match[3]}, ${alpha})`;
    }

    // Handle invalid color formats
    return color;
  }

  fillRectangles = (color, documentId, page, alpha, canvasData) => {
    const canvas = this._fc
      //console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG')
     // console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG canvasData', canvasData)
    let colors = new Map();

    let i = page;
    if (canvasData[i] !== undefined) {

      let j = 0;

      while (typeof canvasData[i][j] === "string" && canvasData[i][j] !== undefined) {
        let jsonObject = JSON.parse(canvasData[i][j]);
        let obj = {};
        obj['color'] = this.modifyAlpha(jsonObject.color, alpha);
        obj['pagePointSum'] = jsonObject.pagePointSum;
        colors.set(obj['pagePointSum'], obj['color']);
        j++;
      }
    }

    i = 0;
    canvas.getObjects().forEach((rectangle) => {
      let l = canvas.getObjects().length;
      //console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG rectangle', rectangle)
      if (!rectangle.defaultSmartviewOCR) {
        rectangle.set('fill', colors.get(rectangle.pagePointSum) ? colors.get(rectangle.pagePointSum) :
            alpha === 1 && rectangle.fill && rectangle.fill.includes('0.3') ? rectangle.fill.replace(', 0.3)', ', 1)') :
                alpha === 1 && rectangle.fill && rectangle.fill.includes('0.4') ? rectangle.fill.replace(', 0.4)', ', 1)') :
                    alpha === 0.3 && rectangle.fill ? rectangle.fill.replace(', 1)', ', 0.3)') : color);
      }
      //rectangle.set('fill', 'rgba(0,0,0,0,0)');
      rectangle.set('stroke', 'rgba(0,0,0,0.41)')
      rectangle.set('strokeWidth', 1)
      i++;
    })
    canvas.renderAll()

  }

  /**
   * Hide annotation
   */
  showAnnotation = (type) => {
    const canvas = this._fc
    canvas.getObjects().forEach((rectangle) => {
      if (rectangle.annotationType === 2) {
        if (type === true) {
          rectangle.opacity = 1
        } else {
          rectangle.opacity = 0
        }
      }
    })
    canvas.renderAll()
  }

  copy = () => {
    const canvas = this._fc
    canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned))
  };

  paste = () => {
    // clone again, so you can do multiple copies.
    //console.log('KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK 55555555555555555555555555555555555555')
    this._clipboard.clone((clonedObj) => {
      const canvas = this._fc
      canvas.discardActiveObject()
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true
      })
      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas
        clonedObj.forEachObject((obj) => canvas.add(obj))
        clonedObj.setCoords()
      } else {
        canvas.add(clonedObj)
      }
      this._clipboard.top += 10
      this._clipboard.left += 10
      canvas.setActiveObject(clonedObj)
      canvas.requestRenderAll()
    })
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */
  setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    const canvas = this._fc
    if (options.stretched) {
      delete options.stretched
      Object.assign(options, {
        width: canvas.width,
        height: canvas.height
      })
    }
    if (options.stretchedX) {
      delete options.stretchedX
      Object.assign(options, {
        width: canvas.width
      })
    }
    if (options.stretchedY) {
      delete options.stretchedY
      Object.assign(options, {
        height: canvas.height
      })
    }
    const img = new Image()
    img.onload = () =>
      canvas.setBackgroundImage(
        new fabric.Image(img),
        () => canvas.renderAll(),
        options
      )
    img.src = dataUrl
  };

  addText = (text, options = {}) => {
    const canvas = this._fc
    const iText = new fabric.IText(text, options)
    const opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5
    }
    Object.assign(options, opts)
    iText.set({
      left: 50,
      top: 50,
      fontFamily: 'Helvetica',
      fill: '#333',
      lineHeight: 1.1,
      styles: {
        0: {
          0: { textDecoration: 'underline', fontSize: 80 },
          1: { textBackgroundColor: 'red' }
        },
        1: {
          0: { textBackgroundColor: 'rgba(0,255,0,0.5)' },
          4: { fontSize: 20 }
        }
      }
    })

    canvas.add(iText)
  };

  componentDidMount = () => {
    const { tool, value, undoSteps, defaultValue, backgroundColor } = this.props
    const canvas = (this._fc = new fabric.Canvas(
      this._canvas /*, {
         preserveObjectStacking: false,
         renderOnAddRemove: false,
         skipTargetFind: true
         }*/
    ))
    this._initTools(canvas)

    // set initial backgroundColor
    this._backgroundColor(backgroundColor)

    const selectedTool = this._tools[tool]
    selectedTool.configureCanvas(this.props)
    this._selectedTool = selectedTool

    // Control resize
    window.addEventListener('resize', this._resize, false)

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps)

    // Events binding
    canvas.on('object:added', this._onObjectAdded)
    canvas.on('object:modified', this._onObjectModified)
    canvas.on('object:removed', this._onObjectRemoved)
    canvas.on('mouse:down', this._onMouseDown)
    canvas.on('mouse:move', this._onMouseMove)
    canvas.on('mouse:up', this._onMouseUp)
    canvas.on('mouse:out', this._onMouseOut)
    canvas.on('object:moving', this._onObjectMoving)
    canvas.on('object:scaling', this._onObjectScaling)
    canvas.on('object:rotating', this._onObjectRotating)
    canvas.on('object:selected', this.onObjectSelecting)
    this.disableTouchScroll()

    this._resize();

    // initialize canvas with controlled value if exists
    (value || defaultValue) && this.fromJSON(value || defaultValue)
  };

  unselectObject = () => {
    const canvas = this._fc
    canvas.discardActiveObject()
  }

  onObjectSelecting = (e) => {

  }

  fillPointsOcr = (point, color) => {
    //console.log('pagePointSum AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
    let square = {}
    square = new fabric.Rect({
      idd: point.id,
      width: Math.abs(point.x1 - point.x2),
      height: Math.abs(point.y1 - point.y2),
      left: Math.min(point.x1, point.x2),
      top: Math.min(point.y1, point.y2),
      fill: color,
      //opacity: 0.3,
      hasRotatingPoint: false,
      strokeStyle: color,
      //strokeOpacity: 0.3,
      //strokeopacity: 0.3,
      selectable: false,
      evented: false,
      annotation: point.annotation,
      annotationType: point.type,
      pagePointSum: point.pagePointSum,
      text: point.text
    })
    if ((point.type === 'ANNOTATION' || point.type === 2 || point.type === '2') && point.annotation) {
      square = new fabric.Text(point.annotation, {
        idd: point.id,
        width: Math.abs(point.x1 - point.x2),
        height: Math.abs(point.y1 - point.y2),
        left: Math.min(point.x1, point.x2),
        top: Math.min(point.y1, point.y2),
        fill: '#000000',
        fontSize: 20,
        backgroundColor: point.backgroundColor ? point.backgroundColor : '#FFFF00',
        hasBorders: false,
        hasControls: false,
        padding: 5,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        lockUniScaling: true,
        annotation: point.annotation,
        annotationType: 2,
        type: 2,
        pagePointSum: point.pagePointSum,
        text: point.text
      })
    }
    if (point.type === 3 || point.annotationType === 3) {
      //console.log('SELECT 222222222222222222222222222222222222222222222222')
      square = new fabric.Polygon(point.points, {
        idd: point.id,
        fill: 'rgba(0, 0, 0, 0.4)',
        hasBorders: true,
        hasControls: true,
        angle: 0,
        selectable: true,
        padding: 5,
        annotation: point.annotation,
        annotationType: 3,
        type: 3,
        pagePointSum: point.pagePointSum,
        text: point.text
      })
    }

    if ((point.text && point.text !== '!@#draw-by-hand#@!') || (point.annotation && point.annotation !== '!@#draw-by-hand#@!')) {
      if (point.type === 1) {
        //console.log('SKETCH 111111111111111111111111111111111111111111')
        square = new fabric.Polygon(point.points, {
          idd: point.id,
          fill: 'rgba(0, 0, 0, 0.4)',
          hasBorders: true,
          hasControls: true,
          angle: 0,
          selectable: true,
          padding: 5,
          annotation: point.annotation,
          annotationType: 1,
          type: 1,
          pagePointSum: point.pagePointSum,
          text: point.text
        })
      }
    }

    if (Object.keys(square).length !== 0) {
      const canvas = this._fc
      canvas.add(square)
      canvas.renderAll()
    }
  }
  fillPointsHighlightingMixedFabricReact(point, color) {
    if ((point.type === 'ANNOTATION' || point.type === 2 || point.type === '2') && point.annotation) {
      return new fabric.Text(point.annotation, {
        idd: point.id,
        width: Math.abs(point.x1 - point.x2),
        height: Math.abs(point.y1 - point.y2),
        left: Math.min(point.x1, point.x2),
        top: Math.min(point.y1, point.y2),
        fill: '#000000',
        fontSize: 20,
        backgroundColor: point.backgroundColor ? point.backgroundColor : '#FFFF00',
        hasBorders: false,
        hasControls: false,
        padding: 5,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        lockUniScaling: true,
        annotation: point.annotation,
        annotationType: 2,
        type: 2,
        pagePointSum: point.pagePointSum,
        text: point.annotation ? point.annotation : point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }
    if (point.type === 3 || point.annotationType === 3) {
      return new fabric.Polygon(point.points, {
        idd: point.id,
        fill: 'rgba(0, 0, 0, 0.4)',
        hasBorders: true,
        hasControls: true,
        angle: 0,
        selectable: true,
        padding: 5,
        annotation: point.annotation,
        annotationType: 3,
        type: 3,
        pagePointSum: point.pagePointSum,
        text: point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }
    return new fabric.Rect({
      idd: point.id,
      width: Math.abs(point.x1 - point.x2),
      height: Math.abs(point.y1 - point.y2),
      left: Math.min(point.x1, point.x2),
      top: Math.min(point.y1, point.y2),
      fill: color,
      //opacity: 0.3,
      hasRotatingPoint: false,
      strokeStyle: color,
      //strokeOpacity: 0.3,
      //strokeopacity: 0.3,
      selectable: false,
      evented: false,
      annotation: point.annotation,
      annotationType: point.type,
      pagePointSum: point.pagePointSum,
      text: point.text,
      keyUI: point.keyUI ? point.keyUI : null,
      category: point.category ? point.category : null,
      subcategory: point.subcategory ? point.subcategory : null,
      color: point.color ? point.color : null,
      colorRedaction: point.colorRedaction ? point.colorRedaction : null,
      annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
      defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
    })
  }

  fillPointsHighlightingMixedUpdate = (elemstsJson, statusHideAllHighlightingMixed) => {
    const canvas = this._fc
    const alreadyRedactedElementsMixedDefault = []
    const alreadyRedactedElementsMixed = []
    canvas.renderOnAddRemove = false
    let rectsUpdate = elemstsJson.map((point) => {
      if (point) {
        const color = point.color ? point.color : 'rgba(0,0,0,0.3)'
        if (statusHideAllHighlightingMixed) {
          if (point.defaultSmartviewOCR) {
            if (!alreadyRedactedElementsMixedDefault.includes(point.text + point.x1 + point.y1)) {
              return this.fillPointsHighlightingMixedFabricReact(point, color)
              if (point.text) {
                alreadyRedactedElementsMixedDefault.push(point.text + point.x1 + point.y1)
              }
            }
          } else if (!alreadyRedactedElementsMixed.includes(point.text + point.x1 + point.y1)) {
            return this.fillPointsHighlightingMixedFabricReact(point, color)
            if (point.text) {
              alreadyRedactedElementsMixed.push(point.text + point.x1 + point.y1)
            }
          }
        } else {
          if (point.text === 'picture') {
            return this.fillPointsHighlightingMixedFabricReact(point, 'transparent')
          } else {
            return this.fillPointsHighlightingMixedFabricReact(point, 'rgba(0,0,0,0.4)')
          }
        }
      }
    })

    if (Object.keys(rectsUpdate).length !== 0) {
      canvas.add(...rectsUpdate)
      canvas.renderOnAddRemove = true
      canvas.requestRenderAll()
      //canvas.renderAll()
    }
  }
  fillPointsHighlightingMixed = (point, color) => {
    //console.log('point VVVVVVVVVVVVVVVVVVVVVVVVVVV', point)
    //console.log('pagePointSum BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB point', point)
    let square = {}
    if ((point.type === 'ANNOTATION' || point.type === 2 || point.type === '2') && point.annotation) {
      square = new fabric.Text(point.annotation, {
        idd: point.id,
        width: Math.abs(point.x1 - point.x2),
        height: Math.abs(point.y1 - point.y2),
        left: Math.min(point.x1, point.x2),
        top: Math.min(point.y1, point.y2),
        fill: '#000000',
        fontSize: 20,
        backgroundColor: point.backgroundColor ? point.backgroundColor : '#FFFF00',
        hasBorders: false,
        hasControls: false,
        padding: 5,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        lockUniScaling: true,
        annotation: point.annotation,
        annotationType: 2,
        type: 2,
        pagePointSum: point.pagePointSum,
        text: point.annotation ? point.annotation : point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }
    if (point.type === 3 || point.annotationType === 3) {
      return new fabric.Polygon(point.points, {
        idd: point.id,
        fill: 'rgba(0, 0, 0, 0.4)',
        hasBorders: true,
        hasControls: true,
        angle: 0,
        selectable: true,
        padding: 5,
        annotation: point.annotation,
        annotationType: 3,
        type: 3,
        pagePointSum: point.pagePointSum,
        text: point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }

    if ((point.text && point.text !== '!@#draw-by-hand#@!') || (point.annotation && point.annotation !== '!@#draw-by-hand#@!')) {
      if (point.type === 1) {
        //console.log('SKETCH 22222222222222222222222222222222222')
        return new fabric.Polygon(point.points, {
          idd: point.id,
          fill: 'rgba(0, 0, 0, 0.4)',
          hasBorders: true,
          hasControls: true,
          angle: 0,
          selectable: true,
          padding: 5,
          annotation: point.annotation,
          annotationType: 1,
          type: 1,
          pagePointSum: point.pagePointSum,
          text: point.text,
          keyUI: point.keyUI ? point.keyUI : null,
          category: point.category ? point.category : null,
          subcategory: point.subcategory ? point.subcategory : null,
          color: point.color ? point.color : null,
          colorRedaction: point.colorRedaction ? point.colorRedaction : null,
          annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
          defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
        })
      }
    }
    square = new fabric.Rect({
      idd: point.id,
      width: Math.abs(point.x1 - point.x2),
      height: Math.abs(point.y1 - point.y2),
      left: Math.min(point.x1, point.x2),
      top: Math.min(point.y1, point.y2),
      fill: color,
      //opacity: 0.3,
      hasRotatingPoint: false,
      strokeStyle: color,
      //strokeOpacity: 0.3,
      //strokeopacity: 0.3,
      selectable: false,
      evented: false,
      annotation: point.annotation,
      annotationType: point.type,
      pagePointSum: point.pagePointSum,
      text: point.text,
      keyUI: point.keyUI ? point.keyUI : null,
      category: point.category ? point.category : null,
      subcategory: point.subcategory ? point.subcategory : null,
      color: point.color ? point.color : null,
      colorRedaction: point.colorRedaction ? point.colorRedaction : null,
      annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
      defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
    })

    if (Object.keys(square).length !== 0) {
      const canvas = this._fc
      //console.log('color VVVVVVVVVVVVVVVVVVVVVVVVVVV 333333333333333333333333', color)
      canvas.add(square)
      canvas.renderAll()
    }
  }
  fillPointsFabricReact(point, color) {
    if ((point.type === 'ANNOTATION' || point.type === 2 || point.type === '2') && point.annotation) {
      return new fabric.Text(point.annotation, {
        idd: point.id,
        width: Math.abs(point.x1 - point.x2),
        height: Math.abs(point.y1 - point.y2),
        left: Math.min(point.x1, point.x2),
        top: Math.min(point.y1, point.y2),
        fill: '#000000',
        fontSize: 20,
        backgroundColor: point.backgroundColor ? point.backgroundColor : '#FFFF00',
        hasBorders: false,
        hasControls: false,
        padding: 5,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        lockUniScaling: true,
        annotation: point.annotation,
        annotationType: 2,
        type: 2,
        pagePointSum: point.pagePointSum,
        text: point.annotation ? point.annotation : point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }
    if (point.type === 3 || point.annotationType === 3) {
      return new fabric.Polygon(point.points, {
        idd: point.id,
        fill: 'rgba(0, 0, 0, 0.4)',
        hasBorders: true,
        hasControls: true,
        angle: 0,
        selectable: true,
        padding: 5,
        annotation: point.annotation,
        annotationType: 3,
        type: 3,
        pagePointSum: point.pagePointSum,
        text: point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }

    if ((point.text && point.text !== '!@#draw-by-hand#@!') || (point.annotation && point.annotation !== '!@#draw-by-hand#@!')) {
      if (point.type === 1) {
        //console.log('SKETCH 22222222222222222222222222222222222')
        return new fabric.Polygon(point.points, {
          idd: point.id,
          fill: 'rgba(0, 0, 0, 0.4)',
          hasBorders: true,
          hasControls: true,
          angle: 0,
          selectable: true,
          padding: 5,
          annotation: point.annotation,
          annotationType: 1,
          type: 1,
          pagePointSum: point.pagePointSum,
          text: point.text,
          keyUI: point.keyUI ? point.keyUI : null,
          category: point.category ? point.category : null,
          subcategory: point.subcategory ? point.subcategory : null,
          color: point.color ? point.color : null,
          colorRedaction: point.colorRedaction ? point.colorRedaction : null,
          annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
          defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
        })
      }
    }
      return new fabric.Rect({
        idd: point.id,
        width: Math.abs(point.x1 - point.x2),
        height: Math.abs(point.y1 - point.y2),
        left: Math.min(point.x1, point.x2),
        top: Math.min(point.y1, point.y2),
        fill: color,
        //opacity: 0.3,
        hasRotatingPoint: false,
        strokeStyle: color,
        //strokeOpacity: 0.3,
        //strokeopacity: 0.3,
        selectable: false,
        evented: false,
        annotation: point.annotation,
        annotationType: point.type,
        pagePointSum: point.pagePointSum,
        text: point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
  }
  fillPointsUpdate = (elemstsJson) => {
    const canvas = this._fc
    const alreadyRedactedElementsMixedDefault = []
    const alreadyRedactedElementsMixed = []
    canvas.renderOnAddRemove = false
    let rects = elemstsJson.map((point) => {
      if (point) {
        const color = point.color ? point.color : 'rgba(0,0,0,0.3)'
        if (point.defaultSmartviewOCR) {
          if (!alreadyRedactedElementsMixedDefault.includes(point.text + point.x1 + point.y1)) {
            return this.fillPointsFabricReact(point, color)
          }
          if (point.text) {
            alreadyRedactedElementsMixedDefault.push(point.text + point.x1 + point.y1)
          }
        } else  if (!alreadyRedactedElementsMixed.includes(point.text + point.x1 + point.y1)) {
          return this.fillPointsFabricReact(point, color)
          if (point.text) {
            alreadyRedactedElementsMixed.push(point.text + point.x1 + point.y1)
          }
        }
      }
    })
    if (Object.keys(rects).length !== 0) {
      canvas.add(...rects)
      canvas.renderOnAddRemove = true
      canvas.requestRenderAll()
      //canvas.renderAll()
    }
  }

  fillPoints = (point, color) => {
    //console.log('pagePointSum CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC point', point)
    let square = {}
    square = new fabric.Rect({
      idd: point.id,
      width: Math.abs(point.x1 - point.x2),
      height: Math.abs(point.y1 - point.y2),
      left: Math.min(point.x1, point.x2),
      top: Math.min(point.y1, point.y2),
      fill: color,
      //opacity: 0.3,
      hasRotatingPoint: false,
      strokeStyle: color,
      //strokeOpacity: 0.3,
      //strokeopacity: 0.3,
      selectable: false,
      evented: false,
      annotation: point.annotation,
      annotationType: point.type,
      pagePointSum: point.pagePointSum,
      text: point.text,
      keyUI: point.keyUI ? point.keyUI : null,
      category: point.category ? point.category : null,
      subcategory: point.subcategory ? point.subcategory : null,
      color: point.color ? point.color : null,
      colorRedaction: point.colorRedaction ? point.colorRedaction : null,
      annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
      defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
    })
    if ((point.type === 'ANNOTATION' || point.type === 2 || point.type === '2') && point.annotation) {
      square = new fabric.Text(point.annotation, {
        idd: point.id,
        width: Math.abs(point.x1 - point.x2),
        height: Math.abs(point.y1 - point.y2),
        left: Math.min(point.x1, point.x2),
        top: Math.min(point.y1, point.y2),
        fill: '#000000',
        fontSize: 20,
        backgroundColor: point.backgroundColor ? point.backgroundColor : '#FFFF00',
        hasBorders: false,
        hasControls: false,
        padding: 5,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        lockUniScaling: true,
        annotation: point.annotation,
        annotationType: 2,
        type: 2,
        pagePointSum: point.pagePointSum,
        text: point.annotation ? point.annotation : point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }
    if (point.type === 3 || point.annotationType === 3) {
      //console.log('SELECT 3333333333333333333333333333333333333333333333333333')
      square = new fabric.Polygon(point.points, {
        idd: point.id,
        fill: 'rgba(0, 0, 0, 0.4)',
        hasBorders: true,
        hasControls: true,
        angle: 0,
        selectable: true,
        padding: 5,
        annotation: point.annotation,
        annotationType: 3,
        type: 3,
        pagePointSum: point.pagePointSum,
        text: point.text,
        keyUI: point.keyUI ? point.keyUI : null,
        category: point.category ? point.category : null,
        subcategory: point.subcategory ? point.subcategory : null,
        color: point.color ? point.color : null,
        colorRedaction: point.colorRedaction ? point.colorRedaction : null,
        annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
        defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
      })
    }

    if ((point.text && point.text !== '!@#draw-by-hand#@!') || (point.annotation && point.annotation !== '!@#draw-by-hand#@!')) {
      if (point.type === 1) {
        //console.log('SKETCH 22222222222222222222222222222222222')
        square = new fabric.Polygon(point.points, {
          idd: point.id,
          fill: 'rgba(0, 0, 0, 0.4)',
          hasBorders: true,
          hasControls: true,
          angle: 0,
          selectable: true,
          padding: 5,
          annotation: point.annotation,
          annotationType: 1,
          type: 1,
          pagePointSum: point.pagePointSum,
          text: point.text,
          keyUI: point.keyUI ? point.keyUI : null,
          category: point.category ? point.category : null,
          subcategory: point.subcategory ? point.subcategory : null,
          color: point.color ? point.color : null,
          colorRedaction: point.colorRedaction ? point.colorRedaction : null,
          annotationRedaction: point.annotationRedaction ? point.annotationRedaction : null,
          defaultSmartviewOCR: point.defaultSmartviewOCR ? point.defaultSmartviewOCR : null,
        })
      }
    }

    if (Object.keys(square).length !== 0) {
      const canvas = this._fc
      canvas.add(square)
      canvas.renderAll()
    }
  }

  componentWillUnmount = () =>
    window.removeEventListener('resize', this._resize);

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.parentWidth !== prevState.parentWidth ||
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this._resize()
    }

    if (this.props.tool !== prevProps.tool) {
      this._selectedTool =
        this._tools[this.props.tool] || this._tools[Tool.Pencil]
    }

    //Bring the cursor back to default if it is changed by a tool
    this._fc.defaultCursor = 'default'
    this._selectedTool.configureCanvas(this.props)

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor)
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value)
    }
  };

  removeDuplicatedPoints = (points) => {
    const response = []
    const existing = []
    points.forEach((element) => {
      if (existing.length) {
        existing.forEach((exist) => {
          if (element.x1 !== exist.x1 && element.y2 !== exist.y2) {
            response.push(element)
            existing.push({ x1: element.x1, y2: element.y2 })
          }
        })
      } else {
        response.push(element)
        existing.push({ x1: element.x1, y2: element.y2 })
      }
    })
    return response
  };
  
  addAnnotation = (annotation) => {
    //console.log('addAnnotation ADD DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')
    let topPosition= this.state.selectedObject.top - 25
    if (topPosition < 0) {
      topPosition = this.state.selectedObject.top + this.state.selectedObject.height + 3
    }
    const canvas = this._fc
    const pagePointSum = (this.state.selectedObject) ? this.state.selectedObject.pagePointSum : ''
    const text = new fabric.Text(annotation, {
      left: this.state.selectedObject.left,
      top: topPosition,
      fill: '#000000',
      fontSize: 20,
      backgroundColor: '#FFFF00',
      hasBorders: false,
      hasControls: false,
      padding: 5,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      annotation: annotation,
      annotationType: 2,
      type: 2,
      typeMixed: 13,
      pagePointSum: pagePointSum
    })
    canvas.add(text)
  }

  editAnnotation = (annotation) => {
    //console.log('editAnnotation EDIT DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')
    const canvas = this._fc
    const obj = this.state.selectedObject
    //const relatedObj = this.findByPagePointSum(obj.pagePointSum, obj.annotationType)
    const relatedObj = this.findByPagePointSumAnnotate(obj.pagePointSum, obj.annotationType, obj.pagePointSumAnnotate)
    //console.log('editAnnotation EDIT DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD relatedObj', relatedObj)
    relatedObj.text = annotation
    relatedObj.annotation = annotation
    relatedObj.typeMixed = 13
    this.setState({
      selectedObject: obj
    })
    this._fc.renderAll()
  }

  editLabel = (obj, label) => {
   // console.log('editLabel EDIT DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD 111111111111111111111111111')
    const canvas = this._fc
    const relatedObj = this.findByPagePointSum(obj.pagePointSum, obj.annotationType)
    if (relatedObj) {
      relatedObj.text = label
    }
    this.setState({
      selectedObject: obj
    }, () => this._fc.renderAll())

  }

  repositioningAnnotation = () => {
    const obj = this.state.selectedObject
    //console.log('repositioningAnnotation aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    const relatedObj = this.findByPagePointSum(obj.pagePointSum, obj.annotationType)
    if (relatedObj) {
      if (relatedObj.annotationType === 2) {
        relatedObj.left = obj.left
        relatedObj.top = obj.top - 30
      }
    }
    this._fc.renderAll()
  }

  getNumberOfObject = () => {
    const canvas = this._fc
    const objects = canvas.getObjects()
    return objects.length
  }

  render = () => {
    const { className, style, width, height } = this.props
    const canvasDivStyle = Object.assign(
      {},
      style ? style : {},
      width ? { width: width } : {},
      height ? { height: height } : { height: 512 }
    )

    return (
      <div
        className={className}
        ref={(c) => (this._container = c)}
        style={canvasDivStyle}
      >
        <canvas id={uuid4()} ref={(c) => (this._canvas = c)}>
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </div>
    )
  };
}

export default SketchField
