import React from 'react'
import './NewManualTabs.scss'
import {notification, Tree} from 'antd'
import {
    renderTreeBusiness,
    generateHtmlForBusiness, renderTreeBusinessCopy
} from '../../../utils/SmartBox/SmartBoxMethods'
import {
    removeCategoryBusiness,
    apiSmartBoxUpdateRedactionNumber,
    apiSmartBoxCheckAllCanvasPoints, apiSmartBoxSaveQueryStatusMixed
} from '../../../api/SmartBox'
import Mark from 'mark.js'
import _ from 'lodash'
import swal from 'sweetalert2'
import PropTypes from "prop-types";
import CustomNotificationManager from "../../Common/CustomNotificationManager";

class BusinessCopy extends React.Component {
    state = {
        redactedKeys: [],
        expandedKeys: [ 'select-all' ],
        autoExpandParent: true,
        checkedKeys: [],
        unCheckedKeys:[],
        selectedKeys: [],
        categoryColours: {},
        newBusiness: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        docAnnotations: {},
        selectedChildrenBold: [],
        disableRemoveCategory: false,
        sendStatusOnClearAll: false,
        selectedPgNrBoldBusiness: [],
        itemSelectRedacted: {},
        checkedKeysSaved: [],
        newBusinessOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        setIntervalCheckPdfCanvas: 0,
        responseCheckPdfCanvas: '',
    }

    componentDidMount() {
        if (this.props.isSmartBoxApplication) {
            //this.newFetchDataBusiness(this.props.idDocument)
            //this.newFetchExpandedKeys(this.props.idDocument)
            this.props.onCheckedKeysObjectRefsBoxTerms([])
            // this.onDictionaryCheckedKeysOcr()
            // this.onBusinessCheckedKeys()
        }
    }

    newFetchExpandedKeys = async(docId) => {
        const { docAnnotationsBusiness, categoryColoursBusiness, treeDataAnnotationsBusiness, docAnnotationsBusinessOcr } = this.props
        this.props.onDocAnnotationsList(docAnnotationsBusiness)
        this.setState({
            docAnnotations: docAnnotationsBusiness,
            docAnnotationsRegExOcr: docAnnotationsBusiness
        })
        this.setState({
            docAnnotations: docAnnotationsBusiness,
            docAnnotationsOcr: docAnnotationsBusinessOcr,
            categoryColours: categoryColoursBusiness,
            newBusiness: treeDataAnnotationsBusiness,
            newBusinessOcr: treeDataAnnotationsBusiness,
        }, () => {
            this.renderCategoryExpandedKeys()
        })
    }

    renderCategoryExpandedKeys = () => {
        const { newBusiness, docAnnotations, categoryColours, newBusinessOcr } = { ...this.state }
        let { expandedKeys, checkedKeys } = { ...this.state }
        const { landscape, idDocument, treeDataAnnotationsBusiness } = this.props
        this.props.allChildrenTreeBusiness(treeDataAnnotationsBusiness)
        const checkedKeysUpdateBusiness = []
        if (newBusiness[0].children && newBusiness[0].children.length > 0 && this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 ) {
            newBusiness[0].children.forEach((children1) => {
                children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                    children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                        if ((this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-${children3.index}`)) ||
                            this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-all`)) {
                            checkedKeysUpdateBusiness.push(children3.key)
                        }
                    })
                })
            })
        }
        checkedKeys = checkedKeysUpdateBusiness
        this.setState({
            newBusiness: treeDataAnnotationsBusiness,
            newBusinessOcr: treeDataAnnotationsBusiness,
            autoExpandParent: false,
            expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColours) ],
            checkedKeys
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isNextDocumentId !== this.props.isNextDocumentId) {
            //this.props.onCheckedKeysObjectRefsBoxTerms([])
        }
        // if (prevProps.updateRedactAllStatus !== this.props.updateRedactAllStatus) {
        //     //console.log('checkedKeys EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE')
        //     this.setState({
        //         checkedKeys: this.props.checkedKeysAllBusiness
        //     })
        // }
        // if (prevProps.checkedChildBusiness !== this.props.checkedChildBusiness && this.props.checkedChildBusiness.length > 0) {
        //     //console.log('checkedKeys FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF this.props.checkedChildBusiness', this.props.checkedChildBusiness)
        //     this.setState({
        //         checkedKeys: this.props.checkedChildBusiness
        //     })
        // }
        if (prevProps !== this.props && this.props.removeAllDictionaries) {
            this.state.newBusiness && this.state.newBusiness[0].children.length > 0 && this.state.newBusiness[0].children.forEach((keyColour) => {
                keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                    keyColour2.redacted = false
                })
            })
            // this.setState({
            //     checkedKeys: []
            // })
        }
        if (prevProps.alreadyRedactedKeys.business !== this.props.alreadyRedactedKeys.business) {
            //this.onDictionaryCheckedKeysOcr()
            //this.onBusinessCheckedKeys()
        }

        if (prevProps.getMixedChildrenBusiness !== this.props.getMixedChildrenBusiness && !this.props.statusNextDocumentBusiness) {
            this.props.onGetStatusNextDocumentBusiness(false)
            //this.newFetchDataBusiness(this.props.idDocument)
            ///this.newFetchDataBusiness(this.props.idDocument)
        }
        if (prevProps !== this.props && this.props.treeDataAnnotationsBusinessMixed[0].children.length > 0 && !this.props.statusNextDocumentBusiness) {
            //console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
            this.props.onGetStatusNextDocumentBusiness(false)
            this.newFetchDataBusinessCopy(this.props.idDocument)
            //this.newFetchDataBusiness(this.props.idDocument)
            ///this.newFetchDataBusiness(this.props.idDocument)
        }
        if (prevProps !== this.props && this.props.treeDataAnnotationsBusinessMixed[0].children.length > 0 && this.props.statusNextDocumentBusiness) {
            //console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB 2222222222222222222222222222222222222')
            this.newFetchDataBusinessCopy(this.props.idDocument)
        }
    }

    onBusinessCheckedKeys = () => {
        if (!this.props.isOcr) {
            // if (this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0) {
            //     //console.log('checkedKeys AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
            //     this.setState({
            //         checkedKeys: this.props.alreadyRedactedKeys.business
            //     })
            // }
        }
    }
    onDictionaryCheckedKeysOcr = () => {
        if (this.props.isOcr) {
            const checkedKeysOcr = []
            this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 && this.props.alreadyRedactedKeys.business.forEach((regOcr) => {
                const newKeysOcr = regOcr.substr(0, regOcr.length - 2)
                checkedKeysOcr.push(newKeysOcr.toLowerCase())
            })
            //console.log('checkedKeys BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
            // this.setState({
            //     checkedKeys: checkedKeysOcr
            // })
        }
    }

    newFetchDataBusinessCopy = () => {
        const { docAnnotationsBusiness, categoryColoursBusiness, treeDataAnnotationsBusiness, docAnnotationsBusinessOcr,
            treeDataAnnotationsBusinessMixed, categoryColours, idDocument, categoryColoursBusinessOcr } = this.props
        let { expandedKeys, checkedKeys } = { ...this.state }
        this.props.onDocAnnotationsList(docAnnotationsBusiness)
        this.props.allChildrenTreeBusiness(treeDataAnnotationsBusiness)
        this.props.setTreeDataDict(treeDataAnnotationsBusinessMixed)
        this.setState({
            docAnnotations: docAnnotationsBusiness,
            docAnnotationsRegExOcr: docAnnotationsBusiness,
            categoryColours: categoryColoursBusiness,
            newBusiness: treeDataAnnotationsBusinessMixed,
            expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColoursBusiness).concat(Object.keys(categoryColoursBusinessOcr)) ],
        }, () => {
            let checkedKeysNonOcr = []
            let checkedKeysOcr = []
            const checkedKeysUpdateMount = []
            const checkedKeysUpdateMountOcr = []
            if (this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0) {
                if (treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0) {
                    treeDataAnnotationsBusinessMixed[0].children.forEach((children1) => {
                        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                            children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                                if (!children3.isOcr) {
                                    if ((this.props.alreadyRedactedKeys.business.includes(children3.keyMixtRedaction)) ||
                                        (this.props.alreadyRedactedKeys.business.includes(children3.keyMixtRedaction.toLowerCase()))) {
                                        checkedKeysUpdateMount.push(children3.key)
                                    }
                                }
                            })
                        })
                    })
                }
                checkedKeysNonOcr = checkedKeysUpdateMount
            }

            if (this.props.alreadyRedactedKeys.businessOCR && this.props.alreadyRedactedKeys.businessOCR.length > 0) {
                if (treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 ) {
                    treeDataAnnotationsBusinessMixed[0].children.forEach((children1) => {
                        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                            children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                                if (children3.isOcr) {
                                    if ((this.props.alreadyRedactedKeys.businessOCR.includes(children3.key)) || (this.props.alreadyRedactedKeys.businessOCR.includes(children3.key.toLowerCase()))) {
                                        checkedKeysUpdateMountOcr.push(children3.key)
                                    }
                                }
                            })
                        })
                    })
                }
                checkedKeysOcr = checkedKeysUpdateMountOcr
            }
            checkedKeys = checkedKeysNonOcr.concat(checkedKeysOcr)
            this.props.onCheckedKeysAllMixedBusiness(checkedKeys)
            this.setState({
                checkedKeys
            })
        })

    }

    newFetchDataBusiness = async(docId) => {
        const { docAnnotationsBusiness, categoryColoursBusiness, treeDataAnnotationsBusiness, docAnnotationsBusinessOcr } = this.props
        this.props.onDocAnnotationsList(docAnnotationsBusiness)
        this.setState({
            docAnnotations: docAnnotationsBusiness,
            docAnnotationsRegExOcr: docAnnotationsBusiness
        })
        this.setState({
            docAnnotations: docAnnotationsBusiness,
            docAnnotationsOcr: docAnnotationsBusinessOcr,
            //docAnnotations: { silviu :['06/06/202316:38', '06/06/2023,16:38']},
            categoryColours: categoryColoursBusiness,
            newBusiness: treeDataAnnotationsBusiness,
            newBusinessOcr: treeDataAnnotationsBusiness,
            //categoryColours: { silviu: "rgba(192, 125, 81, 0.3)" }
        }, () => {
            this.renderCategoryBusinessUpdate()
        })
    }

    renderCategoryBusinessUpdate = () => {
        const { newBusiness, docAnnotations, categoryColours, newBusinessOcr } = { ...this.state }
        let { expandedKeys, checkedKeys } = { ...this.state }
        const { landscape, idDocument, treeDataAnnotationsBusiness } = this.props
        // const newCategoryBusiness = []
        // for (const categoryParent of Object.keys(docAnnotations)) {
        //   newCategoryBusiness.push(categoryParent)
        // }

        this.props.allChildrenTreeBusiness(treeDataAnnotationsBusiness)
        // if (!this.props.isOcr) {
        //   this.props.setTreeDataDict(treeDataAnnotationsBusiness)
        // }
        const checkedKeysUpdateBusiness = []
        if (newBusiness[0].children && newBusiness[0].children.length > 0 && this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 ) {
            newBusiness[0].children.forEach((children1) => {
                children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                    children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                        if ((this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-${children3.index}`)) ||
                            this.props.alreadyRedactedKeys.business.includes(`${children3.name.toLowerCase()}-all`)) {
                            checkedKeysUpdateBusiness.push(children3.key)
                        }
                    })
                })
            })
        }
        checkedKeys = checkedKeysUpdateBusiness
        this.setState({
            newBusiness: treeDataAnnotationsBusiness,
            newBusinessOcr: treeDataAnnotationsBusiness,
            autoExpandParent: false,
            expandedKeys: [ ...expandedKeys, ...Object.keys(categoryColours) ],
            checkedKeys
        }, () => {
            if (!this.props.isOcr) {
                this.props.setTreeDataDict(newBusiness)
                //this.onBackgroundDictionary(newBusiness)
            }
            if (this.props.isOcr) {
                //this.props.onGetDictionaryOcrUpdate(treeDataAnnotationsBusiness)
                this.checkDocAnnotationCategoryOcrBusiness()
            }
        })
    }

    onBackgroundDictionary = (dictionaryList) => {
        //const { dictionaryList } = this.state
        //const { dictionaryList } = this.props
        dictionaryList && dictionaryList[0].children.length > 0 && dictionaryList[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                    if (keyColour3 && keyColour3.key) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            element.style.background = keyColour3.backgroundColor
                            //element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor

                        }
                    }
                })
            })
        })
    }

    checkDocAnnotationCategoryOcrBusiness = () => {
        const { docAnnotationsOcr, newBusinessOcr } = this.state
        newBusinessOcr && newBusinessOcr[0].children && newBusinessOcr[0].children.length > 0 && newBusinessOcr[0].children.forEach((children1) => {
            if (docAnnotationsOcr && Object.keys(docAnnotationsOcr).length) {
                Object.keys(docAnnotationsOcr).forEach((category) => {
                    let title = category && category.replace('doc_annotate_', '')
                    title = title && title.replace('_', ' ')
                    if (children1.key === title) {
                        docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
                            const parentAllCategory = {
                                backgroundColor: children1.backgroundColor,
                                children: [],
                                key: `${ nameParent }`,
                                name: nameParent,
                                index: index,
                                title: nameParent,
                                pageNumber: '1',
                                isOcr: this.props.isOcr
                            }
                            children1.children.push(parentAllCategory)
                        })
                    }
                })
            }

        })
        this.setState({
            newBusiness: newBusinessOcr
        })
    }

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: true,
        })
    };

    onSelect = (selectedKeys, info) => {
        this.setState({ selectedKeys })
    }

    extractLeafKeys = (node) => {
        const { unCheckedKeys } = this.state
        if (node.children && node.children.length) {
            node.children.forEach((item) => {
                this.extractLeafKeys(item.props)
            })
        } else {
            if (!unCheckedKeys.includes(node['data-key'])) {
                unCheckedKeys.push(node['data-key'])
            }
            this.setState({ unCheckedKeys })
        }
    }

    onCheck = (checkedKeys, { checkedNodes, checked, node }) => {
        const newFilterCategory = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent')
        const { unCheckedKeys, sendStatusOnClearAll } = this.state

        if (this.props.isSmartBoxApplication) {
            this.props.checkOnClearAllBusiness(sendStatusOnClearAll)
        }

        if (!checked) {
            this.extractLeafKeys(node.props)
        } else {
            if (unCheckedKeys.length) {
                unCheckedKeys.forEach((item, index) => {
                    if (checkedKeys.includes(item)) {
                        unCheckedKeys.splice(index, 1)
                    }
                })
            }
        }
        if (newFilterCategory.length > 0) {
            this.setState({
                disableRemoveCategory: true
            })
        } else {
            this.setState({
                disableRemoveCategory: false
            })
        }
        const nodesBusiness = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.key)
        this.props.onCheckedKeysObjectRefsBoxTerms(nodesBusiness)
        this.setState({
            //checkedKeys,
            unCheckedKeys,
            checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord),
            checkedKeysObjectRefsOcr: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isOcr && obj.isSearchedWord ),
            checkedKeysObjectRefsOcrUpdateFilter: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isOcr && obj.isSearchedWord && !this.state.checkedKeys.includes(obj.key)),
            checkedKeysObjectRefsNonOcr: checkedNodes.map((node) => node.props.dataRef).filter((obj) => !obj.isOcr && obj.isSearchedWord),
            checkedKeysObjectRefsNonOcrUpdateFilter: checkedNodes.map((node) => node.props.dataRef).filter((obj) => !obj.isOcr && obj.isSearchedWord && !this.state.checkedKeys.includes(obj.key)),
            checkedKeysForDelete: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.identifier === 'category-parent')
        })
    };

    onCheckDisableSelectAll = () => {
        return false
    }

    removeCategoryBusiness = (e) => {
        e.stopPropagation()
        const { expandedKeys, categoryColours, checkedKeysForDelete, newBusiness, checkedKeys } = { ...this.state }
        const newKeysDelete = []
        const newListNameRemove = []
        const keysDeleteBusiness = []
        let checkKeysDeleteFinally = []
        checkKeysDeleteFinally = checkedKeys
        checkedKeysForDelete && checkedKeysForDelete.forEach((check) => {
            keysDeleteBusiness.push(check.key)
            check && check.children.forEach((check2) => {
                keysDeleteBusiness.push(check2.key)
                check2 && check2.children.forEach((check3) => {
                    keysDeleteBusiness.push(check3.key)
                })
            })
        })
        keysDeleteBusiness && keysDeleteBusiness.length > 0 && keysDeleteBusiness.forEach((newCheck) => {
            checkKeysDeleteFinally = checkKeysDeleteFinally.filter((check) => check !== newCheck)
        })
        for (const checkedKey of checkedKeysForDelete.map((cKey) => cKey.key)) {
            const aiAnnotations = document.querySelectorAll(`.${ checkedKey }`)
            for (const aiAnnotate of aiAnnotations) {
                aiAnnotate.style.background = 'transparent'
            }
            const options = {
                className: checkedKey
            }
            newKeysDelete.push(checkedKey)
            const instance = new Mark(document.querySelector('#pdf-page'))
            instance.unmark(options)
            newBusiness[0].children && newBusiness[0].children.map((check) => {
                if (check.key === checkedKey) {
                    check.children && check.children.map((childrenKey) => {
                        newListNameRemove.push(childrenKey.name)
                        return true
                    })
                    return true
                }
                return true
            })

            newBusiness[0].children = newBusiness[0].children.filter((child) => child.key !== checkedKey)

            const data = {
                id: this.props.idDocument,
                listKeysDelete: newListNameRemove
            }

            if (this.props.isSmartBoxApplication) {
                removeCategoryBusiness(data).then(() => {
                }).catch((error) => {
                    if (error?.response?.status === 504) {
                        CustomNotificationManager.cleanBuffer('warning', '')
                    }else{
                        console.log(error)
                    }
                })
            }
            // if (checkKeysDeleteFinally && checkKeysDeleteFinally.length === 1 && checkKeysDeleteFinally[0] === 'select-all') {
            //     //console.log('checkedKeys ccccccccccccccccccccccccccccccccccccccccccc')
            //     this.setState({
            //         checkedKeys: [],
            //     })
            // } else {
            //     this.setState({
            //         checkedKeys: checkKeysDeleteFinally,
            //     })
            // }
            this.setState({
                categoryColours: _.omit(categoryColours, checkedKey),
                expandedKeys: expandedKeys.filter((eKey) => eKey !== checkedKey),
                autoExpandParent: false,
                newBusiness,
                checkedKeysForDelete: [],
                disableRemoveCategory: false
            })
        }
    }

    expandHideCategory = (e, key, action) => {
        e.stopPropagation()
        let { expandedKeys } = { ...this.state }
        if (action === 'add') {
            expandedKeys.push(key)
        } else {
            expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
        }
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        })
    }

    findElementBusiness(selectedKeys, node) {
        let { expandedKeys } = { ...this.state }
        const checkNodeBusiness = []
        if (node && node.props.dataRef.key) {
            checkNodeBusiness.push(node.props.dataRef.key)
            this.setState({
                selectedChildrenBold: checkNodeBusiness,
                selectedPgNrBoldBusiness: checkNodeBusiness
            })
        }
        if (node.props.dataRef.key.includes('found-parent-all') || node.props.dataRef.identifier === 'category-parent') {
            if (!expandedKeys.includes(node.props.dataRef.key)) {
                expandedKeys.push(node.props['data-key'])
                this.setState({
                    expandedKeys,
                    autoExpandParent: false
                })
            } else {
                expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
                this.setState({
                    expandedKeys,
                    autoExpandParent: false
                })
            }
        } else {
            this.setState({
                selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
            }, () => {
                if (this.state.selectedWord) {
                    const { backgroundColor, pageNumber } = node.props.dataRef
                    const oldElement = document.querySelector('#matched-word--current')
                    if (oldElement) {
                        oldElement.removeAttribute('id')
                    }
                    if (+pageNumber !== +this.props.pageNumber) {
                        //this.props.changePage(+pageNumber)
                        this.props.changePageMixed(+pageNumber)
                    }
                    const element = document.getElementsByClassName(this.state.selectedWord)
                    if (element && element.length > 0) {
                        element[0].id = 'matched-word--current'
                        element[0].style.borderColor = backgroundColor.replace('0.3', '1')
                        element[0].scrollIntoView(false)
                    }
                } else {
                    const element = document.querySelector('#matched-word--current')
                    element.removeAttribute('id')
                }
            })
        }
    }

    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }

    onCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
            this.setState({
                responseCheckPdfCanvas: response.data
            })
            if(response.data === 'Processing') {
                this.setIntervalCheckAllCanvasPoints()
            } else {
                this.props.fetchSmartBoxDataCanvasOcr()
            }
        })
    }

    setIntervalCheckAllCanvasPoints = () => {
        const { responseCheckPdfCanvas} = this.state
        let { setIntervalCheckPdfCanvas} = this.state
        if (setIntervalCheckPdfCanvas === 0) {
            if (responseCheckPdfCanvas === 'Processing') {
                setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
                this.setState({
                    setIntervalCheckPdfCanvas
                })
            } else {
                clearInterval(setIntervalCheckPdfCanvas)
            }
        }
    }

    callBackCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        if(responseCheckPdfCanvas === 'Processing') {
            apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
                this.setState({
                    responseCheckPdfCanvas: response.data
                })
                if (response.data === 'Finished') {
                    this.props.fetchSmartBoxDataCanvasOcr()
                }
            })
        }
    }
    displayCheckKeysNonDeleteBusiness() {
        const { newListKeysBusiness } = this.props
        if (newListKeysBusiness) {
            return newListKeysBusiness
        }
    }

    onDisplayDifferenceColourOnActiveTab = () => {
        const { newBusiness } = this.state
        const { activeKeyTab, activeKey } = this.props
        newBusiness && newBusiness[0].children.map((keyColour) => {
            const aiAnnotations = document.querySelectorAll(`.${ keyColour.key }`)
            for (const aiAnnotate of aiAnnotations) {
                if (activeKeyTab === 'business' && activeKey === 'manualTab') {
                    aiAnnotate.style.background = keyColour.backgroundColor
                    aiAnnotate.style.borderColor = keyColour.backgroundColor ? keyColour.backgroundColor.replace('0.3', '1') : keyColour.backgroundColor
                } else {
                    aiAnnotate.style.background = 'transparent'
                    aiAnnotate.style.borderColor = 'initial'
                }
            }
            return true
        })
    }

    onFindElementTreeBusiness = (e, item) => {
        e.stopPropagation()
        let checkedKeysRef = []
        let checkedKeysRefOcr = []
        if (!item.isOcr) {
            if (item && item.children.length > 0) {
                item.children.forEach((item) => {
                    checkedKeysRef.push(item)
                })
            } else {
                checkedKeysRef.push(item)
            }
            if (item && item.children && item.children.length > 0) {
                this.props.onCheckedKeysObjectRefsBoxTerms(checkedKeysRef)
            } else {
                this.findElementByClassChildren(item)
            }
            this.setState({
                selectedChildrenBold: item.key
            })
        } else if (item.isOcr) {
                if (item && item.children.length > 0) {
                    item.children.forEach((item) => {
                        checkedKeysRefOcr.push(item)
                    })
                } else {
                    checkedKeysRefOcr.push(item)
                }
            if (item && item.children && item.children.length > 0) {
                this.props.onCheckedKeysObjectRefs(checkedKeysRefOcr)
            } else {
                this.findElementByClassChildrenOcr(item)
            }
            this.setState({
                selectedChildrenBold: item.key
            })
        }
    }

    findElementByClassChildren(className) {
        const element = document.getElementsByClassName(className.key)
        const oldElement = document.querySelector('#matched-word--current')
        if (className) {
            if (oldElement) {
                oldElement.removeAttribute('id')
            }
            if (+className.pageNumber !== +this.props.pageNumber) {
                //this.props.changePage(+className.pageNumber)
                this.props.changePageMixed(+className.pageNumber)
            }
            if (element && element.length > 0) {
                element[0].id = 'matched-word--current'
                element[0].style.borderColor = className.backgroundColor.replace('0.3', '1')
                element[0].scrollIntoView(false)
            }
        } else {
            const element = document.querySelector('#matched-word--current')
            element.removeAttribute('id')
        }
    }
    findElementByClassChildrenOcr(className) {
        if (+className.pageNumber !== +this.props.pageNumber) {
            this.props.changePageMixed(+className.pageNumber)
        }
    }
    onCheckSelectedTreeRedacted = () => {
        const { lockSmartViewChanges } = this.props

        if(!lockSmartViewChanges) {
            this.setState({
                disabledCheckBoxTree: true
            }, () => {
                this.onRedactAiTagCopy()
            })
        }
    }

    onRedactAiTagCopy = () => {
        const { checkedKeysObjectRefs, checkedKeysObjectRefsOcr, checkedKeysObjectRefsNonOcr } = this.state
        const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        this.drawMatchedWordsCopy(sortedByPageItems.map((item) => item.key), sortedByPageItems, checkedKeysObjectRefsNonOcr, checkedKeysObjectRefsOcr)
    }

    drawMatchedWordsCopy = (classList, sortedByPageItems, checkedKeysObjectRefsNonOcr, checkedKeysObjectRefsOcr) => {
        const { newBusiness, checkedKeys, checkedKeysSaved, checkedKeysObjectRefsNonOcrUpdateFilter, checkedKeysObjectRefsOcrUpdateFilter } = this.state
        let { unCheckedKeys } = this.state
        let { listCategories } = this.props
        const { treeDataAnnotationsBusinessMixed } = this.props
        let checkKeysRedacted = []
        const selectedKeysOcr = []
        const classListMixed = []
        this.props.onStatusRemoveDictionaries(false)
        const selectRedactionBusinessOcr = []
        const selectRedactionBusinessOcrFilter = []
        const checkedKeysObjectAll = []
        checkedKeysObjectRefsOcr && checkedKeysObjectRefsOcr.length > 0 && checkedKeysObjectRefsOcr.forEach((elem) => {
            selectRedactionBusinessOcrFilter.push(elem.key)
        })
        // if (this.props.isOcr) {
        //     this.state.newBusinessOcr && this.state.newBusinessOcr[0].children.length > 0 && this.state.newBusinessOcr[0].children.forEach((item) => {
        //         item && item.children && item.children.length > 0 && item.children.forEach((item2) => {
        //             if (item2 !== undefined) {
        //                 checkedKeys && checkedKeys.forEach((key) => {
        //                     if (item2.key === key) {
        //                         selectedKeysOcr.push(item2.key)
        //                     }
        //                 })
        //             }
        //         })
        //     })
        // }
        const allWordsRedacted = []
        const allWordsRedactedOcr = []
        treeDataAnnotationsBusinessMixed && treeDataAnnotationsBusinessMixed[0].children && treeDataAnnotationsBusinessMixed[0].children.length > 0 && treeDataAnnotationsBusinessMixed[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                // if (item2.children.length === 0 && item2.isOcr) {
                //     if (selectRedactionBusinessOcrFilter.includes(item2.key)) {
                //         selectRedactionBusinessOcr.push({
                //             word: item2.key,
                //             category: 'Dictionaries',
                //             subcategory: item.key,
                //         })
                //         allWordsRedactedOcr.push(item2)
                //     }
                // }
                item2 && item2.children.forEach((item3) => {
                    checkedKeysObjectAll.push(item3)
                    if (!item3.isOcr) {
                        if (classList.includes(item3.key)) {
                            item3.categoryName = 'Dictionaries'
                            item3.subcategoryName = item.key
                            allWordsRedacted.push(item3)
                            classListMixed.push(item3.key)
                        }
                    }
                    if (item3.isOcr) {
                        if (selectRedactionBusinessOcrFilter.includes(item3.key)) {
                            selectRedactionBusinessOcr.push({
                                word: item3.key,
                                category: 'Dictionaries',
                                subcategory: item.key,
                            })
                            allWordsRedactedOcr.push(item3)
                        }
                    }
                })
            })
        })
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                    if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                        item.categoryColor = elem2.subcategoryColorWithAlpha
                    }
                })
            })
        })
        const existingRedactedKeysNew = new Set([ ...this.state.redactedKeys, ...classList ])
        const newUnCheckedKeysRemoved = []
        if (unCheckedKeys && unCheckedKeys.length) {
            unCheckedKeys.forEach((item, index) => {
                if (existingRedactedKeysNew.has(item)) {
                    existingRedactedKeysNew.delete(item)
                }
                if (!classList.includes(item)) {
                    newUnCheckedKeysRemoved.push(item)
                }
            })
        }
        const numberRedactNonOcr = []
        const numberRedactOcr = []
        checkedKeysObjectRefsNonOcrUpdateFilter && checkedKeysObjectRefsNonOcrUpdateFilter.length > 0 && checkedKeysObjectRefsNonOcrUpdateFilter.forEach((elem) => {
            numberRedactNonOcr.push(elem.key)
        })
        checkedKeysObjectRefsOcrUpdateFilter && checkedKeysObjectRefsOcrUpdateFilter.length > 0 && checkedKeysObjectRefsOcrUpdateFilter.forEach((elem) => {
            numberRedactOcr.push(elem.key)
        })
        checkKeysRedacted = numberRedactNonOcr.concat(numberRedactOcr)
        const keysRedacted = checkKeysRedacted
        const textMessageRedact = newUnCheckedKeysRemoved && newUnCheckedKeysRemoved.length > 0 ? `Remove ${ newUnCheckedKeysRemoved.length } selected redactions?` : keysRedacted && keysRedacted.length === 1 ? `Redact ${ keysRedacted.length } selected?` : `Redact all ${ keysRedacted.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title">${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: newUnCheckedKeysRemoved && newUnCheckedKeysRemoved.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                this.props.getStatusElementRedaction('elementDict')
                this.setState({
                    //checkedKeys,
                    checkedKeysSaved: checkedKeys
                })
            } else {
                // this.setState({
                //     checkedKeys: checkedKeysSaved
                // })
            }
            if (result.value) {
                // this.props.toggleLoadingProcess(true, 'redact')

                //de decomentat
                if (this.props.isSmartBoxApplication && unCheckedKeys.length === 0) {
                    this.onUpdateRedactionNumber(keysRedacted)
                }
                let existingRedactedKeys= new Set([...this.state.redactedKeys, ...classList])
                const newUnCheckedKeys = []
                if (unCheckedKeys && unCheckedKeys.length) {
                    unCheckedKeys.forEach( (item) => {
                        if (existingRedactedKeys.has(item)) {
                            existingRedactedKeys.delete(item)
                        }
                        if (!classList.includes(item)) {
                            newUnCheckedKeys.push(item)
                        }
                    })
                }
                const objectListRedact = {
                    allWordsRedacted: allWordsRedacted,
                    allWordsRedactedOcr: allWordsRedactedOcr,
                    classList: classList,
                    classListMixed: classListMixed,
                    selectAllItemsBusiness: checkedKeysObjectRefsNonOcrUpdateFilter,
                    selectAllItemsBusinessSaveKeys: checkedKeysObjectRefsNonOcr,
                    checkedKeysObjectRefsNonOcrUpdateFilter: checkedKeysObjectRefsNonOcrUpdateFilter,
                    selectAllItemsBusinessOcr: checkedKeysObjectRefsOcrUpdateFilter,
                    selectAllItemsBusinessOcrSaveKeys: checkedKeysObjectRefsOcr,
                    checkedKeysObjectRefsOcrUpdateFilter: checkedKeysObjectRefsOcrUpdateFilter,
                    selectRedactionBusinessOcr: selectRedactionBusinessOcr,
                    newUnCheckedKeys: newUnCheckedKeys,
                    checkedKeysObjectAll: checkedKeysObjectAll,
                }
                //de decomentat
                this.onSaveQueryStatusMixed(objectListRedact)
                this.setState({
                    redactedKeys: existingRedactedKeys
                }, () => {
                    //this.props.drawAllMatchedWordsByClassList(classList, newUnCheckedKeys, 'dictionaries', allWordsRedacted)
                    this.props.drawRedactAllMatchedWordsMixed(classList, classListMixed, newUnCheckedKeys, 'business', objectListRedact)
                    this.setState({unCheckedKeys: []})
                })
            }
        })
    }

    onSaveQueryStatusMixed = (objectListRedact) => {

        const keyListWordNonOcrDictionary = []
        let keyListWordNonOcr = []
        let keyListWordNonOcrRegex = []
        const keyListWordOcrDictionary = []
        let keyListWordOcr = []
        let keyListWordOcrRegex = []

        let keyListWordNonOcrUnChecked = []
        let keyListWordOcrUnChecked = []
        if (objectListRedact.selectAllItemsBusinessSaveKeys && objectListRedact.selectAllItemsBusinessSaveKeys.length > 0) {
            objectListRedact.selectAllItemsBusinessSaveKeys.forEach((dict) => {
                //keyListWordNonOcrDictionary.push(`${ dict.key } => ${ dict.name } => ${ dict.pageNumber }`)
                keyListWordNonOcrDictionary.push(dict.keyMixtRedaction)
            })
        }
        if (objectListRedact.selectAllItemsBusinessOcrSaveKeys && objectListRedact.selectAllItemsBusinessOcrSaveKeys.length > 0) {
            objectListRedact.selectAllItemsBusinessOcrSaveKeys.forEach((dict) => {
                keyListWordOcrDictionary.push(dict.key)
            })
        }
        let updateStatus = false
        if ((keyListWordNonOcrDictionary && keyListWordNonOcrDictionary.length > 0)) {
            updateStatus = true
        } else {
            updateStatus = false
        }

        if (objectListRedact.checkedKeysObjectAll && objectListRedact.checkedKeysObjectAll.length > 0) {
            objectListRedact.checkedKeysObjectAll.forEach((elemCheck) => {
                if (objectListRedact.newUnCheckedKeys && objectListRedact.newUnCheckedKeys.length > 0) {
                    if (elemCheck.isOcr && objectListRedact.newUnCheckedKeys.includes(elemCheck.key)) {
                        keyListWordOcrUnChecked.push(elemCheck.key)
                    }
                    if (!elemCheck.isOcr && objectListRedact.newUnCheckedKeys.includes(elemCheck.key)) {
                        keyListWordNonOcrUnChecked.push(elemCheck.keyMixtRedaction)
                    }
                }
            })
        }
        if (keyListWordOcrUnChecked && keyListWordOcrUnChecked.length > 0) {
            keyListWordOcr = this.props.alreadyRedactedKeys.standardOCR && this.props.alreadyRedactedKeys.standardOCR.length > 0 ? this.props.alreadyRedactedKeys.standardOCR.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
            keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR && this.props.alreadyRedactedKeys.regexOCR.length > 0 ? this.props.alreadyRedactedKeys.regexOCR.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
        } else {
            keyListWordOcr = this.props.alreadyRedactedKeys.standardOCR
            keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR
        }
        if (keyListWordNonOcrUnChecked && keyListWordNonOcrUnChecked.length > 0) {
            keyListWordNonOcr = this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ? this.props.alreadyRedactedKeys.standard.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
            keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex && this.props.alreadyRedactedKeys.regex.length > 0 ? this.props.alreadyRedactedKeys.regex.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
        } else {
            keyListWordNonOcr = this.props.alreadyRedactedKeys.standard
            keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex
        }
        const saveAnnotationWithStateDTO = {
            keyWordOcr: keyListWordOcr,
            keyWordOcrRegex: keyListWordOcrRegex,
            keyWordOcrDictionary: keyListWordOcrDictionary,
            keyWordNonOcr: keyListWordNonOcr,
            keyWordNonOcrRegex: keyListWordNonOcrRegex,
            keyWordNonOcrDictionary: keyListWordNonOcrDictionary,
            updateRedactions: updateStatus,
            idDocument: this.props.idDocument
        }

        apiSmartBoxSaveQueryStatusMixed(saveAnnotationWithStateDTO).then((response) => {
            this.props.onGetRedactedKeysMixed()
        })
    }

    onDisplayRedaction = (e, item) => {
        this.setState({
            itemSelectRedacted: item
        })
    }
    onLeaveMouseRedaction = () => {
        this.setState({
            itemSelectRedacted: {}
        })
    }
    onDefaultCategoryTop = (e) => {
        e.stopPropagation()
        return true
    }
    onShowVisibleCatDictionary = () => {
        const { newBusiness } = this.state
        const { showVisibleDictionary } = this.props
        newBusiness && newBusiness[0].children.length > 0 && newBusiness[0].children.forEach((keyColour) => {
            keyColour && keyColour.children.length > 0 && keyColour.children.forEach((keyColour2) => {
                keyColour2 && keyColour2.children.length > 0 && keyColour2.children.forEach((keyColour3) => {
                    // const aiAnnotations = document.querySelectorAll(`.${ keyColour3.key }`)
                    if (keyColour3 && keyColour3.key && showVisibleDictionary !== null) {
                        const aiAnnotations = document.getElementsByClassName(keyColour3.key)
                        for (const element of aiAnnotations) {
                            if (showVisibleDictionary === false) {
                                element.style.background = keyColour3.backgroundColor
                                element.style.borderColor = keyColour3.backgroundColor ? keyColour3.backgroundColor.replace('0.3', '1') : keyColour3.backgroundColor
                            } else if (showVisibleDictionary === true) {
                                element.style.background = 'transparent'
                                element.style.borderColor = 'initial'
                            }
                        }
                    }
                })
            })
        })
    }
    render() {
        const { expandedKeys,
            redactedKeys,
            categoryColours,
            newBusiness,
            selectedChildrenBold,
            disableRemoveCategory,
            itemSelectRedacted, docAnnotations } = this.state
        let { checkedKeys } = this.state
        const { verificationOnClickClearAllBusiness, isOcr, lockSmartViewChanges } = this.props
        //this.onDisplayDifferenceColourOnActiveTab()
        // console.log('checkedKeys ---- RENDER Business', checkedKeys)
        // console.log('newBusiness ---- RENDER Business', newBusiness)
        // if (verificationOnClickClearAllBusiness && this.props.isSmartBoxApplication) {
        //     checkedKeys = this.displayCheckKeysNonDeleteBusiness()
        // }
        //this.onShowVisibleCatDictionary()
        const treeSaved = {}
        if (checkedKeys && checkedKeys.length > 0) {
            for (let element of checkedKeys) {
                element = element.substring(0, element.lastIndexOf('-'))
                if (treeSaved[element]) {
                    treeSaved[element] += 1
                } else {
                    treeSaved[element] = 1
                }
            }
        }
        // console.log('checkedKeys --- RENDER BUSINESSS', checkedKeys)
        // console.log('treeSaved --- RENDER BUSINESSS', treeSaved)
        return (
            <div className={ newBusiness[0].children && newBusiness[0].children.length > 0 ? "new-tabs-page update-list" : '' }>
                <div className="new-tab-container">
                    {/*<div className="new-tab-container__title-tab">Business</div>*/}
                    <div className="new-tab-container__content-tab image-tags-scroll ai-tags-scroll">
                        <Tree
                            checkable
                            onExpand={ this.onExpand }
                            defaultExpandAll={ true }
                            expandedKeys={ expandedKeys }
                            defaultExpandedKeys={ expandedKeys }
                            autoExpandParent={ this.state.autoExpandParent }
                            onCheck={ newBusiness && newBusiness[0].children.length === 0 ? this.onCheckDisableSelectAll : this.onCheck }
                            checkedKeys={ checkedKeys }
                            onSelect={ (selectedKeys, { node }) => this.findElementBusiness(selectedKeys, node) }
                            selectedKeys={ this.state.selectedKeys }

                        >
                            { renderTreeBusinessCopy(
                                newBusiness[0].children,
                                expandedKeys,
                                this.expandHideCategory,
                                redactedKeys,
                                categoryColours,
                                this.removeCategoryBusiness,
                                selectedChildrenBold,
                                checkedKeys,
                                disableRemoveCategory,
                                this.onDisplayRedaction,
                                itemSelectRedacted,
                                this.onLeaveMouseRedaction,
                                this.onCheckSelectedTreeRedacted,
                                this.onFindElementTreeBusiness,
                                this.onDefaultCategoryTop,
                                treeSaved,
                                isOcr) }
                        </Tree>
                    </div>
                </div>

            </div>
        )
    }
}

BusinessCopy.propTypes = {
    isDocumentReady: PropTypes.bool.isRequired,
    toggleLoadingProcess: PropTypes.func
}

export default BusinessCopy