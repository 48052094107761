//import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './HeaderNavigationBox.scss'
//import imgFolderParent from '../../assets/images/Box 80x80.png'
import imgFolderParentNegative from '../../assets/box/box_negative_20x20.png'
import imgFolderParentNegativeEmpty from '../../assets/box/box_empty_negative_20x20.png'
import imgFolderParentNegativeProcessing from '../../assets/box/box_processing_negative_20x20.png'
import { Progress, Tooltip, Spin } from 'antd'
import { connect } from 'react-redux'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import {
  setBoxWorkflowDashboard,
  setSmartBoxProgressCleanDeleteDuplicates,
  setSmartBoxResponseDeleteDuplicates,
  setSmartBoxSelectedDuplicateFile,
  setSmartReprocessingFileDelete
} from '../../actions/smartBoxActions'
import CullingModal from "./CullingModal";
import DataMappingModal from './DataMappingModal'
import {
  apiGetStatusStarReport, getBoxInfoFromSubFolderOrFile,
  getFolderFilesNumber,
  getTicketWorkflowAndHeaderDetails,
  sendReportStar
} from "../../api/SmartBox";
import withRouter from '../../private-routers/withRouter'
import cookie from "react-cookies";

export class HeaderNavigationBox extends Component {

  state = {
    onHoverMenuNavigation: false,
    displayProgressDeleteDeduplicate: false,
    baseBoxHeader: {},
    boxNameBase: '',
    displayCullingStatus: false,
    displayDataMappingStatus:false,
    boxNameBaseId: '',
    statusDotsReport: false,
    getReportStatus: false,
    boxFilesCount: null,
    hoverRequestDetail: false,
    idParentBox: null
  }

  componentDidMount() {
    this.props.setSmartReprocessingFileDelete(false)
    this.updateBaseParentBox()
    this.onGetFilesNumberInBox()
    this.onGetBoxParentInfo()
    cookie.remove('checkCollectorPageUploadHistoryData', { path: '/' })
    cookie.remove('emailDisclosureEmailTrackingSteps', { path: '/' })
    cookie.remove('openTicketArchive', { path: '/' })
    //this.onGetSubHeaderBrandingColours()
    //this.onSendStatusStarReport()
    setTimeout(() => {
      this.onSendStatusStarReport()
    }, 3000);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.smartBoxResponseDeleteDuplicates.responseApi === true) {
      this.props.setSmartBoxResponseDeleteDuplicates('', '')
    }
    if (prevProps !== this.props && this.props.smartBoxProgressCleanDeleteDuplicates.statusApi === true) {
      this.props.setSmartBoxProgressCleanDeleteDuplicates('', '')
    }
    if (prevProps !== this.props && this.props.smartBoxBaseDataTree.length > 0) {
      this.updateBaseParentBox()
    }
    // if (prevProps !== this.props && this.props.filesTable.length > 0) {
      //this.onSendStatusStarReport()
    // }
  }

  onGetBoxParentInfo = () => {
    this.setState({
      folderId: this.props.params.id
    }, () => {
      getBoxInfoFromSubFolderOrFile(this.state.folderId).then((response) => {
        this.setState({
          idParentBox: response.data.boxId
        })
      })
    })
  }
  onGetSubHeaderBrandingColours = () => {
    const { client } = this.props
    const { brandingProfileColors } = client
    brandingProfileColors && brandingProfileColors.colorsDTOS && brandingProfileColors.colorsDTOS.length > 0 && brandingProfileColors.colorsDTOS.forEach((brand) => {
      switch (brand.colorCode) {
        case 'sub-background':
          if (document.getElementsByClassName('header-navigation-contain')[0]) {
            document.getElementsByClassName('header-navigation-contain')[0].style.backgroundColor = brand.color;
          }
          break
        case 'sub-background-hover':

          break
        case 'sub-nav-body-font':
          if (document.getElementsByClassName('header-navigation-contain')[0]) {
            document.getElementsByClassName('header-navigation-contain')[0].style.color = brand.color;
          }
          if (document.getElementsByClassName('icon-v19-share-1')[0]) {
            document.getElementsByClassName('icon-v19-share-1')[0].style.color = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('icon-v19-share-1').length; i++) {
            const navButtons = document.getElementsByClassName('icon-v19-share-1')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          for (let i = 0; i < document.getElementsByClassName('header-navigation-item').length; i++) {
            const navButtons = document.getElementsByClassName('header-navigation-item')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }

          for (let i = 0; i < document.getElementsByClassName('enumeration-header-elements').length; i++) {
            const navButtons = document.getElementsByClassName('enumeration-header-elements')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          for (let i = 0; i < document.getElementsByClassName('content-box-name-update').length; i++) {
            const navButtons = document.getElementsByClassName('content-box-name-update')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        case 'sub-nav-body-font-hover':
          // if (document.getElementsByClassName('active-navigation-box')[0]) {
          //   document.getElementsByClassName('active-navigation-box')[0].style.color = brand.color;
          // }
          for (let i = 0; i < document.getElementsByClassName('active-navigation-box').length; i++) {
            const navButtons = document.getElementsByClassName('active-navigation-box')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }

          for (let i = 0; i < document.getElementsByClassName('header-navigation-item').length; i++) {
            const navButtons = document.getElementsByClassName('header-navigation-item')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }

          for (let i = 0; i < document.getElementsByClassName('icon-v19-share-1').length; i++) {
            const navButtons = document.getElementsByClassName('icon-v19-share-1')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }

          for (let i = 0; i < document.getElementsByClassName('enumeration-header-elements').length; i++) {
            const navButtons = document.getElementsByClassName('enumeration-header-elements')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }

          for (let i = 0; i < document.getElementsByClassName('icon-v21-quick-sweep-2').length; i++) {
            const navButtons = document.getElementsByClassName('icon-v21-quick-sweep-2')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }

          for (let i = 0; i < document.getElementsByClassName('manage-insights').length; i++) {
            const navButtons = document.getElementsByClassName('manage-insights')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }

          // for (let i = 0; i < document.getElementsByClassName('refine-data').length; i++) {
          //   const navButtons = document.getElementsByClassName('refine-data')[i]
          //   navButtons.addEventListener('mouseover', function() {
          //     navButtons.style.color = brand.color
          //   })
          // }

          for (let i = 0; i < document.getElementsByClassName('content-box-name-update').length; i++) {
            const navButtons = document.getElementsByClassName('content-box-name-update')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        case 'sub-nav-body-font-active' :
          if (document.getElementsByClassName('active-navigation-box')[0]) {
            document.getElementsByClassName('active-navigation-box')[0].style.color = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('active-navigation-box').length; i++) {
            const navButtons = document.getElementsByClassName('active-navigation-box')[i]
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }

          if (document.getElementsByClassName('active-manage-insights')[0]) {
            document.getElementsByClassName('active-manage-insights')[0].style.color = brand.color;
          }
          for (let i = 0; i < document.getElementsByClassName('active-manage-insights').length; i++) {
            const navButtons = document.getElementsByClassName('active-manage-insights')[i]
            navButtons.addEventListener('mouseover', function() {
              navButtons.style.color = brand.color
            })
          }
          if (document.getElementsByClassName('active')[0]) {
            document.getElementsByClassName('active')[0].style.color = brand.color;
            document.getElementsByClassName('active')[0].addEventListener('mouseout', function () {
              document.getElementsByClassName('active')[0].style.color = brand.color;
            })
          }
          for (let i = 0; i < document.getElementsByClassName('active-manage-insights').length; i++) {
            const navButtons = document.getElementsByClassName('active-manage-insights')[i]
            navButtons.style.color = brand.color
            navButtons.addEventListener('mouseout', function() {
              navButtons.style.color = brand.color
            })
          }
          break
        default: break
      }
    })
  }


  onGetFilesNumberInBox = () => {
    const ids = []
    ids.push(this.props.params.id)
    const folderCountFilesInput = {
      ids: ids
    }
    getFolderFilesNumber(folderCountFilesInput).then((response) => {
      if (response.data[0] && response.data[0].numberOfFiles !== null) {
        this.setState({
          boxFilesCount: response.data[0].numberOfFiles
        })
      }
    })
  }
   onClickReportStatusStar = () => {
    const { folderParent, baseParentBox, boxIdBasePath } = this.props
    const { baseBoxHeader } = this.state
     const dtoId = folderParent && folderParent.id ? folderParent.id : baseParentBox && baseParentBox.id ? baseParentBox.id : baseBoxHeader && baseBoxHeader.id ? baseBoxHeader.id : boxIdBasePath
     sendReportStar(dtoId).then((response) => {
       setTimeout(() => {
         this.setState({
           statusDotsReport: false
         })
       }, 2000);
     })
   }
   onSendStatusStarReport = (nameBoxId) => {
     const { folderParent, baseParentBox, boxIdBasePath, activeItem } = this.props
     const { baseBoxHeader, getReportStatus, idParentBox } = this.state
     const id = nameBoxId ? nameBoxId : (folderParent && folderParent.id) ? folderParent.id : (baseParentBox && baseParentBox.id) ? baseParentBox.id : (baseBoxHeader && baseBoxHeader.id) ? baseBoxHeader.id :
         idParentBox ? idParentBox  : boxIdBasePath
     if (activeItem !== 'data-insights') {
       if (!getReportStatus){
         this.setState({
           onSendStatusStarReport: true
         }, () => {
           apiGetStatusStarReport(id).then((response) => {
             this.setState({
               statusDotsReport: response.data,
               getReportStatus: true,
             })
           })
         })
       }
     }
   }
  onClickCloseHeader = () => {
    this.props.onStatusCloseNavigationBar(true)
  }
  redirectToSmartboxHome = () => {
    const path = '/smart-box/management'
    this.props.navigate(path)
  }

  routeChangeFolderOverview(e) {
    e.stopPropagation()
    const { folderParent, boxIdBasePath, baseIdParent, loadingOpenedFolder, baseDataForTree, activeItem } = this.props
    if (!loadingOpenedFolder) {
      let basePathBox = ''
      let nameBox = ''
      if (folderParent && typeof folderParent.relativePath !== 'undefined') {
        nameBox = folderParent.relativePath.split('/')[1]
      }
      baseDataForTree && baseDataForTree.length > 0 && baseDataForTree.forEach((baseBox) => {
        if (baseBox.name === nameBox) {
          basePathBox = baseBox.id
        }

      })
      if (basePathBox || baseIdParent) {
        const path = `/smart-box/folder/${basePathBox ? basePathBox : baseIdParent}`
        this.props.navigate(path)
      }
    }
    if (activeItem === 'analysis-settings' || activeItem === 'redaction' || activeItem === 'data-insights' || activeItem === 'cull' || activeItem === 'data-collector') {
      const path = `/smart-box/folder/${ boxIdBasePath ? boxIdBasePath : folderParent.id }`
      this.props.navigate(path)
    }
    // if (folderParent.type === 'DIRECTORY') {
    //   const path = `/smart-box/folder/${ baseIdParent ? baseIdParent : boxIdBasePath }`
    //   this.props.navigate(path)
    // }
  }
  routeChangeAnalysisSettings(e) {
    e.stopPropagation()
    const { folderParent, boxIdBasePath, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
    if (folderParent.type === 'DIRECTORY' && (this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || (folderParent.statisticsReady === 'true') || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      const path = `/smart-box/folder/${ boxIdBasePath }/analysis-settings`
      this.props.navigate(path)
    } else if (this.props.smartBoxParentFolderEmpty) {
      const path = `/smart-box/folder/${ boxIdBasePath }/analysis-settings`
      this.props.navigate(path)
    }
  }

  routeChangeToWorkflow(e, ticketId, secondTicketId) {
    const { baseBoxHeader } = this.state
    const { folderParent, boxIdBasePath, baseParentBox } = this.props
    if (baseBoxHeader && baseBoxHeader.workflow_ticket_id) {
      cookie.save('setBoxWorkflowDashboard', baseBoxHeader.workflow_ticket_id, {path: '/'})
    } else if (folderParent && folderParent.workflow_ticket_id) {
      cookie.save('setBoxWorkflowDashboard', folderParent.workflow_ticket_id, {path: '/'})
    }
    e.stopPropagation()
      const path = `/sar/workflow/workflow-dashboard`
      this.props.navigate(path)
/*
    let id = null;
    if(ticketId !== null){
      id = ticketId;
    }
    else if(secondTicketId !== null){
      id = secondTicketId;
    }


      getTicketWorkflowAndHeaderDetails(id).then((response) => {
        this.setState({
          selectEditTickets: response.data,
          //loadingBtnNewTicket:true,
          visibleNewTicket:true,
          statusModalNewTickets: 'edit'
        })

        this.props.openNewTicketModal(id, response.data)
      })*/


  }

  routeChangeDataInsight(e) {
    e.stopPropagation()
    const { folderParent, boxIdBasePath, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
    if (folderParent.type === 'DIRECTORY' && ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      const path = `/smart-box/folder/${ boxIdBasePath }/data-insights/dashboard`
      this.props.navigate(path)
      this.props.setSmartBoxSelectedDuplicateFile({}, '', '')
      this.onClickReportStatusStar()
    }
  }
  routeChangeCull(e) {
    e.stopPropagation()
    const { folderParent, boxIdBasePath, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
    if (folderParent.type === 'DIRECTORY' && ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      //const path = `/smart-box/folder/${ boxIdBasePath }/cull/duplicates`
      const boxParentId = baseParentBox && baseParentBox.id ? baseParentBox.id : folderParent && folderParent.id ? folderParent.id : boxIdBasePath
      const path = `/smart-box/folder/${ boxParentId }/cull/duplicates`
      this.props.navigate(path)
      this.props.setSmartBoxSelectedDuplicateFile({}, '', '')
    }
  }
  routeChangeFolderRedaction(e) {
    e.stopPropagation()
    const { folderParent, boxIdBasePath, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
     if (folderParent.type === 'DIRECTORY' && ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      const path = `/smart-box/folder/${ boxIdBasePath }/folder-redaction`
      this.props.navigate(path)
     }
  }
  routeChangeCulling(e) {
    e.stopPropagation()
    const { folderParent, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
    if (folderParent.type === 'DIRECTORY' && ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      this.setState({
        displayCullingStatus: true
      })
    }
  }
  routeChangeDataMapping (e) {
    e.stopPropagation()
    const { folderParent, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
      // const path = `/data-mapping`
      // window.open(path)
    if (folderParent.type === 'DIRECTORY' && (this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || (folderParent.statisticsReady === 'true') || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      this.setState({
        displayDataMappingStatus: true
      })
    }
  }

  routeChangeFolderDataCollector(e) {
    e.stopPropagation()
    const { folderParent, boxIdBasePath, baseParentBox } = this.props
    const { baseBoxHeader } = this.state
    if (folderParent.type === 'DIRECTORY' && (this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true'))) {
      const path = `/smart-box/folder/${ boxIdBasePath }/data-collector`
      this.props.navigate(path)
    }
  }

  statusCloseModalCulling = (status) => {
    this.setState({
      displayCullingStatus: status
    })
  }
  statusCloseModalDataMapping = (status) => {
    this.setState({
      displayDataMappingStatus: status
    })
  }

  onMouseEnter = (e, status) => {

    switch (status) {
      case 'request':
        this.setState({
          hoverRequestDetail: true
        })
        break
      default: break
    }
  }

  onMouseLeave = () => {
    this.setState({
      hoverRequestDetail: false
    })
  }

  onMouseEnterOnMenu(e, status) {
    this.setState({
      onHoverMenuNavigation: true
    })
  }
  onMouseLeaveOnMenu(e, status) {
    this.setState({
      onHoverMenuNavigation: false
    })
  }
  updateBaseParentBox = () => {
    const { folderParent, smartBoxBaseDataTree } = this.props
    //let { smartBoxParentFolderName } = this.props
    //const { getReportStatus } = this.state
    let parentName = ''
    let nameBox = ''
    let nameBoxId = ''
    if (folderParent && typeof folderParent.relativePath !== 'undefined') {
      parentName = folderParent.relativePath.split('/')[1]
    }
    let baseBos = {}
    smartBoxBaseDataTree && smartBoxBaseDataTree.length > 0 && smartBoxBaseDataTree.forEach((list) => {
         if (parentName === list.name) {
           baseBos = list
           nameBox = list.name
           nameBoxId = list.id
         }
    })
    this.setState({
      baseBoxHeader: baseBos,
      boxNameBase: nameBox,
      boxNameBaseId: nameBoxId
    }, () => {
      //this.onSendStatusStarReport(nameBoxId)
    })

  }
  getBoxFilesCount = () => {
    const { smartBoxFolderCountTree, folderParent } = this.props
    let mappingData = []
     mappingData = cookie.load('countFiles')
    if (smartBoxFolderCountTree && smartBoxFolderCountTree.length > 0) {
      const match = smartBoxFolderCountTree.filter(el => el.path === folderParent.path)
      if (match && match.length > 0) {
        return match[0].numberOfFiles
      }
    } else if(mappingData && mappingData.length > 0) {
      const match = mappingData.filter(el => el.path === folderParent.path)
      if (match && match.length > 0) {
        return match[0].numberOfFiles
      }
    }
    return 0
  }
  render() {
    const { onHoverMenuNavigation, hoverRequestDetail } = this.state
    const { folderParent, activeItem, smartBoxAnalysisPercent, smartBoxParentFolderEmpty, smartBoxParentFolderErrors, loadingOpenedFolder, baseDataForTree, baseIdParent, boxNameBaseId } = this.props
    let { smartBoxParentFolderName, baseParentBox, filesTable, foldersCountFiles } = this.props
    const { baseBoxHeader, boxNameBase, displayCullingStatus, displayDataMappingStatus,statusDotsReport, boxFilesCount, idParentBox } = this.state
    if (!smartBoxParentFolderName && typeof folderParent.relativePath !== 'undefined') {
      smartBoxParentFolderName = folderParent.relativePath.split('/')[1]
    }

    return (
      <div className="header-navigation-contain">
        <div className={ `header-navigation-contain__content-box-name content-box-name-update ${ activeItem === 'folder-overview' ? ' active active-navigation-box' : '' }` }>
          <img src={ !smartBoxParentFolderEmpty ? (smartBoxParentFolderErrors ? imgFolderParentNegativeProcessing : imgFolderParentNegative) : imgFolderParentNegativeEmpty } alt={ folderParent.name }/>
          <span className={ loadingOpenedFolder ? "name-box name-box-disabled" : 'name-box' } onClick={(e) => this.routeChangeFolderOverview(e)}>
            { boxNameBase ? boxNameBase : folderParent ? folderParent.name : smartBoxParentFolderName }</span>
        </div>
        { window.innerWidth < 600 ?
          <div className="actionsDotsCell navigation-one-inner-width">
            <DropdownButton
              className=""
              title={ <div className=""><span
                onMouseEnter={ (e) => this.onMouseEnterOnMenu(e, 'small-active') }
                onMouseLeave={ () => this.onMouseLeaveOnMenu() }
                className={ onHoverMenuNavigation ? 'icon-v14-options-small-active options-small-active' : 'icon-v14-options-small-active' }></span></div> }
              nocaret="true"
              data-toggle="dropdown"
              id={ 'dropdown-1' }
              onClick={ (e) => {
                e.stopPropagation()
              } }
              bsstyle="default">
              <Dropdown.Item className="menu-left-item-directory">
                <div>
                  <span onClick={(e) => this.routeChangeAnalysisSettings(e)} className="enumeration-header-elements icon-v16-gear"></span>
                  {/*<span className="box-settings" onClick={(e) => this.routeChangeAnalysisSettings(e)}>Box settings</span>*/}
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="menu-left-item-directory">
                <div>
                  {/*<span className="enumeration-header-elements icon-v12-2-white"></span>*/}
                  <span className="refine-data" onClick={(e) => this.routeChangeCull(e)}>Cull</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="menu-left-item-directory">
                <div>
                  {/*<span className="enumeration-header-elements icon-v12-3-white"></span>*/}
                  <span className="manage-insights" onClick={(e) => this.routeChangeFolderRedaction(e)}>Redact</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item className="menu-left-item-directory">
                <div>
                  {/*<span className="enumeration-header-elements icon-v12-2-white"></span>*/}
                  <span className="refine-data" onClick={(e) => this.routeChangeDataInsight(e)}>Report</span>
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div> :
          <div>
            <div className={ `header-navigation-contain__content-box-settings header-navigation-item ${ activeItem === 'analysis-settings' ? ' active' : '' }` }>
              {
                ((this.getBoxFilesCount() === 0 || baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                    <Tooltip title={'Box Settings'}>
              <span onClick={(e) => this.routeChangeAnalysisSettings(e)}
                    className={ (this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? 'enumeration-header-elements icon-v16-gear' : "enumeration-header-elements icon-v16-gear disabled-generating" }></span></Tooltip> :
                    this.props.smartBoxParentFolderEmpty ?
                        <Tooltip title={'Box Settings'}>
                        <span onClick={(e) => this.routeChangeAnalysisSettings(e)}
                              className={ (this.props.smartBoxParentFolderEmpty ? 'enumeration-header-elements icon-v16-gear' : this.getBoxFilesCount() === 0 ||  (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? 'enumeration-header-elements icon-v16-gear' : "enumeration-header-elements icon-v16-gear disabled-generating" }></span></Tooltip> :
                    <Tooltip title="Generating stats. Please wait.">
              <span onClick={(e) => this.routeChangeAnalysisSettings(e)}
                    className={ (this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true' )) ? 'enumeration-header-elements icon-v16-gear' : "enumeration-header-elements icon-v16-gear disabled-generating" }></span>
                    </Tooltip>
              }
              {/*<span className="box-settings" onClick={(e) => this.routeChangeAnalysisSettings(e)}>Box settings</span>*/}
            </div>


              {
                ((baseBoxHeader && baseBoxHeader.workflow_ticket_id !== null && baseBoxHeader.workflow_ticket_id !== undefined) ||
                    (folderParent && folderParent.workflow_ticket_id !== undefined && folderParent.workflow_ticket_id !== null )) ?
                <div className={ `header-navigation-contain__content-box-settings header-navigation-item ${ activeItem === 'analysis-settings' ? ' active' : '' }` }>
                  <Tooltip title={'Go to Workflow'}>
                  <span className={hoverRequestDetail ? 'icon-v22-request-detail-active' : "icon-v22-request-detail" } style={{fontSize:"20px"}}

                        onMouseEnter={(e) => this.onMouseEnter(e, 'request')}
                        onMouseLeave={() => this.onMouseLeave()}
                        onClick={(e) => this.routeChangeToWorkflow(e, baseBoxHeader.workflow_ticket_id, folderParent.workflow_ticket_id )}>
                  </span>
                  </Tooltip>
                </div> :""
              }

            { this.props.client && this.props.client.dataMappingAppVisible &&
            <div className={`header-navigation-contain__content-data-mapping header-navigation-item${activeItem === 'data-collector' ? ' active' : ''}`}>
              {
                (this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                    <span
                        className={(this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "data-mapping" : 'data-cull disabled-generating'}
                        onClick={(e) => this.routeChangeFolderDataCollector(e)}>Collector</span> :
                    <Tooltip title="Generating stats. Please wait.">
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "data-mapping" : 'data-cull disabled-generating'}
                    >Collector</span>
                    </Tooltip>
              }
            </div>
            }
            { this.props.client && this.props.client.dataMappingAppVisible &&
            <div className={'header-navigation-contain__content-manage-data-mapping'}>
              {(this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                  <Tooltip title={"Connector import"}>
                  <span
                      className={(this.getBoxFilesCount() === 0 || (baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "icon-v19-share-1" : 'icon-v19-share-1 disabled-generating'}
                      onClick={(e) => this.routeChangeDataMapping(e)}></span></Tooltip> :
                  <Tooltip title="Generating stats. Please wait.">
                   <span
                       className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "icon-v19-share-1" : 'icon-v19-share-1 disabled-generating'}
                   ></span>
                  </Tooltip>
              }
            </div>
            }
            <div className={ `header-navigation-contain__content-cull header-navigation-item${ activeItem === 'cull' ? ' active' : '' }` }>
              {/*<span className="enumeration-header-elements icon-v12-2-white"></span>*/}
              {
                ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "data-cull" : 'data-cull disabled-generating'}
                        onClick={(e) => this.routeChangeCull(e)}>Cull</span> :
                    <Tooltip title={this.getBoxFilesCount() === 0 ? '' : "Generating stats. Please wait." }>
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "data-cull" : 'data-cull disabled-generating'}
                        onClick={(e) => this.routeChangeCull(e)}>Cull</span>
                    </Tooltip>
              }
            </div>
            <div className={ 'header-navigation-contain__content-manage-culling header-navigation-item' } >
              { ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                  <Tooltip title={'Quick Cull'}>
                   <span className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "icon-v21-quick-sweep icon-v21-quick-sweep-2" : 'icon-v21-quick-sweep disabled-generating' }
                         onClick={(e) => this.routeChangeCulling(e)}></span></Tooltip> :
                  <Tooltip title={this.getBoxFilesCount() === 0 ? '' : "Generating stats. Please wait." }>
                   <span className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "icon-v21-quick-sweep icon-v21-quick-sweep-2" : 'icon-v21-quick-sweep disabled-generating' }
                         onClick={(e) => this.routeChangeCulling(e)}></span>
                  </Tooltip>
              }
            </div>
            <div className={ `header-navigation-contain__content-manage-insights header-navigation-item${ activeItem === 'redaction' ? ' active active-manage-insights' : '' }` }>
              {/*<span className="enumeration-header-elements icon-v12-3-white"></span>*/}
              {
                ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "manage-insights" : 'manage-insights disabled-generating'}
                        onClick={(e) => this.routeChangeFolderRedaction(e)}>Redact</span> :
                    <Tooltip title={this.getBoxFilesCount() === 0 ? '' : "Generating stats. Please wait." }>
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "manage-insights" : 'manage-insights disabled-generating'}
                        onClick={(e) => this.routeChangeFolderRedaction(e)}>Redact</span>
                    </Tooltip>
              }
            </div>
            <div className={ `header-navigation-contain__content-refine-data header-navigation-item${ activeItem === 'data-insights' ? ' active active-manage-insights' : '' }` }>
              {/*<span className="enumeration-header-elements icon-v12-2-white"></span>*/}
              {
                ((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ?
                    <span className="content-report">
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "refine-data" : 'refine-data disabled-generating'}
                        onClick={(e) => this.routeChangeDataInsight(e)}>Report</span>
                      <span className={ statusDotsReport ? "dots-report" : '' }></span>
                      </span> :
                    <Tooltip title={this.getBoxFilesCount() === 0 ? '' : "Generating stats. Please wait." }>
                    <span
                        className={((baseBoxHeader && baseBoxHeader.statisticsReady === 'true') || folderParent.statisticsReady === 'true' || (baseParentBox && baseParentBox.statisticsReady === 'true')) ? "refine-data" : 'refine-data disabled-generating'}
                        onClick={(e) => this.routeChangeDataInsight(e)}>Report</span>
                    </Tooltip>
              }
            </div>
          </div>
        }
        <Tooltip title={'Close'} placement={'topLeft'}>
        <div key="progress-2" className="icon-v14-close-black close-header-bar" onClick={ () => this.redirectToSmartboxHome() }></div>
        </Tooltip>
        <div className="header-navigation-contain__content-analysis-progress">
          {boxFilesCount ?
          <React.Fragment>
            {((baseParentBox && baseParentBox.statisticsReady === 'false') || (baseBoxHeader && baseBoxHeader.statisticsReady === 'false') || (folderParent && folderParent.statisticsReady === 'false')) && smartBoxAnalysisPercent > 0 &&
            <div key="progress-1"
                 className={baseParentBox && baseParentBox.doc_extraction === 'true' ? 'progress-analysis progress-analysis-striped' : "progress-analysis"}>

              {((baseParentBox && baseParentBox.analyticsReady === 'false') || (baseBoxHeader && baseBoxHeader.analyticsReady === 'false') || (folderParent && folderParent.analyticsReady === 'false'))
              && <span className="analysis-progress">Processed</span>}

              {((baseParentBox && baseParentBox.statisticsStatus === 'Failed') ||
                  (baseBoxHeader && baseBoxHeader.statisticsStatus === 'Failed')) ?
                  <span className="analysis-progress">Generating stats failed</span> :
                  ((baseParentBox && baseParentBox.analyticsReady === 'true' && baseParentBox.statisticsReady === 'false') ||
                      (baseBoxHeader && baseBoxHeader.analyticsReady === 'true' && baseBoxHeader.statisticsReady === 'false')) &&
                  <span className="analysis-progress">Generating stats</span>
              }


              {/*    <span className="analysis-progress">{ folderParent && folderParent.doc_extraction === 'true' ? 'Processed' : 'Generating stats' }</span>*/}
              <Progress
                  percent={smartBoxAnalysisPercent === 100 ? 99.9 : smartBoxAnalysisPercent}
                  //showInfo={false}
              />
            </div>
            }
          </React.Fragment> : <div></div>
          }
          { (smartBoxAnalysisPercent === 100 || smartBoxAnalysisPercent === -1) && this.props.smartBoxResponseDeleteDuplicates.responseApi === false &&
            <React.Fragment>
              <div key="progress-1" className="progress-analysis">
                <span className="analysis-progress">Delete duplicates</span>
                <Progress
                  percent={ 50 }
                  //showInfo={false}
                />
              </div>
            </React.Fragment>
          }
          {
            (boxFilesCount && smartBoxAnalysisPercent === 100 || smartBoxAnalysisPercent === -1) && this.props.smartBoxProgressCleanDeleteDuplicates.statusApi === false &&
            <React.Fragment>
              <div key="progress-1" className="progress-analysis">
                <span className="analysis-progress">Delete duplicates</span>
                <Progress
                  percent={ 50 }
                  //showInfo={false}
                />
              </div>
            </React.Fragment>
          }
          { this.props.smartReprocessingFileDelete && this.props.activeErrorTab && this.props.activeErrorTab === 'errorReport' &&
            <React.Fragment>
              <div key="progress-6" className="reprocessing-files">
                <span className="reprocessing-files-title">Reprocessing files</span>
                <Spin className="custom-spin-reprocessing" spinning={true} >
                </Spin>
              </div>
            </React.Fragment>
          }
          {
            displayCullingStatus &&
                <CullingModal
                    boxNameBase={ boxNameBase }
                    folderParent={ folderParent }
                    boxNameBaseId={ boxNameBaseId }
                    smartBoxParentFolderName={ smartBoxParentFolderName }
                    baseDataForTree={ baseDataForTree }
                    baseIdParent={ baseIdParent }
                    idParentBox={ idParentBox }
                    statusCloseModalCulling={ this.statusCloseModalCulling }
                    client={ this.props.client }/>
          }
          {
            displayDataMappingStatus &&
                <DataMappingModal
                statusCloseModalDataMapping={ this.statusCloseModalDataMapping }
                boxNameBase={ boxNameBase }
                folderParent={ folderParent }
                smartBoxParentFolderName={ smartBoxParentFolderName }
                />
          }
        </div>
      </div>
    )
  }
}

HeaderNavigationBox = withRouter(HeaderNavigationBox)

const mapStateToProps = (state) => ({
  clientId: state.subscriptionReducer.subscription.subscription.client.id,
  smartBoxAnalysisPercent: state.smartBox.smartBoxAnalysisPercent,
  smartBoxParentFolderEmpty: state.smartBox.smartBoxParentFolderEmpty,
  smartBoxParentFolderErrors: state.smartBox.smartBoxParentFolderErrors,
  smartBoxParentFolderName: state.smartBox.smartBoxParentFolderName,
  smartBoxResponseDeleteDuplicates: state.smartBox.smartBoxResponseDeleteDuplicates,
  smartBoxProgressCleanDeleteDuplicates: state.smartBox.smartBoxProgressCleanDeleteDuplicates,
  smartReprocessingFileDelete: state.smartBox.smartReprocessingFileDelete,
  smartBoxBaseDataTree: state.smartBox.smartBoxBaseDataTree,
  smartBoxFolderCountTree: state.smartBox.smartBoxFolderCountTree,
  setBoxRouteDashboardValue: state.smartBox.setBoxRouteDashboardValue,
  client: state.subscriptionReducer.subscription.subscription.client,
})
export default connect(mapStateToProps, {
  setSmartBoxSelectedDuplicateFile,
  setSmartBoxResponseDeleteDuplicates,
  setSmartBoxProgressCleanDeleteDuplicates,
  setSmartReprocessingFileDelete,
  setBoxWorkflowDashboard
})(HeaderNavigationBox)