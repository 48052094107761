import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CustomNotificationManager from '../Common/CustomNotificationManager'
import { apiUserActivate, checkResetLink } from '../../api/User'
import * as actions from '../../actions/messageActions'
import Loading from '../Common/Loading'
import Message from '../Common/Message'
import { getParameterByName } from '../../utils'
import '../../style/common.scss'
import './SetPassword.scss'
import './LoginUpdate.scss'
import { Tooltip, Icon } from 'antd'
import Smartbox_Logo_neg from '../../assets/Smartbox_Logo_neg.svg'

export class SetPassword extends Component {
  constructor() {
    super()

    this.state = {
      choosePassword: '',
      isLogged: false,
      isSending: false,
      retypePassword: '',
      validPassowrd: {
        border: '1px solid #CCCCCC'
      },
      isPasswordsEqual: true,
      goBackToLoginPage: false,
      conditionsPassword: [],
      conditionsConfirmPassword: [],
      passwordValid: false,
      confirmPasswordValid: false,
      passwordVisible: false,
      confirmPasswordVisible: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    checkResetLink(getParameterByName('userToken')).then((response) => {
      if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
        CustomNotificationManager.success(response.data.message, 'Success')
      } else if(response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
        CustomNotificationManager.info(response.data.message, 'Info')
      }
    })
      .catch(() => {
        this.setState({
          goBackToLoginPage: true
        })
      })
    this.updatePasswprdStrengthCheckers(true, true, true, true, 8, '')
    this.updateConfirmPasswordStrengthCheckers('')
  }

  componentWillUnmount() {
    this.props.hideMessage()
  }

  updateValue(field, e) {
    if (field === 'choosePassword') {
      this.updatePasswprdStrengthCheckers(true, true, true, true, 8, e.target.value)
    }
    if (field === 'retypePassword') {
      this.updateConfirmPasswordStrengthCheckers(e.target.value)
    }
    this.setState({
      [field]: e.target.value
    }, () => this.validateField(field))
  }


  validateField() {
    const { choosePassword, retypePassword } = this.state
    let isPasswordsEqual = false
    let style = {}
    if (choosePassword !== retypePassword) {
      isPasswordsEqual = false
      style = {
        border: '2px solid red'
      }
    }
    else {
      isPasswordsEqual = true
      style = {
        border: '1px solid #CCCCCC'
      }
    }
    this.setState({
      validPassowrd: style,
      isPasswordsEqual: isPasswordsEqual
    })
  }

  handleSubmit(e) {
    const { passwordValid, confirmPasswordValid } = this.state
    e.preventDefault()
    e.stopPropagation()
    if (passwordValid && confirmPasswordValid) {
      this.setPassword()
    }
  }

  updatePasswprdStrengthCheckers = (upper, lower, number, special, minLength, value) => {
    const conditionsPassword = []
    if (upper) {
      conditionsPassword[0] = { res: false, msg: 'at least one uppercase letter', type: 'upper' }
      if (/[A-Z]/.test(value)) {
        conditionsPassword[0]['res']= true
      }
    }
    if (lower) {
      conditionsPassword[1] = { res: false, msg: 'at least one lowercase letter', type: 'lower' }
      if (/[a-z]/.test(value)) {
        conditionsPassword[1]['res']= true
      }
    }
    if (number) {
      conditionsPassword[2] = { res: false, msg: 'at least one number letter', type: 'number' }
      if (/[0-9]/.test(value)) {
        conditionsPassword[2]['res']= true
      }
    }
    if (special) {
      conditionsPassword[3] = { res: false, msg: 'at least one special letter', type: 'special' }
      if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)) {
        conditionsPassword[3]['res']= true
      }
    }
    if (minLength > 0) {
      conditionsPassword[4] = { res: false, msg: `at least ${ minLength } characters`, type: 'minLength' }
      if (value.length >= minLength) {
        conditionsPassword[4]['res'] = true
      }
    }

    let passwordValid = true
    if (conditionsPassword && conditionsPassword.length) {
      conditionsPassword.forEach((item) => {
        if (item.res === false) {
          passwordValid = false
        }
      })
    }
    this.setState({ conditionsPassword, passwordValid })
  }

  updateConfirmPasswordStrengthCheckers = (value) => {
    const conditionsConfirmPassword = []
    const { choosePassword } = this.state
    conditionsConfirmPassword[0] = { res: false, msg: 'to not be empty' }
    if (value.length) {
      conditionsConfirmPassword[0]['res'] = true
    }
    conditionsConfirmPassword[1] = { res: false, msg: 'to be equal' }
    if (value === choosePassword) {
      conditionsConfirmPassword[1]['res'] = true
    }

    let confirmPasswordValid = true
    if (conditionsConfirmPassword && conditionsConfirmPassword.length) {
      conditionsConfirmPassword.forEach((item) => {
        if (item.res === false) {
          confirmPasswordValid = false
        }
      })
    }
    this.setState({ conditionsConfirmPassword, confirmPasswordValid })
  }

  setPassword() {
    this.setState({
      isSending: true
    })
    this.props.hideMessage()

    const data = {
      validatePassword: this.state.retypePassword,
      password: this.state.choosePassword,
      userToken: getParameterByName('userToken')
    }

    apiUserActivate(data)
      .then((response) => {
        if (response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
          CustomNotificationManager.success(response.data.message, 'Success')
        } else if(response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
          CustomNotificationManager.info(response.data.message, 'Info')
        }
        if (response.data.id_token) {
          CustomNotificationManager.success('Your password has been set. Warp speed into Smartbox.ai!', 'Success')
        }
        this.setState({
          isSending: false,
          goBackToLoginPage: true
        }, () => {
          //this.props.navigate('/osp/login') /*this.goToLogin()*/
          window.location.href ='/osp/login'
        })
      }).catch((e) => {
        this.setState({
          isSending: false
        })
      })
  }

  goToLogin = () => {
    const { choosePassword, retypePassword } = this.state
    let response = true
    if (choosePassword !== retypePassword) {
      CustomNotificationManager.error('The passwords are not equal.', 'Error')
      response = false
      this.setState({ isSending: false }, () => { return response })
      return response
    } 
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
    const passwordIsValid = passwordRegex.exec(choosePassword)
    if (!passwordIsValid) {
      response = false
      this.setState({ isSending: false }, () => {
        return response
      })
    }
    
    if (response) {
      this.props.navigate('/osp/login')
    }
  }

  renderPasswordConditions = (field) => {
    let conditions = this.state.conditionsPassword
    if (field === 'confirmPassword') {
      conditions = this.state.conditionsConfirmPassword
    }
    return (
      <div className="tooltip-wrapper">
        <p className="tooltip-wrapper__title">
          { field === 'repeatPassword' ? 'This field must' : 'The password must contain' }
        </p>
        { conditions && conditions.length > 0 && conditions.map((item, index) => {
          return (
            <p key={index} className={item.res ? 'tooltip-wrapper__item-green' : 'tooltip-wrapper__item-red' }>
              <Icon type={ item.res ? 'check-circle' : 'close-circle'} />
              { item.msg }
            </p>
          )
        }) }
      </div>
    )
  }

  render() {
    const { choosePassword, isLogged, isSending, retypePassword, isPasswordsEqual, passwordValid, confirmPasswordValid, passwordVisible, confirmPasswordVisible } = this.state
    const { messageArea } = this.props

    return (
      <div className="setPassword-page">
        {
          isLogged &&
          <Navigate to="/smart-box/management" />
        }
        <div className="new-set-password-page">
          { !isLogged &&
          <div className="setPassword-header">
            <img className="logo-osprey-header" src={ Smartbox_Logo_neg } alt="Osprey" />
          </div>
          }
          { !isLogged &&
          <div className="setPassword-box">
            <h1>Reset password</h1>
            <div className="setPassword-container">
              <div className="setPassword-formGroup">
                <label htmlFor="choose">PASSWORD</label>
                <Tooltip trigger={ ['focus', 'hover'] } title={ this.renderPasswordConditions('password') }
                         overlayClassName="tooltip-over">
                  <input
                    id="choose"
                    type={ passwordVisible ? `text` : 'password' }
                    onChange={ this.updateValue.bind(this, 'choosePassword') }
                    style={ this.state.validPassowrd }
                  />
                  <i className={ passwordVisible ? `icon-v11-visible` : `icon-v11-unvisible`}
                     onClick={ () => this.setState({ passwordVisible: !this.state.passwordVisible }) }></i>
                </Tooltip>
              </div>

              <div className="setPassword-formGroup">
                <label htmlFor="retype">CONFIRM PASSWORD</label>
                <Tooltip trigger={ ['focus', 'hover'] } title={ this.renderPasswordConditions('confirmPassword') }
                         overlayClassName="tooltip-over">
                  <input
                    id="retype"
                    type={ confirmPasswordVisible ? `text` : 'password' }
                    onChange={ this.updateValue.bind(this, 'retypePassword') }
                    style={ this.state.validPassowrd }
                  />
                  <i className={ confirmPasswordVisible ? `icon-v11-visible` : `icon-v11-unvisible`}
                     onClick={ () => this.setState({ confirmPasswordVisible: !this.state.confirmPasswordVisible }) }></i>
                </Tooltip>
              </div>

              { messageArea.visible &&
              <div className="setPassword-message">
                <Message
                  hideMessageAction={ this.props.hideMessage }
                  status={ messageArea.status }
                  text={ messageArea.text }/>
              </div>
              }

              <div className="setPassword-footer">
                { !isSending &&
                <div className="setPassword-footerForm">
                  <input
                    disabled={ choosePassword === '' || retypePassword === '' || messageArea.visible || !isPasswordsEqual || !passwordValid || !confirmPasswordValid }
                    type="submit"
                    value="Reset"
                    className="setPassword-btn reset-password-button"
                    onClick={ (e) => this.handleSubmit(e) }
                  />
                </div>
                }
                { isSending &&
                <div className="setPassword-loading">
                  <Loading/>
                </div>
                }
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    )
  }
}

const stateMap = (state) => ({
  messageArea: state.messageAreaReducer.messageArea
})

const dispatchMap = (dispatch) => ({
  hideMessage: () => dispatch(actions.hideMessage()),
  showMessage: (status, text) => dispatch(actions.showMessage({ status, text }))
})

SetPassword.propTypes = {
  hideMessage: PropTypes.func.isRequired,
  messageArea: PropTypes.object.isRequired,
  showMessage: PropTypes.func.isRequired
}

export default compose(
  connect(stateMap, dispatchMap)
)(SetPassword)
