import React from 'react'
import {
    apiSmartBoxGetNewQueryStatus,
    apiSendKeysHelper,
    apiSmartBoxUpdateRedactionNumber,
    apiSmartBoxCheckAllCanvasPoints, apiSmartBoxSaveQueryStatusMixed
} from '../../../api/SmartBox'
import { getAnnotationColorsByClientId, getScanAnnotations, updateAnnotationColor } from '../../../api/Sar'
import CustomNotificationManager from '../../Common/CustomNotificationManager'
import { Tree, notification, Icon, Spin } from 'antd'
import PropTypes from 'prop-types'
import '../Tracker/Tracker.scss'
import './AiAnnotationsSidebar.scss'
import { connect } from 'react-redux'
//import ReviewRedact from './ReviewRedact'
import {
    generateHtmlForSecurityScanResult,
    renderTreeNodes,
    markWordsOnDocumentForCategory,
    timerCheckCanvas,
    markWordsOnDocumentForCategoryCopy, renderTreeNodesCopy
} from '../../../utils/SmartBox/SmartBoxMethods'
import swal from 'sweetalert2'
import { categories } from '../../../utils/AITagsNew'

let timerCheckMarkReady

class StandardTabCopy extends React.Component {

    state = {
        docAnnotations: [],
        docAnnotationsColors: [],
        rowSelectedCategory: '',
        displayColorPicker: [],
        lastSelectedColor: '',
        defaultActiveKeys: [ 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ],
        categoriesColors: {
            names: '',
            locations: '',
            numbers: '',
            time: '',
            addresses: '',
            identifiers: '',
            organisations: '',
        },
        namesSection: '',
        locationsSection: '',
        numbersSection: '',
        timeSection: '',
        addressesSection: '',
        identifiersSection: '',
        organisationsSection: '',
        categoriesVisible: {
            names: true,
            locations: true,
            numbers: true,
            time: true,
            addresses: true,
            organisations: true,
            categories: true,
            identifiers: true
        },
        checkList: [],
        treeDataAnnotations: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        treeDataAnnotationsOcr: [ {
            title: 'Select All',
            key: 'select-all',
            identifier: 'top-parent',
            children: []
        } ],
        initCheckedKeys: [],
        checkedKeys: [],
        unCheckedKeys: [],
        selectedKeys: [],
        expandedKeys: [ 'select-all', 'names', 'locations', 'numbers', 'time', 'addresses', 'identifiers', 'organisations' ],
        autoExpandParent: true,
        redactedKeys: [],
        docAnnotationsNewQuery: [],
        docAnnotationCategories: [],
        checkedChildrenNew: [],
        docAnnotationsChildrenKeys: [],
        selectedChildrenBold: [],
        disableCheckedKeys: '',
        checkStatusChildren: [],
        sendStatusOnClearAll: false,
        selectClassDisabled: '',
        selectedPgNrBoldStandard: [],
        docAnnotationsOcr: [],
        isOcr: false,
        checkedKeysOcr: [],
        redactedDisplayHover: {},
        disabledCheckBoxTree: false,
        checkedKeysNew: [],
        allTermsChildren: [],
        showVisibleCategory: {},
        statusSaveQuery: false,
        classListBusiness: [],
        itemSelectForRedact: {},
        setIntervalCheckPdfCanvas: 0,
        responseCheckPdfCanvas: '',
        showHideCategoryItems: []
    }

    componentDidMount() {
        if (this.props.isDocumentReady) {
            //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered)
            //this.checkNode()
            this.setState({
                isOcr: this.props.isOcr,
            })
        }
        //this.fetchDataUpdateCopy()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkForDocAnnotationCategories()
        if (prevProps.isDocumentReady !== this.props.isDocumentReady) {
            //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
            //this.checkNode()
            this.setState({
                isOcr: this.props.isOcr,
            })
        }

        if (prevProps !== this.props && this.props.treeDataAnnotationsStandardMixed[0].children.length > 0 && !this.props.statusNextDocumentStandard) {
            //console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG')
            //this.props.startDrawRedactMatchedWordsMixed === false &&
            this.props.onGetStatusNextDocumentStandard(false)
            //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
            this.fetchDataUpdateCopy()
            //this.checkNode()
        }
        if (prevProps.expandedKeysAll.length !== this.props.expandedKeysAll.length && this.props.expandedKeysAll.length > 0) {
            this.setState({
                expandedKeys: [...this.state.expandedKeys, ...this.props.expandedKeysAll ]
            })
            //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
        }

        if (prevProps.docAnnotationCategoriesStandard  !== this.props.docAnnotationCategoriesStandard ) {
            this.setState({
                docAnnotationCategories: this.props.docAnnotationCategoriesStandard
            })
        }
        if (prevProps !== this.props && this.props.treeDataAnnotationsStandardMixed[0].children.length > 0 && this.props.statusNextDocumentStandard) {
            this.props.onGetStatusNextDocumentStandard(false)
            //this.fetchDataUpdate(this.props.documentId, this.props.clientId, this.props.userId, this.props.idDocument, this.props.allPagesRendered && !prevProps.allPagesRendered)
           // console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG 111111111111111111111111111')
            this.fetchDataUpdateCopy()
            //this.checkNode()
        }

        if (prevProps.statusHideAllHighlighting !== this.props.statusHideAllHighlighting) {
            const { treeDataAnnotations, categoriesVisible } = this.state
            if (!this.props.statusHideAllHighlighting) {
                treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
                    const aiAnnotations = document.querySelectorAll(`.${item2.key}`)
                    Object.keys(categoriesVisible) && Object.keys(categoriesVisible).length > 0 && Object.keys(categoriesVisible).forEach((cat) => {
                        categoriesVisible[cat] = this.props.statusHideAllHighlighting
                    })
                    this.setState({
                        // categoriesVisible: {
                        //   ...this.state.categoriesVisible, [item2.key]: this.props.statusHideAllHighlighting
                        // }
                    }, () => {
                        for (const aiAnnotate of aiAnnotations) {
                            aiAnnotate.style.background = 'transparent'
                        }
                    })
                })
            } else {
                treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
                    const aiAnnotations = document.querySelectorAll(`.${item2.key}`)
                    Object.keys(categoriesVisible) && Object.keys(categoriesVisible).length > 0 && Object.keys(categoriesVisible).forEach((cat) => {
                        categoriesVisible[cat] = this.props.statusHideAllHighlighting
                    })
                    this.setState({
                        // categoriesVisible: {
                        //   ...this.state.categoriesVisible, [item2.key]: this.props.statusHideAllHighlighting
                        // }
                    }, () => {
                        for (const aiAnnotate of aiAnnotations) {
                            aiAnnotate.style.background = this.state.categoriesColors[item2.key]
                        }
                    })
                })
            }
        }
    }

    renderCategoriesSectionsUpdate = () => {
        let { categoriesColors, treeDataAnnotations, docAnnotations, docAnnotationCategories, treeDataAnnotationsOcr, expandedKeys,
            checkedKeys
        } = this.state
        const { landscape } = this.props
        const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, treeDataAnnotationsStandard, expandedKeysAll, docAnnotationCategoriesStandard,
            treeDataAnnotationsStandardOcr, treeDataAnnotationsStandardMixed} = this.props
        timerCheckMarkReady = setInterval(() => {
            if (global.itemsToBeMarked === global.markedTimes) {
                clearInterval(timerCheckMarkReady)
                this.props.hideLoadingBeforeMark()
                this.setState({
                    statusSaveQuery: true
                })
                if (this.props.isSmartBoxApplication) {
                    this.saveKeysForClearAll(this.props.idDocument, treeDataAnnotationsStandard)
                }
                const treeData = treeDataAnnotationsStandard
                this.props.setTreeDataAnnotations(treeDataAnnotationsStandard)
                this.props.allChildrenTree(treeDataAnnotationsStandard)
                this.props.treeDataFilter(treeData)
                if (treeDataAnnotationsStandardMixed[0].children && treeDataAnnotationsStandardMixed[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
                  checkedKeys = this.props.alreadyRedactedKeys.standard
                }
                //expandedKeys = [...expandedKeys, ...expandedKeysAll ],
                const checkedKeysUpdateMount = []
                if (this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0) {
                    if (treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
                        treeDataAnnotationsStandard[0].children.forEach((children1) => {
                            children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                                children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                                    children3 && children3.children && children3.children.length > 0 && children3.children.forEach((children4) => {
                                        if ((this.props.alreadyRedactedKeys.standard.includes(`${children4.name.toLowerCase()}-${children4.index}`)) ||
                                            this.props.alreadyRedactedKeys.standard.includes(`${children4.name.toLowerCase()}-all`)) {
                                            checkedKeysUpdateMount.push(children4.key)
                                        }
                                    })
                                })
                            })
                        })
                    }
                    checkedKeys = checkedKeysUpdateMount
                }

                this.setState({
                    treeDataAnnotations: treeDataAnnotationsStandard,
                    docAnnotationCategories,
                    treeDataAnnotationsOcr: treeDataAnnotationsStandard,
                    //expandedKeys,
                    autoExpandParent: false,
                    checkedKeys
                }, () => {
                    if (this.props.isOcr) {
                        this.checkDocAnnotationCategoryOcrUpdate()
                    }
                    this.checkNode()
                    this.onColourAnnotations()
                })
                global.itemsToBeMarked = 0
                global.markedTimes = 0
            }
        }, 100)
    }
    fetchDataUpdateCopy = () => {
        const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, expandedKeysAll, treeDataAnnotationsStandardOcr,
            docAnnotationsOcr, docAnnotationsStandardOcr, treeDataAnnotationsStandardMixed } = this.props
        let { expandedKeys, checkedKeys } = this.state

        this.props.setTreeDataAnnotations(treeDataAnnotationsStandardMixed)
        this.props.allChildrenTree(treeDataAnnotationsStandardMixed)
        this.props.treeDataFilter(treeDataAnnotationsStandardMixed)
        expandedKeys = [...expandedKeys, ...expandedKeysAll ],
            this.setState({
                docAnnotations: docAnnotationsStandard,
                docAnnotationsOcr: docAnnotationsStandardOcr,
                docAnnotationsColors: docAnnotationsColorsStandard,
                categoriesColors: categoriesColorsStandard,
                expandedKeys,
                //checkedKeys
                //expandedKeys
            }, () => {
                //this.props.onCheckAiAnnotations(true)
            })
        this.setState({
            treeDataAnnotations: treeDataAnnotationsStandardMixed,
        }, () => {
            let checkedKeysNonOcr = []
            let checkedKeysOcr = []

            let checkedKeysUpdateMount = []
            const checkedKeysUpdateMountOcr = []
            // console.log('this.props.alreadyRedactedKeys HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', this.props.alreadyRedactedKeys)
             //console.log('treeDataAnnotationsStandardMixed HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', treeDataAnnotationsStandardMixed)
             //console.log('this.props.alreadyRedactedKeys HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', this.props.alreadyRedactedKeys)
            if (this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0) {
                if (treeDataAnnotationsStandardMixed[0].children && treeDataAnnotationsStandardMixed[0].children.length > 0 && this.props.alreadyRedactedKeys.standard && this.props.alreadyRedactedKeys.standard.length > 0 ) {
                    treeDataAnnotationsStandardMixed[0].children.forEach((children1) => {
                        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                            children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                                children3 && children3.children && children3.children.length > 0 && children3.children.forEach((children4) => {
                                    //console.log('children4 CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC', children4)
                                    if (!children4.isOcr && (this.props.alreadyRedactedKeys.standard.includes(children4.keyMixtRedaction.toLowerCase())) || (this.props.alreadyRedactedKeys.standard.includes(children4.keyMixtRedaction))) {
                                        checkedKeysUpdateMount.push(children4.key)
                                        checkedKeysUpdateMount = checkedKeysUpdateMount.filter((a, i) => checkedKeysUpdateMount.findIndex((s) => a === s) === i)
                                    }
                                })
                            })
                        })
                    })
                }
                checkedKeysNonOcr = checkedKeysUpdateMount
            }
            //console.log('checkedKeysNonOcr CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC', checkedKeysNonOcr)
            if (this.props.alreadyRedactedKeys.standardOCR && this.props.alreadyRedactedKeys.standardOCR.length > 0) {
                if (treeDataAnnotationsStandardMixed[0].children && treeDataAnnotationsStandardMixed[0].children.length > 0) {
                    treeDataAnnotationsStandardMixed[0].children.forEach((children1) => {
                        children1 && children1.children && children1.children.length > 0 && children1.children.forEach((children2) => {
                            children2 && children2.children && children2.children.length > 0 && children2.children.forEach((children3) => {
                                children3 && children3.children && children3.children.length > 0 && children3.children.forEach((children4) => {
                                    if (children4.isOcr) {
                                        if ((this.props.alreadyRedactedKeys.standardOCR.includes(children4.key.toLowerCase()) || this.props.alreadyRedactedKeys.standardOCR.includes(children4.key))) {
                                            checkedKeysUpdateMountOcr.push(children4.key)
                                        }
                                    }
                                })
                            })
                        })
                    })
                }
                checkedKeysOcr = checkedKeysUpdateMountOcr
            }
            //console.log('RRRRRRRRRRRR OCR STANDARD 22222222222222222 checkedKeysOcr', checkedKeysOcr)
            checkedKeys = checkedKeysNonOcr.concat(checkedKeysOcr)
            this.props.onCheckedKeysAllMixedStandard(checkedKeys)
            this.setState({
                checkedKeys
            })
        })
    }
    fetchDataUpdate = () => {
        const { docAnnotationsStandard, docAnnotationsColorsStandard, categoriesColorsStandard, expandedKeysAll, docAnnotationsStandardOcr } = this.props
        let { expandedKeys } = this.state
        expandedKeys = [...expandedKeys, ...expandedKeysAll ],
            this.setState({
                docAnnotations: docAnnotationsStandard,
                docAnnotationsOcr: docAnnotationsStandardOcr,
                docAnnotationsColors: docAnnotationsColorsStandard,
                categoriesColors: categoriesColorsStandard,
                expandedKeys
                //expandedKeys
            }, () => {
                this.props.onCheckAiAnnotations(true)
                this.renderCategoriesSectionsUpdate()
            })
    }

    onColourAnnotations = () => {
        const { treeDataAnnotations, categoriesVisible } = this.state
        const { activeKey } = this.props

        treeDataAnnotations && treeDataAnnotations[0].children.map((keyColour) => {
            const aiAnnotations = document.querySelectorAll(`.${ keyColour.key }`)
            for (const aiAnnotate of aiAnnotations) {
                if (activeKey === 'standardTab' && categoriesVisible[keyColour.key]) {
                    aiAnnotate.style.background = keyColour.backgroundColor
                    aiAnnotate.style.borderColor = keyColour && keyColour.backgroundColor ? keyColour.backgroundColor.replace('0.3', '1') : ''
                } else {
                    aiAnnotate.style.background = 'transparent'
                    aiAnnotate.style.borderColor = 'initial'
                }
            }
            return true
        })
    }

    checkDocAnnotationCategoryOcrUpdate = () => {
        const { docAnnotationsOcr, treeDataAnnotationsOcr } = this.state
        const { treeDataAnnotationsStandard, docAnnotationCategoriesStandard, docAnnotationsStandardOcr } = this.props
        treeDataAnnotationsStandard && treeDataAnnotationsStandard[0].children && treeDataAnnotationsStandard[0].children.length > 0 && treeDataAnnotationsStandard[0].children.forEach((children1) => {
            children1.children.length > 0 && children1.children.forEach((children2) => {
                if (Object.keys(docAnnotationsStandardOcr) && Object.keys(docAnnotationsStandardOcr).length) {
                    Object.keys(docAnnotationsStandardOcr).forEach((category) => {
                        let title = category && category.replace('doc_annotate_', '')
                        title = title && title.replace('_', ' ')
                        if (children2.key === title) {
                            docAnnotationsStandardOcr[category] && docAnnotationsStandardOcr[category].length > 0 && docAnnotationsStandardOcr[category].forEach((nameParent, index) => {
                                const parentAllCategory = {
                                    backgroundColor: children2.backgroundColor,
                                    children: [],
                                    key: `${ nameParent }`,
                                    name: nameParent,
                                    index: index,
                                    title: nameParent,
                                    pageNumber: '0',
                                    isOcr: this.props.isOcr,
                                }
                                children2.children.push(parentAllCategory)
                            })
                        }
                    })
                }
                else
                if (Object.keys(docAnnotationsOcr) && Object.keys(docAnnotationsOcr).length) {
                    Object.keys(docAnnotationsOcr).forEach((category) => {
                        let title = category && category.replace('doc_annotate_', '')
                        title = title && title.replace('_', ' ')
                        if (children2.key === title) {
                            docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
                                const parentAllCategory = {
                                    backgroundColor: children2.backgroundColor,
                                    children: [],
                                    key: `${ nameParent }`,
                                    name: nameParent,
                                    index: index,
                                    title: nameParent,
                                    pageNumber: '0',
                                    isOcr: this.props.isOcr,
                                }
                                children2.children.push(parentAllCategory)
                            })
                        }
                    })
                }
            })

        })
        this.setState({
            treeDataAnnotations: treeDataAnnotationsOcr
        })
    }


    checkDocAnnotationCategoryOcr = () => {
        const { docAnnotationsOcr, treeDataAnnotationsOcr } = this.state
        treeDataAnnotationsOcr && treeDataAnnotationsOcr[0].children && treeDataAnnotationsOcr[0].children.length > 0 && treeDataAnnotationsOcr[0].children.forEach((children1) => {
            children1.children.length > 0 && children1.children.forEach((children2) => {
                if (docAnnotationsOcr && Object.keys(docAnnotationsOcr).length) {
                    Object.keys(docAnnotationsOcr).forEach((category) => {
                        let title = category && category.replace('doc_annotate_', '')
                        title = title && title.replace('_', ' ')
                        if (children2.key === title) {
                            docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
                                const parentAllCategory = {
                                    backgroundColor: children2.backgroundColor,
                                    children: [],
                                    key: `${ nameParent }`,
                                    name: nameParent,
                                    index: index,
                                    title: nameParent,
                                    pageNumber: '0',
                                    isOcr: this.props.isOcr
                                }
                                children2.children.push(parentAllCategory)
                            })
                        }
                    })
                }
            })

        })
        this.setState({
            treeDataAnnotations: treeDataAnnotationsOcr
        })
    }
    changeDataAnnotationCategory = (docAnnotationCategories) => {
        const { treeDataAnnotations } = this.state
        const newDocAnnotationCategories = []
        if (treeDataAnnotations && treeDataAnnotations.length) {
            const tree = treeDataAnnotations[0]
            if (tree.children && tree.children.length) {
                tree.children.forEach((parent) => {
                    if (parent.children && parent.children.length) {
                        parent.children.forEach((children) => {
                            const temp = `doc_annotate_${ children.key.split(' ').join('_') }`
                            if (docAnnotationCategories.includes(temp)) {
                                if (children.children.length) {
                                    let numberNumberOfChildren = 0
                                    children.children.forEach((nephew) => {
                                        numberNumberOfChildren += nephew.children.length
                                    })
                                    newDocAnnotationCategories.push({ 'name': temp, 'children': numberNumberOfChildren })
                                } else {
                                    newDocAnnotationCategories.push({ 'name': temp, 'children': children.children.length })
                                }
                            }
                        })
                    }
                })
            }
        }
        return newDocAnnotationCategories
    }

    checkForDocAnnotationCategories = () => {
        const { docAnnotations } = this.state
        const docAnnotationCategories = []
        const notAnnotationCategories = ['doc_annotated', 'doc_annotate_address', 'doc_annotate_keyword']
        if (docAnnotations && Object.keys(docAnnotations).length) {
            Object.keys(docAnnotations).map((category) => {
                if (!notAnnotationCategories.includes(category)) {
                    docAnnotationCategories.push(category)
                }
                return true
            })
            if (!this.state.docAnnotationCategories.length) {
                this.setState({ docAnnotationCategories })
            }
        }
    }
    saveKeysForClearAll = (idDocument, treeData) => {
        const data = {
            id: idDocument,
            treeData: treeData
        }
        apiSendKeysHelper(data).then(() => {

        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }

    showHideCategory = (e, category, state, item) => {
        e.stopPropagation()
        const { treeDataAnnotations } = this.state
        const showHideCategoryItemsUpdate = []
        if (this.props.isOcr) {
            this.props.showHideCategoryOcr(e, category, state, item)
        }
        const aiAnnotations = document.querySelectorAll(`.${ category }`)
        this.setState({
            categoriesVisible: {
                ...this.state.categoriesVisible, [category]: state
            }
        }, () => {
            treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
                if (!this.state.categoriesVisible[item2.title]) {
                    item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                        item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item4) => {
                            item4 && item4.children && item4.children.length > 0 && item4.children.forEach((item5) => {
                                showHideCategoryItemsUpdate.push(item5.key)
                            })
                        })
                    })
                }
            })
            this.setState({
                showHideCategoryItems: showHideCategoryItemsUpdate
            })
            if (!this.state.categoriesVisible[category]) {
                for (const aiAnnotate of aiAnnotations) {
                    aiAnnotate.style.background = 'transparent'
                }
            } else {
                this.props.onChangeStatusIconHideAllHighlighting()
                for (const aiAnnotate of aiAnnotations) {
                    aiAnnotate.style.background = this.state.categoriesColors[category]
                }
            }
        })
    }

    onCategoryRenderVisible = () => {
        const { categoriesVisible } = this.state
        Object.keys(categoriesVisible) && Object.keys(categoriesVisible).length > 1 && Object.keys(categoriesVisible).forEach((category) => {
            const aiAnnotations = document.querySelectorAll(`.${ category }`)
            if (categoriesVisible[category] === false) {
                for (const aiAnnotate of aiAnnotations) {
                    aiAnnotate.style.background = 'transparent'
                }
            } else {
                for (const aiAnnotate of aiAnnotations) {
                    aiAnnotate.style.background = this.state.categoriesColors[category]
                }
            }
        })
    }

    expandHideCategory = (e, key, action) => {
        e.stopPropagation()
        let { expandedKeys } = { ...this.state }
        const expandKeysStandard = []
        expandKeysStandard.push(key)
        if (action === 'add') {
            expandedKeys.push(key)
            this.setState({
                expandedKeys: this.state.expandedKeys.concat(expandKeysStandard)
            })
        } else {
            //expandedKeys = expandedKeys.filter((eKey) => eKey !== key)
            this.setState({
                expandedKeys: expandedKeys.filter((eKey) => eKey !== key)
            })
        }
        this.setState({
            //expandedKeys,
            autoExpandParent: false,
        })
    }

    toggleColorPicker = (key, option, event) => {
        if (option) {
            const newVal = this.state.displayColorPicker
            newVal[key] = !Boolean(this.state.displayColorPicker[key])
            this.setState({
                displayColorPicker: newVal,
                rowSelectedCategory: key
            })
        } else {
            const newVal = this.state.displayColorPicker
            newVal[key] = false
            this.setState({
                displayColorPicker: newVal,
                lastSelectedColor: ''
            })
        }
        if (event) event.stopPropagation()
    }

    changeColorPicker = (color, e) => {
        e.stopPropagation()
        this.setState({ lastSelectedColor: color.rgb })
    };

    closeAndSaveColorPicker = (record, key, e) => {
        e.stopPropagation()
        const { rowSelectedCategory, docAnnotationsColors, lastSelectedColor } = this.state
        const newVal = docAnnotationsColors
        for (let i = 0; i < newVal.length; i++) {
            if (newVal[i].id === record.id) {
                newVal[i].color = lastSelectedColor
            }
        }
        const newValue = this.state.displayColorPicker
        newValue[rowSelectedCategory] = false
        record.color.a = 0.3
        record.color = this.convertRgbaToString(record.color)
        this.saveColor(record, newVal, key, newValue)
    }

    convertRgbaToString = ({ r, g, b, a }) => {
        return `rgba(${ r }, ${ g }, ${ b }, ${ a })`
    }

    onChangeTreeDataColour = (newVal) => {
        const { treeDataAnnotations } = this.state
        newVal && newVal.map((newValue) => {
            treeDataAnnotations && treeDataAnnotations[0].children.map((treeData) => {
                if (newValue.category === treeData.key) {
                    treeData.backgroundColor = newValue.color
                }
                return true
            })
            return true
        })
        this.setState({
            treeDataAnnotations
        })
    }

    saveColor(record, newVal, key, newValue) {
        record.clientId = this.props.clientId
        record.defaultColor = 0
        record.userId = this.props.userId
        if (newVal) {
            this.onChangeTreeDataColour(newVal)
        }

        updateAnnotationColor(record).then((response) => {
            if (response.status === 200) {
                CustomNotificationManager.success(`You successfully changed the color for the ${ record.category } category`, 'Color changed')
                this.setState(
                    {
                        data: newVal,
                        lastSelectedColor: '',
                        displayColorPicker: newValue,
                        categoriesColors: {
                            ...this.state.categoriesColors, [key]: record.color
                        }
                    }, () => {
                        const aiAnnotations = document.querySelectorAll(`.${ key }`)
                        for (const aiAnnotate of aiAnnotations) {
                            aiAnnotate.style.background = this.state.categoriesColors[key]
                        }
                    }
                )
            }
        }).catch((error) => {
            if (error?.response?.status === 504) {
                CustomNotificationManager.cleanBuffer('warning', '')
            }else{
                console.log(error)
            }
        })
    }

    extractLeafKeys = (node) => {
        const { unCheckedKeys } = this.state
        if (node.children && node.children.length) {
            node.children.forEach((item) => {
                this.extractLeafKeys(item.props)
            })
        } else {
            if (!unCheckedKeys.includes(node['data-key'])) {
                unCheckedKeys.push(node['data-key'])
            }
            this.setState({ unCheckedKeys })
        }
        //console.log('unCheckedKeys SSSSSSSSSSSSSSSSSS 666666666666666666666666666666666', unCheckedKeys)
    }

    onCheck = (checkedKeys, { checkedNodes, checked, node }) => {
        const { docAnnotationsChildrenKeys, unCheckedKeys, sendStatusOnClearAll, docAnnotationsOcr } = this.state
        if (this.props.isSmartBoxApplication) {
            this.props.checkOnClearAll(sendStatusOnClearAll)
        }
        if (!checked) {
            this.extractLeafKeys(node.props)
        } else if (unCheckedKeys.length) {
            unCheckedKeys.forEach((item, index) => {
                if (checkedKeys.includes(item)) {
                    unCheckedKeys.splice(index, 1)
                }
            })
        }
        //this.extractLeafKeys(node.props)
        let newChildren = []
        const newFinalChildren = []

        newChildren = docAnnotationsChildrenKeys.filter((redChild) => checkedKeys.map((red) => red).includes(redChild.key))
        newChildren && newChildren.map((newChildren2) => {
            newFinalChildren.push(`${ newChildren2.key } => ${ newChildren2.name } => ${ newChildren2.pageNumber }`)
            return true
        })

        if (checkedKeys && checkedKeys.length) {
            this.props.setNumberOfAnnotateSelected(checkedKeys)
        }
        const listSavedName = []
        if (docAnnotationsOcr && Object.keys(docAnnotationsOcr).length) {
            Object.keys(docAnnotationsOcr).forEach((category) => {
                docAnnotationsOcr[category] && docAnnotationsOcr[category].length > 0 && docAnnotationsOcr[category].forEach((nameParent, index) => {
                    checkedKeys && checkedKeys.length > 0 && checkedKeys.forEach((keyName) => {
                        if (keyName === nameParent) {
                            listSavedName.push(keyName)
                        }
                    })
                })
            })
        }
        const checkNodes = checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord)
        this.props.onCheckedKeysObjectRefs(checkNodes)
        this.setState({
            //checkedKeys,
            checkedKeysOcr: listSavedName,
            unCheckedKeys,
            newFinalChildren: newFinalChildren,
            selectClassDisabled: '',
            checkedKeysObjectRefs: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord),
            checkedKeysObjectRefsNonOcr: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord && !obj.isOcr),
            checkedKeysObjectRefsNonOcrUpdateFilter: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord && !obj.isOcr && !this.state.checkedKeys.includes(obj.key)),
            checkedKeysObjectRefsOcr: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord && obj.isOcr),
            checkedKeysObjectRefsOcrUpdateFilter: checkedNodes.map((node) => node.props.dataRef).filter((obj) => obj.isSearchedWord && obj.isOcr && !this.state.checkedKeys.includes(obj.key))
        })
    };

    findElementByClassAndAddMarkPulse(selectedKeys, node) {
        let { expandedKeys } = { ...this.state }
        const { categoriesColors, treeDataAnnotations } = this.state
        const checkNodeKeyStandard = []
        const newCategoryColour = []
        const treeChildrenCategory = []
        categoriesColors && Object.keys(categoriesColors).forEach((key) => {
            newCategoryColour.push({
                key: key,
                nameCategory: key,
                colorCategory: categoriesColors[key]
            })
        })
        treeDataAnnotations && treeDataAnnotations[0].children.forEach((child) => {
            child && child.children.forEach((child2) => {
                child2.backgroundColor = child.backgroundColor
                child2 && child2.children.forEach((child3) => {
                    child3.backgroundColor = child2.backgroundColor
                    treeChildrenCategory.push(child3)
                })
            })
        })
        if (node && node.props.dataRef.key) {
            checkNodeKeyStandard.push(node.props.dataRef.key)
            this.setState({
                selectedChildrenBold: checkNodeKeyStandard,
                selectedPgNrBoldStandard: checkNodeKeyStandard
            })
        }
        if (node.props.dataRef.key.includes('found-parent-all') || node.props.dataRef.identifier === 'category-parent') {
            if (!expandedKeys.includes(node.props.dataRef.key)) {
                expandedKeys.push(node.props['data-key'])
                this.setState({
                    expandedKeys,
                    autoExpandParent: false
                })
            } else {
                expandedKeys = expandedKeys.filter((eKey) => eKey !== node.props['data-key'])
                this.setState({
                    expandedKeys,
                    autoExpandParent: false
                })
            }
        } else {
            this.setState({
                selectedWord: selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ''
            }, () => {
                if (this.state.selectedWord) {
                    const { pageNumber, name } = node.props.dataRef
                    let { backgroundColor } = node.props.dataRef
                    treeChildrenCategory && treeChildrenCategory.length > 0 && treeChildrenCategory.forEach((children) => {
                        if (children.name === name) {
                            backgroundColor = children.backgroundColor
                        }
                    })
                    const oldElement = document.querySelector('#matched-word--current')
                    if (oldElement) {
                        oldElement.removeAttribute('id')
                    }
                    if (+pageNumber !== +this.props.pageNumber) {
                        //this.props.changePage(+pageNumber)
                        this.props.changePageMixed(+pageNumber)
                    }
                    const element = document.getElementsByClassName(this.state.selectedWord)
                    if (element && element.length > 0) {
                        element[0].id = 'matched-word--current'
                        element[0].style.borderColor = backgroundColor.replace('0.3', '1')
                        element[0].scrollIntoView(false)
                    }
                } else {
                    //const element = document.querySelector('#matched-word--current')
                    //element.removeAttribute('id')
                }
            })
        }
    }
    onUpdateRedactionNumber = (classList) => {
        const data = {
            doc_id: this.props.idDocument,
            box_number_words_redacted: classList.length
        }
        apiSmartBoxUpdateRedactionNumber(data).then((response) => {

        })
    }

    onCheckAllCanvasPoints = () => {
        apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
            this.setState({
                responseCheckPdfCanvas: response.data
            })
            if(response.data === 'Processing') {
                this.setIntervalCheckAllCanvasPoints()
            } else {
                this.props.fetchSmartBoxDataCanvasOcr()
            }
        })
    }
    setIntervalCheckAllCanvasPoints = () => {
        const { responseCheckPdfCanvas} = this.state
        let { setIntervalCheckPdfCanvas} = this.state
        if (setIntervalCheckPdfCanvas === 0) {
            if (responseCheckPdfCanvas === 'Processing') {
                setIntervalCheckPdfCanvas = setInterval(this.callBackCheckAllCanvasPoints, 5000)
                this.setState({
                    setIntervalCheckPdfCanvas
                })
            } else {
                clearInterval(setIntervalCheckPdfCanvas)
            }
        }
    }

    callBackCheckAllCanvasPoints = () => {
        const { setIntervalCheckPdfCanvas, responseCheckPdfCanvas} = this.state
        if(responseCheckPdfCanvas === 'Processing') {
            apiSmartBoxCheckAllCanvasPoints(this.props.idDocument).then((response) => {
                this.setState({
                    responseCheckPdfCanvas: response.data
                })
                if (response.data === 'Finished') {
                    this.props.fetchSmartBoxDataCanvasOcr()
                }
            })
        }
    }

    onDrawRegExAndDict = () => {
        const { treeBusiness } = this.props
        const selectAllItemsBusiness = []
        treeBusiness && treeBusiness.length > 0 && treeBusiness[0].children && treeBusiness[0].children.length > 0 && treeBusiness[0].children.forEach((child) => {
            child && child.children && child.children.length > 0 && child.children.forEach((child2) => {
                child2 && child2.children && child2.children.length > 0 && child2.children.forEach((child3) => {
                    selectAllItemsBusiness.push(child3)
                })
            })
        })
        const sortedByPageItemsBusiness = selectAllItemsBusiness.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        this.onRedactDict(sortedByPageItemsBusiness.map((item) => item.key))
    }
    onRedactDict = (classListBusiness) => {
        const { treeBusiness } = this.props
        if (treeBusiness && treeBusiness.length > 0) {
            this.setState({
                classListBusiness: classListBusiness
            })
            this.props.drawAllMatchedWordsByClassList(classListBusiness, [], 'dictionaries')
        }
    }

    componentWillUnmount() {
        global.finalItems = {}
        global.items = {}
        global.markedTimes = 0
        global.itemsToBeMarked = 0
        global.arEl = []
        global.arOptions = []

        clearInterval(timerCheckMarkReady)
        clearInterval(timerCheckCanvas)
    }

    checkNode = () => {
        const { docAnnotationsNewQuery, treeDataAnnotations } = this.state
        const { treeDataAnnotationsStandard } = this.props
        let { checkedKeys } = this.state
        const checkedChildren = []
        const docAnnotationsChildren = []
        treeDataAnnotationsStandard[0].children.length > 0 && treeDataAnnotationsStandard[0].children.forEach((item) => {
            item && item.children.length > 0 && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                    item3 && item3.children.forEach((item4) => {
                        docAnnotationsChildren.push(item4)
                    })
                })
            })
        })
        docAnnotationsChildren && docAnnotationsChildren.map((items) => {
            docAnnotationsNewQuery && docAnnotationsNewQuery.map((chil) => {
                chil && chil.children.map((item) => {
                    if (item.status === 1 && items.key === item.key) {
                        checkedChildren.push(items.key)
                    }
                    return true
                })
                return true
            })
            return true
        })

        treeDataAnnotations && treeDataAnnotations[0].children.map((item) => {
            item && item.children.map((item2) => {
                item2 && item2.children.map((item3) => {
                    item3 && item3.children.map((item4) => {
                        docAnnotationsNewQuery && docAnnotationsNewQuery.map((chil) => {
                            chil && chil.children.map((item5) => {
                                if (item4.key === item5.key) {
                                    item4.status = item5.status
                                    item3.parent = item.title
                                    item4.parent = item.title
                                }
                                return true
                            })
                            return true
                        })
                        return true
                    })
                    return true
                })
                return true
            })
            return true
        })
        checkedKeys = this.updateCheckedList(checkedKeys.concat(checkedChildren))
        this.props.statusCheckedChildrenRedact(checkedKeys)
        this.setState({
            checkedKeys,
            checkedKeysNew: checkedKeys,
            docAnnotationsChildrenKeys: docAnnotationsChildren,
            checkStatusChildren: checkedChildren,
            treeDataAnnotations
        }, () => this.checkStatusParent())
    }
    checkStatusParent = () => {
        const { treeDataAnnotations } = this.state
        treeDataAnnotations && treeDataAnnotations[0].children.forEach((item) => {
            item && item.children.forEach((item2) => {
                item2 && item2.children.forEach((item3) => {
                    item3 && item3.children.forEach((item4) => {
                        // if (item.title === item4.parent) {
                        if (item4.status === 1) {
                            item.redactAllChild = true
                        }
                        else {
                            item.redactAllChild = false
                        }
                        // }
                    })
                })
            })
        })
        this.setState({
            treeDataAnnotations
        })
    }
    updateCheckedList = (checkedList) => {
        const { extraCheckedKeys } = this.state
        if (extraCheckedKeys && extraCheckedKeys.length && checkedList.length) {
            extraCheckedKeys.forEach((item) => {
                if (!checkedList.includes(item)) {
                    checkedList.push(item)
                }
            })
        }
        return checkedList
    }

    onClickDisableCheckedKeys = (status) => {
        this.setState({
            disableCheckedKeys: status
        })
    }

    onCheckDisabled = () => {
        CustomNotificationManager.info('Select update redactions first', 'Aborted')
        this.setState({
            selectClassDisabled: 'disabled-standard-tree'
        })
        return null
    }
    onDisplayDifferenceColourOnActiveTab = () => {
        const { treeDataAnnotations, categoriesVisible } = this.state
        const { activeKey } = this.props
        treeDataAnnotations && treeDataAnnotations[0].children.map((keyColour) => {
            const aiAnnotations = document.querySelectorAll(`.${ keyColour.key }`)
            for (const aiAnnotate of aiAnnotations) {
                if (activeKey === 'standardTab' && categoriesVisible[keyColour.key]) {
                    aiAnnotate.style.background = keyColour.backgroundColor
                    aiAnnotate.style.borderColor = keyColour && keyColour.backgroundColor ? keyColour.backgroundColor.replace('0.3', '1') : ''
                } else {
                    aiAnnotate.style.background = 'transparent'
                    aiAnnotate.style.borderColor = 'initial'
                }
            }
            return true
        })
    }

    onSelectOcr = () => {
        return true
    }
    onMouseEnter = (e) => {
    }
    onMouseEnterItemsTree = (e, item) => {
        this.setState({
            redactedDisplayHover: item,
            showVisibleCategory: item
        })
    }
    findElementByClassChildren(className) {
        if (!this.props.isOcr) {
            //console.log('className SSSSSSSSSSSSSSSSSSSSSSSS 111111111111111111111', className)
            const element = document.getElementsByClassName(className.key)
            const oldElement = document.querySelector('#matched-word--current')
            if (className) {
                //console.log('oldElement SSSSSSSSSSSSSSSSSSSSSSSS', oldElement)
                if (oldElement) {
                    oldElement.removeAttribute('id')
                }
                if (+className.pageNumber !== +this.props.pageNumber) {
                    //this.props.changePage(+className.pageNumber)
                    this.props.changePageMixed(+className.pageNumber)
                }
                if (element && element.length > 0) {
                    element[0].id = 'matched-word--current'
                    element[0].style.borderColor = className.backgroundColor.replace('0.3', '1')
                    element[0].scrollIntoView(false)
                }
            } else {
                const element = document.querySelector('#matched-word--current')
                element.removeAttribute('id')
            }
        }
    }
    findElementByClassChildrenOcr(className) {
        if (className && className.children.length === 0) {
            if (+className.pageNumber !== +this.props.pageNumber) {
                this.props.changePageMixed(+className.pageNumber)
            }
        }
    }
    onSelectFindElementTree = (e, item) => {
        e.stopPropagation()
        const { treeDataAnnotations, showHideCategoryItems } = this.state
        if (!showHideCategoryItems.includes(item.key) && !item.isOcr) {
            let checkedKeysRef = []
            if (item && item.children.length > 0) {
                item.children.forEach((item) => {
                    checkedKeysRef.push(item)
                })
            } else {
                checkedKeysRef.push(item)
            }
            if (item && item.children && item.children.length > 0) {
                this.props.onCheckedKeysObjectRefs(checkedKeysRef)
            } else {
                this.findElementByClassChildren(item)
            }
            this.setState({
                selectedChildrenBold: item.key
            })
        } else if (item.isOcr && item) {
            let checkedKeysRefOcr = []
            if (item && item.children.length > 0) {
                item.children.forEach((item) => {
                    checkedKeysRefOcr.push(item)
                })
            } else {
                checkedKeysRefOcr.push(item)
            }
            if (item && item.children && item.children.length > 0) {
                this.props.onCheckedKeysObjectRefs(checkedKeysRefOcr)
            } else {
                this.findElementByClassChildrenOcr(item)
            }
            this.setState({
                selectedChildrenBold: item.key
            })
        }
    }
    findElementByClass(className) {
        const element = document.getElementsByClassName(className)
        if (element && element.length > 0) {
            element[0].removeAttribute('id')
        }
    }
    onCheckCategoryDisabled = (e) => {
        e.stopPropagation()
        return "true"
    }
    onCheckSelectedTree = (e, item) => {
        const { lockSmartViewChanges } = this.props
        //e.stopPropagation()
        if (!lockSmartViewChanges) {
            const { isOcr } = this.state
            this.setState({
                itemSelectForRedact: item
            })

            this.setState({
                disabledCheckBoxTree: true
            }, () => {
                this.onRedactAiTagCopy()
            })
        }
    }

    onRedactAiTagCopy = () => {
        const { checkedKeysObjectRefs, treeDataAnnotations, itemSelectForRedact, checkedKeysObjectRefsOcr, checkedKeysObjectRefsNonOcr } = this.state
        const sortedByPageItems = checkedKeysObjectRefs.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)
        this.drawMatchedWordsCopy(sortedByPageItems.map((item) => item.key), sortedByPageItems, checkedKeysObjectRefsNonOcr, checkedKeysObjectRefsOcr)
    }

    drawMatchedWordsCopy = (classList, sortedByPageItems, checkedKeysObjectRefsNonOcr, checkedKeysObjectRefsOcr) => {
        const { unCheckedKeys, checkedKeys, checkedKeysNew, itemSelectForRedact, checkedKeysObjectRefsNonOcrUpdateFilter,
            checkedKeysObjectRefsOcrUpdateFilter } = this.state
        const { treeDataAnnotations } = this.state
        const { activeKey, listCategories } = this.props
        const allWordsRedacted = []
        const allWordsRedactedOcr = []
        let allWordsRedactedMixed = []
        const classListMixed = []
        const selectRedactionStandardOcr = []
        const checkedKeysObjectRefsOcrFilter = []
        const checkedKeysObjectAll = []
        checkedKeysObjectRefsOcr && checkedKeysObjectRefsOcr.length > 0 && checkedKeysObjectRefsOcr.forEach((elem) => {
            checkedKeysObjectRefsOcrFilter.push(elem.key)
        })

        treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 && treeDataAnnotations[0].children.forEach((item2) => {
            item2 && item2.children && item2.children.length > 0 && item2.children.forEach((item3) => {
                item3 && item3.children && item3.children.length > 0 && item3.children.forEach((item4) => {
                    // if (item4.children.length === 0 && item4.isOcr && classList.includes(item4.key)) {
                    //     item4.categoryName = item2.key
                    //     item4.subcategoryName = item3.key
                    //     allWordsRedactedOcr.push(item4)
                    //     if (checkedKeysObjectRefsOcrFilter.includes(item4.key)) {
                    //         selectRedactionStandardOcr.push({
                    //             word: item4.key,
                    //             category: item2.key,
                    //             subcategory: item3.key,
                    //         })
                    //     }
                    // }
                    item4 && item4.children && item4.children.length > 0 && item4.children.forEach((item5) => {
                        checkedKeysObjectAll.push(item5)
                        if (item5.isOcr && classList.includes(item5.key)) {
                            item5.categoryName = item2.key
                            item5.subcategoryName = item3.key
                            allWordsRedactedOcr.push(item5)
                            if (checkedKeysObjectRefsOcrFilter.includes(item5.key)) {
                                selectRedactionStandardOcr.push({
                                    word: item5.key,
                                    category: item2.key,
                                    subcategory: item3.key,
                                })
                            }
                        }
                        if (!item5.isOcr && classList.includes(item5.key)) {
                            item5.categoryName = item2.key
                            item5.subcategoryName = item3.key
                            allWordsRedacted.push(item5)
                            classListMixed.push(item5.key)
                        }
                    })
                })
            })
        })
        allWordsRedactedMixed = allWordsRedacted.concat(allWordsRedactedOcr)
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                allWordsRedacted && allWordsRedacted.length > 0 && allWordsRedacted.forEach((item) => {
                    if ((elem2.smartViewSubcategoryName && elem2.smartViewSubcategoryName.toLowerCase() === item.subcategoryName.toLowerCase())) {
                        item.categoryColor = elem2.subcategoryColorWithAlpha
                    }
                })
            })
        })
        let numberRedact = []
        if (itemSelectForRedact && itemSelectForRedact.key.includes('found-parent-all')) {
            numberRedact = itemSelectForRedact.children
        } else if (itemSelectForRedact.identifier !== 'category-parent' && !itemSelectForRedact.key.includes('found-parent-all') && itemSelectForRedact.children.length === 0) {
            numberRedact.push(itemSelectForRedact)
        } else {
            const numberRedactNonOcr = []
            const numberRedactOcr = []
            checkedKeysObjectRefsNonOcrUpdateFilter && checkedKeysObjectRefsNonOcrUpdateFilter.length > 0 && checkedKeysObjectRefsNonOcrUpdateFilter.forEach((elem) => {
                numberRedactNonOcr.push(elem.key)
            })
            checkedKeysObjectRefsOcrUpdateFilter && checkedKeysObjectRefsOcrUpdateFilter.length > 0 && checkedKeysObjectRefsOcrUpdateFilter.forEach((elem) => {
                numberRedactOcr.push(elem.key)
            })
            numberRedact = numberRedactNonOcr.concat(numberRedactOcr)
            //numberRedact = classList
        }
        const existingRedactedKeysNew = new Set([ ...this.state.redactedKeys, ...classList ])
        const newUnCheckedKeysRemoved = []
        if (unCheckedKeys && unCheckedKeys.length) {
            unCheckedKeys.forEach((item, index) => {
                if (existingRedactedKeysNew.has(item)) {
                    existingRedactedKeysNew.delete(item)
                }
                if (!classList.includes(item)) {
                    newUnCheckedKeysRemoved.push(item)
                }
            })
        }
        const textMessageRedact = newUnCheckedKeysRemoved && newUnCheckedKeysRemoved.length === 1 ? `Remove ${ newUnCheckedKeysRemoved.length } selected redaction?` :
            newUnCheckedKeysRemoved && newUnCheckedKeysRemoved.length > 0 ? `Remove ${ newUnCheckedKeysRemoved.length } selected redactions?` : numberRedact && numberRedact.length === 1 ? `Redact ${ numberRedact.length } selected?` : `Redact all ${ numberRedact.length } selected?`
        swal.fire({
            text: 'Redact all selected?',
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 19 25">
                  <g fill="none" fillRule="evenodd">
                    <path stroke=" #00D3D8" strokeWidth="4" d="M18 24V7.215L11.716 1H1v23h17z"/>
                    <path stroke="#00D3D8"  strokeWidth="4" d="M11 1v7h7"/>
                    <path fill="#00D3D8" d="M5 12h9v3H5zM5 17h6v3H5z"/>
                  </g>
                </svg>
          <h3 class="swall-redact-title"> ${ textMessageRedact }</h3>`,
            showCancelButton: true,
            confirmButtonColor: '#3DD8DB',
            cancelButtonColor: '#3DD8DB',
            confirmButtonText: newUnCheckedKeysRemoved && newUnCheckedKeysRemoved.length > 0 ? 'Unredact' : 'REDACT',
            customClass: {
                container: 'redact-swall-wr'
            }
        }).then((result) => {
            if (result.value) {
                this.props.getStatusElementRedaction('elementStandard')
                this.setState({
                    checkedKeys,
                    checkedKeysNew: checkedKeys
                })
            } else {
                // this.setState({
                //     checkedKeys: checkedKeysNew
                // })
            }
            if (result.value) {
                // this.props.toggleLoadingProcess(true, 'redact')
                //de decomentat 22222222222
                if (unCheckedKeys.length === 0) {
                    this.onUpdateRedactionNumber(classList)
                }
                this.setState({
                    disabledCheckBoxTree: false
                })
                const existingRedactedKeys = new Set([ ...this.state.redactedKeys, ...classList ])
                const newUnCheckedKeys = []
                if (unCheckedKeys && unCheckedKeys.length) {
                    unCheckedKeys.forEach((item, index) => {
                        if (existingRedactedKeys.has(item)) {
                            existingRedactedKeys.delete(item)
                        }
                        if (!classList.includes(item)) {
                            newUnCheckedKeys.push(item)
                        }
                    })
                }
                const objectListRedact = {
                    allWordsRedacted: allWordsRedacted,
                    allWordsRedactedOcr: allWordsRedactedOcr,
                    allWordsRedactedMixed: allWordsRedactedMixed,
                    classList: classList,
                    classListMixed: classListMixed,
                    selectAllItemsStandard: checkedKeysObjectRefsNonOcrUpdateFilter,
                    selectAllItemsStandardSaveKeys: checkedKeysObjectRefsNonOcr,
                    checkedKeysObjectRefsNonOcrUpdateFilter: checkedKeysObjectRefsNonOcrUpdateFilter,
                    selectAllItemsStandardOcr: checkedKeysObjectRefsOcrUpdateFilter,
                    selectAllItemsStandardOcrSaveKeys: checkedKeysObjectRefsOcr,
                    checkedKeysObjectRefsOcrUpdateFilter: checkedKeysObjectRefsOcrUpdateFilter,
                    selectRedactionStandardOcr: selectRedactionStandardOcr,
                    newUnCheckedKeys: newUnCheckedKeys,
                    checkedKeysObjectAll: checkedKeysObjectAll,
                }
                //de decomentat
                this.onSaveQueryStatusMixed(objectListRedact)
                this.setState({
                    redactedKeys: existingRedactedKeys
                }, () => {
                    //this.props.drawAllMatchedWordsByClassList(classList, newUnCheckedKeys, 'standard', allWordsRedacted)
                    // am comentat
                    this.props.drawRedactAllMatchedWordsMixed(classList, classListMixed, newUnCheckedKeys, 'standard', objectListRedact)
                    this.setState({
                        unCheckedKeys: []
                    })
                    if (activeKey === 'standardTab') {
                        this.setState({
                            disableCheckedKeys: false
                        })
                        this.props.onCheckStatusSelectAgainRedacted(false)
                    }
                })
            } else if (activeKey === 'standardTab') {
                this.setState({
                    disableCheckedKeys: true
                })
                this.setState({
                    disabledCheckBoxTree: false
                })
                this.props.onCheckStatusSelectAgainRedacted(true)
            } else {
                this.setState({
                    disabledCheckBoxTree: false
                })
            }
        })
    }

    onSaveQueryStatusMixed = (objectListRedact) => {
      const { alreadyRedactedKeys } = this.props
        let keyListWordNonOcr = []
        let keyListWordNonOcrDictionary = []
        let keyListWordNonOcrRegex = []
        let keyListWordOcr = []
        let keyListWordOcrDictionary = []
        let keyListWordOcrRegex = []

        let keyListWordNonOcrUnChecked = []
        let keyListWordOcrUnChecked = []

        if (objectListRedact.selectAllItemsStandardSaveKeys && objectListRedact.selectAllItemsStandardSaveKeys.length > 0) {
            objectListRedact.selectAllItemsStandardSaveKeys.forEach((standard) => {
                //keyListWordNonOcr.push(`${ standard.key } => ${ standard.name } => ${ standard.pageNumber }`)
                keyListWordNonOcr.push(standard.keyMixtRedaction)
            })
        }
        if (objectListRedact.selectAllItemsStandardOcrSaveKeys && objectListRedact.selectAllItemsStandardOcrSaveKeys.length > 0) {
            objectListRedact.selectAllItemsStandardOcrSaveKeys.forEach((standard) => {
                keyListWordOcr.push(standard.key)
            })
        }
        let updateStatus = false
        if ((keyListWordNonOcr && keyListWordNonOcr.length > 0)) {
            updateStatus = true
        } else {
            updateStatus = false
        }

        if (objectListRedact.checkedKeysObjectAll && objectListRedact.checkedKeysObjectAll.length > 0) {
            objectListRedact.checkedKeysObjectAll.forEach((elemCheck) => {
                 if (objectListRedact.newUnCheckedKeys && objectListRedact.newUnCheckedKeys.length > 0) {
                     if (elemCheck.isOcr && objectListRedact.newUnCheckedKeys.includes(elemCheck.key)) {
                         keyListWordOcrUnChecked.push(elemCheck.key)
                     }
                     if (!elemCheck.isOcr && objectListRedact.newUnCheckedKeys.includes(elemCheck.key)) {
                         keyListWordNonOcrUnChecked.push(elemCheck.keyMixtRedaction)
                     }
                 }
            })
        }
        if (keyListWordOcrUnChecked && keyListWordOcrUnChecked.length > 0) {
            keyListWordOcrDictionary = this.props.alreadyRedactedKeys.businessOCR && this.props.alreadyRedactedKeys.businessOCR.length > 0 ? this.props.alreadyRedactedKeys.businessOCR.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
            keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR && this.props.alreadyRedactedKeys.regexOCR.length > 0 ? this.props.alreadyRedactedKeys.regexOCR.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
        } else {
            keyListWordOcrDictionary = this.props.alreadyRedactedKeys.businessOCR
            keyListWordOcrRegex = this.props.alreadyRedactedKeys.regexOCR
        }
        if (keyListWordNonOcrUnChecked && keyListWordNonOcrUnChecked.length > 0) {
            keyListWordNonOcrDictionary = this.props.alreadyRedactedKeys.business && this.props.alreadyRedactedKeys.business.length > 0 ? this.props.alreadyRedactedKeys.business.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
            keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex && this.props.alreadyRedactedKeys.regex.length > 0 ? this.props.alreadyRedactedKeys.regex.filter((elem) => !keyListWordOcrUnChecked.includes(elem)) : []
        } else {
            keyListWordNonOcrDictionary = this.props.alreadyRedactedKeys.business
            keyListWordNonOcrRegex = this.props.alreadyRedactedKeys.regex
        }
        const saveAnnotationWithStateDTO = {
            keyWordOcr: keyListWordOcr,
            keyWordOcrRegex: keyListWordOcrRegex,
            keyWordOcrDictionary: keyListWordOcrDictionary,
            keyWordNonOcr: keyListWordNonOcr,
            keyWordNonOcrRegex: keyListWordNonOcrRegex,
            keyWordNonOcrDictionary: keyListWordNonOcrDictionary,
            updateRedactions: updateStatus,
            idDocument: this.props.idDocument
        }
        apiSmartBoxSaveQueryStatusMixed(saveAnnotationWithStateDTO).then((response) => {
            this.props.onGetRedactedKeysMixed()
        })
    }

    onCheckSelectedTreeNon = () => {
        return false
    }
    render() {
        const {
            docAnnotations,
            treeDataAnnotations,
            expandedKeys,
            autoExpandParent,
            categoriesColors,
            docAnnotationsColors,
            categoriesVisible,
            displayColorPicker,
            redactedKeys,
            selectedChildrenBold,
            selectClassDisabled,
            isOcr, redactedDisplayHover, showVisibleCategory } = this.state
        let { checkedKeys } = this.state
        const { verificationOnClickClearAll, statusFilterReview, alreadyRedactedKeys, fetchResponseLoadingStandard, docAnnotationsStandardOcr,
            lockSmartViewChanges } = this.props
        this.onDisplayDifferenceColourOnActiveTab()
          //console.log('checkedKeys --- RENDER STANDARD', checkedKeys)
         // console.log('redactedKeys --- RENDER STANDARD', redactedKeys)
        // console.log('treeDataAnnotations --- RENDER', treeDataAnnotations)
       // console.log('treeDataAnnotations --- RENDER 8888888888888888888888', treeDataAnnotations)
        //console.log('categoriesColors --- RENDER 8888888888888888888888', categoriesColors)
        //console.log('this.props --- RENDER 8888888888888888888888', this.props)
        return (
            <React.Fragment>
                {treeDataAnnotations && treeDataAnnotations[0].children && treeDataAnnotations[0].children.length > 0 &&
                <div>
                    <div className="find-asset-container ai-tags-scroll update-find-asset-container">
                        <Tree
                            checkable
                            expandedKeys={expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            defaultExpandAll={true}
                            onCheck={this.onCheck}
                            checkedKeys={checkedKeys}
                            className={selectClassDisabled}
                            onSelect={this.props.isOcr ? this.onSelectOcr : (selectedKeys, {node}) => this.findElementByClassAndAddMarkPulse(selectedKeys, node)}
                            switcherIcon={<Icon type="down"/>}
                        >
                            {renderTreeNodesCopy(treeDataAnnotations, categoriesColors, expandedKeys,
                                this.expandHideCategory, docAnnotationsColors,
                                categoriesVisible, this.toggleColorPicker, this.showHideCategory, this.changeColorPicker,
                                this.closeAndSaveColorPicker, displayColorPicker, redactedKeys,
                                this.props.collapseAnnotateSelectModal, checkedKeys, selectedChildrenBold, isOcr, this.onMouseEnterItemsTree, redactedDisplayHover,
                                this.onSelectFindElementTree, this.onCheckCategoryDisabled, this.onCheckSelectedTree, showVisibleCategory,
                                statusFilterReview, alreadyRedactedKeys, lockSmartViewChanges)}
                        </Tree>
                    </div>

                </div>

                }
            </React.Fragment>
        )

    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
    userId: state.userReducer.user.id
})

StandardTabCopy.propTypes = {
    clientId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    isDocumentReady: PropTypes.bool.isRequired,
    collapseAnnotateSelectModal: PropTypes.func,
    setNumberOfAnnotateSelected: PropTypes.func,
    hideLoadingBeforeMark: PropTypes.func,
    setQueryStatus: PropTypes.func,
    setTreeDataAnnotations: PropTypes.func,
    toggleLoadingProcess: PropTypes.func
}

export default connect(mapStateToProps)(StandardTabCopy)