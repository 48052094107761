import initialStateSmartBox from './initialStateSmartBox'
import {
  SET_SMART_BOX_UPLOADING_OBJECT,
  FETCH_FOLDER_CONTENT,
  SET_SMART_BOX_SEARCHED_TEXT,
  REMOVE_SMART_BOX_INTERVAL_FOR_AI_PAGES,
  REMOVE_SMART_BOX_INTERVAL_FOR_PDF_PAGES,
  SET_SMART_BOX_INTERVAL_FOR_AI_PAGES,
  SET_SMART_BOX_INTERVAL_FOR_PDF_PAGES,
  SET_SMART_BOX_VIEW_MODE,
  SET_SMART_BOX_SCROLL_POSITION_OBJECT,
  SET_SMART_BOX_INTERVAL_FOR_BULK_REDACTION,
  REMOVE_SMART_BOX_INTERVAL_FOR_BULK_REDACTION,
  SET_SMART_BOX_EXPORT_DATA_VISIBILITY,
  SET_SMART_BOX_FETCH_TREE_DATA,
  SET_AXIOS_HEADERS_COPY,
  SET_SMART_BOX_PROJECTS,
  SET_SMART_BOX_TREE,
  SET_SMART_BOX_CURRENT_FOLDER,
  SET_SMART_BOX_LOAD_TREE,
  SET_SMART_BOX_NEW_NODE,
  SET_SMART_BOX_FOLDER_CLOSED,
  SET_SMART_BOX_MOVED_FILES,
  SET_SMART_BOX_SELECTED_FILES,
  SET_SMART_BOX_DELETED_FOLDER,
  SET_SMART_BOX_FILES_TABLE,
  SET_SMART_BOX_FETCH_FILES,
  SET_SMART_BOX_BASE_DATA_TREE,
  SET_SMART_BOX_TOGGLE_FILE_PREVIEW,
  SET_SMART_BOX_VISIBLE_DIRECTORY_NOTE,
  SET_SMART_BOX_DOCUMENT_SELECTED_ID,
  SET_SMART_BOX_RENAMED_FOLDER,
  SET_SMART_BOX_ANALYSIS_PERCENT,
  SET_SMART_BOX_PARENT_FOLDER_EMPTY,
  SET_SMART_BOX_PARENT_FOLDER_ERRORS,
  SET_SMART_BOX_PARENT_FOLDER_NAME,
  SET_SMART_BOX_FOLDERS_CHECK_ERRORS,
  SET_SMART_BOX_DOCUMENT_SELECTED_DUPLICATE,
  SET_SMART_BOX_MOVED_FOLDERS,
  SET_SMART_BOX_OLD_PARENT_OF_MOVED_FOLDERS,
  SET_SMART_BOX_NEW_PARENT_OF_MOVED_FOLDERS,
  SET_SMART_BOX_SECURITY_CONFIGURATION,
  SET_SMART_BOX_RESPONSE_DELETE_DUPLICATES,
  SET_SMART_BOX_PROGRESS_CLEAN_DELETE_DUPLICATES,
  SET_SMART_BOX_CHECK_FIRST_UPLOAD_FILE,
  SET_SMART_BOX_NOTIFICATION_BUTTON_FIRST_UPLOAD,
  SET_SMART_BOX_PAGE_LIMIT,
  SET_LIST_TERMS_PAGE_LIMIT,
  SET_BUSINESS_TERMS_PAGE_LIMIT,
  SET_OVERVIEW_PAGE_LIMIT,
  SET_SMART_BOX_DATA_READY,
  SET_SMART_BOX_TREE_CURRENT_DEPTH,
  SET_SMART_BOX_HOME_CLICKED,
  SET_SMART_BOX_CURRENT_Sort_Option,
  SET_SMART_BOX_FOLDER_HAS_BULK,
  SET_SMART_BOX_ERROR_LOADING_THUMBNAILS,
  SET_SORT_CLOSE_SMART,
  SET_RESPONSE_PROGRESS_BAR_RENAME,
  SET_SMART_BOX_PARENT_STATISTICS_READY,
  SET_SMART_FILES_ERROR_NOT_BE_CONVERTED,
  SET_SMART_BOX_UPLOAD_FAILED,
  SET_SMART_BOX_GENERATE_UNIQUE_ID,
  SET_SMART_NOT_SAR_TRACKER,
  SET_SMART_NOT_FOLDER_REDACTION_COMPLETE,
  SET_SMART_ADD_NOTE,
  SET_SMART_STATUS_CHANGE,
  SET_SMART_PER_PAGE_DUPLICATE,
  SET_SMART_NOT_UPLOAD_COMPLETE,
  SET_SMART_NOT_GIVE_ACCESS_BOX,
  SET_SMART_NOT_ANALYSIS_COMPLETE,
  SET_SMART_NOT_ACCESS_PERMISSION,
  SET_SMART_BOX_FOLDER_COUNT_TREE,
  SET_SMART_FOLDER_COUNT_TREE,
  SET_SMART_BOX_FILTER_REFRESH_OPENED_BOX,
  SET_SMART_REPROCESSING_FILE_DELETE,
  SET_SMART_BOX_BASE_LIST,
  SET_SMART_BOX_SORT_SEARCH,
  SET_SMART_BOX_CHECK_STATUS_DEDUPLICATION,
  SET_SMART_BOX_SHOW_UPLOAD_NAVIGATION,
  SET_SMART_BOX_CURRENT_Filter_Option,
  SET_SMART_BOX_CHECK_BOX_REDACTION_STATUS,
  SET_SMART_BOX_NEW_BOX, SET_COLLECT_DATA_MAPPING,
  SET_ADMIN_MODIFY_CLIENT,
  SET_SMART_BOX_CURRENT_FOLDER_NAME,
  SET_SMART_BOX_SOURCE_DROP,
  SET_LEFT_NAVIGATION_TREE_SHOW,
  SET_SMART_BOX_CURRENT_NEW_FILTERS,
  SET_SMART_BOX_CURRENT_NEW_FILTERS_SORT,
  SET_SMART_BOX_CLICK_OUTSIDE_FILTER,
  SET_REDACTION_NON_SELECTED_WORDS,
  SET_STATUS_CLEAR_ALL_FILTERS_SMARTBOX,
  SET_SMART_BOX_SELECTED_KEYS,
  SET_BOX_LIST_VIEW_SELECTED_KEYS,
  SET_PROGRESS_EVENT_UPLOAD,
  SET_ACTIVE_DELETE_FILE,
  SET_DUPLICATE_CARD_INFO,
  SET_GET_VARIABLES_EMAILS_WORKFLOW,
  SET_SMART_BOX_EXISTS_FILES_WARNINGS_NOTIFICATION,
  SET_IMAGE_PROFILE_SETTINGS,
  SET_SMART_BOX_PREVIOUS_CURRENT_PAGE,
  SET_BOX_WORKFLOW_DASHBOARD,
  SET_VISIBLE_SUBHEADER_STEPS,
  SET_SMART_BOX_CURRENT_PAGE
} from '../actions/actionTypes'

/*const INITIAL_STATE = {
  smartBoxUploadingObject: {},
  fetchFolderContent: false,
  smartBoxSearchedText: '',
  intervalForAiPages: [],
  intervalForPdfPages: [],
  intervalForBulkRedaction: [],
  smartBoxViewMode: 'grid',
  smartBoxScrollPositionObject: {
    expandedRowKeys: [],
    url: '',
    scrollPosition: 0
  },
  smartBoxExportDataVisible: false,
  smartBoxFetchTreeData: false,
  smartBoxAxiosHeaders: {},
  smartBoxProjects: [],
  smartBoxTree: [],
  smartBoxCurrentFolder: '',
  smartBoxLoadTree: false,
  smartBoxNewNode: null,
  smartBoxFolderClosed: '',
  smartBoxMovedFiles: [],
  smartBoxSelectedFiles: [],
  smartBoxDeletedFolder: [],
  smartBoxFilesTable: [],
  smartBoxFetchFiles: {},
  smartBoxBaseDataTree: [],
  smartBoxPreviewToggleFile: {},
  smartBoxVisibleAddNote: false,
  smartBoxSelectedDocumentId: {},
  smartBoxRenamedFolder: { id: '', newName: '' },
  smartBoxAnalysisPercent: 100,
  smartBoxParentFolderEmpty: false,
  smartBoxParentFolderErrors: false,
  smartBoxParentFolderName: '',
  smartBoxFoldersCheckErrors: [],
  smartBoxSelectedDocumentDuplicate: {},
  smartBoxMovedFolders: [],
  smartBoxOldParentOfMovedFolders: '',
  smartBoxNewParentOfMovedFolders: '',
  smartBoxResponseDeleteDuplicates: {},
  smartBoxProgressCleanDeleteDuplicates: {},
  smartBoxCheckFirstUploadFile: {},
  smartBoxButtonUploadStatus: {},
  smartBoxPageLimit: 20,
  listTermsPageLimit: 10,
  businessTermsPageLimit: 10,
  overviewPageLimit: 10,
  smartBoxDataReady: {}
}*/

const smartBoxReducer = (state = initialStateSmartBox, action) => {
  switch (action.type) {
    case SET_SMART_BOX_UPLOADING_OBJECT:
      return {
        ...state, smartBoxUploadingObject: action.payload
      }
    case FETCH_FOLDER_CONTENT:
      return {
        ...state, fetchFolderContent: action.payload
      }
    case SET_SMART_BOX_SEARCHED_TEXT:
      return {
        ...state, smartBoxSearchedText: action.payload
      }
    case SET_SMART_BOX_INTERVAL_FOR_AI_PAGES:
      return {
        ...state, intervalForAiPages: [ ...state.intervalForAiPages, action.payload ]
      }
    case SET_SMART_BOX_INTERVAL_FOR_PDF_PAGES:
      return {
        ...state, intervalForPdfPages: [ ...state.intervalForPdfPages, action.payload ]
      }
    case SET_SMART_BOX_INTERVAL_FOR_BULK_REDACTION:
      return {
        ...state, intervalForBulkRedaction: [ ...state.intervalForBulkRedaction, action.payload ]
      }
    case REMOVE_SMART_BOX_INTERVAL_FOR_AI_PAGES:
      return {
        ...state, intervalForAiPages: action.payload
      }
    case REMOVE_SMART_BOX_INTERVAL_FOR_PDF_PAGES:
      return {
        ...state, intervalForPdfPages: action.payload
      }
    case REMOVE_SMART_BOX_INTERVAL_FOR_BULK_REDACTION:
      return {
        ...state, intervalForBulkRedaction: action.payload
      }
    case SET_SMART_BOX_VIEW_MODE:
      return {
        ...state, smartBoxViewMode: action.payload
      }
    case SET_SMART_BOX_SCROLL_POSITION_OBJECT:
      return {
        ...state, smartBoxScrollPositionObject: action.payload
      }
    case SET_SMART_BOX_EXPORT_DATA_VISIBILITY:
      return {
        ...state, smartBoxExportDataVisible: action.payload
      }
    case SET_SMART_BOX_FETCH_TREE_DATA:
      return {
        ...state, smartBoxFetchTreeData: action.payload
      }  
    case SET_AXIOS_HEADERS_COPY:
      return {
        ...state, smartBoxAxiosHeaders: action.payload
      }
    case SET_SMART_BOX_PROJECTS:
      return {
        ...state, smartBoxProjects: action.payload 
      }
    case SET_SMART_BOX_TREE:
      return {
        ...state, smartBoxTree: action.payload
      }
    case SET_SMART_BOX_CURRENT_FOLDER:
      return {
        ...state, smartBoxCurrentFolder: action.payload
      }
    case SET_SMART_BOX_LOAD_TREE:
      return {
        ...state, smartBoxLoadTree: action.payload
      }
    case SET_SMART_BOX_NEW_NODE:
      return {
        ...state, smartBoxNewNode: action.payload
      }
    case SET_SMART_BOX_FOLDER_CLOSED:
      return {
        ...state, smartBoxFolderClosed: action.payload
      }
    case SET_SMART_BOX_MOVED_FILES:
      return {
        ...state, smartBoxMovedFiles: action.payload
      }
    case SET_SMART_BOX_SELECTED_FILES:
      return {
        ...state, smartBoxSelectedFiles: action.payload
      }
    case SET_SMART_BOX_DELETED_FOLDER:
      return {
        ...state, smartBoxDeletedFolder: action.payload
      }
      case SET_SMART_BOX_FILES_TABLE:
      return {
        ...state, smartBoxFilesTable: action.payload
      }
    case SET_SMART_BOX_FETCH_FILES:
      return {
        ...state, smartBoxFetchFiles: action.payload
      }
    case SET_SMART_BOX_BASE_DATA_TREE:
      return {
        ...state, smartBoxBaseDataTree: action.payload
      }
    case SET_SMART_BOX_TOGGLE_FILE_PREVIEW:
      return {
        ...state, smartBoxPreviewToggleFile: action.payload
      }
    case SET_SMART_BOX_VISIBLE_DIRECTORY_NOTE:
      return {
        ...state, smartBoxVisibleAddNote: action.payload
      }
    case SET_SMART_BOX_DOCUMENT_SELECTED_ID:
      return {
        ...state, smartBoxSelectedDocumentId: action.payload
      }
    case SET_SMART_BOX_RENAMED_FOLDER:
      return {
        ...state, smartBoxRenamedFolder: action.payload
      }
    case SET_SMART_BOX_ANALYSIS_PERCENT:
      return {
        ...state, smartBoxAnalysisPercent: action.payload
      }
    case SET_SMART_BOX_PARENT_FOLDER_EMPTY:
      return {
        ...state, smartBoxParentFolderEmpty: action.payload
      }
    case SET_SMART_BOX_PARENT_FOLDER_ERRORS:
      return {
        ...state, smartBoxParentFolderErrors: action.payload
      }
    case SET_SMART_BOX_PARENT_FOLDER_NAME:
      return {
        ...state, smartBoxParentFolderName: action.payload
      }
    case SET_SMART_BOX_FOLDERS_CHECK_ERRORS:
      return {
        ...state, smartBoxFoldersCheckErrors: action.payload
      }
    case SET_SMART_BOX_DOCUMENT_SELECTED_DUPLICATE:
      return {
        ...state, smartBoxSelectedDocumentDuplicate: action.payload
      }
    case SET_SMART_BOX_MOVED_FOLDERS:
      return {
        ...state, smartBoxMovedFolders: action.payload
      }
    case SET_SMART_BOX_OLD_PARENT_OF_MOVED_FOLDERS:
      return {
        ...state, smartBoxOldParentOfMovedFolders: action.payload
      }
    case SET_SMART_BOX_NEW_PARENT_OF_MOVED_FOLDERS:
      return {
        ...state, smartBoxNewParentOfMovedFolders: action.payload
      }
    case SET_SMART_BOX_SECURITY_CONFIGURATION:
      return {
        ...state, smartBoxSecurityConfiguration: action.payload
      }
    case SET_SMART_BOX_RESPONSE_DELETE_DUPLICATES:
      return {
        ...state, smartBoxResponseDeleteDuplicates: action.payload
      }
    case SET_SMART_BOX_PROGRESS_CLEAN_DELETE_DUPLICATES:
      return {
        ...state, smartBoxProgressCleanDeleteDuplicates: action.payload
      }
    case SET_SMART_BOX_CHECK_FIRST_UPLOAD_FILE:
      return {
        ...state, smartBoxCheckFirstUploadFile: action.payload
      }
    case SET_SMART_BOX_NOTIFICATION_BUTTON_FIRST_UPLOAD:
      return {
        ...state, smartBoxButtonUploadStatus: action.payload
      }
    case SET_SMART_BOX_PAGE_LIMIT:
      return {
        ...state, smartBoxPageLimit: action.payload
      }
    case SET_LIST_TERMS_PAGE_LIMIT:
      return {
        ...state, listTermsPageLimit: action.payload
      }
    case SET_BUSINESS_TERMS_PAGE_LIMIT:
      return {
        ...state, businessTermsPageLimit: action.payload
      }
    case SET_OVERVIEW_PAGE_LIMIT:
      return {
        ...state, overviewPageLimit: action.payload
      }
    case SET_SMART_BOX_DATA_READY:
      return {
        ...state, smartBoxDataReady: action.payload
      }
    case SET_SMART_BOX_TREE_CURRENT_DEPTH:
      return {
        ...state, smartBoxTreeCurrentDepth: action.payload
      }
    case SET_SMART_BOX_HOME_CLICKED:
      return {
        ...state, smartBoxHomeClicked: action.payload
      }
    case SET_SMART_BOX_CURRENT_Sort_Option:
      return {
        ...state, smartBoxCurrentSortOption: action.payload
      }
    case SET_SMART_BOX_FOLDER_HAS_BULK:
      return {
        ...state, smartBoxFolderHasBulk: action.payload
      }
    case SET_SMART_BOX_ERROR_LOADING_THUMBNAILS:
      return {
        ...state, smartBoxErrorLoadingThumbnails: action.payload
      }
    case SET_SORT_CLOSE_SMART:
      return {
        ...state, sortSelectionCloseSmartview: action.payload
      }
    case SET_RESPONSE_PROGRESS_BAR_RENAME:
      return {
        ...state, responseProgressBarRename: action.payload
      }
    case SET_SMART_BOX_PARENT_STATISTICS_READY:
      return {
        ...state, smartBoxParentStatisticsReady: action.payload
      }
    case SET_SMART_FILES_ERROR_NOT_BE_CONVERTED:
      return {
        ...state, smartBoxFilesErrorConverted: action.payload
      }
    case SET_SMART_BOX_UPLOAD_FAILED:
      return {
        ...state, smartBoxUploadFailed: action.payload
      }
    case SET_SMART_BOX_GENERATE_UNIQUE_ID:
      return {
        ...state, smartBoxUploadUniqueId: action.payload
      }
    case SET_SMART_NOT_SAR_TRACKER:
      return {
        ...state, smartBoxNotSarTracker: action.payload
      }
    case SET_SMART_NOT_FOLDER_REDACTION_COMPLETE:
      return {
        ...state, smartBoxNotRedactComplete: action.payload
      }
    case SET_SMART_ADD_NOTE:
      return {
        ...state, smartBoxAddedNote: action.payload
      }
    case SET_SMART_STATUS_CHANGE:
      return {
        ...state, smartBoxStatusChange: action.payload
      }
    case SET_SMART_PER_PAGE_DUPLICATE:
      return {
        ...state, smartBoxPerPageDuplicate: action.payload
      }
    case SET_SMART_NOT_UPLOAD_COMPLETE:
      return {
        ...state, smartBoxNotUploadComplete: action.payload
      }
    case SET_SMART_NOT_GIVE_ACCESS_BOX:
      return {
        ...state, smartBoxNotGiveAccessBox: action.payload
      }
    case SET_SMART_NOT_ANALYSIS_COMPLETE:
      return {
        ...state, smartBoxNotAnalysisComplete: action.payload
      }
    case SET_SMART_NOT_ACCESS_PERMISSION:
      return {
        ...state, smartBoxNotAccessPermission: action.payload
      }
    case SET_SMART_BOX_FOLDER_COUNT_TREE:
      return {
        ...state, smartBoxFolderCountTree: action.payload
      }
    case SET_SMART_FOLDER_COUNT_TREE:
      return {
        ...state, smartFolderCountTree: action.payload
      }
    case SET_SMART_BOX_FILTER_REFRESH_OPENED_BOX:
      return {
        ...state, smartBoxFilterRefreshBox: action.payload
      }
    case SET_SMART_REPROCESSING_FILE_DELETE:
      return {
        ...state, smartReprocessingFileDelete: action.payload
      }
    case SET_SMART_BOX_BASE_LIST:
      return {
        ...state, smartBoxBaseList: action.payload
      }
    case SET_SMART_BOX_SORT_SEARCH:
      return {
        ...state, smartBoxSortSearch: action.payload
      }
    case SET_SMART_BOX_CHECK_STATUS_DEDUPLICATION:
      return {
        ...state, smartBoxCheckStatusDeduplication: action.payload
      }
    case SET_SMART_BOX_SHOW_UPLOAD_NAVIGATION:
      return {
        ...state, smartBoxShowUploadNavigation: action.payload
      }
    case SET_SMART_BOX_CURRENT_Filter_Option:
      return {
        ...state, smartBoxCurrentFilterOption: action.payload
      }
    case SET_SMART_BOX_NEW_BOX:
      return {
        ...state, smartBoxNewBox: action.payload
      }
    case SET_SMART_BOX_CHECK_BOX_REDACTION_STATUS:
      return {
        ...state, smartBoxCheckBoxRedactionStatus: action.payload
      }
    case SET_COLLECT_DATA_MAPPING:
      return {
        ...state, collectDataMapping: action.payload
      }
    case SET_ADMIN_MODIFY_CLIENT:
      return {
        ...state, collectAdminClient: action.payload
      }
    case SET_SMART_BOX_CURRENT_FOLDER_NAME:
      return {
        ...state, smartBoxCurrentFolderName: action.payload
      }
    case SET_SMART_BOX_SOURCE_DROP:
      return {
        ...state, smartBoxSourceFile: action.payload
      }
    case SET_LEFT_NAVIGATION_TREE_SHOW:
      return {
        ...state, leftNavigationTreeShow: action.payload
      }
    case SET_SMART_BOX_CURRENT_NEW_FILTERS:
      return {
        ...state, currentNewFilters: action.payload
      }
    case SET_SMART_BOX_CURRENT_NEW_FILTERS_SORT:
      return {
        ...state, currentNewFiltersSort: action.payload
      }
    case SET_SMART_BOX_CLICK_OUTSIDE_FILTER:
      return {
        ...state, clickOutsideFiler: action.payload
      }
    case SET_REDACTION_NON_SELECTED_WORDS:
      return {
        ...state, nonSelectedWords: action.payload
      }
    case SET_STATUS_CLEAR_ALL_FILTERS_SMARTBOX:
      return {
        ...state, statusClearAllFiltersSmart: action.payload
      }
    case SET_SMART_BOX_SELECTED_KEYS:
      return {
        ...state, selectKeysSmartBox: action.payload
      }
    case SET_BOX_LIST_VIEW_SELECTED_KEYS:
      return {
        ...state, selectKeysListViewBox: action.payload
      }
    case SET_PROGRESS_EVENT_UPLOAD:
      return {
        ...state, setProgressEventUpload: action.payload
      }
    case SET_ACTIVE_DELETE_FILE:
      return {
        ...state, activeDeleteFile: action.payload
      }
    case SET_DUPLICATE_CARD_INFO:
      return {
        ...state, responseSetCardInfoDuplicates: action.payload
      }
    case SET_GET_VARIABLES_EMAILS_WORKFLOW:
      return {
        ...state, setVariablesEmailWorkflow: action.payload
      }
    case SET_SMART_BOX_EXISTS_FILES_WARNINGS_NOTIFICATION:
      return {
        ...state, smartBoxExistsFilesWarningsNotification: action.payload
      }
    case SET_IMAGE_PROFILE_SETTINGS:
      return {
        ...state, setImageProfile: action.payload
      }
    case SET_SMART_BOX_PREVIOUS_CURRENT_PAGE:
      return {
        ...state, setPreviousCurrentPage: action.payload
      }
      case SET_BOX_WORKFLOW_DASHBOARD:
      return {
        ...state, setBoxRouteDashboardValue: action.payload
      }
    case SET_VISIBLE_SUBHEADER_STEPS:
      return {
        ...state, visibleSubheaderSteps: action.payload
      }
    case SET_SMART_BOX_CURRENT_PAGE:
      return {
        ...state, valueSmartBoxCurrentPage: action.payload
      }
    default:
      return state
  }
}


export default smartBoxReducer