import React from "react"
import RedactionColour from "./RedactionColour";
import {Select, Modal, Table, Button, Checkbox, Popover, Input, Spin} from 'antd'
import {
    apiRedactionColourProfileGetCategories,
    apiSaveRedactionColorProfilesCategories
} from "../../../../../api/SmartBox";
import {CompactPicker} from "react-color";
import CustomNotificationManager from "../../../../Common/CustomNotificationManager";
const Option = Select.Option

class RedactionColourProfiles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          listSelectProfileColour: [
              // { key: "Colour profile default black", name: "Colour profile default black", id: 'black' },
              // { key: "Colour profile default white", name: "Colour profile default white", id: 'white' },
              { key: "Colour profile default AI highlighting", name: "Colour profile default AI highlighting", id: 'PII' },
          ],
            valueSelectProfile: 'Colour profile default AI highlighting',
            listCategories: [],
            colorPickerCategoryVisible: {},
            colorPickerSubCategoryVisible: {},
            colorCategory: '#000000',
            colorSubCategory: '#000000',
            onHoverRefresh: false,
            onHoverSave: false,
            onHoverBack: false,
            onDisabledRefresh: true,
            onDisabledSave: false,
            valueInputColor: null,
            onLoadingSaveRedactionColour: false
        }
    }

    componentDidMount () {
        this.onRedactionColourProfileGetCategories()
    }
    onRedactionColourProfileGetCategories = () => {
        const { selectedRedactionColour } = this.props
        const { listSelectProfileColour } = this.state
        const getRedactionColorProfileCategoriesRequestDTO = {
            defaultColorProfile: null,
            redactionColorProfileId: selectedRedactionColour.redactionColorProfileId,
        }
        apiRedactionColourProfileGetCategories(getRedactionColorProfileCategoriesRequestDTO).then((response) => {
            let defaultColorProfile = null
            listSelectProfileColour && listSelectProfileColour.length > 0 && listSelectProfileColour.forEach((item) => {
                if (item.id === response.data.redactionColorProfileColorOption) {
                    defaultColorProfile = item.key
                }
            })
            this.setState({
                listCategories: response.data.categoryResponseDTOList,
               // valueSelectProfile: defaultColorProfile ? defaultColorProfile : 'Colour profile default black',
            })
        })
    }

    onRedactionColourProfileGetCategoriesSelected = () => {
        const { listSelectProfileColour, valueSelectProfile } = this.state
        const { selectedRedactionColour } = this.props
        let defaultColorProfile = null
        listSelectProfileColour && listSelectProfileColour.length > 0 && listSelectProfileColour.forEach((item) => {
            if (item.key === valueSelectProfile) {
                defaultColorProfile = item.id
            }
        })
        const getRedactionColorProfileCategoriesRequestDTO = {
            defaultColorProfile: defaultColorProfile,
            redactionColorProfileId: selectedRedactionColour.redactionColorProfileId,
        }
        apiRedactionColourProfileGetCategories(getRedactionColorProfileCategoriesRequestDTO).then((response) => {
            let defaultColorProfile = null
            listSelectProfileColour && listSelectProfileColour.length > 0 && listSelectProfileColour.forEach((item) => {
                if (item.id === response.data.redactionColorProfileColorOption) {
                    defaultColorProfile = item.key
                }
            })
            this.setState({
                listCategories: response.data.categoryResponseDTOList,
                onDisabledRefresh: true,
                //valueSelectProfile: defaultColorProfile ? defaultColorProfile : 'Colour profile default black',
            })
        })
    }

    onSelectResetProfiles = (e) => {
        const { listSelectProfileColour } = this.state
      this.setState({
          valueSelectProfile: e,
          onDisabledSave: false
      }, () => {
          this.onRedactionColourProfileGetCategoriesSelected()
      })
    }
    onRefreshRedactionColour = () => {
        this.onRedactionColourProfileGetCategoriesSelected()
    }
    renderColorCategory = (category, index) => {
        const { colorPickerCategoryVisible, colorCategory } = this.state
        const renderColorPickerCategory = (
            <React.Fragment>
                <div className="redaction-color-picker">
                    <CompactPicker
                        className="color-picker-a color-picker-redaction"

                        colors={['#e7968b', '#7f00ff', '#365194', '#893843', '#ff7a00', '#100c08', '#ffff00', '#8a7f80', '#ffa8f0', '#a74e7b',
                            '#866c83', '#736978', '#767085', '#6d7978', '#918a8f', '#7c8185', '#8e896e', '#ff00c6', '#4e15c8', '#d420ef',
                            '#aed136', '#5936d1', '#d136ae', '#d05d28', '#971b1b', '#8430c8', '#41006c', '#f66868', '#dd5c76', '#cf68f7',
                            '#669a1b', '#2d8113', '#114b38', '#123449', '#0d0a4b', '#b8acd1', '#e2d5f1', '#c9e8fd', '#526d85', '#4e4466',
                            '#000000', '#ffffff'
                        ]}
                        disableAlpha={true}
                        color={'#736978'}
                        onChange={(color) => this.closeAndSaveColorPickerCategory(color, category, index)}
                    />
                </div>
            </React.Fragment>
        )
        return (
            <Popover content={renderColorPickerCategory} trigger="click" visible={ colorPickerCategoryVisible.categoryKeyUI === category.categoryKeyUI ? true : false } >
                <span className="selected-color-category"  style={{backgroundColor: category.categoryColor}} onClick={ (e) => this.toggleColorPickerCategoryVisible(e,category, index) } />
            </Popover>
        )
    }
    closeAndSaveColorPickerCategory = (color, category, index) => {
        const { listCategories } = this.state
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            if (elem.categoryKeyUI === category.categoryKeyUI) {
              elem.categoryColor = color.hex
                elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                        elem2.subcategoryColor = color.hex
                })
            }
        })
        this.setState({
            colorPickerCategoryVisible: {},
            listCategories,
            onDisabledRefresh: false,
            onDisabledSave: false,
        })
    }
    toggleColorPickerCategoryVisible = (e,category, index) => {
        this.setState({
            colorPickerCategoryVisible: category
        })
    }
    renderColorSubCategory = (subCategory, indexSub) => {
        const { colorPickerSubCategoryVisible, colorSubCategory } = this.state
        const renderColorSubPickerCategory = (
            <React.Fragment>
                <div className="redaction-color-picker">
                    <CompactPicker
                        className="color-picker-a color-picker-redaction"

                        colors={['#e7968b', '#7f00ff', '#365194', '#893843', '#ff7a00', '#100c08', '#ffff00', '#8a7f80', '#ffa8f0', '#a74e7b',
                            '#866c83', '#736978', '#767085', '#6d7978', '#918a8f', '#7c8185', '#8e896e', '#ff00c6', '#4e15c8', '#d420ef',
                            '#aed136', '#5936d1', '#d136ae', '#d05d28', '#971b1b', '#8430c8', '#41006c', '#f66868', '#dd5c76', '#cf68f7',
                            '#669a1b', '#2d8113', '#114b38', '#123449', '#0d0a4b', '#b8acd1', '#e2d5f1', '#c9e8fd', '#526d85', '#4e4466',
                            '#000000', '#ffffff'
                        ]}
                        disableAlpha={true}
                        color={'#736978'}
                        onChange={(color) => this.closeAndSaveColorPickerSubCategory(color, subCategory, indexSub)}
                    />
                </div>
            </React.Fragment>
        )
        return (
            <Popover content={renderColorSubPickerCategory} trigger="click" visible={ colorPickerSubCategoryVisible.subcategoryKeyUI === subCategory.subcategoryKeyUI ? true : false } >
                <span className="selected-color-subCategory"  style={{backgroundColor: subCategory.subcategoryColor}} onClick={ (e) => this.toggleColorPickerSubCategoryVisible(e,subCategory, indexSub) } />
            </Popover>
        )
    }
    closeAndSaveColorPickerSubCategory = (color, subCategory, indexSub) => {
        const { listCategories } = this.state
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                if (elem2.subcategoryKeyUI === subCategory.subcategoryKeyUI) {
                    elem2.subcategoryColor = color.hex
                }
            })
        })
        this.setState({
            colorPickerSubCategoryVisible: {},
            listCategories,
            onDisabledRefresh: false,
            onDisabledSave: false,
        })
    }
    toggleColorPickerSubCategoryVisible = (e,category, indexSub) => {
        this.setState({
            colorPickerSubCategoryVisible: category
        })
    }
    onChangeInputColorPicker = (e, subCategory, indexSub) => {
        const { listCategories } = this.state
        const hexRegex = /^#([A-Fa-f0-9]{6})$/
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                if (elem2.subcategoryKeyUI === subCategory.subcategoryKeyUI) {
                    if (hexRegex.test(e.target.value)) {
                        elem2.subcategoryColor = e.target.value
                    } else {
                        elem2.subcategoryColor = elem2.subcategoryColor
                    }
                    //elem2.subcategoryColor = e.target.value
                }
            })
        })
        this.setState({
            listCategories,
            valueInputColor: e.target.value
        })
    }
    onHoverAction = (e, status) => {
        switch (status) {
            case 'refresh':
                this.setState({
                    onHoverRefresh: true
                })
                break
            case 'save':
                this.setState({
                    onHoverSave: true
                })
                break
            case 'back':
                this.setState({
                    onHoverBack: true
                })
                break
            default: break
        }
    }
    onLeaveAction = () => {
       this.setState({
           onHoverRefresh: false,
           onHoverSave: false,
           onHoverBack: false,
       })
    }
    onSaveRedactionColor = () => {
        const { listCategories, valueSelectProfile, listSelectProfileColour } = this.state
        const { selectedRedactionColour } = this.props
        let defaultColorProfile = null
        listSelectProfileColour && listSelectProfileColour.length > 0 && listSelectProfileColour.forEach((item) => {
            if (item.key === valueSelectProfile) {
                defaultColorProfile = item.id
            }
        })
                const saveRedactionColorProfileCategoryListDTO = {
            redactionColorProfileId: selectedRedactionColour.redactionColorProfileId,
            categoryResponseDTOList: listCategories,
            redactionColorProfileColorOption: defaultColorProfile
        }
        this.setState({
            onDisabledSave: true,
            onLoadingSaveRedactionColour: true,
        }, () => {
            apiSaveRedactionColorProfilesCategories(saveRedactionColorProfileCategoryListDTO).then((response) => {
                if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'success') {
                    CustomNotificationManager.success(response.data.message, 'Success')
                } else if (response && response.data && response.data.title && response.data.title.toLowerCase() === 'info') {
                    CustomNotificationManager.info(response.data.message, 'Info')
                }
                this.setState({
                    onDisabledSave: true,
                    onLoadingSaveRedactionColour: false,
                })
            })
        })
    }
    onBackRedactionColour = () => {
       this.props.onBackRedactionColour()
    }
    onBackRedactionColourDisabled = () => {
        return true
    }
    onChangeSubcategoryAnnotation = (e, subCategory, indexSub) => {
        const { listCategories } = this.state
        listCategories && listCategories.length > 0 && listCategories.forEach((elem) => {
            elem.subcategoryResponseDTOList && elem.subcategoryResponseDTOList.length > 0 && elem.subcategoryResponseDTOList.forEach((elem2) => {
                if (elem2.subcategoryKeyUI === subCategory.subcategoryKeyUI) {
                    elem2.subcategoryAnnotation = e.target.value
                }
            })
        })
        this.setState({
            listCategories,
            onDisabledSave: false,
            onDisabledRefresh: false,
        })
    }
    render() {
        const { listSelectProfileColour, valueSelectProfile, listCategories, onHoverRefresh, onDisabledRefresh, onHoverSave, valueInputColor,
            onDisabledSave, onHoverBack, onLoadingSaveRedactionColour } = this.state
        const { selectedRedactionColour } = this.props
        return (
            <div className='redaction-colour-profiles-page'>
                <div className='redaction-colour-profiles-page__content'>
                    <div className="redaction-colour-profiles-header">
                        <span className={onLoadingSaveRedactionColour ? 'icon-v24-back-1 icon-v24-disabled' : onHoverBack ? 'icon-v24-back-active' : 'icon-v24-back-1'}
                              onClick={onLoadingSaveRedactionColour ? () => this.onBackRedactionColourDisabled() : () => this.onBackRedactionColour()}
                              onMouseEnter={(e) => this.onHoverAction(e, 'back')}
                              onMouseLeave={() => this.onLeaveAction()}></span>
                        <span className="title-header">{selectedRedactionColour.redactionColorProfileName ? selectedRedactionColour.redactionColorProfileName : '' }</span>
                        <span className="icon-v14-question"></span>
                    </div>
                    <div className="redaction-colour-profiles-action">
                          {/*<div className="reset-profiles-colours">*/}
                          {/*    /!*<Select className="select-profiles-colours"*!/*/}
                          {/*    /!*        placeholder="Colour profile default black"*!/*/}
                          {/*    /!*        value={ valueSelectProfile }*!/*/}
                          {/*    /!*        onChange={(e) => this.onSelectResetProfiles(e)}*!/*/}
                          {/*    /!*   >*!/*/}
                          {/*    /!*    { listSelectProfileColour && listSelectProfileColour.length > 0 && listSelectProfileColour.map((elem, index) => {*!/*/}
                          {/*    /!*        return (*!/*/}
                          {/*    /!*            <Option key={index} value={elem.key}>{elem.name}</Option>*!/*/}
                          {/*    /!*        )*!/*/}
                          {/*    /!*    })*!/*/}

                          {/*    /!*    }*!/*/}
                          {/*    /!*</Select>*!/*/}
                          {/*    /!*<span className="value-select-profile">{valueSelectProfile}</span>*!/*/}
                          {/*    /!*<button onClick={(e) => this.onRefreshRedactionColour(e)}*!/*/}
                          {/*    /!*      className={onHoverRefresh ?  'icon-v14-refresh-active' : "icon-v14-refresh-1" }*!/*/}
                          {/*    /!*        disabled={onDisabledRefresh}*!/*/}
                          {/*    /!*onMouseEnter={(e) => this.onHoverAction(e, 'refresh')}*!/*/}
                          {/*    /!*onMouseLeave={() => this.onLeaveAction()}></button>*!/*/}
                          {/*</div>*/}
                        <Button onClick={(e) => this.onRefreshRedactionColour(e)}
                                //className={onHoverRefresh ?  'icon-v14-refresh-active' : "icon-v14-refresh-1" }
                                className="button-refresh-redaction-colour"
                                disabled={onDisabledRefresh}
                                onMouseEnter={(e) => this.onHoverAction(e, 'refresh')}
                                onMouseLeave={() => this.onLeaveAction()}>Cancel</Button>
                        <Button
                            //className={onHoverSave ? 'icon-v24-save-active' : "icon-v24-save-1" }
                                className="button-save-redaction-colour"
                                onClick={(e) => this.onSaveRedactionColor(e)}
                                disabled={onDisabledSave}
                                onMouseEnter={(e) => this.onHoverAction(e, 'save')}
                                onMouseLeave={() => this.onLeaveAction()}>Save</Button>
                    </div>
                    { onLoadingSaveRedactionColour ?
                        <div className="redaction-colour-profiles-content-loading">
                            <Spin className="loading-redaction-colour-profiles" tip="Loading...">
                            </Spin>
                        </div>
                        :
                        <div className="redaction-colour-profiles-content">
                            <div className="redaction-colour-profiles-table">
                                {listCategories && listCategories.length > 0 && listCategories.map((category, index) => {
                                    return (
                                        <div className="profiles-content-list">
                                      <span className="category-name-content">
                                         <span className="category-name">{category.categoryName}</span>
                                          <span
                                              className="data-picker-category">{this.renderColorCategory(category, index)}</span>
                                      </span>
                                            <div className="subCategory-list">
                                                {
                                                    category.subcategoryResponseDTOList && category.subcategoryResponseDTOList.length > 0 && category.subcategoryResponseDTOList.map((subCategory, indexSub) => {
                                                        return (
                                                            <span className="subCategory-content">
                                                          <span
                                                              className="subCategory-name">{subCategory.subcategoryName}</span>
                                                          <span className="data-picker-subcategory-content">
                                                              <span
                                                                  className="data-picker-subCategory">{this.renderColorSubCategory(subCategory, indexSub)}</span>
                                                           <Input className="input-color-picker"
                                                                  value={subCategory.subcategoryColor}
                                                               //onChange={(e) => this.onChangeInputColorPicker(e,subCategory, indexSub)}
                                                           />
                                                          </span>
                                                           <Input placeHolder="Annotation" className="input-annotation"
                                                                  value={subCategory.subcategoryAnnotation}
                                                                  onChange={(e) => this.onChangeSubcategoryAnnotation(e, subCategory, indexSub)}/>

                                                      </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
RedactionColourProfiles.propTypes = {

}

export default RedactionColourProfiles