import React, {useRef} from "react";
import {connect} from "react-redux";
import './EmailWork.scss';
import {Tabs, Input, Button, notification, Tooltip, Spin} from 'antd'
import ReactQuill, {Quill} from "react-quill";
import EmailWorkVariables from './EmailWorkVariables'
import EmailWorkVariablesUsed from './EmailWorkVariablesUsed'
import {
    apiEditEmailTemplateWorkflow, apiGetEmailTemplatesWorkflowVariablesContentDefault, apiResetEmailTemplateWorkflow,
    apiSaveEmailTemplateWorkflow, apiTestEmailTemplateWorkflow,
    apiUpdateInitialStepAcceptEmailTemplate
} from "../../../../api/Sar";
import CustomNotificationManager from "../../../Common/CustomNotificationManager";
import imageCompress from 'quill-image-compress';

Quill.register('modules/imageCompress', imageCompress);

const TabPane = Tabs.TabPane
class EmailWorkTemplate extends React.Component {
    // modules = {
    //     toolbar: [
    //         [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    //         [{ size: [] }],
    //         [{ align: [] }],
    //         ['bold', 'italic', 'underline', 'strike'],
    //         [{ 'color': [] }, { 'background': [] }],
    //         [{ 'list': 'ordered' }, { 'list': 'bullet' },
    //             { 'indent': '-1' }, { 'indent': '+1' }],
    //         ['link', 'image']
    //     ],
    //     clipboard: {
    //         // toggle to add extra line breaks when pasting HTML:
    //         matchVisual: false,
    //     },
    //     imageCompress: {
    //         quality: 0.7, // default
    //         maxWidth: 500, // default
    //         maxHeight: 500, // default
    //         imageType: 'image/jpeg', // default
    //         debug: false,
    //         suppressErrorLogging: false
    //     },
    //     // handlers: {
    //     //
    //     //     image: this.imageHandler
    //     //
    //     // }
    // }
    constructor(props) {
        super(props)
        this.state = {
            // emailTemplate: {
            //     clientId: 115,
            //     content: `"<p class="ql-align-center">Dear ${name},</p><p class="ql-align-center"><br></p><p class="ql-align-center">Thank you for completing the Subject Access Request form, we will send you feedback</p><p class="ql-align-center">with confirmation of your data as soon as possibleeeeeeeeeee</p><p class="ql-align-center"><br></p><p class="ql-align-center">If you are having any issues with your account, please don't hesitate to contact</p><p class="ql-align-center">us by replying to this email.</p><p class="ql-align-center"><br></p><p class="ql-align-center">Thanks!!!!</p>"`,
            //     id: 1,
            //     sentFromEmail: "elvis_asaftei@yahoo.com",
            //     sentFromName: "Elviss",
            //     subject: "Thank you for filling out the Subject Access Request form!",
            //     templateName: "mail-sar-complete-success",
            //     type: "ACCEPT",
            //     variables: ['${name}']
            // },
            emailTemplate: {
                content: ''
            },
            isNameLinkPersist: false,
            nameTemplateName: '',
            checkedVariables: [],
            emailTestTo: {},
            emailTemplateInfo: '',
            blockEditSaveTemplateWorkflow: false,
            stopTestEmailTemplate: false,
            loadingButtonSave: false,
        }
        this.quillRef = null;
        this.reactQuillRef = null;
        //this.toolbarExists = false;
        this.imageHandler = this.imageHandler.bind(this);
    }

    componentDidMount() {
        //this.toolbarExists = !!document.getElementById('custom-toolbar')
        //this.forceUpdate()
        if (this.reactQuillRef && this.reactQuillRef.getEditor()) {
            const quill = this.reactQuillRef.getEditor();
            this.attachQuillRefs(quill);
        }
        if (this.props.selectedEmailTemplate === '0' && !this.props.statusAddTemplate && Object.keys(this.props.getEmailsTemplate) && Object.keys(this.props.getEmailsTemplate).length > 0) {

            this.setState({
                emailTemplate: this.props.getEmailsTemplate
            })
            if (this.props.getEmailsTemplate[0] && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
                this.setState({
                    checkedVariables: this.props.getEmailsTemplate[0].variables
                })
            }
        } else {
            if (this.props.selectedEmailTemplate === '0' && this.props.statusAddTemplate && Object.keys(this.props.getEmailsTemplate) && Object.keys(this.props.getEmailsTemplate).length > 0) {
                this.setState({
                    emailTemplate: this.props.getEmailsTemplate
                })
                if (this.props.getEmailsTemplate[0] && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
                    this.setState({
                        checkedVariables: this.props.getEmailsTemplate[0].variables
                    })
                }
            }
        }
        // if (this.props.getEmailsTemplate && this.props.getEmailsTemplate.length > 0) {
        //     if (this.props.getEmailsTemplate.emailCode !== null) {
        //         this.onGetEmailTemplatesWorkflowVariablesContentDefault(this.props.getEmailsTemplate.emailCode)
        //     }
        //     if (this.props.selectedEmailTemplate === '0' && this.props.statusAddTemplate) {
        //         //this.props.onUpdateGetEmailTemplates(true)
        //         this.setState({
        //             emailTemplate:  this.props.getEmailsTemplate[0],
        //             nameTemplateName: this.props.getEmailsTemplate[0].templateName
        //         })
        //         if (this.props.getEmailsTemplate[0] && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
        //             this.setState({
        //                 checkedVariables: this.props.getEmailsTemplate[0].variables
        //             })
        //         }
        //     } else if (this.props.selectedEmailTemplate === '0' && !this.props.statusAddTemplate) {
        //         this.setState({
        //             emailTemplate: this.props.getEmailsTemplate
        //         })
        //         if (this.props.getEmailsTemplate[0] && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
        //             this.setState({
        //                 checkedVariables: this.props.getEmailsTemplate[0].variables
        //             })
        //         }
        //     }
        // }
        if (this.props.selectedEmailTemplate !== '0' && this.props.getEmailsTemplate !== this.state.emailTemplate) {
            if (this.props.getEmailsTemplate.emailCode !== null) {
                this.onGetEmailTemplatesWorkflowVariablesContentDefault(this.props.getEmailsTemplate.emailCode)
            }
            //this.props.onUpdateGetEmailTemplates(false)
            this.setState({
                emailTemplate:  this.props.getEmailsTemplate,
                nameTemplateName: this.props.getEmailsTemplate.templateName
            })
            if (this.props.getEmailsTemplate && this.props.getEmailsTemplate.variables && this.props.getEmailsTemplate.variables.length > 0) {
                this.setState({
                    checkedVariables: this.props.getEmailsTemplate.variables
                })
            }
        }

        if (this.reactQuillRef && this.reactQuillRef.getEditor && typeof this.reactQuillRef.getEditor !== 'undefined') {
            this.quillRef = this.reactQuillRef.getEditor();
        }
    }

    onGetEmailTemplatesWorkflowVariablesContentDefault = (emailCode) => {
        if (emailCode !== null && emailCode !== undefined) {
            apiGetEmailTemplatesWorkflowVariablesContentDefault(emailCode).then((response) => {
               this.setState({
                   emailTemplateInfo: response.data.emailTemplateInfo
               })
            })
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.selectedEmailTemplate === '0' && !this.props.statusAddTemplate && Object.keys(this.props.getEmailsTemplate) && Object.keys(this.props.getEmailsTemplate).length > 0) {
            this.setState({
                emailTemplate: this.props.getEmailsTemplate
            })
            if (this.props.getEmailsTemplate[0] && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
                this.setState({
                    checkedVariables: this.props.getEmailsTemplate[0].variables
                })
            }
        }
        // if (prevProps !== this.props && this.props.getEmailsTemplate.length > 0) {
        //     if (this.props.selectedEmailTemplate === '0' && this.props.statusAddTemplate) {
        //         this.props.onUpdateGetEmailTemplates(true)
        //         this.setState({
        //             emailTemplate:  this.props.getEmailsTemplate[0],
        //             nameTemplateName: this.props.getEmailsTemplate[0].templateName
        //         })
        //         if (this.props.getEmailsTemplate[0].templateName && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
        //             this.setState({
        //                 checkedVariables: this.props.getEmailsTemplate[0].variables
        //             })
        //         }
        //     } else if (this.props.selectedEmailTemplate === '0' && !this.props.statusAddTemplate) {
        //         this.setState({
        //             emailTemplate: this.props.getEmailsTemplate
        //         })
        //         if (this.props.getEmailsTemplate[0] && this.props.getEmailsTemplate[0].variables && this.props.getEmailsTemplate[0].variables.length > 0) {
        //             this.setState({
        //                 checkedVariables: this.props.getEmailsTemplate[0].variables
        //             })
        //         }
        //     }
        // }
        if (prevProps !== this.props && this.props.selectedEmailTemplate !== '0' && this.props.updateGetEmailTemplates !== true) {
                this.props.onUpdateGetEmailTemplates(true)
                this.setState({
                    nameTemplateName: this.props.getEmailsTemplate.templateName,
                    emailTemplate:  this.props.getEmailsTemplate
                })
            if (this.props.getEmailsTemplate.templateName && this.props.getEmailsTemplate.variables && this.props.getEmailsTemplate.variables.length > 0) {
                this.setState({
                    checkedVariables: this.props.getEmailsTemplate.variables
                })
            }
        }
        if (prevProps !== this.props) {
            //this.attachQuillRefs();
        }
        if (prevProps.getEmailsTemplate.emailTemplateId !== this.props.getEmailsTemplate.emailTemplateId) {
            if (this.reactQuillRef) {
                const quill = this.reactQuillRef.getEditor();
                setTimeout(() => {
                    this.attachQuillRefs(quill)
                }, 3000)
            }
        }
    }

    handleSubmit = (e) => {
        this.setState({
            emailTemplate: {
                ...this.state.emailTemplate,
                clientId: this.props.clientId
            }
        })
    }
    handleEditorChange = (e, delta, source, editor) => {
        let validateName = []
        let validateCompany = []
        let validateUrl = []
        // validateName = this.state.emailTemplate.content.toString().match(new RegExp(/\${name}/, 'g'))
        // validateCompany = this.state.emailTemplate.content.toString().match(new RegExp(/\${companyName}/, 'g'))
        // validateUrl = this.state.emailTemplate.content.toString().match(new RegExp(/\${resultUrl}/, 'g'))
        // let variablesEmail = []
        // if (validateName || validateCompany || validateUrl) {
        //     variablesEmail = validateName.concat(validateCompany, validateUrl)
        // }
        //const variablesEmailUpdate = variablesEmail.filter((item) => item !== null)
        this.setState({
            editMode: true,
            valid: this.validateBody(e),
        })
        const quill = this.reactQuillRef.getEditor();
        if (source === 'user') {
            this.attachQuillRefsUser()
        } else {
            this.attachQuillRefsUserNon()
        }
            this.props.onStatusActiveEdit(true)
    }
    validateBody(data) {
        this.checkNameVariable(data)
        this.onExtractVariables(data)
        return /(?=.*\${name})/.test(data)
    }
    onExtractVariables = (data) => {
        const { emailTemplate } = this.state
        let delWords = [];
        let variables = [];
        let variables2 = [];
        let variables3 = [];
        // for(let i = 0; i < data.length; i++) {
        //     if (data[i - 1] == '$' && data[i] == '{') {
        //         delWords.push(i)
        //     } else if ((data[i] == '}') && (delWords.length > 0)) {
        //         let position = delWords[delWords.length - 1]
        //         delWords.pop()
        //         let len = i - 1 - position
        //         let ansMatch
        //         if (position > 0 && len > 0) {
        //             ansMatch = data.substring(position + 1, len + position + 1)
        //         }
        //         // if (position <= len) {
        //         //     ansMatch = data.substring(position + 1, len + 1)
        //         // } else {
        //         //     ansMatch = data.substring(position + 1, len + position + 1);
        //         // }
        //
        //
        //         if (ansMatch) {
        //             if (emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0) {
        //                 emailTemplate.variablesTemplate.forEach((item) => {
        //                     console.log('item 5555555555555555', item)
        //                         if ((item.name === ansMatch) || (item.variableEmail === `\#{${ansMatch}}`)) {
        //                             variables2.push({
        //                                 name: ansMatch,
        //                                 nameVariables: `\#{${ansMatch}}`,
        //                                 content: item.variableValue,
        //                                 variableValue: item.variableValue,
        //                             })
        //                         } else if ((item.name !== ansMatch) || (item.variableEmail !== `\#{${ansMatch}}`)) {
        //                             variables3.push({
        //                                 name: ansMatch,
        //                                 nameVariables: `\#{${ansMatch}}`,
        //                                 content: ``,
        //                                 variableValue: ``,
        //                             })
        //                         }
        //                 })
        //             } else {
        //                 console.log('ansMatch 66666666666666666', ansMatch)
        //                 variables2.push({
        //                     name: ansMatch,
        //                     nameVariables: `\#{${ansMatch}}`,
        //                     content: ``,
        //                     variableValue: ``,
        //                 })
        //             }
        //
        //             // variables.push({
        //             //     name: ansMatch,
        //             //     nameVariables: `\${${ansMatch}}`,
        //             //     //content: ``,
        //             //     //variableValue: ``,
        //             // })
        //         }
        //     }
        // }

        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();

        const range = this.quillRef

        const quill = this.reactQuillRef.getEditor();

        const text = quill.getText()

        let index = 0
        const words = text.split(/\s+/)
        words.forEach((word, i) => {

            if(word.length>0){
                        if (word && word.includes('${') && word.includes('}')) {
                            const indexWordSub = word.indexOf('}')
                            const word2 = word.substring(0, indexWordSub + 1)
                        variables2.push({
                            name: word2,
                            nameVariables: word2,
                            content: ``,
                            variableValue: ``,
                        })
                    }
            }

            index += word.length + 1
        })

        variables = variables2.concat(variables3)
        let uniqueVariables = []
        if (variables && variables.length > 0) {
            uniqueVariables = variables.filter((a, i) => variables.findIndex((s) => a.name && s.name && a.name.toLowerCase() === s.name.toLowerCase()) === i)
        } else if (emailTemplate.variables && emailTemplate.variables.length > 0) {
            uniqueVariables = emailTemplate.variables
        }
        if ( emailTemplate.variables && emailTemplate.variables.length > 0) {
            emailTemplate.variables.forEach((item) => {
                uniqueVariables && uniqueVariables.length > 0 && uniqueVariables.forEach((item2) => {
                    if (item.variableEmail === item2.nameVariables) {
                        item2.variableValue = item.variableValue
                    }
                })
            })
            // this.setState({
            //     emailTemplate: {...this.state.emailTemplate, variablesTemplate: uniqueVariables, variables: uniqueVariables}
            // })
        } else {
            emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 && emailTemplate.variablesTemplate.forEach((item) => {
                uniqueVariables && uniqueVariables.length > 0 && uniqueVariables.forEach((item2) => {
                    if (item.variableEmail === item2.nameVariables) {
                        item2.variableValue = item.variableValue
                    }
                })
            })
        }
        // if (emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0) {
        //     emailTemplate.variablesTemplate.forEach((item) => {
        //         uniqueVariables && uniqueVariables.length > 0 && uniqueVariables.forEach((item2) => {
        //             if (item.variableEmail === item2.nameVariables) {
        //                 item2.variableValue = item.variableValue
        //             }
        //         })
        //     })
        // } else  if ( emailTemplate.variables && emailTemplate.variables.length > 0) {
        //     emailTemplate.variables.forEach((item) => {
        //         uniqueVariables && uniqueVariables.length > 0 && uniqueVariables.forEach((item2) => {
        //             if (item.variableEmail === item2.nameVariables) {
        //                 item2.variableValue = item.variableValue
        //             }
        //         })
        //     })
        // }
        this.setState({
            emailTemplate: {...this.state.emailTemplate, variablesTemplate: uniqueVariables}
        })
    }
    checkNameVariable(data) {
        if (data && data.includes('{name}')) {
            this.setState({
                isNameLinkPersist: true
            })
        } else {
            this.setState({
                isNameLinkPersist: true
            })
        }
    }
    updateValueTemplateName(e) {
        const { nameTemplateName } = this.state
        this.setState({
            nameTemplateName: e.target.value
        })
        const regexWord = /.*\s\S/
        const nameValue = e.target.value
        if (regexWord.test(nameValue)) {
            this.props.onStatusActiveEdit(true)
        } else {
            if (e.target.value.includes(' ') && !this.props.statusActiveEdit) {
                this.props.onStatusActiveEdit(false)
             } else {
                this.props.onStatusActiveEdit(true)
            }
        }
    }
    handleChange = (e, key) => {
        const regexWord = /.*\s\S/
        const nameValue = e.target.value
        switch (key) {
            case 'subject':
                this.setState({
                    emailTemplate: { ...this.state.emailTemplate, subject: e.target.value },
                    subject: e.target.value,
                    valid: this.validateBody(this.state.emailTemplate.content),
                })
                if (regexWord.test(nameValue)) {
                    this.props.onStatusActiveEdit(true)
                } else {
                    if (e.target.value.includes(' ') && !this.props.statusActiveEdit) {
                        this.props.onStatusActiveEdit(false)
                    } else {
                        this.props.onStatusActiveEdit(true)
                    }
                }
                break
            case 'from-email':
                this.setState({
                    emailTemplate: { ...this.state.emailTemplate, fromEmail: e.target.value },
                    sentFromEmail: e.target.value,
                    valid: this.validateBody(this.state.emailTemplate.content),
                })
                if (regexWord.test(nameValue)) {
                    this.props.onStatusActiveEdit(true)
                } else {
                    if (e.target.value.includes(' ') && !this.props.statusActiveEdit) {
                        this.props.onStatusActiveEdit(false)
                    } else {
                        this.props.onStatusActiveEdit(true)
                    }
                }
                break
            case 'from-name':
                this.setState({
                    emailTemplate: { ...this.state.emailTemplate, fromName: e.target.value },
                    sentFromName: e.target.value,
                    valid: this.validateBody(this.state.emailTemplate.content),
                })
                if (regexWord.test(nameValue)) {
                    this.props.onStatusActiveEdit(true)
                } else {
                    if (e.target.value.includes(' ') && !this.props.statusActiveEdit) {
                        this.props.onStatusActiveEdit(false)
                    } else {
                        this.props.onStatusActiveEdit(true)
                    }
                }
                break
            default:
                break
        }
    }
    onCLickSaveTemplateEmail = (e) => {
      const { nameTemplateName, emailTemplate } = this.state
      const { listEmailTemplate, selectedEmailTemplate, responseSavedEmailTemplate } = this.props
        e.preventDefault()
        const listNameTemplate = []
        listEmailTemplate && listEmailTemplate.length > 0 && listEmailTemplate.forEach((list) => {
            listNameTemplate.push(list.name)
        })
        const addTemplate = []
        //if (!listNameTemplate.includes(nameTemplateName)) {
            addTemplate.push({
                name: nameTemplateName,
                id: nameTemplateName
            })
            this.props.onGenerateListEmailTemplate(addTemplate)
            this.setState({
                emailTemplate: {
                    ...this.state.emailTemplate,
                    clientId: this.props.clientId
                },
                blockEditSaveTemplateWorkflow: true,
                loadingButtonSave: true
            }, () => {
                if (emailTemplate && emailTemplate.statusTab === 'parent' && responseSavedEmailTemplate !== true) {
                    this.onSaveEmailTemplateWorkflow()
                } else {
                    this.onEditEmailTemplateWorkflow()
                }
            })
        // } else {
        //     notification.info({ message: 'Template already exist', duration: 6 })
        // }
    }
    onSaveEmailTemplateWorkflow = () => {
        const { emailTemplate, nameTemplateName, checkedVariables } = this.state
        let delWords = [];
        let variables = [];
        for(let i = 0; i < emailTemplate.content.length; i++) {
            if (emailTemplate.content[i - 1] == '#' && emailTemplate.content[i] == '{') {
                delWords.push(i)
            } else if ((emailTemplate.content[i] == '}') && (delWords.length > 0)) {
                let position = delWords[delWords.length - 1]
                delWords.pop()
                let len = i - 1 - position
                let ansMatch
                if (position > 0 && len > 0) {
                    ansMatch = emailTemplate.content.substring(position + 1, len + position + 1)
                }
                // if (position < len) {
                //     ansMatch = emailTemplate.content.substring(position + 1, len + 1)
                // } else {
                //     ansMatch = emailTemplate.content.substring(position + 1, len + position + 1);
                // }
                if (ansMatch) {
                    variables.push(`\#{${ansMatch}}`)
                }
            }
        }
        const variablesUpdate = []
        emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 && emailTemplate.variablesTemplate.forEach((item) => {
            variablesUpdate.push({
                variableName: item.nameVariables.replaceAll('${', '#{'),
                variableValue: item.content,
            })
        })
        if (emailTemplate.content) {
            //emailTemplate.content = emailTemplate.content.replaceAll('${', '#{')
        }
        const emailSaveTemplateWorkflowDTO = {
            //emailTemplateId: emailTemplate.id,
            templateName: nameTemplateName,
            subject: emailTemplate.subject ? emailTemplate.subject.replaceAll('${', '#{') : emailTemplate.subject,
            fromEmail: emailTemplate.fromEmail,
            fromName: emailTemplate.fromName,
            content: emailTemplate.content.replaceAll('${', '#{'),
            testEmailAddress: emailTemplate.emailAddress,
            emailTemplateType: emailTemplate.emailTemplateType,
            variables: variablesUpdate
        }
        apiSaveEmailTemplateWorkflow(emailSaveTemplateWorkflowDTO).then((response) => {
            if (response.data === 'Email template with this name already exists!') {
                notification.info({ message: 'Email template with this name already exists!', duration: 6 })
            }
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                this.props.onStatusActiveEdit(false)
                this.props.onResponseSaveEmailTemplate(true)
                this.props.onGetActiveKeyTab(this.props.getEmailsTemplate.length + 1)
            } else {
                this.props.onResponseSaveEmailTemplate(false)
                this.setState({
                    blockEditSaveTemplateWorkflow: false
                })
            }
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                this.props.onGetEmailsTemplate()
                this.setState({
                    blockEditSaveTemplateWorkflow: false
                })
            }
            this.setState({
                loadingButtonSave: false
            })
        })
    }
    onEditEmailTemplateWorkflow = () => {
        const { emailTemplate, nameTemplateName, checkedVariables } = this.state
        const { getEmailsTemplate, listEmailTemplate } = this.props
        let delWords = [];
        let variables = [];
        for(let i = 0; i < emailTemplate.content.length; i++) {
            if (emailTemplate.content[i - 1] == '#' && emailTemplate.content[i] == '{') {
                delWords.push(i)
            } else if ((emailTemplate.content[i] == '}') && (delWords.length > 0)) {
                let position = delWords[delWords.length - 1]
                delWords.pop()
                let len = i - 1 - position
                let ansMatch
                if (position > 0 && len > 0) {
                    ansMatch = emailTemplate.content.substring(position + 1, len + position + 1)
                }
                // if (position < len) {
                //     ansMatch = emailTemplate.content.substring(position + 1, len + 1)
                // } else {
                //     ansMatch = emailTemplate.content.substring(position + 1, len + position + 1);
                // }
                if (ansMatch) {
                    variables.push(`\#{${ansMatch}}`)
                }
            }
        }
        const variablesUpdate = []
        emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 && emailTemplate.variablesTemplate.forEach((item) => {
            variablesUpdate.push({
                variableName: item.nameVariables ? item.nameVariables.replaceAll('${', '#{') : item.variableEmail.replaceAll('${', '#{'),
                variableValue: item.content ? item.content : item.variableValue,
            })
        })
        if (emailTemplate.content) {
            //emailTemplate.content = emailTemplate.content.replaceAll('${', '#{')
        }
        const emailTemplateWorkflowDTO = {
            emailTemplateId: emailTemplate.emailTemplateId,
            templateName: nameTemplateName,
            subject: emailTemplate.subject ? emailTemplate.subject.replaceAll('${', '#{') : emailTemplate.subject,
            fromEmail: emailTemplate.fromEmail,
            fromName: emailTemplate.fromName,
            content: emailTemplate.content.replaceAll('${', '#{'),
            testEmailAddress: emailTemplate.emailAddress ? emailTemplate.emailAddress.replaceAll('${', '#{') : emailTemplate.emailAddress,
            emailTemplateType: emailTemplate.emailTemplateType,
            variables: variablesUpdate,
            emailCode: getEmailsTemplate.emailCode !== null ? getEmailsTemplate.emailCode : listEmailTemplate.emailCode !== null ? listEmailTemplate.emailCode : null
        }
        apiEditEmailTemplateWorkflow(emailTemplateWorkflowDTO).then((response) => {
            if (response.data === 'Email template with this name already exists!') {
                notification.info({ message: 'Email template with this name already exists!', duration: 6 })
            }
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                CustomNotificationManager.success(response.data.message, 'Success')
            } else if(response.data.title && response.data.title.toLowerCase() === 'info') {
                CustomNotificationManager.info(response.data.message, 'Info')
            }
            this.props.onResponseSaveEmailTemplate(false)
            if (response.data.title && response.data.title.toLowerCase() === 'success') {
                this.props.onStatusActiveEdit(false)
                this.props.onGetEmailsTemplate()
            }
            this.setState({
                blockEditSaveTemplateWorkflow: false,
                loadingButtonSave: false,
            })
        })
    }

    onCLickCancelTemplateEmail = () => {
        this.setState({
            nameTemplateName: ''
        })
    }
    onChangeVariables = (checkedValues) => {
        this.setState({
            checkedVariables: checkedValues
        })
    }
    handleChangeEmailTestTo = (e, status, variable) => {
        const { emailTestTo, emailTemplate } = this.state
        switch (status) {
            case 'email':
                this.setState({
                    emailTestTo: { ...this.state.emailTestTo, variable: e.target.value, testEmailAddress: e.target.value },
                    emailTemplate: { ...this.state.emailTemplate, emailAddress: e.target.value, testEmailAddress: e.target.value },
                })
                break
            case 'name':
                const variablesUpdate = []
                emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 && emailTemplate.variablesTemplate.forEach((item) => {
                    if ((item.variableValue === variable.variableValue) && (item.id === variable.id) && (item.name === variable.name)) {
                        item.content = e.target.value
                        item.variableValue = e.target.value
                    }
                    variablesUpdate.push(item)
                })
                //emailTemplate.variablesUpdate = variablesUpdate
                this.setState({
                    emailTestTo: { ...this.state.emailTestTo, variable: e.target.value },
                    emailTemplate: { ...this.state.emailTemplate, variablesTemplate: variablesUpdate },
                })
                break
            case 'identifier':
                this.setState({
                    emailTestTo: { ...this.state.emailTestTo, identifier: e.target.value },
                })
                break
            case 'company':
                this.setState({
                    emailTestTo: { ...this.state.emailTestTo, company: e.target.value },
                })
                break
            default:
                break
        }
    }

   onTestEmailTemplateWorkflow = () => {
       const { emailTemplate, nameTemplateName } = this.state
       const { getEmailsTemplate, listEmailTemplate } = this.props
       let delWords = [];
       let variables = [];
       for(let i = 0; i < emailTemplate.content.length; i++) {
           if (emailTemplate.content[i - 1] == '#' && emailTemplate.content[i] == '{') {
               delWords.push(i)
           } else if ((emailTemplate.content[i] == '}') && (delWords.length > 0)) {
               let position = delWords[delWords.length - 1]
               delWords.pop()
               let len = i - 1 - position
               let ansMatch
               if (position > 0 && len > 0) {
                   ansMatch = emailTemplate.content.substring(position + 1, len + position + 1)
               }
               // if (position < len) {
               //     ansMatch = emailTemplate.content.substring(position + 1, len + 1)
               // } else {
               //     ansMatch = emailTemplate.content.substring(position + 1, len + position + 1);
               // }
               if (ansMatch) {
                   variables.push(`\#{${ansMatch}}`)
               }
           }
       }
       const variablesTemplate = []
       emailTemplate.variablesTemplate && emailTemplate.variablesTemplate.length > 0 &&emailTemplate.variablesTemplate.forEach((item) => {
           variablesTemplate.push({
               variableName: item.nameVariables ? item.nameVariables.replaceAll('${', '#{') : item.variableEmail.replaceAll('${', '#{'),
               variableValue: item.content ? item.content : item.variableValue,
           })
       })
       if (emailTemplate.content) {
           emailTemplate.content = emailTemplate.content.replaceAll('${', '#{')
       }
       const testEmailTemplateWorkflowDTO  = {
           subject: emailTemplate.subject,
           fromEmail: emailTemplate.fromEmail,
           fromName: emailTemplate.fromName,
           content: emailTemplate.content,
           emailAddress: emailTemplate.emailAddress ? emailTemplate.emailAddress : emailTemplate.testEmailAddress,
           variables: variablesTemplate,
           emailCode: getEmailsTemplate.emailCode !== null ? getEmailsTemplate.emailCode : listEmailTemplate.emailCode !== null ? listEmailTemplate.emailCode : null
       }
       this.setState({
           stopTestEmailTemplate: true
       }, () => {
           apiTestEmailTemplateWorkflow(testEmailTemplateWorkflowDTO).then((response) => {
               if (response.data.title === 'Success') {
                   CustomNotificationManager.success(response.data.message, 'Success')
               } else {
                   CustomNotificationManager.info(response.data.message, 'Info')
               }
               this.setState({
                   stopTestEmailTemplate: false
               })
           })
       })
    }
    onResetEmailTemplateWorkflow = () => {
        const { getEmailsTemplate, listEmailTemplate } = this.props
        const idTemplate = getEmailsTemplate.emailTemplateId ? getEmailsTemplate.emailTemplateId : listEmailTemplate.emailTemplateId ? listEmailTemplate.emailTemplateId : null
        apiResetEmailTemplateWorkflow(idTemplate).then((response) => {
            this.props.onGetEmailsTemplate()
        })
    }
    attachQuillRefs = (quill) => {
        const { emailTemplate } = this.state
        if (this.reactQuillRef && this.reactQuillRef.getEditor) {
            if (typeof this.reactQuillRef.getEditor !== 'function') return;
            //this.quillRef = this.reactQuillRef.getEditor();

            //const quill = this.reactQuillRef.getEditor();

            const text = quill.getText()

            let index = 0
            const words = text.split(/\s/)
            words.forEach((word, i) => {

                if (word.length > 0) {
                    if (word && word.includes('${') && word.includes('}')) {
                        const indexWordSub = word.indexOf('}')
                        const indexWordSubStart = word.indexOf('${')
                        const word2 = word.substring(indexWordSubStart, indexWordSub + 1)
                        // console.log('word SSSSSSSSSSSSSSSSSSS', word)
                        // console.log('word2 SSSSSSSSSSSSSSSSSSS', word2)
                        quill.formatText(index, word.length, {color: '#377E22'})
                    }
                    // else {
                    //     quill.formatText(index, word.length, {color: 'rgba(0, 0, 0, 0.65)'})
                    // }
                }

                index += word.length + 1
            })
        }
        // if (emailTemplate && emailTemplate.content) {
        //     const words = emailTemplate.content.split('')
        //     words.forEach((word, index2) => {
        //         let startIndex = 0
        //         while (startIndex < text.length) {
        //             const index = text.indexOf(word, startIndex)
        //             if (index === -1) break
        //             quill.formatText(index, word.length, {color: 'red'})
        //             startIndex = index + word.length
        //
        //         }
        //
        //     })
        // }
        // this.setState({
        //     emailTemplate
        // })
    }
    attachQuillRefsUserNon = () => {
        const {emailTemplate} = this.state
        if (this.reactQuillRef && this.reactQuillRef.getEditor) {
            if (typeof this.reactQuillRef.getEditor !== 'function') return;
            //this.quillRef = this.reactQuillRef.getEditor();

            const quill = this.reactQuillRef.getEditor();

            const text = quill.getText()

            let index = 0
            const words = text.split(/\s/)
            words.forEach((word, i) => {

                if (word.length > 0) {
                    if (word && word.includes('${') && word.includes('}')) {
                        const indexWordSub = word.indexOf('}')
                        const indexWordSubStart = word.indexOf('${')
                        const word2 = word.substring(indexWordSubStart, indexWordSub + 1)
                        // console.log('word SSSSSSSSSSSSSSSSSSS', word)
                        // console.log('word2 SSSSSSSSSSSSSSSSSSS', word2)

                        quill.formatText(index, indexWordSub + 1, {color: '#377E22'})
                        quill.formatText(index + indexWordSub + 1, word.length - indexWordSub - 1, {color: '#000000'})
                    }
                    // else {
                    //     quill.formatText(index, word.length, {color: '#000000'})
                    // }
                }

                index += word.length + 1
            })
        }
    }

        attachQuillRefsUser = () => {
        const { emailTemplate } = this.state
        if (this.reactQuillRef && this.reactQuillRef.getEditor) {
            if (typeof this.reactQuillRef.getEditor !== 'function') return;
            //this.quillRef = this.reactQuillRef.getEditor();

            const quill = this.reactQuillRef.getEditor();

            const text = quill.getText()

            let index = 0
            const words = text.split(/\s/)
            words.forEach((word, i) => {

                if (word.length > 0) {
                    if (word && word.includes('${') && word.includes('}')) {
                        const indexWordSub = word.indexOf('}')
                        const indexWordSubStart = word.indexOf('${')
                        const word2 = word.substring(indexWordSubStart, indexWordSub + 1)
                        // console.log('word SSSSSSSSSSSSSSSSSSS', word)
                        // console.log('word2 SSSSSSSSSSSSSSSSSSS', word2)

                        quill.formatText(index, indexWordSub+1, {color: '#377E22'})
                        quill.formatText(index+indexWordSub+1, word.length - indexWordSub - 1, {color: '#000000'})
                    }
                    else {
                        quill.formatText(index, word.length, {color: '#000000'})
                    }
                }

                index += word.length + 1
            })
        }
    }

    onSelectVariable = (e, variable) => {
        const quill = this.reactQuillRef.getEditor();
        //const cursorPosition = quill.getSelection().index
        // const contentLength = quill.length
        // const wordToInsert = variable.variable.replaceAll('#{', '${')
        // quill.insertText(contentLength - 1, wordToInsert, 'bold', true)

        const contentLength = quill.getLength()
        const currentText = quill.getText(0, contentLength)
        if (currentText.endsWith('\n')) {
            quill.deleteText(contentLength - 1, 1)
        }
        const wordToInsert = variable.variable.replaceAll('#{', '${')
        quill.insertText(contentLength - 1, (currentText.endsWith('\n') ? ' ' : ' ') + wordToInsert)

    }
    onDisabledSelectVariable = (e) => {
       return true
    }

    imageHandler() {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');

        input.setAttribute('accept', 'image/*');

        input.click();

        input.onchange = async () => {

            const file = input.files[0];

           // const maxFileSize = 1 * 1024 * 1024; // 1MB
            const maxFileSize = 100 * 1024; // 100kb

            if (file.size > maxFileSize) {
                CustomNotificationManager.info('Image size is too large! Please upload an image under 100kb.', 'Info')
                return;

            }

            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
                const quill = this.reactQuillRef.getEditor();
                const range = quill.getSelection();
                quill.insertEmbed(range.index, 'image', reader.result);
            };

            reader.onerror = (error) => {
                console.error('Error loading image ', error);
            };

        };

    }

    render() {
        const { emailTemplate, isNameLinkPersist, nameTemplateName, checkedVariables, emailTestTo, emailTemplateInfo, blockEditSaveTemplateWorkflow, stopTestEmailTemplate,
            loadingButtonSave } = this.state
        const { responseSavedEmailTemplate, selectedEmailTemplate, statusActiveEdit, getEmailsTemplate, listEmailTemplate, listVariables, loadingGetEmailTemplates, toolbarVisible} = this.props

        const toolbarOptions = [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            [{ align: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image']
        ]
        const modules = {
            toolbar: {
                container: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ size: [] }],
                    [{ align: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                        { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'],
                    ['text']
                ],
                handlers: {
                    image: this.imageHandler
                }
            }
        }

        // const modules = {
        //
        //     toolbar: {
        //
        //         container: "#custom-toolbar",
        //         //     [
        //         //
        //         //     [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        //         //     [{ size: [] }],
        //         //     [{ align: [] }],
        //         //     ['bold', 'italic', 'underline', 'strike'],
        //         //     [{ 'color': [] }, { 'background': [] }],
        //         //     [{ 'list': 'ordered' }, { 'list': 'bullet' },
        //         //         { 'indent': '-1' }, { 'indent': '+1' }],
        //         //     ['link', 'image'],
        //         //     [{'text': 'max. 100kb'}]
        //         //
        //         // ],
        //
        //         handlers: {
        //
        //             image: this.imageHandler
        //
        //         }
        //
        //     },
        //     // imageCompress: {
        //     //     quality: 0.7, // default
        //     //     maxWidth: 500, // default
        //     //     maxHeight: 500, // default
        //     //     imageType: 'image/jpeg', // default
        //     //     debug: false,
        //     //     suppressErrorLogging: false
        //     // },
        //     clipboard: {
        //         // toggle to add extra line breaks when pasting HTML:
        //         matchVisual: false,
        //     },
        // }

        return (
            <div className="email-workflow-template">
                { loadingGetEmailTemplates &&
                    <div className="content-loading-get-email-templates">
                        <Spin className="loading-email-template" tip="Loading...">
                        </Spin>
                    </div>
                }
                <div className={loadingGetEmailTemplates ? "header-email-template header-email-template-loading" : 'header-email-template' }>
                 <Input className="input-template-name" placeholder="Template Name" value={ nameTemplateName } onChange={(e) => this.updateValueTemplateName(e)}/>
                    {emailTemplateInfo &&
                    <Tooltip overlayClassName="help-message-email-workflow"
                             title={<div className="content-help-message-locations">
                                 <div>
                                     <span className="text-information"> <div dangerouslySetInnerHTML={{ __html: emailTemplateInfo}}></div> </span>
                                 </div>
                             </div>}>
                        <span className="icon-v14-question"></span>
                    </Tooltip>
                    }
                    <Button className="button-cancel-template" onClick={ this.onCLickCancelTemplateEmail }>Cancel</Button>
                    <Button className="button-save-template"
                            disabled={blockEditSaveTemplateWorkflow === true || nameTemplateName === '' || (selectedEmailTemplate !== '0' && statusActiveEdit === false)}
                            onClick={ (e) => this.onCLickSaveTemplateEmail(e) }
                    loading={loadingButtonSave}>Save</Button>
                </div>
                <div className={loadingGetEmailTemplates ? "email-template email-template-loading" : 'email-template' }>
                    <form className="form-content" onSubmit={ this.handleSubmit }>
                        <div className="form-group">
                            <label className="col-md-2">Email Subject*</label>
                            <input type="text" placeholder="Subject" className="col-md-10"
                                   onChange={ (e) => this.handleChange(e, 'subject') }
                                   value={ emailTemplate.subject ? emailTemplate.subject.replaceAll('#{', '${') : emailTemplate.subject || '' } required/>
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <label className="col-md-2">From email</label>*/}
                        {/*    <input type="email" placeholder="From Email" className="col-md-10"*/}
                        {/*           onChange={ (e) => this.handleChange(e, 'from-email') }*/}
                        {/*           defaultValue={ emailTemplate.fromEmail || '' }/>*/}
                        {/*</div>*/}
                        {/*<div className="form-group">*/}
                        {/*    <label className="col-md-2">From name</label>*/}
                        {/*    <input type="text" placeholder="From Name" className="col-md-10"*/}
                        {/*           onChange={ (e) => this.handleChange(e, 'from-name') }*/}
                        {/*           defaultValue={ emailTemplate.fromName || '' }/>*/}
                        {/*</div>*/}
                        { emailTemplate &&
                            <div className="content-email-variables-quill">
                                <div className="react-quill-personalized">
                                {/*<div id="custom-toolbar">*/}
                                {/*    { toolbarVisible &&*/}
                                {/*        <React.Fragment>*/}
                                {/*    <span className="ql-formats">*/}
                                {/*        <button className="ql-header" value="1"></button>*/}

                                {/*        <button className="ql-header" value="2"></button>*/}
                                {/*        <select className="ql-font"></select>*/}
                                {/*        <select className="ql-size">*/}
                                {/*             <option value="small"></option>*/}
                                {/*             <option selected></option>*/}
                                {/*            <option value="large"></option>*/}
                                {/*            <option value="huge"></option>*/}
                                {/*        </select>*/}

                                {/*      </span>*/}
                                {/*            <span className="ql-formats">*/}
                                {/*    <select className="ql-align"></select>*/}
                                {/*    </span>*/}
                                {/*            <span className="ql-formats">*/}
                                {/*        <button className="ql-bold"></button>*/}

                                {/*        <button className="ql-italic"></button>*/}

                                {/*        <button className="ql-underline"></button>*/}
                                {/*          <button className="ql-strike"></button>*/}

                                {/*      </span>*/}
                                {/*            <span className="ql-formats">*/}

                                {/*        <select className="ql-color"></select>*/}

                                {/*        <select className="ql-background"></select>*/}

                                {/*      </span>*/}
                                {/*            <span className="ql-formats">*/}

                                {/*        <button className="ql-list" value="ordered"></button>*/}

                                {/*        <button className="ql-list" value="bullet"></button>*/}

                                {/*        <button className="ql-indent" value="-1"></button>*/}

                                {/*        <button className="ql-indent" value="+1"></button>*/}

                                {/*      </span>*/}
                                {/*            <span className="ql-formats">*/}
                                {/*         <button className="ql-link"></button>*/}
                                {/*        <button className="ql-image"></button>*/}
                                {/*        <span className="custom-text">max. 100kb</span> /!* Textul personalizat *!/*/}
                                {/*      </span>*/}
                                {/*        </React.Fragment>*/}
                                {/*    }*/}
                                {/*</div>*/}

                                        <ReactQuill
                                            ref={(el) => {
                                                    this.reactQuillRef = el;
                                            }}
                                            theme={this.state.theme}
                                            className="quill-write"
                                            onChange={(value, delta, source, editor) => {
                                                this.setState({
                                                    emailTemplate: {
                                                        ...this.state.emailTemplate,
                                                        content: value
                                                    }
                                                }, () => this.handleEditorChange(value, delta, source, editor))
                                            }
                                            }
                                            value={emailTemplate.content}
                                            modules={modules}
                                        />
                                </div>

                                <EmailWorkVariables variabels= { ['name', 'companyName'] }
                                isNameLinkPersist={ isNameLinkPersist } onChangeVariables={this.onChangeVariables}
                                emailTemplate={ emailTemplate } checkedVariables = { checkedVariables }
                                                    listVariables={listVariables} variablesTemplate={emailTemplate.variablesTemplate}
                                                    onSelectVariable={this.onSelectVariable}
                                                    onDisabledSelectVariable={this.onDisabledSelectVariable}
                                />
                            </div>
                        }
                    </form>

                    {/*<EmailWorkVariables variabels= { ['name', 'companyName'] }*/}
                    {/*                    isNameLinkPersist={ isNameLinkPersist } onChangeVariables={this.onChangeVariables}*/}
                    {/*                    emailTemplate={ emailTemplate } checkedVariables = { checkedVariables } />*/}

                    <EmailWorkVariablesUsed variables= { ['name', 'companyName'] } variablesTemplate={emailTemplate.variablesTemplate} handleChangeEmailTestTo={ this.handleChangeEmailTestTo }
                                            emailTestTo={ emailTestTo } onTestEmailTemplateWorkflow={ this.onTestEmailTemplateWorkflow }
                                            emailTemplate={ emailTemplate }
                                            onStatusActiveEdit={ this.props.onStatusActiveEdit }
                                            onResetEmailTemplateWorkflow={ this.onResetEmailTemplateWorkflow }
                                            getEmailsTemplate={ getEmailsTemplate }
                                            listEmailTemplate={ listEmailTemplate }
                                            stopTestEmailTemplate={ stopTestEmailTemplate }/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientId: state.subscriptionReducer.subscription.subscription.client.id,
})

export default connect(mapStateToProps)(EmailWorkTemplate)